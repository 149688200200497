import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import InputBase from '@mui/material/InputBase';
import SearchBox from '../Utils/SearchBox';
import SelectAutoWidth from '../Utils/DropdownStandard';
import DirectionIcon from '../Utils/DirectionIcon';
import { Grid } from "@mui/material";
import { styled, alpha, createTheme, ThemeProvider } from "@mui/material/styles"; //TP-5488

const darkTheme = createTheme({
    palette: {
      primary: {
        main: '#384571',
      },
    },
    typography: {
      fontFamily: "Titillium Web",
    }
  });
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const ExpiryCheckbox = (props) => {
    return <div>
      <label className="col-xs-3 disable-pointer-event" htmlFor={props.id}>{props.label}&nbsp;</label>
      <label className="col-xs-4 col-xs-offset-2 switch">
        <input type="checkbox" id={props.id}
        name={props.id}
        onChange={props.toggleExpiry}
        checked={props.value}/>
        <span className="slider round"></span>
      </label>
    </div>
  }

export default function SearchAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{  
          style: {  
            width: 400,  
          },  
      }}
    >
      <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
          <ExpiryCheckbox {...props.showExpiry} label={"Show All"} toggleExpiry={props.toggleExpiry}/>
      </MenuItem>       
      <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={10} md={10}>
            <SelectAutoWidth  
              //sortFunction={(field, direction)=>props.customSortCards(field, direction)} 
              setSortColumn={props.setSortColumn} 
              columns={props.sortcolumns} labelText="Sort By"
            />
          </Grid>
          <Grid item xs={2} md={2}>
            <DirectionIcon  
              setSortDir={props.setSortDir}
            />
          </Grid>
        </Grid>
      </MenuItem>     
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 1, ml: 1, mr: 1}}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static" color="primary" sx={{borderRadius: "8px" }}>
            <Toolbar>  
                <Typography
                      variant="h6"
                      
                      component="div"
                      sx={{ flexGrow: 0.1, display: { xs: 'block', sm: 'block' }, paddingTop: "6px" }}
                  >
                      <i onClick={()=>props.toggleContainer()} className="fa fa-table switch-icons" style={{color: 'white'}}></i>
                  </Typography>

                  <Typography 
                      sx={{ flexGrow: {xs: 0.1, sm: 0.05, md: 0.05, lg: 0.1, xl: 0.25}, display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'block' }, marginLeft:{ xs: "70px", sm: "30px", md: "350px", lg: "550px", xl: "450px"},  align:"right" }}
                      component="div"
                  >
                      <SearchBox keyPressFn={props.keyPress} labelText="Search Customers"/>
                  </Typography>
                
                  <Typography 
                      sx={{ flexGrow: 0.2, display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' }, paddingTop: "9px"}}
                      component="div"
                  >
                      <ExpiryCheckbox {...props.showExpiry} label={"Show All"} toggleExpiry={props.toggleExpiry}/>
                  </Typography>
                  <Typography 
                      sx={{ flexGrow: 0.25, display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' }, marginTop: "-13px"}}
                      component="div"
                  >
                       <SelectAutoWidth  
                          //sortFunction={(field, direction)=>props.customSortCards(field, direction)} 
                          setSortColumn={props.setSortColumn} 
                          columns={props.sortcolumns} labelText="Sort By"
                      />
                  </Typography>                
                  <Typography 
                      sx={{ flexGrow: 0.1, display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' }, marginLeft:{ lg: "45px", xl: "35px"}/* , paddingTop: "3px" */}}
                      component="div"
                  >
                      <DirectionIcon  
                          setSortDir={props.setSortDir}
                      />
                  </Typography>
                  <Box noWrap sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'none' }}}>
                      <IconButton
                        size="large"
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={handleMobileMenuOpen}
                        color="inherit"
                      >
                        <MoreIcon />
                      </IconButton>
                  </Box>
            </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </ThemeProvider>
    </Box>
  );
}