const encryptionAlgo = 'aes-128-cbc';
const encryptionPassword = '619bf494133645e2';
const encryptionIv = 'c5d7d8f5d59fd3e4';
const crypto = require('crypto');

export const encryptData = (text) => {
    try{
        const cipher = crypto.createCipheriv(encryptionAlgo, encryptionPassword, encryptionIv)//FQ3-417
        let crypted = cipher.update(text,'utf8','hex')
        crypted += cipher.final('hex');
        return crypted;
    }catch(error){
        console.error(error);
        return ''
    }
}