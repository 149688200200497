import React from "react";
import DashboardHeader from "./dashboardHeader.js";
import Footer from "./footer.js";
import moment from "moment";
import RegTrial from "./vendorTrial_tab/vendorTrial";
import EnterpriseForm from "./enterprise_tab/enterpriseForm";
import superagent from "superagent";
import UsersTab from "./users_tab/users";
import HomeTab from "./analytics/home";
import Customers from "./customers_tab/customers";
import PendingApprovalsTab from "./approvals_tab/pendingApprovals"; //TP-4921
import CustomerDtlsTab from "./customers_tab/customerDetail";
import BreadcrumbView from "./breadcrumbView";
import Licenses from "./analytics/licenses";
import UserDetails from "./users_tab/userDetail";
import GroupsTab from "./groups_tab/groups";
import GroupDetail from "./groups_tab/groupDetail";
import socketIOClient from "socket.io-client";
import SocketDiagnosticDetails from "./SocketConnection/SocketDiagnosticDetails";
import JobDetails from "./jobTabs/jobDetails";
import Flash from "./Flash/flash";
import Bus from './Flash/Bus';
import "chartjs-plugin-labels";
import "chartjs-plugin-datalabels";
import CustomerAnalytics from './customerAnalytics/customerAnalytics';
import {encryptData} from './cryptography';
import { connect } from "react-redux";
import { fetchAllGroupsList } from "./redux/Group/GroupActions";
import { fetchAllInvitesList } from "./redux/Invite/InviteAction";
import { fetchAllCustomers } from './redux/Customer/CustomerActions';


window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));

let enterpriseData = [],
  proData = [],
  basicData = [],
  trialData = [],
  activeData = [],
  enterpriseActiveData = [],
  enterpriseRequestedData = [],
  othersData = [],
  pastDueData = [],
  trialApprovedData = [],
  trialPendingApprovedData = [],
  trialingData = [],
  assignedData = [],
  unAssignedData = [],
  expertUsersData = [],
  adminUsersData = [],
  techUsersData = [],
  unRegisterData = [],
  costCustomersData = [];

const mainTextColor = "white";
const selectedCustomers = (value) => {
  switch (value) {
    case "Enterprise ":
      return enterpriseData;
    case "Basic":
      return basicData;
    case "Pro":
      return proData;
    case "Vendor Trial":
      return trialData;
    case "Prepaid":
      return activeData;
    case "Enterprise":
      return enterpriseActiveData;
    case "Enterprise Requested":
      return enterpriseRequestedData;
    case "Past Due":
      return pastDueData;
    case "Prepaid Trialing":
      return trialingData;
    case "Trial Approved":
      return trialApprovedData;
    case "Trial Pending Approval":
      return trialPendingApprovedData;
    case "Others":
      return othersData;
    default:
      return [];
  }
};

const selectedUsers = (value) => {
  switch (value) {
    case "Admins":
      return adminUsersData;
    case "Experts":
      return expertUsersData;
    case "Technicians":
      return techUsersData;
    case "Invitees":
      return unRegisterData;
    default:
      return [];
  }
};

const USER_ROLE = {
  read_pro: "Limited Pro/Basic Account Access",
  read_ent: "Limited Enterprise Account Access",
  read_all: "Limited Customers Account Access",
  read_my_account: "My Account Limited Access", /**TP-4952*/
  write_pro: "Pro/Basic Account Access",
  write_ent: "Enterprise Account Access",
  write_all: "All Customers Account Access",
  write_my_account: "My Account Admin Access", /**TP-4952*/
  admin: "Admin Access",
  group_admin : "Group Admin",
  none: "No",
};
const PAGINATION_PER_PAGE = 10;

const defaultLabelStyle = {
  displayColors: false,
  fontColor: mainTextColor,
  fontSize: window.matchMedia("(max-width: 992px)").matches ? 12 : 16,
  fontStyle: "normal",
  fontFamily: "Titillium Web",
  backgroundColor: mainTextColor,
  titleFontSize: 14,
  titleFontColor: "black",
  titleFontFamily: "Titillium Web",
  bodyFontColor: "black",
  bodyFontSize: 12,
  bodyFontFamily: "Titillium Web",
  textMargin: 7,
};
const labelPlugins = [
  {
    ...defaultLabelStyle,
    render: (args) => {
      return args.label;
    },
    position: "outside",
  },
  {
    ...defaultLabelStyle,
    render: (args) => {
      return args.value;
    },
    fontColor: "black",
  },
];
const labelPlugins_small = [
  {
    ...defaultLabelStyle,
    fontSize: 12,
    render: (args) => {
      return args.label;
    },
    position: "outside",
  },
  {
    ...defaultLabelStyle,
    fontSize: 12,
    render: (args) => {
      return args.value;
    },
    fontColor: "black",
  },
];

const ErrorMessage = (props) => (
  <div className="alert alert-danger">
    <div className="close" data-dismiss="alert" aria-label="close">
      &times;
    </div>
    <small>{props.errorMessage}</small>
  </div>
);

function calculateCost(row) {
  let totalcost = 0;
  row.purchases.forEach((purchase, i) => {
    if (2 === purchase.plan_id) {
      totalcost = totalcost + 240 * purchase.total_licences;
      // console.log(purchase.total_licences,240);
    } else if (3 === purchase.plan_id) {
      totalcost = totalcost + 600 * purchase.total_licences;
      // console.log(purchase.total_licences,600);
    } else if (row.total_price) {
      // for enterprise account
      totalcost = row.total_price;
      // console.log(purchase.total_licences,240);
    } else if (undefined !== row.price) {
      totalcost = purchase.total_licences * row.price;
      // console.log(purchase.total_licences,240);
    } else {
      totalcost = "";
      // console.log("plan_id: "+purchase.plan_id);
    }
  });
  return totalcost;
}
function effectiveCost(row) {
  let totalcost = 0;
  if(row.purchases){
    row.purchases.forEach((purchase, i) => {
      if (!purchase.is_cancelled) {
        if (2 === purchase.plan_id)
          totalcost = totalcost + 240 * purchase.total_licences;
        else if (3 === purchase.plan_id)
          totalcost = totalcost + 600 * purchase.total_licences;
        else if (row.total_price) totalcost = row.total_price;
        else if (undefined !== row.price)
          totalcost = purchase.total_licences * row.price;
        else totalcost = "";
      }
    });
  }
  
  return totalcost;
}
const customTooltips = {
  ...defaultLabelStyle,
  enabled: false,
  callbacks: {
    title: function (tooltipItem, data) {
      return data["labels"][tooltipItem[0]["index"]];
    },
  },
  custom: function (tooltipModel) {
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");
    if (null === tooltipEl) return;
    // Hide if no tooltip
    if (0 === tooltipModel.opacity && undefined !== tooltipEl) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltipModel.body) {
      tooltipEl.innerHTML =
        '<p style="text-align: center;">' + tooltipModel.title[0] + " List</p>";
      tooltipEl.innerHTML += '<table class="hoverTable"></table>';
      let innerHtml = "<thead><tr>";
      innerHtml += "<th>Account Id</th>";
      innerHtml += "<th>Name</th>";
      innerHtml += "<th>Licenses</th>";
      innerHtml += "<th>Cost</th>";
      innerHtml += "<th>Last used by</th>";
      innerHtml += "<th>Last used on</th>";
      innerHtml += "<th>Usage</th>";
      innerHtml += "</tr></thead><tbody>";

      let tableBody = selectedCustomers(tooltipModel.title[0]);
      tableBody.forEach((body, index) => {
        innerHtml += "<tr>";
        innerHtml +=
          '<td style="word-wrap: anywhere">' + body.account_id + "</td>";
        innerHtml +=
          '<td style="word-wrap: anywhere">' +
          (null === body.company_name ? "" : body.company_name) +
          "</td>";
        innerHtml +=
          '<td style="text-align: center">' + body.total_licences + "</td>";
        innerHtml +=
          '<td style="text-align: center">' + calculateCost(body) + "</td>";
        innerHtml +=
          "<td>" +
          (null !== body.last_used_by ? body.last_used_by : "") +
          "</td>";
        innerHtml +=
          "<td>" +
          (null !== body.last_used_on
            ? moment(body.last_used_on).format("DD/MM/YY")
            : "") +
          "</td>";
        innerHtml +=
          "<td>" + (body.total_used + "/" + body.total_max) + "</td>";
        innerHtml += "</tr>";
      });
      innerHtml += "</tbody>";

      let tableRoot = tooltipEl.querySelector("table");
      tableRoot.innerHTML = innerHtml;
    }

    // `this` will be the overall tooltip
    // let position = this._chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.left = "30vw";
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.opacity = 1;
    // tooltipEl.style.left = position.width-100 + 'px';
    // tooltipEl.style.top = tooltipEl.style.top+10+'px';
  },
};

const customRoleTooltips = {
  ...defaultLabelStyle,
  enabled: false,
  callbacks: {
    title: function (tooltipItem, data) {
      return data["labels"][tooltipItem[0]["index"]];
    },
  },
  custom: function (tooltipModel) {
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");
    if (null === tooltipEl) return;
    // Hide if no tooltip
    if (0 === tooltipModel.opacity && undefined !== tooltipEl) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltipModel.body) {
      tooltipEl.innerHTML =
        '<p style="text-align: center;">' + tooltipModel.title[0] + " List</p>";
      tooltipEl.innerHTML += '<table class="hoverTable"></table>';
      let innerHtml = "<thead><tr>";
      innerHtml += "<th>Name</th>";
      innerHtml += "<th>Email</th>";
      innerHtml += "<th>Used Mins</th>";
      innerHtml += "<th>Role</th>";
      innerHtml += "<th>Last active on</th>";
      innerHtml += "</tr></thead><tbody>";

      let tableBody = selectedUsers(tooltipModel.title[0]);
      tableBody.forEach((body, index) => {
        innerHtml += "<tr>";
        innerHtml +=
          '<td style="word-wrap: anywhere">' +
          (body.first_name + " " + body.last_name) +
          "</td>";
        innerHtml += '<td style="text-align: center">' + body.email + "</td>";
        innerHtml +=
          '<td style="text-align: center">' +
          (body.max_monthly_minutes -
            body.available_monthly_minutes +
            body.overage_monthly_minutes) +
          "</td>";
        innerHtml += "<td>" + typePane(body) + "</td>";
        innerHtml +=
          "<td>" +
          (null !== body.last_accessed_on
            ? moment(body.last_accessed_on).format("DD/MM/YY")
            : "") +
          "</td>";
        innerHtml += "</tr>";
      });
      innerHtml += "</tbody>";

      let tableRoot = tooltipEl.querySelector("table");
      tableRoot.innerHTML = innerHtml;
    }

    // `this` will be the overall tooltip
    // let position = this._chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.left = "35vw";
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.opacity = 1;
    // tooltipEl.style.left = position.width-100 + 'px';
    // tooltipEl.style.top = tooltipEl.style.top+80+'px';
    // tooltipEl.style.pointerEvents = 'none';
    // tooltipEl.style.overflowY = 'scroll';
  },
};

function prepaidAccess() {
  let flag = sessionStorage.getItem("userDbRole");
  if (
    flag === "write_pro" ||
    flag === "write_all" ||
    flag === "admin" ||
    flag === "read_pro" ||
    flag === "read_all" ||
    flag === "read_my_account"
  ) {
    return true;
  } else {
    return false;
  }
}
function enterpriseAccess() {
  let flag = sessionStorage.getItem("userDbRole");
  if (
    flag === "read_ent" ||
    flag === "admin" ||
    flag === "write_ent" ||
    flag === "write_all" ||
    flag === "read_all" ||
    flag === "write_my_account" ||
    flag === "read_my_account"
  ) {
    return true;
  } else {
    return false;
  }
}
const prepaidWriteAccess = () => {
  switch (sessionStorage.userDbRole) {
    case "admin":
    case "write_all":
    case "write_pro":
      return true;
    default:
      return false;
  }
};
const enterpriseWriteAccess = () => {
  switch (sessionStorage.userDbRole) {
    case "admin":
    case "write_all":
    case "write_ent":
      return true;
    default:
      return false;
  }
};
const myWriteAccountAccess = () => {
  switch (sessionStorage.userDbRole) {
    case "write_ent":
    case "write_my_account":
      return true;
    default:
      return false;
  }
};
const myReadAccountAccess = () => {
  switch (sessionStorage.userDbRole) {
    case "read_my_account":
      return true;
    default:
      return false;
  }
};

function typePane(item) {
  if (undefined !== item.invitehash) {
    return "<div class='user-type-flag' style='background-color:#777777;font-weight:400;color:white'>Invitee</div>";
  } else {
    return item.isadmin
      ? item.isexpert
        ? "<div class='user-type-flag' style='background-color:#89783f;font-weight:400;color:white'>Admin/Expert</div>"
        : "<div class='user-type-fla' style='background-color:#89783f;font-weight:400;color:white'>Admin</div>"
      : item.isexpert
      ? "<div class='user-type-flag' style='background-color:#485890;font-weight:400;color:white'>Expert</div>"
      : !item.isadmin && !item.isexpert
      ? "<div class='user-type-flag' style='background-color:#71a95a;font-weight:400;color:white'>Technician</div>"
      : "";
  }
}
/*function getRandomRolor() {
  var letters = '3456789ABCDEF'.split('');
  var color = '#';
  for (var i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 10)];
  }
  return color;
}*/

 class DashBoard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbItems: [],
      breadcrumbData: [],
      allCustomers: [],
      globalHunt: [],
      tabIndex: 0,
      pieChart1: {},
      pieChart2: {},
      pieChart3: {},
      pieChart4: {},
      pieChart5: {},
      pieChart6: {},
      pieChart7: {},
      barChart: {},
      selectedCustomers: {},
      selectedLabel: "",
      customerDtls: {
        credits: [],
        users: [],
      },
      licenses: {},
      userDtls: {},
      groupDtls: {},
      users: [],
      groups: [],
      userLabel: "",
      inviteList: [],
      groupList: [],
      serverErrors: [],
      sum_of_total_licenses: 0,
      avg_monthly_cost: 0,
      filterBy: null,
      filterValue: null,
      job: {},
      accountUsers: [],
      logginStatus: true
    };
    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];
    this.licenseRef = React.createRef();
    this.socket = {
      on: () => {},
      emit: () => {},
    };

    // this.warn = this.warn.bind(this);
    this.callLogOut = this.callLogOut.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setTimeout();

  }

  clearTimeout() {
    // if (this.warnTimeout) clearTimeout(this.warnTimeout);

    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    // this.warnTimeout = setTimeout(this.warn, 16 * 1000);

    this.logoutTimeout = setTimeout(this.callLogOut, 900000);  //15 minutes = 900 seconds = 900000 milliseconds) 
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  // warn() {
  //   alert("You will be logged out automatically in 1 minute.");
  // }


  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  componentDidMount() {
    this.refresh();
    this.initSocket();
    
  }

  componentWillReceiveProps(nextProps){
    const {groups,invites,customers} = nextProps;
    this.setState({
      groupList:groups.groups,
      inviteList:invites.invites,
      allCustomers:customers.allCustomers
    })
    this.setState({ isLoading: customers.isFetching });
    if(!customers.isFetching && customers.isFirstPageLoad && customers.allCustomers.length  &&
      sessionStorage.getItem("userDbRole") !== "group_admin"){
      this.breadcrumbTail("home");
    }else if(!groups.isFetching && customers.isFirstPageLoad && customers.allCustomers.length && 
      sessionStorage.getItem("userDbRole") === "group_admin"){
        const {groupList} = this.state;
        this.breadcrumbTail('groups',groupList,'All Groups');
    }else if(customers.errorMessage){ //TP-943
        this.setServerError(customers.errorPayLoad, customers.errorMessage);
    } 
  }

  refresh = () => {
    const { fetchAllCustomers,fetchAllGroupsList,fetchAllInvitesList } = this.props;
      fetchAllCustomers();
      fetchAllGroupsList();
      fetchAllInvitesList()
  }

  initSocket = () => {
    this.socket = socketIOClient(sessionStorage.getItem("url"), {
      query: { token: sessionStorage.getItem("userToken") },
      transports: ["websocket"],
      path: "/ws/socket.io",
    });


    if(sessionStorage.getItem("allowLogMeIn") === "true"){
        const json = {
          userIsDeleted:false,
          email:sessionStorage.getItem("userEmail"),
          loginTimeStamp:sessionStorage.getItem("lastAccessedOn")
        }
        console.log("Emiting the call out signal Request Param:"+JSON.stringify(json));
        this.socket.emit('log-me-out', json); 
    }
    
    this.socket.on("log-me-out",this.socketLogeMeOut);
      console.log('Socket Connection Established');
  };

  componentWillUnmount(){
    this.socket.removeEventListener("Log Me out",this.socketLogeMeOut)
  }

  socketLogeMeOut = ({email,loginTimeStamp}) => {

    if (sessionStorage.getItem("userEmail") === email && 
              new Date(loginTimeStamp) > new Date(sessionStorage.getItem("lastAccessedOn"))) {
         sessionStorage.removeItem("userToken");
         window.location = "/"
         return;
      }
    }

  breadcrumbTail = async (target, row, label, filterBy, filterValue) => {
    let menuItems = [...this.state.breadcrumbItems];
    let breadcrumbData = {};

    if ("home" === target) {
      this.createHomeDataSets(this.state.allCustomers,this.state.groupList);//TP-749
      this.feedGlobalHunt();
      menuItems = [{ id: target, label: "" }];
      breadcrumbData = [
        {
          id: target,
          desc: (
            <HomeTab
              params={this}
              prepaidWriteAccess={prepaidWriteAccess()}
              enterpriseWriteAccess={enterpriseWriteAccess()}
              myWriteAccountAccess={myWriteAccountAccess()}
              myReadAccountAccess={myReadAccountAccess()}
            />
          ),
        },
      ];
    } else if ("customers" === target) {
      if (undefined === row) {
        label = this.state.selectedLabel;
        row = filterValue && this.state.selectedCustomers.length ? this.state.selectedCustomers.filter(cust=> cust.account_id !== filterValue) :this.state.selectedCustomers;
        this.onBreadCrumbClick(target, menuItems);
      }
      this.setState({
        selectedLabel: label,
        selectedCustomers: row,
      });
      menuItems.push({ id: target, label: label && label === "Others" ? "Other" : label + " Customers" });
      breadcrumbData = [
        {
          id: target,
          desc: (
            <Customers
              customers={row}
              pagination={PAGINATION_PER_PAGE}
              params={this}
            />
          ),
        },
      ];
    } else if ("customer" === target) {
      if (undefined === row) {
        row = this.state.customerDtls;
        label = null !== row.company_name ? row.company_name : row.account_id;
        this.onBreadCrumbClick(target, menuItems);
      }

      this.setState({ customerDtls: row });

      menuItems.push({ id: target, label: label });
      breadcrumbData = [
        {
          id: target,
          desc: (
            <CustomerDtlsTab
              prepaidWriteAccess={prepaidWriteAccess()}
              enterpriseWriteAccess={enterpriseWriteAccess()}
              myWriteAccountAccess={myWriteAccountAccess()}
              params={this}
              viewDetails={row}
            />
          ),
        },
      ];
    } else if ("licenses" === target) {
      // TP-5749     
      if (undefined === row) {
        row = this.state.licenses;
        label = "Licenses";
        this.onBreadCrumbClick(target, menuItems);
      }
      if (row.users.length > 0 ){
        row.users = row.users.filter((user) => (user.isexpert || user.isadmin && user.isexpert || !user.isadmin && !user.isexpert));
        } 
      this.setState({ licenses: row });

      await this.createLicenseDataSets(row);

      menuItems.push({ id: target, label: label });
      breadcrumbData = [
        {
          id: target,
          desc: (
            <Licenses
              params={this}
              licenses={row}
              pieChart1={this.state.pieChart3}
              pieChart2={this.state.pieChart4}
              barChart={this.state.barChart}
              customerDtls={this.state.customerDtls}
              ref={this.licenseRef}
            />
          ),
        },
      ];
    } else if ("user" === target) {
      if (undefined === row) {
        //below condition added to get updated user info if user id matches
        row = this.state.userDtls && this.state.accountUsers ? this.state.accountUsers.filter(usr=> usr.id === this.state.userDtls.id).pop() : this.state.userDtl;
        label = row.first_name;
        filterBy = this.state.accountUsers;
        this.onBreadCrumbClick(target, menuItems);
      }
      var customerDtls = this.state.customerDtls;
      if ((!customerDtls.behaviour) || (this.state.customerDtls.account_id !== row.account_id)) {
        customerDtls = this.state.allCustomers.filter(
          (ele) => ele.account_id === row.account_id
        )[0];
      }

      if (filterBy !== undefined && !filterBy)
        filterBy = this.state.userList.filter(
          (value) => value.account_id === row.account_id
        );

      this.setState({ userDtls: row, accountUsers: filterBy });

      menuItems.push({ id: target, label: label });

      breadcrumbData = [
        {
          id: target,
          desc: (
            <UserDetails
              userRole={USER_ROLE}
              viewDetails={row}
              socket={this.socket}
              customerDtls={customerDtls}
              groups={this.state.groupList}
              filterValue={filterBy}
              params={this}
            />
          ),
        },
      ];
    } else if ("users" === target) {
      if (undefined === row) {
        row = this.state.users;
        label = this.state.userLabel;
        filterBy = this.state.filterBy;
        filterValue = this.state.filterValue;
        this.onBreadCrumbClick(target, menuItems);
      }

      this.setState({
        userLabel: label,
        users: row,
        filterBy: filterBy,
        filterValue: filterValue,
      });

      menuItems.push({ id: target, label: label });
      breadcrumbData = [
        {
          id: target,
          desc: (
            <UsersTab
              users={row}
              pagination={PAGINATION_PER_PAGE}
              params={this}
              userLabel={label}
              filterBy={filterBy}
              filterValue={filterValue}
            />
          ),
        },
      ];
    } else if ("addTrial" === target) {
      menuItems.push({ id: target, label: label });
      breadcrumbData = [{ id: target, desc: <RegTrial /> }];
    } else if ("addEnt" === target) {
      menuItems.push({ id: target, label: label });
      breadcrumbData = [
        {
          id: target,
          desc: <EnterpriseForm modify="create_enterprise" viewDetails={row} />,
        },
      ];
    } else if ("pendApprvl" === target) {
      /**TP-4921 */
      if (undefined === row) {
        label = this.state.selectedLabel;
        row = filterValue && this.state.selectedCustomers.length ? this.state.selectedCustomers.filter(cust=> cust.account_id !== filterValue) :this.state.selectedCustomers;
        this.onBreadCrumbClick(target, menuItems);
      }
      this.setState({
        selectedLabel: label,
        selectedCustomers: row,
      });
      menuItems = [{ id: "home", label: "home" }];  // TP-6475
      menuItems[1] = ({ id: target, label: label });  // TP-6475
      breadcrumbData = [
        {
          id: target,
          desc: (
            <PendingApprovalsTab
              customers={row}
              pagination={PAGINATION_PER_PAGE}
              params={this}
            />
          ),
        },
      ];
    } else if ("modEnt" === target) {
      menuItems.push({ id: target, label: label });
      breadcrumbData = [
        {
          id: target,
          desc: <EnterpriseForm modify="enterprise" viewDetails={row} />,
        },
      ];
    } else if ("modPrepaid" === target) {
      menuItems.push({ id: target, label: label });
      breadcrumbData = [
        {
          id: target,
          desc: <EnterpriseForm modify="prepaid" viewDetails={row} />,
        },
      ];
    } else if ("groups" === target) {
      if (undefined === row) {
        row = this.state.groups;
          // added to get label name from previous array instead of new name TP-749
        let targetBreadData =  this.state.breadcrumbItems.filter((bread)=>bread.id ===target)
        label = targetBreadData && targetBreadData.length > 0 ? targetBreadData.pop().label :"Groups";
        this.onBreadCrumbClick(target, menuItems);
      }

      this.setState({ groups: row });

       let groupUsersList = this.state.userList ? this.state.userList.filter((user) => row[0] && row[0].account_id === user.account_id) : '';

      menuItems.push({ id: target, label: label });
      breadcrumbData = [
        {
          id: target,
          desc: (
            <GroupsTab
              groups={row}
              pagination={PAGINATION_PER_PAGE}
              params={this}
              userInfo={groupUsersList}
            />
          ),
        },
      ];
    } else if ("group" === target) {
      if (undefined === row) {
        row = this.state.groupDtls;
        label = row.group_name;
        this.onBreadCrumbClick(target, menuItems);
      }

      this.setState({ groupDtls: row });

      menuItems.push({ id: target, label: label });
      breadcrumbData = [
        {
          id: target,
          desc: (
            <GroupDetail
              viewDetails={row}
              enterpriseWriteAccess={enterpriseWriteAccess()}
              params={this}
            />
          ),
        },
      ];
    } else if ("socket" === target) {
      if (undefined === row) {
        row = this.state.users;
        label = this.state.userLabel;
        filterBy = this.state.filterBy;
        filterValue = this.state.filterValue;
        this.onBreadCrumbClick(target, menuItems);
      }

      this.setState({
        userLabel: label,
        users: row,
        filterBy: filterBy,
        filterValue: filterValue,
      });

      menuItems.push({ id: target, label: label });
      breadcrumbData = [
        {
          id: target,
          desc: (
            <SocketDiagnosticDetails
              users={row}
              pagination={PAGINATION_PER_PAGE}
              params={this}
              userLabel={label}
              filterBy={filterBy}
              filterValue={filterValue}
              socket={this.socket}
              allCustomers={this.state.allCustomers}
            />
          ),
        },
      ];
    } else if ("jobDetails" === target) {
      if (undefined === row) {
        row = this.state.job;
        this.onBreadCrumbClick(target, menuItems);
      }
      this.setState({ job: row });
      menuItems.push({ id: target, label: label ? row.name : "" });
      breadcrumbData = [
        {
          id: target,
          desc: (
            <JobDetails
              params={this}
              selectedJob={row}
              viewDetails={this.state.accountUsers}
              customerDtls={filterValue}
            />
          ),
        },
      ];
    }else if('custAnalytics'=== target){
      menuItems.push({id:target,label:label});
      breadcrumbData = [
        {
          id:target,
          desc:<CustomerAnalytics customerInfo={row} />
        }
      ]
    }

    this.setState({
      breadcrumbItems: menuItems,
      breadcrumbData: breadcrumbData,
      tabIndex: menuItems.length - 1,
    });
  };

  onBreadCrumbClick = (target, menuItems, findPos) => {
    findPos = this.state.breadcrumbItems.findIndex((m) => m.id === target);
    this.state.breadcrumbItems.forEach((b, i) => {
      if (i >= findPos) {
        menuItems.pop();
      }
    });
  };

  setServerError = (error, msg) => {
    if (undefined === error.status) {
      msg = "Internet is not connected, please try again after connecting network";
    } else if (401 === error.status) {
      msg = "Session token experied, Please re-login";
      this.callLogOut();
    } else if (403 === error.status) {
      msg = "You are not authorized";
      this.callLogOut();
    }
    this.setState({
      serverErrors: [...this.state.serverErrors, msg],
    });
  };

  getSpecificCustomerDetails = (allCustInfo, accountId) => {
    let particularInfo = allCustInfo.filter(function (cust) {
      return cust.account_id === accountId;
    });
    return particularInfo;
  };

  getAllCustomers = async () => {
    if (null != sessionStorage.getItem("userToken")) {
      this.setState({ isLoading: true });
      await superagent
        .get(sessionStorage.getItem("url") + "/api/dash/getAllCustomers")
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .then((response) => response)
        .then(async (data) => {
          if (null != data.body) {
            let customerInfo = data.body;
            this.setState({ allCustomers: customerInfo });
            let promises = [];
            customerInfo.forEach((item) => {
              promises.push(this.getEnterpriseDetails(item));
              promises.push(this.getCustomerDetails(item));
              promises.push(this.getPurchaseDetails(item));
              promises.push(this.getUsersList(item));
            });
            // promises.push(this.getInviteList());
            // promises.push(this.getGroupList());
            /* Promise.all(promises).then((a) => {
              //Initialized at the last
              this.breadcrumbTail("home");
              this.setState({ isLoading: false });
            }); */
            //  console.log(data.body);
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          this.setServerError(error, "Not able to fetch customers list");
        });
    }
  };

  getEnterpriseDetails = async (item) => {
    let url = "/api/getEnterpriseCustomerDetailsByAccountId/";
    if (enterpriseAccess() && "enterprise" === item.subscription_status) {
      await superagent
        .get(sessionStorage.getItem("url") + url + item.account_id)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .then((response) => response)
        .then((data) => {
          if (null != data.body) {
            data.body.entAccess = true;
            this.setState((prevState) => ({
              allCustomers: prevState.allCustomers.map((obj) =>
                obj.account_id === item.account_id
                  ? Object.assign(obj, { ...item, ...data.body })
                  : obj
              ),
            }));
          }
        })
        .catch((error) => {
          if (undefined !== error.status) {
            this.setServerError(
              error,
              item.account_id + " enterprise account not found"
            );
          }
        });
    }
  };

  getPurchaseDetails = async (item) => {
    if (null != sessionStorage.getItem("userToken")) {
      let apiUrl = "/api/dash/getPurchasesByAccountId/" + item.account_id;
      await superagent
        .get(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .then((response) => response)
        .then(async (data) => {
          if (null !== data.body) {
            let total_used = 0;
            let total_max = 0;
            let credits = [];
            let total_licences = 0;
            let plan_id = null;

            data.body.forEach((purchase, i) => {
              if (i === 0) plan_id = purchase.plan_id;
              purchase.credits.forEach((credit) => {
                total_max = total_max + credit.max_monthly_minutes;
                total_used =
                  total_used +
                  (credit.max_monthly_minutes -
                    credit.available_monthly_minutes);
                credits.push(credit);
              });
              total_licences = total_licences + purchase.total_licences;
            });
            item.plan_id = plan_id;
            item.total_licences = total_licences;
            item.credits = credits;
            item.total_max = total_max;
            item.total_used = total_used;
            item.purchases = data.body;

            this.setState((prevState) => ({
              allCustomers: prevState.allCustomers.map((obj) =>
                obj.account_id === item.account_id
                  ? Object.assign(obj, { ...item })
                  : obj
              ),
            }));
          }
        })
        .catch((error) => {
          if (undefined !== error.status) {
            this.setServerError(
              error,
              item.account_id + " purchase details not found"
            ); //404
          }
        });
    }
  };

  getCustomerDetails = async (item) => {
    if (null != sessionStorage.getItem("userToken")) {
      let apiUrl = "/api/dash/getCustomerByAccountId/" + item.account_id;
      await superagent
        .get(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .then((response) => response)
        .then((data) => {
          if (null != data.body) {
            this.setState((prevState) => ({
              allCustomers: prevState.allCustomers.map((obj) =>
                obj.account_id === item.account_id
                  ? Object.assign(obj, { ...item, ...data.body })
                  : obj
              ),
            }));
          }
        })
        .catch((error) => {
          if (undefined !== error.status) {
            this.setServerError(
              error,
              item.account_id + " customer account is not found"
            ); //422
          }
        });
    }
  };

  getUsersList = async (item) => {
    if (null != sessionStorage.getItem("userToken") && item.account_id) {
      await superagent
        .get(sessionStorage.getItem("url") + "/api/getusers/" + item.account_id)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .then((response) => response)
        .then((data) => {
          if (null != data.body) {
            //TP-809
            let last_used_by = null,last_used_on = null,last_used_by_Obj = null;
            // console.log("*************"+item.account_id+"*********");
            let dt = data.body.map((user) => {
              if (null !== user.last_accessed_on) {
                user.company_name = item.company_name;
                user.account_id = item.account_id;
                return user;
              } else return [];
            });
            if (dt.length > 0) {
              dt.sort((d1, d2) => {
                if (
                  moment(d2.last_accessed_on).isBefore(
                    moment(d1.last_accessed_on)
                  )
                ) {
                  return 1;
                } else if (
                  moment(d2.last_accessed_on).isAfter(
                    moment(d1.last_accessed_on)
                  )
                ) {
                  return -1;
                } else return 0;
              });
              last_used_on = dt[dt.length - 1].last_accessed_on;
              last_used_by = dt[dt.length - 1].first_name +" " +dt[dt.length - 1].last_name;
              last_used_by_Obj = dt[dt.length -1];
            }
            let params = {
              ...item,
              users: data.body,
              last_used_by: last_used_by,
              last_used_on: last_used_on,
              last_used_by_Obj:last_used_by_Obj
            };
            this.setState((prevState) => ({
              allCustomers: prevState.allCustomers.map((obj) =>
                obj.account_id === item.account_id
                  ? Object.assign(obj, { ...params })
                  : obj
              ),
            }));

            const userInfo = data.body;
            let promises = [];
            userInfo.forEach((item) => {
              promises.push(this.getUserProfileSettings(item));
            });

            Promise.all(promises).then((a) => {
              //Initialized at the last
              this.breadcrumbTail("home");
              this.setState({ isLoading: false });
            });
          }
        })
        .catch((error) => {
          if (undefined !== error.status) {
            this.setServerError(error, "Not able to fetch users list");
          }
        });
    }
  };

  getUserProfileSettings = async (item) => {
    let url = "/api/user/getProfileData/";
    if (enterpriseAccess()) {
      await superagent
        .get(sessionStorage.getItem("url") + url + item.id)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .then((response) => response)
        .then((data) => {
          if (null != data.body) {
            data.body.entAccess = true;
            this.setState((prevState) => ({
              allCustomers: prevState.allCustomers.map((cust) =>
                cust.users.map((obj) => 
                    obj.id === item.id 
                      ? Object.assign(obj, { ...item, ...data.body }) 
                      : obj
                    )               
              ),
            }));
          }
        })
        .catch((error) => {
          if (undefined !== error.status) {
            this.setServerError(
              error,
              item.id + " user not found"
            );
          }
        });
    }
  };

  // getInviteList = async () => {
  //   if (null != sessionStorage.getItem("userToken")) {
  //     await superagent
  //       .get(
  //         sessionStorage.getItem("url") + "/api/dash/getUnregisteredInvitees"
  //       )
  //       .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
  //       .accept("application/json")
  //       .then((response) => response)
  //       .then(async (data) => {
  //         if (null != data.body) {
  //           let inviteDataList = data.body;
  //           inviteDataList.sort((d1, d2) => {
  //             if (
  //               moment(d1.last_location_report_on).isAfter(
  //                 moment(d2.last_location_report_on)
  //               )
  //             ) {
  //               return 1;
  //             } else if (
  //               moment(d1.last_location_report_on).isAfter(
  //                 moment(d2.last_location_report_on)
  //               )
  //             ) {
  //               return -1;
  //             } else return 0;
  //           });
  //           this.setState({ inviteList: inviteDataList });
  //         }
  //       })
  //       .catch((error) => {
  //         if (undefined !== error.status) {
  //           this.setServerError(
  //             error,
  //             "Not able to fetch unregistered users list"
  //           );
  //         }
  //       });
  //   }
  // };

  // getGroupList = async () => {
  //   if (null != sessionStorage.getItem("userToken")) {
  //     await superagent
  //       .get(sessionStorage.getItem("url") + "/api/dash/getAllGroups")
  //       .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
  //       .accept("application/json")
  //       .then((response) => response)
  //       .then((data) => {
  //         if (null != data.body) {
  //           let allGroupDataList = data.body;
  //           let groups = [];
  //             // added to ignore groups that has no id. TP-749
  //           allGroupDataList = allGroupDataList.filter(group => group.group_id); 
  //           allGroupDataList.forEach((group, index) => {
  //             let experts = [];
  //             let techs = [];
  //             let expertNames = "";
  //             let techNames = "";
  //             group.users.forEach((user, index) => {
  //               user.company_name = group.company_name;
  //               user.account_id = group.account_id;
  //               if (user.isexpert) {
  //                 expertNames =
  //                   expertNames +
  //                   " " +
  //                   user.first_name +
  //                   " " +
  //                   user.last_name +
  //                   ",";
  //                 experts.push(user);
  //               } else if (!user.isexpert && !user.isadmin) {
  //                 techNames =
  //                   techNames +
  //                   " " +
  //                   user.first_name +
  //                   " " +
  //                   user.last_name +
  //                   ",";
  //                 techs.push(user);
  //               }
  //             });
  //             group.experts = experts;
  //             group.techs = techs;
  //             group.expertNames = expertNames.slice(0, -1);
  //             group.techNames = techNames.slice(0, -1);
  //             groups.push(group);
  //           });
  //           groups.sort((d1, d2) => {
  //             if (
  //               moment(d1.last_active_on).isAfter(moment(d2.last_active_on))
  //             ) {
  //               return 1;
  //             } else if (
  //               moment(d1.last_active_on).isBefore(moment(d2.last_active_on))
  //             ) {
  //               return -1;
  //             } else return 0;
  //           });

  //           this.setState({ groupList: groups });
  //         }
  //       })
  //       .catch((error) => {
  //         if (undefined !== error.status) {
  //           this.setServerError(error, "Not able to fetch groups list");
  //         }
  //       });
  //   }
  // };

  callLogOut = () => {
    if (null != sessionStorage.getItem("userToken")) {
      let params = {
        email: encryptData(sessionStorage.getItem("userEmail").toLowerCase()),
        groupid: null,
        id: sessionStorage.getItem("userId"),
        isencrypted: true
      };
      this.setState({ isLoading: true });
      fetch(sessionStorage.getItem("url") + "/api/signout", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + sessionStorage.getItem("userToken")//TP-2411
        },
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({ isLoading: false,logginStatus :false });
          sessionStorage.clear();
          window.location.reload();
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          this.setServerError(
            error,
            "Something went wrong, try again sometime"
          );
        });
    }
  };

  // refresh = () => {
  //   //this.getAllCustomers();
  //   this.setState({ breadcrumbData: [] });
  //   setTimeout(() => this.setState({ serverErrors: [] }), 60000);
  // };


  createHomeDataSets = (customers,allGroupsList) => {
    let entFlag = enterpriseAccess(),
      prePaidFlag = prepaidAccess(),
      sum_of_total_monthly_cost = 0,
      //nonZero_cost_count = 0,
      sum_of_total_licenses = 0,
      total_available_credits = 0,
      userList = [],
      costLabels = [],
      costColorCombos = [],
      collectARMCData = [];

    enterpriseData = [];
    proData = [];
    basicData = [];
    trialData = [];
    activeData = [];
    enterpriseActiveData = [];
    enterpriseRequestedData = [];
    othersData = [];
    pastDueData = [];
    trialApprovedData = [];
    trialPendingApprovedData = [];
    trialingData = [];
    expertUsersData = [];
    // adminUsersData = [];
    techUsersData = [];
    unRegisterData = [];
    costCustomersData = [];

    let custPicked = customers.filter((c) => null !== c.last_used_on);
    let custNullPicked = customers.filter((c) => null === c.last_used_on);
    custPicked.sort((c1, c2) => {
      if (moment(c1.last_used_on).isAfter(moment(c2.last_used_on))) {
        return -1;
      } else if (moment(c1.last_used_on).isBefore(moment(c2.last_used_on))) {
        return 1;
      } else return 0;
    });

    customers = [...custPicked, ...custNullPicked];
    /*** Collecting data for Home Tab ***/
    customers.forEach((cust) => {
      let accountLogic = cust.account_id.substr(2, 2);
      /*By Type Pie*/
      if (prePaidFlag && "00" === accountLogic && cust.plan_id === 2) {
        basicData.push(cust);
      } else if (prePaidFlag && "00" === accountLogic && cust.plan_id === 3) {
        proData.push(cust);
      } else if (entFlag && "01" === accountLogic) {
        enterpriseData.push(cust);
      } else if (prePaidFlag && "02" === accountLogic) {
        trialData.push(cust);
      } else {
        othersData.push(cust);
      }
      /*By Status Pie*/
      if (prePaidFlag && "active" === cust.subscription_status) {
        activeData.push(cust);
      } else if (entFlag && "enterprise" === cust.subscription_status) {
        enterpriseActiveData.push(cust);
      } else if (
        entFlag &&
        "enterprise_requested" === cust.subscription_status
      ) {
        enterpriseRequestedData.push(cust);
      } else if (prePaidFlag && "past_due" === cust.subscription_status) {
        pastDueData.push(cust);
      } else if (prePaidFlag && "trial_approved" === cust.subscription_status) {
        trialApprovedData.push(cust);
      } else if (
        prePaidFlag &&
        "trial_pending_approval" === cust.subscription_status
      ) {
        trialPendingApprovedData.push(cust);
      } else if (prePaidFlag && "trialing" === cust.subscription_status) {
        trialingData.push(cust);
      }

      /***Get User data***/
      if(cust.users){
        cust.users.forEach((user) => {
          if (user.isexpert) {
            expertUsersData.push(user);
          }
          /*else if(user.isadmin){
            adminUsersData.push(user);
          }*/ if (!user.isexpert && !user.isadmin) {
            techUsersData.push(user);
          }
          userList.push(user);
        });
      }

      //Calculation of TLC
      let days = 0,
        cost = +effectiveCost(cust);
      // console.log(cust.account_id, cust.subscription_status, total_available_credits, cust.price ,new Date(cust.subscription_end_date)>new Date(), +calculateCost(cust), cost, Math.ceil((new Date(cust.subscription_end_date) - new Date(cust.subscription_start_date)) / 86400000));
      if ("trial_pending_approval" !== cust.subscription_status) {
        if(cust.purchases){
          cust.purchases.forEach((pur) => {
            // console.log("is_cancelled "+pur.is_cancelled, "total_licences "+pur.total_licences, "available_credits "+pur.available_credits);
            if (!pur.is_cancelled) {
              total_available_credits += pur.available_credits;
              sum_of_total_licenses += pur.total_licences;
            }
          });
        }
      }

      //Calculation of ARMC
      if (
        ("enterprise" === cust.subscription_status ||
          "active" === cust.subscription_status) &&
        new Date(cust.subscription_end_date) > new Date() &&
        cost > 0
      ) {
        let startDt = moment(cust.subscription_start_date).format("YYYY-MM-DD");
        let endDt = moment(cust.subscription_end_date).format("YYYY-MM-DD");
        days = Math.round((new Date(endDt) - new Date(startDt)) / 86400000);
        //nonZero_cost_count += 1;
        sum_of_total_monthly_cost += (cost / days) * 30;
        let armc_color = "gainsboro";
        if ("01" === accountLogic) {
          armc_color = "RGB(31, 188, 187)";
        } else if ("00" === accountLogic && 3 === cust.plan_id) {
          armc_color = "RGB(120, 156, 76)";
        } else if ("00" === accountLogic && 2 === cust.plan_id) {
          armc_color = "RGB(174, 230, 106)";
        }

        //Customers by cost pie
        collectARMCData.push({
          label: cust.company_name
            ? cust.company_name.substr(0, 10)
            : cust.account_id,
          color: armc_color,
          datalabel: cost,
        });
      }
    });
    //Sort by accending order
    collectARMCData.sort((armc1, armc2) => {
      if (armc1.datalabel > armc2.datalabel) return 1;
      else if (armc1.datalabel < armc2.datalabel) return -1;
      else return 0;
    });
    /*Iterate the array, incrementing by two. On each iteration, 
    pop the last item off the array and splice it into the position at the current index.*/
    for (var i = 0; i < Object.keys(collectARMCData).length; i += 2) {
      collectARMCData.splice(i, 0, collectARMCData.pop());
    }

    collectARMCData.forEach((armc) => {
      costLabels.push(armc.label);
      costColorCombos.push(armc.color);
      costCustomersData.push(armc.datalabel);
    });

    //To set data for pie chart - home
    let typeColorCombos = [];
    let statusColorCombos = [];
    let typeLabels = [];
    let statusLabels = [];
    let typeData = [];
    let statusData = [];
    // let usersRoleData = [];

    if (prePaidFlag) {
      //Basic & Pro accounts
      typeLabels.push("Basic", "Pro", "Vendor Trial");
      typeColorCombos.push(
        "RGB(174, 230, 106)",
        "RGB(120, 156, 76)",
        "RGB(62, 47, 152)"
      );
      statusLabels.push(
        "Prepaid",
        "Prepaid Trialing",
        "Past Due",
        "Trial Approved",
        "Trial Pending Approval"
      );
      statusColorCombos.push(
        "RGB(120, 156, 76)",
        "RGB(242, 245, 101)",
        "RGB(255, 0, 0)",
        "RGB(131, 188, 233)",
        "#ff4d4d"
      );

      typeData.push(basicData.length);
      typeData.push(proData.length);
      typeData.push(trialData.length);

      statusData.push(activeData.length);
      statusData.push(trialingData.length);
      statusData.push(pastDueData.length);
      statusData.push(trialApprovedData.length);
      statusData.push(trialPendingApprovedData.length);
    }
    if (entFlag) {
      typeLabels.push("Enterprise ");
      statusLabels.push("Enterprise", "Enterprise Requested");
      typeColorCombos.push("RGB(31, 188, 187)");
      statusColorCombos.push("RGB(31, 188, 187)", "RGB(237, 94, 69)");

      typeData.push(enterpriseData.length);
      statusData.push(enterpriseActiveData.length);
      statusData.push(enterpriseRequestedData.length);
    }

    typeData.push(othersData.length);
    typeLabels.push("Others");
    typeColorCombos.push("gainsboro");
    statusColorCombos.push("gainsboro");

    //Starts
    let typeOptions = {
      plugins: {
        labels: labelPlugins,
        datalabels: {
          display: false,
        },
      },
      legend: {
        display: false,
        labels: defaultLabelStyle,
      },
      tooltips: customTooltips,
      hover: {
        onHover: function (e) {
          // customers[0]._model.outerRadius += 10;
          let point = this.getElementAtEvent(e);
          if (point.length) e.target.style.cursor = "pointer";
          else e.target.style.cursor = "default";
        },
      },
      onClick: (evt, item) => {
        if (item.length > 0) {
          this.breadcrumbTail(
            "customers",
            selectedCustomers(item[0]._view.label),
            item[0]._view.label
          );
        }
      },
    };
    /***********Users *********/
    let userOptions = {
      plugins: {
        labels: labelPlugins_small,
        datalabels: {
          display: false,
        },
      },
      legend: {
        display: false,
        labels: defaultLabelStyle,
      },
      tooltips: "",
      hover: {
        onHover: function (e,item) { //TP-914
          let point = this.getElementAtEvent(e);
          if (point.length && item && item[0] && item[0]._view.label !== 'Available licenses')
            e.target.style.cursor = "pointer";
          else
            e.target.style.cursor = "default";
          
        },
      },
      onClick: (evt, item) => {
        //{/*TP-694*/}
        if(item.length > 0 && "Invitees" === item[0]._view.label ){
          this.breadcrumbTail("users",selectedUsers(item[0]._view.label),"Invitees");
        }else if (item.length > 0 && "Available licenses" !== item[0]._view.label) {
          this.breadcrumbTail("users",selectedUsers(item[0]._view.label),item[0]._view.label,"usersbylicense");
        }
      },
    };
    let costCustOptions = {
      plugins: {
        labels: labelPlugins_small,
        datalabels: {
          display: false,
        },
      },
      legend: {
        display: false,
        labels: defaultLabelStyle,
      },
      tooltips: "",
    };

    unRegisterData = this.state.inviteList.filter(
      (inv) =>
        !(inv.isadmin && !inv.isexpert) &&
        "trial_pending_approval" !== inv.subscription_status
    );
    let usersRoleData = [];
    usersRoleData.push(expertUsersData.length);
    usersRoleData.push(techUsersData.length);
    usersRoleData.push(unRegisterData.length);
    usersRoleData.push(total_available_credits);
    let roleLabels = [
      "Experts",
      "Technicians",
      "Invitees",
      "Available licenses",
    ];
    let roleColorCombos = [
      "#2d3858",
      "#71a95a",
      "#777777",
      "RGB(174, 230, 106)",
    ];
    //TP-749
    /********* Groups ************/
    let groupsCategorys = [];
    let owrtcData =  allGroupsList.filter(grp => grp.is_onprem);
    let otData = allGroupsList.filter(grp=> !grp.is_onprem)

    groupsCategorys.push(owrtcData.length);
    groupsCategorys.push(otData.length);
    let groupLabels =[
      "OWRTC",
      "OT"
    ]
    let groupColorCombos = [
      "#2E86C1",
      "#17A589"
    ]

    let groupOptions = {
      plugins: {
        labels: labelPlugins_small,
        datalabels: {
          display: false,
        },
      },
      legend: {
        display: false,
        labels: defaultLabelStyle,
      },
      tooltips: "",
      hover:{
        onHover:function(e){
          let point = this.getElementAtEvent(e);
          if(point.length) e.target.style.cursor = "pointer";
          else e.target.style.cursor="default";
        }
      },
      onClick:(evt,item)=>{
        if (item.length) {
          this.breadcrumbTail( "groups",
            this.getSelectedGroups(item[0]._view.label),
            item[0]._view.label
          );
        }
      }
    }

    this.setState({
      pieChart1: {
        dataSets: typeData,
        options: typeOptions,
        dataLabels: typeLabels,
        colorCombos: typeColorCombos,
      },
      pieChart2: {
        dataSets: statusData,
        options: typeOptions,
        dataLabels: statusLabels,
        colorCombos: statusColorCombos,
      },
      pieChart5: {
        dataSets: usersRoleData,
        options: userOptions,
        dataLabels: roleLabels,
        colorCombos: roleColorCombos,
      },
      pieChart6: {
        dataSets: costCustomersData,
        options: costCustOptions,
        dataLabels: costLabels,
        colorCombos: costColorCombos,
      },
      pieChart7:{
        dataSets:groupsCategorys,
        options:groupOptions,
        dataLabels:groupLabels,
        colorCombos:groupColorCombos
      },
      avg_monthly_cost: sum_of_total_monthly_cost.toFixed(2),
      sum_of_total_licenses: sum_of_total_licenses,
      userList: userList, //Collecting user list - first time
    });
  };

  getSelectedGroups = (grpName)=>{
    if(grpName === 'OT')
      return this.state.groupList.filter(grp => !grp.is_onprem)
    else
      return this.state.groupList.filter(grp => grp.is_onprem)
  }

  createLicenseDataSets = (item) => {
    assignedData = [];
    unAssignedData = [];
    expertUsersData = [];
    adminUsersData = [];
    techUsersData = [];
    let creditColorCombos = [],
      roleColorCombos = [];

    //Collecting data for Licenses Tab
    item.credits.forEach((credit) => {
      if (null !== credit.email && null !== credit.first_name) {
        assignedData.push(credit);
      } else {
        unAssignedData.push(credit);
      }
    });
    item.users.forEach((user) => {
      if (( user.isexpert && user.isadmin ) || user.isexpert ) {   // TP-5749
        expertUsersData.push(user);
      } else if (user.isadmin && !user.isexpert) {
        adminUsersData.push(user);
      } else if (!user.isexpert && !user.isadmin) {
        techUsersData.push(user);
      }
    });

    let creditData = [];
    let creditLabels = ["Assigned", "Unassigned"];
    let roleLabels = ["Experts", "Admins", "Technicians"];
    creditColorCombos.push("RGB(120, 156, 76)", "RGB(174, 230, 106)");
    roleColorCombos.push("#2d3858", "#89783f", "#71a95a");

    //To set data for pie chart - credits
    creditData.push(assignedData.length);
    creditData.push(unAssignedData.length);

    //To set data for pie chart - role
    let roleData = [];
    roleData.push(expertUsersData.length);
    roleData.push(adminUsersData.length);
    roleData.push(techUsersData.length);
    let creditOptions = {
      plugins: {
        labels: labelPlugins,
        datalabels: {
          display: false,
        },
      },
      legend: {
        display: false,
        labels: defaultLabelStyle,
      },
      tooltips: "",
    };
    let roleOptions = {
      plugins: {
        labels: labelPlugins,
        datalabels: {
          display: false,
        },
      },
      legend: {
        display: false,
        labels: defaultLabelStyle,
      },
      tooltips: customRoleTooltips,
      hover: {
        onHover: function (e) {
          let point = this.getElementAtEvent(e);
          if (point.length) e.target.style.cursor = "pointer";
          else e.target.style.cursor = "default";
        },
      },
      onClick: (evt, item) => {
        if (item.length > 0) {
          this.licenseRef.current.getBarChart(
            selectedUsers(item[0]._view.label)
          );
        }
      },
    };
    this.setState({
      pieChart3: {
        dataSets: creditData,
        options: creditOptions,
        dataLabels: creditLabels,
        colorCombos: creditColorCombos,
      },
      pieChart4: {
        dataSets: roleData,
        options: roleOptions,
        dataLabels: roleLabels,
        colorCombos: roleColorCombos,
      },
      barChart: {
        defaultLabelStyle: defaultLabelStyle,
      },
    });
  };

  feedGlobalHunt = () => {
    this.setState({ globalHunt: [] }, () => {
      let searchParams = this.state.globalHunt;
      this.state.allCustomers.forEach((cust, i) => {
        if (cust.company_name) {  //TP-722
          searchParams.push({ ...cust, huntLabel: cust.company_name});
          searchParams.push({...cust,huntLabel: cust.company_name + " Users"});
          searchParams.push({ ...cust, huntLabel: cust.account_id});
          let groups = this.state.groupList.filter((group) => cust.account_id === group.account_id && group.group_id);
          if (groups.length > 0) {
            searchParams.push({...cust,huntLabel: cust.company_name + " Groups"});
          }
          if(groups.length){
            groups.forEach((group)=>{
              group.key="group";
              searchParams.push({...group,huntLabel:group.group_name +" - "+group.description});
            })
            
          } // TP-908
        }else if(!cust.company_name && cust.account_id){
          searchParams.push({ ...cust, huntLabel: cust.account_id});
        }
        if(cust.users){
          cust.users.forEach((user) => {
            if (user.first_name || user.last_name)
              searchParams.push({...user,huntLabel: user.first_name + " " + user.last_name});
              searchParams.push({ ...user, huntLabel: user.email});
          });
        }
        
      });
      searchParams.push({
        huntLabel: "most recent users",
        key: "mru",
      });
      searchParams.push({
        huntLabel: "Online now",
        key: "online",
        key1: "online users",
      });

      this.setState({ globalHunt: searchParams });
    });
    // console.log(this.state.globalHunt);
  };

  render() {
    return (
      // TP-6475
      <div className="dashboard">
        <DashboardHeader 
        userRole={USER_ROLE} 
        signout={this.callLogOut} 
        breadcrumbTail={this.breadcrumbTail} 
        allCustomers={this.state.allCustomers} 
        enterpriseWriteAccess={enterpriseWriteAccess()}
        myWriteAccountAccess={myWriteAccountAccess()}
        myReadAccountAccess={myReadAccountAccess()}
        />   
        <Flash />
        <BreadcrumbView
          items={this.state.breadcrumbItems}
          content={this.state.breadcrumbData}
          tabIndex={this.state.tabIndex}
          params={this}
        />
        {0 === this.state.tabIndex && (
          <>
            <i
              id="dashIconCLs-refresh"
              className={
                this.state.isLoading
                  ? "dashIconCls fa fa-refresh fa-spin"
                  : "dashIconCls fa fa-refresh"
              }
              onClick={this.refresh}></i>
            {this.state.serverErrors.length > 0 && (
              <div className="serverErrorCss">
                {this.state.serverErrors.map((v, k) => (
                  <ErrorMessage errorMessage={v} key={k} />
                ))}
              </div>
            )}
          </>
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {customers,groups,invites} = state;
  
  return {
    customers,
    groups,
    invites
  }
};

const mapDispatchToProps = dispatch => ({
  fetchAllCustomers:() => dispatch(fetchAllCustomers()),
  fetchAllGroupsList:() => dispatch(fetchAllGroupsList()),
  fetchAllInvitesList:() => dispatch(fetchAllInvitesList())
});

export default connect( mapStateToProps, mapDispatchToProps)(DashBoard);


