import React from 'react';

export default function BreadcrumbView ({ items, content, tabIndex, params }) {
  let itemDOMS = [];
  let contents = [];

  items.forEach(({id, label}, index) => {
    if(tabIndex>0 && id==="home") label="Home";
    let oItemDOM = (<div className="breadcrumb-item" id={id} key={id+index+'-'+tabIndex} onClick={()=>params.breadcrumbTail(id)}>{label}</div>);
    if(index<=tabIndex){
      if(index===tabIndex){
         oItemDOM = (<div className="breadcrumb-item active" key={id+index+'-'+tabIndex}>{label}</div>);
      }
      if(index > 0) {
        itemDOMS.push(<strong key={id+">"+index+'-'+tabIndex}> &gt; </strong>)
      }
      itemDOMS.push(oItemDOM);
    }
  });

  content.forEach(({id, desc}, index) => {
  let contentDOM = (<section key={id+"Section"}>{desc}</section>)
  contents.push(contentDOM);
  });

  return (
    <div className="dashboard-content">
      <div className="breadcrumb-container">
        {itemDOMS}
      </div>
      {contents}
    </div>
  )
}
