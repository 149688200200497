import React, { Component } from 'react';
import superagent from "superagent";
import './jobsView.css'
import moment from "moment";
import { constJobStatusFlag } from './jobs';
import { DownloadZipDiv } from './jobs';
import generateZip from './generateZip';
import LoadThreeDots from '../Utils/LoadThreeDots';

export default class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: new Date().setDate(new Date().getDate() - 1),
      end_date: new Date(),
      selectedJob: this.props.selectedJob,
      jobReportsInfo: [],
      jobAssetInfo: [],//TP-2774
      collapse: true,
      isWait:true,
      isOpen: false,
      selectedItem: 1,
      selectedProcedure:{},
      isDownloading:false
    };
  }

  componentDidMount = () => {
    this.getJobReport();
  }

  getJobReport = async () => {
    //TP-2844
    const url_part = this.state.selectedJob.job_type === 'checklist' ? 'getCheckListJobReport' : 'getJobReport'

    let url = `/api/jobs/${url_part}/` + this.state.selectedJob.id;

    if (sessionStorage.userToken) {
      await superagent.get(sessionStorage.url + url)
        .set("Authorization", "Bearer " + sessionStorage.userToken)
        .type("application/json")
        .accept("application/json")
        .then(response => response)
        .then(data => {
          if (null !== data.body) {
            //TP-2844
            if (this.state.selectedJob.job_type === 'checklist') {
              this.setState({ jobReportsInfo: this.showRawJsonChecklistProcedure(data.body)},()=> this.setDefaultProcedure()); //TP-2774
            } else {
              this.setState({ jobReportsInfo: this.showRawJsonProcedure(data.body), jobAssetInfo: this.showRawJsonAsset(data.body)},()=> this.setDefaultProcedure()); //TP-2774
            }
          }
          this.setState({ isWait: false });
        }
        ).catch(error => {
          let msg = null;
          if (undefined === error.status) {
            msg = "Internet is not connected, please try again after connecting network";
          } else if (400 === error.status) {
            msg = "Not able to fetch data";
          } else if (401 === error.status) {
            msg = "Session token experied, Please re-login";
          } else if (403 === error.status) {
            msg = "You are not authorized";
          } else if (422 === error.status) {
            msg = "Invalid parameters sent";
          } else if (500 === error.status) {
            msg = "Something went wrong, try again later";
          } else {
            console.error(error);
          }
          this.setState({ isWait: false, serverError: msg });
        });
    }
  }

  setDefaultProcedure = () => {
    let { jobReportsInfo } = this.state
    
    if (jobReportsInfo && jobReportsInfo.length > 0) {
     let infoObj = jobReportsInfo.find(x => x.sequence === 1);
      this.setState({ selectedProcedure: infoObj });
    }
  }

  getTimeDifference = (from, to) => {
    if (moment(from).isValid() && moment(to).isValid()) {
      let m1 = moment(from);
      let m2 = moment(to);

      let timeDiffer = Math.floor(Math.abs(m1.diff(m2)) / 1000);
      let dateDiffer = m1.format("DD MMMM YYYY, h:mm:ss a") + ' - ' + m2.format("DD MMMM YYYY, h:mm:ss a");

      return (timeDiffer ? 'Duration: (' + timeDiffer + ' seconds) ' + dateDiffer : '');
    }
  }

  download(url, filename,callBackFunc) {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
        if(callBackFunc) callBackFunc(false);
      }
      );
    });
  }

  getExternalEvidence = (event, fileNames) => {
    event.preventDefault();
    if (!fileNames)
      return false;
      
      this.handleLoadingDownload(true);
      this.download(fileNames.location, fileNames.key.split('_')[1],this.handleLoadingDownload);
  }



  getReferenceMaterials = (event, fileNames) => {
    event.preventDefault();
    this.handleLoadingDownload(true);
    let fileInfo = fileNames ? JSON.parse(fileNames.file_info) : '';
    if (fileInfo)
      this.download(fileInfo.location, fileInfo.key.split('_')[1],this.handleLoadingDownload);

  }

  getTaskMaterials = (event, fileNames) => {
    event.preventDefault();
    if (!fileNames)
      return false;

    this.handleLoadingDownload(true);  
    fileNames.forEach((item) => {
      let fileInfo = item ? JSON.parse(item) : '';
      if (fileInfo && fileInfo.url)
        this.download(fileInfo.url, fileInfo.url.split('jobs/')[1],this.handleLoadingDownload);
    });
  }


  getTaskData = (taskURL, obj) => {

     return taskURL ? <a id="task-url" className="job-button" title="Download" onClick={event => this.getTaskMaterials(event, obj.task_data)}>{"Click here to download Task Result"}</a>:<span id="task-url">{'No Task Results'}</span>;
   
  }

  getRoleNameWithLabel = (item,allUsers) => {
    let inputCreated;
    let userInfo = allUsers.filter((user)=>user.id === item.assigned_by)[0];

    if (userInfo.isadmin && userInfo.isexpert)
      inputCreated = <input type="text" disabled name="createdby" style={{ backgroundColor: "#89783f", color: "white" }} value={item.creater_fname} />
    else if (userInfo.isadmin)
      inputCreated = <input type="text" disabled name="createdby" style={{ backgroundColor: "#89783f", color: "white" }} value={item.creater_fname} />
    else if (userInfo.isexpert)
      inputCreated = <input type="text" disabled name="createdby" style={{ backgroundColor: "#2d3858", color: "white" }} value={item.creater_fname} />
    else if (!userInfo.isadmin && !userInfo.isexpert)
      inputCreated = <input type="text" disabled name="createdby" style={{ backgroundColor: "#71a95a", color: "white" }} value={item.creater_fname} />

    return inputCreated;
  }



  toggleDropdown = () => this.setState({ isOpen: !this.state.isOpen });

  handleItemClick = (id,data) => {
    this.state.selectedItem === id ? this.setSelectedItem(id,data) : this.setSelectedItem(id,data);
  }
    
  setSelectedItem = (id,data) => {
    if(data)
      this.setState({ selectedItem: id, isOpen: !this.state.isOpen,selectedProcedure:data});

  }

  /**TP-2844 */
  showRawJsonChecklistProcedure = (Json) => {
    let procedureList = [];
    if (Json.length>0) {
      Json.forEach( (item) => {
        item['label'] = 'Procedure '+item.sequence;
        procedureList.push(item);
      });
    }
    return procedureList;
  }

  //TP-2774
  showRawJsonProcedure = (Json) => {
    let procedureList = [];
    if(Json.subTasks.length>0){
        Json.subTasks.forEach( (item) => {
            item['label'] = 'Procedure '+item.sequence;
            procedureList.push(item);
        });
    }
    return procedureList;
  }

  //TP-2774 -- Method to set the model assets for the job
  showRawJsonAsset = (Json) => {
    let assetList = [];
    if (Json.modelAssets && Json.modelAssets.length>0) {
      Json.modelAssets.forEach( item => {
        assetList.push(item);
      })
    }
    return assetList;
  }

  checkIfObjecthas = (paramObj,key) =>{
    return (paramObj && key && paramObj[key]) 
  }

  handleLoadingDownload = (value)=>{
    this.setState({isDownloading:value})
  }
  

  startDownload = async (selectedJOb,customerDtls) =>{

    if(selectedJOb && selectedJOb.id){
      if(sessionStorage.userToken) {
        const url = selectedJOb.job_type === 'checklist' ? 'getCheckListJobReport' : 'getJobReport' //TP-2772
        this.handleLoadingDownload(true);
        await  superagent
          .get(sessionStorage.getItem("url")+`/api/jobs/${url}/`+selectedJOb.id) //TP-2772
          .set("Authorization", "Bearer " + sessionStorage.userToken)
          .then(response => response)
          .then(async data => {
           await generateZip({data:data.body,selectedJob:selectedJOb, customer:customerDtls,callBackFunction:this.handleLoadingDownload});
        }
      ).catch(error => {
        let msg=null;
        if(undefined===error.status){
          msg = "Internet is not connected, please try again after connecting network";
        }else if(400===error.status){
          msg = "condition not matching so jobs not found";
        }else if(401===error.status){
          msg = "Session token experied, Please re-login";
        }else if(403===error.status){
          msg = "You are not authorized";
        }else if(422===error.status){
          msg = "Invalid parameters sent";
        }else if(500===error.status){
          msg = "Something went wrong, try again later";
        }else{
          console.error(error +msg);
        }
        this.handleLoadingDownload(false);
      });
    }
    }
  
  }
  //TP-842
  downloadResource(urlArr){
    let url = JSON.parse(urlArr).url;
    let filename = url.substring(url.lastIndexOf("/") + 1);
    var handleLoadingDownloadFunc = this.handleLoadingDownload;
    handleLoadingDownloadFunc(true);

    const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function() {
          let a = document.createElement('a');
          a.href = window.URL.createObjectURL(xhr.response);
          a.download = filename; 
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
          a.remove();
          handleLoadingDownloadFunc(false);
        };
    xhr.open('GET', url);
    xhr.send();
  }

  //TP-2774 
  transformJobType(type) {
    switch(type){
      case "evidence":
          return "Work Instructions";
      case "checklist":
          return "Checklist";
    }
  }

  render() {

    const { isWait, selectedJob, jobReportsInfo, selectedItem, isOpen, selectedProcedure, jobAssetInfo /**TP-2774 */ } = this.state;
    
    let deviceInfo = selectedProcedure && selectedProcedure.task_data ? JSON.parse(selectedProcedure.task_data[0]) : "";
    let deviceMf = deviceInfo && deviceInfo.device_manufacturer ? deviceInfo.device_manufacturer : '';
    let deviceMd = deviceInfo && deviceInfo.device_model ? deviceInfo.device_model : '';
    let deviceCd = deviceInfo && deviceInfo.coordinates ? deviceInfo.coordinates : '';
    let serialNum = deviceInfo && deviceInfo.serial_number ? deviceInfo.serial_number : '';
    let checklistObsrv = deviceInfo && deviceInfo.cl_observation ? deviceInfo.cl_observation : ''; //TP-2844
    let deviceObj = deviceMf + ' ' + deviceMd + ' ' + serialNum + ' ' + deviceCd;
    let taskURL = deviceInfo && deviceInfo.url ? deviceInfo.url : '';
    let refMaterail = selectedProcedure && selectedProcedure.reference_materials ? selectedProcedure.reference_materials : '';
    let externalEvidence = selectedProcedure && selectedProcedure.external_evidence ? JSON.parse(selectedProcedure.external_evidence) : '';
     
    return (
      <form id="Jobs-details-form">
        { !isWait &&
        <div id="container">
          <div id="left-job">
            <div className="form-group">
              <label className="control-label col-sm-2" htmlFor="name">Job Name:</label>
                <div className="input-icon-container">
                  <input type="text" className="custom-width" disabled name="name" value={selectedJob.name} />
                  <div className="icon-text-align">{DownloadZipDiv(selectedJob, this.props.customerDtls,this)}</div>
                </div>
            </div>
            <div className="form-group inline-flex-result">
              <label className="control-label col-sm-2" htmlFor="desc">Description:</label>
              <textarea className="jobs-text-desc" disabled value={selectedJob.description}> </textarea>
            </div>
            <div className="form-group-col">
              <label className="control-label col-sm-2" htmlFor="crtd">Created By:</label>
              {this.getRoleNameWithLabel(selectedJob,this.props.viewDetails)}
              <label className="control-label col-sm-2" htmlFor="assigned">Assigned On:</label>
              <input type="text" disabled name="assigned" value={"NA"} />
            </div>
            <div className="form-group-col">
              <label className="control-label col-sm-2" htmlFor="current">Current Assignee:</label>
              <input type="text" disabled name="currenct" value={selectedJob.first_name ? selectedJob.first_name+' '+selectedJob.last_name : 'unassigned'} />
              <label className="control-label col-sm-2" htmlFor="created">Created On:</label>
              <input type="text" disabled name="created" value={moment(this.state.selectedJob.created_on).format("DD MMM YYYY")} />
            </div>
            <div className="form-group-col">
              <label className="control-label col-sm-2" htmlFor="start">Start Date:</label>
              <input type="text" className={selectedJob.className} disabled name="start" value={moment(this.state.selectedJob.start_date).format("DD MMM YYYY")} />
              <label className="control-label col-sm-2" htmlFor="end">End Date:</label>
              <input type="text" className={selectedJob.className} disabled name="end-Date" value={moment(this.state.selectedJob.end_date).format("DD MMM YYYY")} />
            </div>
            {/**TP-2774 */}
            <div className="form-group-col">
              <label className="control-label col-sm-2" htmlFor="type">Job Type:</label>
              <input type="text" disabled name="type" value={this.transformJobType(selectedJob.job_type)} />
              <label className="control-label col-sm-2" htmlFor="status">Status:</label>
              <input type="text"  className={selectedJob.className} disabled  name="status" value={constJobStatusFlag[this.state.selectedJob.status]} />
              
            </div>
            { /*TP-2774*/selectedJob.asset_name && selectedJob.asset_name !== null ?
              <div className="form-group-col">              
                <label className="control-label col-sm-2" htmlFor="asset">Asset:</label>
                <input type="text" disabled name="asset" value={selectedJob.asset_name} />
              </div>
              :
              null
            }
            { /*TP-2774*/jobAssetInfo && jobAssetInfo.length > 0 ?
              jobAssetInfo.map( item => (
                <div /* className="form-group" */ className="form-group display-grid-jobs ">
                  <label className="control-label col-sm-3" name={item.type} htmlFor={item.type}>{ item.type === "look_up_reference" ? "Look up Reference:" : "Asset Reference:"}</label>
                  <div className="task-ref-result">
                    <a id="task-url" href={item.location} className="job-button" title={ item.type === "look_up_reference" ? "Download Look up Reference" : "Download Asset Reference"}>{item.file_name}</a>                      
                  </div>
                </div>
              ))
              :
              null
            }
          </div>
          {/**TP-2844 */ selectedJob.job_type === "checklist" ?
            <div id="right-job">

            <div className='select-dropdown'>
              <div className='dropdown-header' onClick={this.toggleDropdown}>
                {selectedItem && jobReportsInfo.length > 0 ? jobReportsInfo.find(item => item.sequence == selectedItem).label : "Select Procedure"}
                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
              </div>
              <div className={`dropdown-body ${isOpen && 'open'}`}>
                {jobReportsInfo.map(item => (
                  <div className="dropdown-item" onClick={e => this.handleItemClick(e.target.id,item)} id={item.sequence} key={item.sequence}>
                    <span className={`dropdown-item-dot ${item.id == selectedItem && 'selected'}`}></span>
                    {item.label}
                  </div>
                ))}
              </div>
            </div> 
            {selectedProcedure && selectedProcedure.id ?
              
              
                <div id= "procedure-panel">
                  <div className="form-group">
                    <label className="control-label col-sm-3" htmlFor="p-usr">Task Instruction:</label>
                    <input type="text" disabled name="jpb-name" value={selectedProcedure.instruction} />
                  </div>
                  <div className="form-group inline-flex-result">
                    <label className="control-label col-sm-3" htmlFor="p-usr">Response Type:</label>
                    <textarea disabled className="jobs-exp-text-area" value={selectedProcedure.name ? selectedProcedure.name : ''} ></textarea>
                  </div>                  
                  <div className="form-group-col">
                    <label className="control-label col-sm-2" htmlFor="tsk-stus">Task Status:</label>
                    <input type="text" disabled name="status" value={constJobStatusFlag[selectedProcedure.status]} />
                    <label className="control-label col-sm-2 col-space-8" htmlFor="exe-by">Executed By:</label>
                    <input type="text" disabled className="col-space-3" name="executed_by" value={selectedProcedure.first_name ? selectedProcedure.first_name+' '+selectedProcedure.last_name:'unassigned'} />
                  </div>
                  <div className="form-group">
                    <label className="control-label col-sm-3" htmlFor="exec-time">Execution Time:</label>
                    <input type="text" disabled name="time" value={selectedProcedure.execution_start_time ? this.getTimeDifference(selectedProcedure.execution_start_time, selectedProcedure.execution_end_time) : "NA"} />
                  </div>
                  <div className="form-group">
                    <label className="control-label col-sm-3" htmlFor="devi-det">Device Details:</label>
                    <input type="text" disabled name="device-details" value={deviceObj && deviceObj.trim().length > 0 ? deviceObj : "NA"} />
                  </div>
                <div className="form-group">
                  <label className="control-label col-sm-3" htmlFor="task-url">Observation:</label>
                  <input type="text" disabled name="checklist-observation" value={checklistObsrv ? checklistObsrv.trim() : ""} />
                </div>
                    
                </div>
              
              : <div>
                No task found!
              </div>
              
            }
          </div>
            :
            <div id="right-job">

              <div className='select-dropdown'>
                <div className='dropdown-header' onClick={this.toggleDropdown}>
                  {selectedItem && jobReportsInfo.length > 0 ? jobReportsInfo.find(item => item.sequence == selectedItem).label : "Select Procedure"}
                  <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
                </div>
                <div className={`dropdown-body ${isOpen && 'open'}`}>
                  {jobReportsInfo.map(item => (
                    <div className="dropdown-item" onClick={e => this.handleItemClick(e.target.id,item)} id={item.sequence} key={item.sequence}>
                      <span className={`dropdown-item-dot ${item.id == selectedItem && 'selected'}`}></span>
                      {item.label}
                    </div>
                  ))}
                </div>
              </div> 
              {selectedProcedure && selectedProcedure.id ?
                
                
                  <div id= "procedure-panel">
                    <div className="form-group">
                      <label className="control-label col-sm-3" htmlFor="p-usr">Task Instruction:</label>
                      <input type="text" disabled name="jpb-name" value={selectedProcedure.description} />
                    </div>
                    <div className="form-group inline-flex-result">
                      <label className="control-label col-sm-3" htmlFor="p-usr">Expected Result:</label>
                      <textarea disabled className="jobs-exp-text-area" value={selectedProcedure.expected_result ? selectedProcedure.expected_result : ''} ></textarea>
                    </div>
                    <div className="form-group inline-flex-result">
                      <label className="control-label col-sm-3" htmlFor="p-usr">Evidence Type:</label>
                      <input type="text" disabled className="jobs-evidence-type" value={selectedProcedure.name ? selectedProcedure.name : ''} ></input>
                    </div>
                    <div className="form-group-col">
                      <label className="control-label col-sm-2" htmlFor="sch-start">Start Date:</label>
                      <input type="text" disabled name="status" value={moment(selectedProcedure.start_date).format("DD MMM YYYY")} />
                      <label className="control-label col-sm-2 col-space-8" htmlFor="sch-end">End Date:</label>
                      <input type="text" disabled name="end_date" className="col-space-3" value={moment(selectedProcedure.end_date).format("DD MMM YYYY")} />
                    </div>
                  <div className="display-grid-jobs">
                    <label className="control-label col-sm-3" htmlFor="task-ref">Task Reference Material:</label>
                    <div className="task-ref-flex">
                      {refMaterail && refMaterail.length > 0 ? refMaterail.map((matObj, index) => {
                        return <a id={"desc"+index} key={index} className="job-button" onClick={event => this.getReferenceMaterials(event, matObj)} title="Download" >{JSON.parse(matObj.file_info).name.split('_')[1]}</a>
                      }) : <span id={"blank-desc-"+0} >{"No Reference Material"}</span>

                      }
                      </div>
                    </div>
                    <div className="form-group-col">
                      <label className="control-label col-sm-2" htmlFor="tsk-stus">Task Status:</label>
                      <input type="text" disabled name="status" value={constJobStatusFlag[selectedProcedure.status]} />
                      <label className="control-label col-sm-2 col-space-8" htmlFor="exe-by">Executed By:</label>
                      <input type="text" disabled className="col-space-3" name="executed_by" value={selectedProcedure.first_name ? selectedProcedure.first_name+' '+selectedProcedure.last_name:'unassigned'} />
                    </div>
                    <div className="form-group">
                      <label className="control-label col-sm-3" htmlFor="exec-time">Execution Time:</label>
                      <input type="text" disabled name="time" value={selectedProcedure.execution_start_time ? this.getTimeDifference(selectedProcedure.execution_start_time, selectedProcedure.execution_end_time) : "NA"} />
                    </div>
                    <div className="display-grid-jobs ">
                      <label className="control-label col-sm-3" htmlFor="ext-evi">External Evidence:</label>
                        <div className="external-ref-flex">
                        { externalEvidence && externalEvidence.length > 0 ? externalEvidence.map((extObj,index) => {
                          return <a id={"external-"+index} key={index} className="job-button"onClick={event => this.getExternalEvidence(event, extObj)} title="Download" >{extObj.name.split('_')[1]}</a>
                          }):<span id={"blank-extenal-"+0} >{"No External Evidence"} </span>
                        }
                      </div>
                      </div>
                    <div className="form-group">
                      <label className="control-label col-sm-3" htmlFor="devi-det">Device Details:</label>
                      <input type="text" disabled name="device-details" value={deviceObj && deviceObj.trim().length > 0 ? deviceObj : "NA"} />
                    </div>
                  <div className="form-group" className="display-grid-jobs ">
                    <label className="control-label col-sm-3" name="task-result" htmlFor="task-url">Task Results:</label>
                    <div className="task-ref-result">
                        {this.getTaskData(taskURL, selectedProcedure)}
                      </div>
                    </div>
                    {/* {TP-842} */}
                      { selectedProcedure.audio_link &&
                      <div className="form-group" className="display-grid-jobs ">
                      <label className="control-label col-sm-3" name="task-result" htmlFor="task-url">Recorded Audio:</label>
                      <div className="task-ref-result">
                        <a href="#" id="download-audio" className="job-button"  key={"download-audio"} title="Download Audio" onClick={()=>this.downloadResource(selectedProcedure.audio_link)}>Download Audio</a>
                      </div>
                    </div>
                    }  
                  </div>
                
                : <div>
                  No task found!
                </div>
                
              }
            </div>
          }
        </div>
          }
        {this.state.isDownloading && <LoadThreeDots isLoading={this.state.isDownloading}/>}  
      </form>

    );
  };
}


