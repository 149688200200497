import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";

let middlewares = [];//[logger, thunk];

if(process.env.NODE_ENV === "development"){
    middlewares = [logger,thunk];
}else{
    middlewares = [thunk];
    if(process?.env?.NODE_ENV){
        console.log('Running Environment in '+process.env.NODE_ENV)
    } 
}

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares),));