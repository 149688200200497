import React from 'react';
import { connect } from "react-redux";
import {updateGroupConfiguration} from '../redux/Group/GroupActions';
class Dropdown extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      haveText: "",
      streamType : ['OT', 'OWRTC'],
    }
  }

  componentDidMount(){
    const {viewDetails} = this.props;
    this.setState({haveText:(viewDetails.is_onprem === true) ? "OWRTC":"OT"})  
  }
  
  render() {
    const {isOpen, haveText} = this.state;

    return (
      <div
        className={isOpen ? "drop-down active" : "drop-down"}
        onClick={this.handleClick} >
        <div className="drop-down-text">
          {!haveText ? "Select" : haveText}
        </div>
        {this.itemList(this.state.streamType)}
      </div>
    )
  }

 handleClick = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  handleText = (ev) => {
    this.setState({haveText: ev.currentTarget.textContent});
    this.setGroupConfiguration(ev.currentTarget.textContent);
    this.setState({streamType: ['OT', 'OWRTC']});
  }

 setGroupConfiguration = async (selectedStreamType) =>{

    const params={
      group_id: this.props.viewDetails.group_id,
      codec_type: this.props.viewDetails.codec_type,
      location_hint: this.props.viewDetails.location_hint,
      is_onprem:(selectedStreamType !== 'OT') ? true:false,
      keyName:"is_onprem",
      groupName:this.props.viewDetails.group_name,
      groupStreamType:selectedStreamType
    }

    const onSuccessMsg = this.props.viewDetails?.isongoingnow ? params.groupName+" is updated to "+params.groupStreamType+". This change will be applied after the current session ends." 
    :params.groupName+" is updated to "+params.groupStreamType; 

    this.props.updateGroupConfiguration(this.props.viewDetails,params,onSuccessMsg);
 }  


  itemList = props => {
     let index = props.indexOf(this.state.haveText);
     let listItem = (index !== -1) ? props.splice(index,1): props;
    const list = listItem.map((item) => (
      <div
        onClick={this.handleText}
        id={item}
        className="drop-down-item"
        key={item.toString()}>
        {item}
      </div>
    ));

    return (
      <div id="stream-mode-drop-down" className="drop-down-items"> { list } </div>
    )
  }

}

const mapDispatchToProps = dispatch => ({
  updateGroupConfiguration: (groupInfo,groupValue,successMsg) => dispatch(updateGroupConfiguration(groupInfo,groupValue,successMsg)),
});

export default connect(null,mapDispatchToProps)(Dropdown);
