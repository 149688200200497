import React from 'react';
import DataTable from "react-data-table-component";
import moment from "moment";
import "react-dropdown-tree-select/dist/styles.css";
import superagent from "superagent";
import {encryptData} from '../cryptography';
import {Modal,Button} from 'react-bootstrap';

const uluColumns = [
    {name:"Id",selector:"id",sortable:true,maxWidth:"70px"},
    {name:"Email",selector:"email",sortable:false,maxWidth:"40vw"},
    {name:"Device Details",selector:"device_details",sortable:true,maxWidth:"40vw"},
    {name:"Signal Type",selector:"signal_type",sortable:true,maxWidth:"40vw"},
    {name:"Created On",selector:"created_on",sortable:true,maxWidth:"40vw"},
    {name:"Action",selector:"action",sortable:true,maxWidth:"120px"}
  ]

export default class RegisterDevice extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            deviceList:[],
            tableHeader:'Registered Device',
            isLoading:false,
            serverStatus:null,
            serverError:null
        }
    }

componentDidMount = () =>{
    this.getPushNotificationRecipients();
}

closeAlert = () => {
    if(this.state.serverStatus > 400){
        this.setState({serverError:null,serverStatus:null});
    }else{
        this.setState({serverError:null,serverStatus:null});
        this.getPushNotificationRecipients();
    }
    
   
}

getPushNotificationRecipients = async ()=> {
    
    let url = '/api/dash/getPushNotificationRecipients';

    if (sessionStorage.userToken) {
        this.setState({isLoading:true});
        await superagent
        .get(sessionStorage.getItem("url") + url)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .then((response) => response)
        .then((data) => {
            if (null != data.body) {
                this.setState({deviceList:data.body.length > 0 ? data.body:[],isLoading: false})
            }
        })
        .catch((error) => {
            let msg=null;
            if(undefined===error.status){
                msg = "Internet is not connected, please try again after connecting network";
            }else if(400===error.status){
                msg = "condition not matching so jobs not found";
            }else if(401===error.status){
                msg = "Session token experied, Please re-login";
            }else if(403===error.status){
                msg = "You are not authorized";
            }else if(422===error.status){
                msg = "Invalid parameters sent";
            }else if(500===error.status){
                msg = "Something went wrong, try again later";
            }else{
                console.error(error +msg);
            }
            this.setState({serverError:msg,showPopUp:false,isLoading: false,serverStatus:error.status});
        });
    }
    
}


updateRegisterNotification = async (dataParam) => {
  
    let apiUrl =  "/api/registerNotification";
    let params = {}, msg = null ;
    params.email = encryptData(dataParam.email);
    params.device_details = dataParam.device_details;
    params.signal_type = dataParam.signal_type;
    params.register = false;


    if (null!==sessionStorage.getItem("userToken")
        && null!==params && null!==apiUrl) {
        
        await superagent.post(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .send(params)
        .then(response => response)
        .then(data => {
        if(200===data.status){
            msg = "Data updated successfully";
            this.setState({serverError:msg,showPopUp:false});
        }
        }).catch(error => {
        
        if(undefined===error.status){
            msg = "May be internet is not connected, try again sometime";
        }else if(400===error.status){
            msg = "Data not updated";
        }else if(401===error.status){
            msg = "Session token experied, please re-login";
        }else if(403===error.status){
            msg = "You are not authorized";
        }else if(500===error.status){
            msg = "Something went wrong, try again sometime";
        }
        this.setState({serverError:msg,showPopUp:false,serverStatus:error.status});
        });
    }else{
        this.close();
    }
}
 

render(){

    uluColumns[0].cell=row=>undefined!==row.id ? <div className={"text-ellipses"}>{row.id}</div>:<div className="text-ellipses"></div>
    uluColumns[1].cell=row=>undefined!==row.email ? <div className={"text-ellipses"}>{row.email}</div>:<div className="text-ellipses"></div>
    uluColumns[2].cell=row=>undefined!==row.device_details ? <div className={"text-ellipses"}>{row.device_details}</div>:<div className="text-ellipses"></div>
    uluColumns[3].cell=row=>undefined!==row.signal_type ? <div className={"text-ellipses"}>{row.signal_type}</div>:<div className="text-ellipses"></div>
    uluColumns[4].cell=row=>undefined!==row.created_on ? <div className={"text-ellipses"}>{moment(row.created_on).format("DD MMM,YYYY HH:mm")}</div>:<div className="text-ellipses"></div>
    uluColumns[5].cell=row=>undefined!==row.id ? <div className={"text-ellipses"}><i className="fa fa-trash-o fa-1x de-registration-icon" title="Delete Registered Device" onClick={e=>(this.updateRegisterNotification(row))}></i></div>:<div className="text-ellipses"></div>
    
    return(
        <div className="page">
        <div className="media">
            <h3>Please rotate your device to landscape mode</h3>
        </div>
            <div className="container-fluid media-show">
                <div className="row">
                <div className="col-12 align-self-center">
                </div>

                    <div className="col-12 align-self-start">
                        <DataTable
                        pagination={this.state.deviceList.length > 10}
                        paginationPerPage={this.props.pagination}
                        columns={uluColumns}
                        keyField={"email"}
                        data={this.state.deviceList}
                        className="table-responsive table-container register-device-info"
                        subHeader={this.state.deviceList.length>0}
                        progressPending={this.state.isLoading}
                        />
                    </div>
                </div>  
            </div>
            {this.state.serverError
                &&<ModalPopUp modalTitle="Alert!!!" 
                modalBody={this.state.serverError} 
                closeAlert={this.closeAlert}
                serverStatus={this.state.serverStatus}
                closeAlertReload={this.closeAlertReload}
            />}
        </div>
    )
}
}

class ModalPopUp extends React.Component{
    constructor(props){
      super(props);
      this.state = { showModal: false }
    }
  
    close = () => {this.props.closeAlert()}
  
    componentDidMount(){
      this.setState({showModal:true});
    }
    render() {
      return (
          <Modal show={this.state.showModal} onHide={this.close} backdrop={false} keyboard={false}>
            <Modal.Header>
              <Modal.Title>{this.props.modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.modalBody}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.close} className="btn-green">OK</Button>
            </Modal.Footer>
          </Modal>
      );
    }
  }

 