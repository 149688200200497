import React from 'react';
import 'ol/ol.css';
import {Map, View, Feature} from 'ol';
import TileLayer from 'ol/layer/Tile';
import {OSM} from 'ol/source';
import {fromLonLat,transformExtent} from 'ol/proj';
import {Point, /*Polygon*/} from 'ol/geom';
import {Fill, Style, Text, Icon} from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Overlay from 'ol/Overlay';
import '../map_tab/viewMap.css';
import markerOffline from '../care4dImg/map-icon-offline.png';
import markerOnline from '../care4dImg/map-icon-online.png';
import moment from "moment";
import {Control, Zoom} from 'ol/control';
import {defaults} from 'ol/interaction';

function toggleFullscreen(){
  let msg;
  var elem = document.getElementById('viewMap');
  if (!document.fullscreenElement) {
    msg="Error attempting to enable full-screen mode: ";
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch(err => {
        console.log(`${msg} ${err.message} (${err.name})`);
      });
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen().catch(err => {
        console.log(`${msg} ${err.message} (${err.name})`);
      });
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen().catch(err => {
        console.log(`${msg} ${err.message} (${err.name})`);
      });
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      try{
        if(!document.webkitCurrentFullScreenElement){
          elem.webkitRequestFullscreen();
        }else{
          document.webkitExitFullscreen();
        }
      }catch(err){
        console.log(`${msg} ${err.message} (${err.name})`);
      }
    }
  } else {
      if (document.exitFullscreen) {
        msg="Error attempting to disable full-screen mode: ";
        document.exitFullscreen().catch(err => {
          console.log(`${msg} ${err.message} (${err.name})`);
        });
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen().catch(err => {
          console.log(`${msg} ${err.message} (${err.name})`);
        });
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen().catch(err => {
          console.log(`${msg} ${err.message} (${err.name})`);
        });
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        try{
          document.webkitExitFullscreen();
        }catch(err){
          console.log(`${msg} ${err.message} (${err.name})`);
        }
      }
  }
}
var customControls = /*@__PURE__*/(function (Control) {
  function customControls(opt_options) {
    var options = opt_options || {};

    var button = document.createElement('button');
    button.innerHTML = "<li class='fa fa-arrow-left'></li>";

    var element = document.createElement('div');
    element.className = 'previous-buton ol-unselectable ol-control';
    element.appendChild(button);

    Control.call(this, {
      element: element,
      target: options.target
    });

    button.addEventListener('click', this.previousBtn.bind(this), false);
  }

  if ( Control ) customControls.__proto__ = Control;
  customControls.prototype = Object.create( Control && Control.prototype );
  customControls.prototype.constructor = customControls;

  customControls.prototype.previousBtn =  () => {
    toggleFullscreen();
  };

  return customControls;
}(Control));

export default class ViewMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersList:[],
      unknown:null,
    }
    this.olmap = {};
  }

  componentDidMount() {
    this.getUsersList();
    if (document.addEventListener){
     document.addEventListener('fullscreenchange', this.props.controls, false);
     document.addEventListener('mozfullscreenchange', this.props.controls, false);
     document.addEventListener('MSFullscreenChange', this.props.controls, false);
     document.addEventListener('webkitfullscreenchange', this.props.controls, false);
    }
    window.addEventListener('unhandledrejection', function(event) {
      // the event object has two special properties:
      console.log(event.promise, event.reason)
    });
  }

  mapInitialize = () => {

    var mapLayerSourceFeature = getMapLayerSourceFeature(this.state.usersList);

    const mapLayerSource = new VectorSource({
      features: mapLayerSourceFeature,
    });

    const mapLayer = new VectorLayer({
      source: mapLayerSource
    });

    var container = document.getElementById('popup');
    container.style.display = "block";
    var content = document.getElementById('popup-content');

    /**
     * Create an overlay to anchor the popup to the map.
     */
    var overlay = new Overlay({
      element: container,
      autoPan: false,
      autoPanAnimation: {
        duration: 1000
      }
    });

    this.olmap = new Map({
      interactions: new defaults({
        altShiftDragRotate:false,
        pinchRotate:false
      }),
      overlays: [overlay],
      target: 'viewMap',
      layers: [
        new TileLayer({
          source: new OSM({
            wrapX: true
          })
        }),
        mapLayer
     ],
    renderer: 'canvas',
      view: new View({
        center: fromLonLat([17, 10]),
        maxZoom: 20,
        zoom: 0,
        minZoom:0,
        extent:transformExtent([-180, -90, 180, 90], 'EPSG:4326', 'EPSG:3857'),
        constrainOnlyCenter: false
      })
    });

    // display popup on click
    this.olmap.on('pointermove', function(evt) {
      var feature = evt.map.forEachFeatureAtPixel(evt.pixel,
        function(feature) { return feature; }
      );
      if (feature) {
        content.innerHTML=feature.get('name');
        overlay.setOffset([0, 0]);
        overlay.setPositioning('bottom-right');
        overlay.setPosition(feature.getGeometry().getCoordinates());
        const delta = getOverlayOffsets(evt.map, overlay);
        if (delta[1] > 0) {
          overlay.setPositioning('bottom-center');
        }
        overlay.setOffset(delta);
      }else{
        overlay.setPosition(undefined);
      }
    });

    //On change resolution
    this.olmap.getView().on('change:resolution', function(evt){
      // document.getElementById("onMapLoad").style.display = "block";
      let view_map = document.getElementById("viewMap");
      if(view_map){
        view_map.style.opacity = "0.5";
        // document.getElementById("viewMap").style.width = "90vw";
      }
    });
    //After map rendered
    this.olmap.on('postrender', function(evt){
      let onMapLoad = document.getElementById("onMapLoad");
      if(null!=onMapLoad){
        onMapLoad.style.display = "none";
      }
      let viewMap = document.getElementById("viewMap");
      if(null!=viewMap){
        viewMap.style.opacity = "1";
      }
    });


    if(window.matchMedia('(max-width: 992px)').matches){
      /*Removing Zoom + & -*/
      this.olmap.getControls().forEach((control)=>{
        if (control instanceof Zoom) {
          this.olmap.removeControl(control);
        }
      });
      /*Adding custom control - previous button*/
      this.olmap.addControl(new customControls());
      toggleFullscreen();
    }

    // this.updateMap();
  }

  /*updateMap = () => {
    let userCenter = this.olmap.getView().targetCenter_;
    let zoomLevel = 0;
    if(this.olmap){
      this.state.usersList.forEach((item, index) => {
        //Implement map center value
        if(null!=item.user_info
          && item.user_info.includes("coordinates")){
          let userCoordinate = JSON.parse(item.user_info).coordinates;
          userCoordinate = userCoordinate.split(",").reverse();
          if(userCoordinate.length===2 && (userCoordinate[0]!=="0.0" || userCoordinate[1]!=="0.0")){
            userCenter = fromLonLat(userCoordinate);
            // var extent = new Polygon([[userCenter]]);
            // this.olmap.getView().fit(extent);
            // console.log(centerOfGeom);
            zoomLevel = zoomLevel + 1;
          }
        }
      });
      // this.olmap.getView().setCenter(userCenter);
      // this.olmap.getView().setZoom(20-zoomLevel);
    }
  }*/

  getUsersList = () => {
    let needMap = true;
    let crd = "";
    let usersList = this.state.usersList;
    let ulu=[];
    if(this.props.users.length>0){
      this.props.users.forEach((item, index) => {
        // console.log(item.first_name+" - "+item.user_info);
        if(item && item.user_info && item.user_info.includes("coordinates")){
          let userInfoMap = JSON.parse(item.user_info);
          let userCoordinate = userInfoMap.coordinates;
          userCoordinate = userCoordinate.split(",").reverse();
          if(userCoordinate.length===2
            && (userCoordinate[0]!=="0.0" || userCoordinate[1]!=="0.0")
            && (userCoordinate[0]>=-180 && userCoordinate[0]<=180)
            && (userCoordinate[1]>=-90 && userCoordinate[1]<=90)){
            crd = crd+JSON.parse(item.user_info).coordinates;
            usersList.push(item);
          }else{
            // console.log(fromLonLat(userCoordinate));
            ulu.push(item);
          }
        }else{
            ulu.push(item);
        }
      });
    }else{
      needMap = false;
    }

    if(needMap && crd.split(",").length>1){
      ulu.sort((s1,s2)=>{
        return moment(s2.last_accessed_on).isAfter(moment(s1.last_accessed_on));
      });
      this.setState({usersList:usersList,unknown:ulu});
      this.mapInitialize();
    }
    else{
      document.getElementById("viewMap").innerHTML = "No user coordinates found";
      document.getElementById("viewMap").style.fontSize = "20px";
    }
  }

  render() {

    return (<div>
        <div id="viewMap" className="mapStyle"></div>
        <div id="popup" className="ol-popup">
          <div id="popup-content"></div>
        </div>
        <div id="onMapLoad" className="fa fa-spinner fa-spin mapLoad"></div>
    </div>);
  }
}

function getMapLayerSourceFeature(coordinateList){
  let mapLSFList = [];
  coordinateList.forEach((item, index)=>{

    if(item && item.user_info
      && item.user_info.includes("coordinates")){
      let userInfoMap = JSON.parse(item.user_info);
      let userCoordinate = userInfoMap.coordinates;
      userCoordinate = userCoordinate.split(",").reverse();

        let userCenter = fromLonLat(userCoordinate);
        let mapLSFeature = new Feature({
          geometry: new Point(userCenter),
          name: mapDetails(item)
        });
        let marker = markerOffline;
        let userStatus = getUserStatus(item);
          if(userStatus==='Online'){
            marker = markerOnline;
          }else if(userStatus==='Streaming'){
            marker = markerOnline;
          }else{
            marker = markerOffline;
          }
        // specific style for that one point
        mapLSFeature.setStyle(new Style({
          image: new Icon({
            opacity: 1,
            scale: 0.05,
            src: marker,
          }),
          text: new Text({
            textAlign: "center",
            textBaseline: "middle",
            font: "inherit",
            text: item.first_name,
            fill: new Fill({color: 'white'}),
            backgroundFill:new Fill({color: roleColor(item)}),
            offsetX: 0,
            offsetY: -25,
            placement: "point",
            padding:[0,3,0,3],
            maxAngle: 0.7853981633974483,
            overflow: false,
            rotation: 0
          })
        }));
        mapLSFList.push(mapLSFeature);
    }
  });
  return mapLSFList;
}

function mapDetails(item){
  let userInfor = JSON.parse(item.user_info);
  let deviceDetail = item.user_info.includes("device_model")?userInfor.device_model+" "+
                      getAndroidVersion(userInfor.device_manufacturer):"";

  let userStatus = getUserStatus(item);
  //Overriding userStatus value
    if(userStatus==='Online'){
      userStatus = "<div class='ring-container'><div class='ringring'></div><div class='circle'></div></div>";
    }else if(userStatus==='Streaming'){
      userStatus = "<span class='spinner-grow text-success'></span>";
    }else{
      userStatus = "<span class='dot bg-danger'></span>";
    }
  let serailNo = item.user_info.includes("serial_number")?"SN: "+userInfor.serial_number:"";
  let osDetail = item.user_info.includes("os_name")?userInfor.os_name+"("+userInfor.os_version+")":"";
  let browserVersion = item.user_info.includes("browser_version")?"("+userInfor.browser_version.substring(0, 5)+")":"";
  let browserDetail = item.user_info.includes("browser_name")?userInfor.browser_name+browserVersion:"";


  return  "<div class='text-center'><b>"+userStatus+" "+item.first_name+" "+item.last_name+"</b></div>"+
          "<div class='text-center'><i class='fa fa-envelope'></i>"+item.email+"</div>"+
          "<div class='text-center'><i class='fa fa-phone'></i>"+item.phonenumber+"</div>"+
          "<div class='text-center'><small>"+deviceDetail+"</small></div> "+
          "<div class='text-center'><small>"+serailNo+"</small></div> "+
          "<div class='text-center'><small>"+osDetail+" "+browserDetail+"</small></div> ";
}

function getAndroidVersion(manuf){
  if(parseInt(manuf)>28){
    return "10";
  }else if(parseInt(manuf)>27){
    return "9";
  }else if(parseInt(manuf)>26){
    return "8.1";
  }else if(parseInt(manuf)>25){
    return "8";
  }else {
    return manuf;
  }
}

function getUserStatus(item){
    if(item.is_loggedin){
      return "Online";
    }else if(item.is_insession){
      return "Streaming";
    }else{
      return "Offline";
    }
}

function roleColor(item){
  return item.isadmin
  ? item.isexpert
    ?"#485890"
    :"#89783f"
  : item.isexpert
    ?"#485890"
    :!item.isadmin&&!item.isexpert
      ?"#71a95a"
      :"";
}

var getOverlayOffsets = function(mapInstance, overlay) {
  const overlayRect = overlay.getElement().getBoundingClientRect();
  const mapRect = mapInstance.getTargetElement().getBoundingClientRect();
  const margin = 50;
  // if (!ol.extent.containsExtent(mapRect, overlayRect)) //could use, but need to convert rect to extent
  const offsetLeft = overlayRect.left - mapRect.left;
  const offsetRight = mapRect.right - overlayRect.right;
  const offsetTop = overlayRect.top - mapRect.top;
  const offsetBottom = mapRect.bottom - overlayRect.bottom;
  // console.log('offsets', offsetLeft, offsetRight, offsetTop, offsetBottom);

  const delta = [0, 0];
  if (offsetLeft < 0) {
    // move overlay to the right
    delta[0] = margin - offsetLeft;
  } else if (offsetRight < 0) {
    // move overlay  to the left
    delta[0] = -(Math.abs(offsetRight) + margin);
  }
  if (offsetTop < 0) {
    // will change the positioning instead of the offset to move overlay down.
    delta[1] = margin - offsetTop;
  } else if (offsetBottom < 0) {
    // move overlay up - never happens if bottome-center is default.
    delta[1] = -(Math.abs(offsetBottom) + margin);
  }
  return (delta);
};
