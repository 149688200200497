/** Code written by Prerana Ray
 *  dated: 28022023 -- TP-4921
 */

import React, {Fragment} from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Row, Col } from 'reactstrap';
import {Button, Modal} from 'react-bootstrap';
import superagent from "superagent";
import LoadingThreeDots from '../Utils/LoadThreeDots';
import { connect } from "react-redux";
import {deleteCustomerSuccess} from '../redux/Customer/CustomerActions';
import "../customers_tab/customers.css";
import "./pendingapprovals.css";

const customerColumnsForEnterprise = [
    {name:"#",selector:"sl_no",maxWidth:"80px"},
    {name:"Account ID",selector:"account_id",maxWidth: "150px"},
    {name:"Account Name",selector:"company_name",maxWidth:"200px"},
    {name:"Description",selector:"description",maxWidth:"1000px"},
    {name:"Requested On",selector:"created_on",sortable:false,maxWidth:"200px"},
    {name:"Action",selector:"action",sortable:false,minWidth:"200px"}
];

  function calculateCost(row){
    let totalcost = 0;
    row.purchases.forEach((purchase, i) => {
      if(2===purchase.plan_id)
        totalcost=totalcost+240*purchase.total_licences;
      else if(3===purchase.plan_id)
        totalcost=totalcost+600*purchase.total_licences;
      else if(row.total_price)
        totalcost = row.total_price; 
      else if(undefined!==row.price)
        totalcost = purchase.total_licences * row.price;
      else totalcost="";
    });
  return totalcost;
  }



// function capitalizeWords(str){
//   let words =  str.split('_');
//   for(let i =0;i < words.length;i++){
//     words[i] =  words[i].charAt(0).toUpperCase() + words[i].slice(1);
//   }
//   return words.join(" ");
// }
 class PendingApprovalsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      showExpiry:{
        value: false,
        label:"Show All",
        id:"expired-accounts"
      },
      showPopUp:false,
      modalTitle:null,
      modalBody:null,
      selectedRow:null,
      serverMsg:null,
      isPostPaidAccDel:true,
      adminUserInfo:null,
      purchaseDetails:null,
      inviteList:[],
      isOnload:true,
      isLoading: true,
      pageNumber:1
    };
  }

  componentDidMount() {
    this.setCustomerList();
  }
// TP-6932
  setCustomerList = () => {
    const {state} = this.props.params;
    const {inviteList} = state;
    let {customers} = this.props;    
    customers = customers.filter(c=>"enterprise_requested"===c.subscription_status);
    if (customers.length > 0) {
        customers.forEach((cs,index) => {
            cs.isModifyReq = false;
            cs.slNo = index + 1;
            cs.description = "New account creation request";
            cs.approveURL = "/api/approveEnterpriseRequest?code="+cs.code+"&purchaseId="+cs.credits[0].purchase_id;
            cs.rejectURL = "/api/rejectEnterpriseRequest?code="+cs.code+"&purchaseId="+cs.credits[0].purchase_id;
        })
    }    
    //console.log(customers);
    this.setState({customers : customers,inviteList : inviteList}, () => {
        this.fetchPendingModifyAccount();
    });
     //TP-962-> if the customer list is 0 then show past date list
    if(customers.length===0 && this.state.isOnload){
      //this.toggleExpiry();
      this.setState({isOnload:false})
    }
  }

  customSort = (rows, field, direction) => {
    if(null===field) field="created_on";
    let custPicked = rows.filter(row=>null!==row[field]);
    let custNullPicked = rows.filter(row=>null===row[field]);
    let mul=-1;
    if("asc"===direction){
      mul=1;
    }else if("desc"===direction){
      mul=-1;
    }

    // console.log(rows, field, direction);
    if("last_used_on"===field||"subscription_start_date"===field||"subscription_end_date"===field||"created_on"===field){
      custPicked.sort((c1,c2)=>{
        if(moment(c1[field]).isAfter(moment(c2[field]))){
          return -1*mul;
        }else if(moment(c1[field]).isBefore(moment(c2[field]))){
          return 1*mul;
        }else return 0;
      });
    }else if("company_name"===field||"last_used_by"===field){
      custPicked.sort((a, b)=>{
        if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
        else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
        return 0;
        })
    }else if("total_licences"===field){
      custPicked.sort((a, b)=>{
        if(a[field] < b[field]) return -1*mul;
        else if(a[field] > b[field]) return 1*mul;
        return 0;
        })
    }else if("cost"===field){
      custPicked = rows.filter(row=>""!==calculateCost(row));
      custNullPicked = rows.filter(row=>""===calculateCost(row));
      custPicked.sort((a, b)=>{
        if(calculateCost(a) < calculateCost(b)) return -1*mul;
        else if(calculateCost(a) > calculateCost(b)) return 1*mul;
        return 0;
        })
    }else if("total_usage"===field){
      custPicked.sort((a, b)=>{
        if(a.total_used+a.total_max < b.total_used+b.total_max) return -1*mul;
        else if(a.total_used+a.total_max > b.total_used+b.total_max) return 1*mul;
        return 0;
        })
    }

        custPicked.forEach((cp,index) => {
              cp.slNo = index + 1;
             })   // TP-6475 
    return [...custPicked, ...custNullPicked];
  }

  keyPress = (e) =>{
    if(!e) e = window.event;
    //Customer History
    if(e.keyCode === 13 && e.target.value.length>0){
      // Declare variables
      var filter = e.target.value.toLowerCase();
      var item = this.state.customers.filter(function(value){
        value.company_name=null==value.company_name?value.account_id:value.company_name;

        return value.company_name.toLowerCase().includes(filter)
        || value.account_id.toLowerCase().includes(filter);
      });
      this.setState({'customers':item});
    }else{
      this.setCustomerList();
    }
  }

setPendingCustomers = (data) => {
    const {customers} = this.props;
    //console.log(customers);
    let { pendingRequest} = data;
    let finalCustomerData = [...this.state.customers];
    const length = finalCustomerData.length;
    pendingRequest.forEach((pr,index) => {
        const customer = customers.find( c => c.id === parseInt(pr.customer_id))
        if (customer && customer !== null) {
            pr.isModifyReq = true;
            pr.account_id = customer.account_id;
            pr.company_name = customer.company_name;
            pr.description = "Modify request";
            pr.account_details = customer;
            pr.approveURL = "/api/approveEnterpriseModificationRequest?code="+customer.code+"&requestId="+pr.id;
            pr.rejectURL = "/api/rejectEnterpriseModificationRequest?code="+customer.code+"&requestId="+pr.id;
            finalCustomerData.push(pr)
        }
    });
    finalCustomerData.forEach((fC, index) => { fC.slNo = index + 1; });
    //console.log(finalCustomerData);
    this.setState({
        customers: finalCustomerData
    }, () => {
      this.setState({isLoading: false});
        //console.log(this.state.customers);
    })
}

fetchPendingModifyAccount = async () => {
    let apiUrl = "/api/dash/getPendingAccountApprovals";
    let msg;
    fetch(sessionStorage.getItem("url") + apiUrl, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + sessionStorage.getItem("userToken")
        },
    })
    .then((response) => response.json())
    .then((data) => {                                                                
        this.setPendingCustomers(data);
    })
    .catch((error) => {
        if (undefined === error.status) {
          msg = "May be internet is not connected, try again sometime";
        } else if (403 === error.status) {
          msg = "You are not authorized to fetch this data";
        } else if (500 === error.status) {
          msg = "Something went wrong, try again sometime";
        }
        this.setState({serverMsg:msg,showPopUp:false,selectedRow:null,modalTitle:"Alert"});
    });
}

setCustomerListAfterRemoved = (removedSerialNo) =>{
  let {customers} = this.state;
      customers = customers.filter((cust) => cust.slNo !== removedSerialNo);
      customers.forEach((cus, index) => { cus.slNo = index + 1; });
      this.setState({customers:customers});
      document.getElementById("superscriptValue").innerText = customers.length;  // TP-6475
}

closeAlertPopUp = () => {
  this.setState({serverMsg:null,selectedRow:null,modalTitle:null,modalBody:null});
}

setDataTablePage(pageNum){
  this.setState({pageNumber:pageNum})
}

getDescription = (row) => {
  return (
    <>{row.description}
      { row.isModifyReq === true ?
          <div className="margin-left" onClick={() => this.openDesModal(row)} id={row.id}>
            - <a className="hyperlink text-ellipses margin-left">Review</a>
          </div>
        :
          ''
      }
    </>
  )
}

openDesModal = (row) => {
  this.setState({
    descTitle: row.company_name,
    descRow: row,
    showDescPopup: true
  })
}

closeDesModal = () => {
  this.setState({
    descTitle: '',
    descRow: null,
    showDescPopup: false
  })
}

onApprove = (serial, url) => {
  this.setCustomerListAfterRemoved(serial);
  this.closeDesModal();
  window.open(sessionStorage.getItem("url")+url);//TP-4968
}

onReject = (serial, url) => {
  this.setCustomerListAfterRemoved(serial);
  this.closeDesModal();
  window.open(sessionStorage.getItem("url")+url);//TP-4968
}


  render() {
        let {isLoading} = this.state;
        customerColumnsForEnterprise[0].cell=(row,index) =><div><span>{row.slNo}</span></div>;
        customerColumnsForEnterprise[1].cell=row =><div onClick={() => this.props.params.breadcrumbTail("customer",row.account_details,row.account_id)}
                                      className="hyperlink text-ellipses" data-toggle="tooltip"
                                      title="View Details">
                                      {row.account_id}
                                      </div>;
        customerColumnsForEnterprise[2].cell=row =><div onClick={null!==row.company_name? () => this.props.params.breadcrumbTail("customer",row.account_details,row.company_name): () => {return null;}}
                                        className="hyperlink text-ellipses" data-toggle="tooltip"
                                        title="View Details">
                                        {null!==row.company_name?row.company_name:''}
                                        </div>;
        customerColumnsForEnterprise[3].cell=row =>this.getDescription(row);
        customerColumnsForEnterprise[4].cell=row => moment(row.created_on).format("DD MMM,YYYY HH:mm");
        customerColumnsForEnterprise[5].cell=row => row.id && (sessionStorage.userDbRole === "admin" || sessionStorage.userDbRole === "write_my_account") && row.subscription_status !== "trial_approved" && row.subscription_status !== "trial_pending_approval" ? 
            <span ><a className="margin-left" onClick={() => this.onApprove(row.slNo, row.approveURL) /**TP-4968*/}><i className="fa fa-check-circle fa-1x account-approve" title="Approve" ></i></a><a className="margin-left" target="_blank" onClick={() => this.onReject(row.slNo, row.rejectURL)}><i className="fa fa-times-circle fa-1x account-reject" title="Reject"></i></a></span> :<div className="text-ellipses"></div>
    
    return (<div className="page">
        <div className="media">
          <h3>Please rotate your device to landscape mode</h3>
        </div>
        <div className="container-fluid media-show">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              { 
              <DataTable
                pagination={this.state.customers.length>this.props.pagination}
                paginationPerPage={this.props.pagination}
                paginationTotalRows={this.state.customers.length}
                onChangePage={page => this.setDataTablePage(page)}
                paginationDefaultPage={this.state.pageNumber}
                columns={this.state.customers.length>0 && sessionStorage.userDbRole === "admin"  ? customerColumnsForEnterprise:customerColumnsForEnterprise}
                data={this.state.customers}
                style={{overflow:'hidden'}}
                keyField={""}
                className="customers-table"
                sortFunction={(rows, field, direction)=>this.customSort(rows, field, direction)}
                
              />
            }
            </div>
          </div>
        </div>
        { this.state.showDescPopup &&
          <DescPopup modalTitle={this.state.descTitle} showDescPopup = {this.state.showDescPopup}
            row={this.state.descRow} closeDesModal={this.closeDesModal} onApprove={this.onApprove} onReject={this.onReject} />
        }

        {this.state.serverMsg&&
        <AlertPopUp modalTitle={this.state.modalTitle} 
        modalBody={this.state.serverMsg} 
        closeAlert={this.closeAlertPopUp}/>}

        {isLoading && <LoadingThreeDots isLoading={isLoading}/> } 
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteCustomerSuccess: (accountId) => dispatch(deleteCustomerSuccess(accountId)),
});


export default connect(null,mapDispatchToProps)(PendingApprovalsTab);




class AlertPopUp extends React.Component{
	constructor(props){
	  super(props);
	  this.state = {
		alertPopUp: false
	  }
	  this.close = this.close.bind(this);
	}
  
	close() {
	  this.setState({alertPopUp:false});
	  this.props.closeAlert();
	}
  
	componentDidMount(){
	  this.setState({alertPopUp:true});
	}
  
	render() {
	  return (
		  <Modal show={this.state.alertPopUp} onHide={this.close} backdrop="static" keyboard={false}>
			<Modal.Header>
			  <Modal.Title>{this.props.modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  {this.props.modalBody}
			</Modal.Body>
			<Modal.Footer>
			  <Button onClick={this.close} className="btn-green">OK</Button>
			</Modal.Footer>
		  </Modal>
	  );
	}
  }

  const descColumns = [
    {name: "Subscription end date", prevId: "subscription_end_date", currId: "end_date", isDate: true},
    {name: "Total number of users", prevId: "quantity", currId: "quantity"},
    {name: "Total Price", prevId: "total_price", currId: "total_price"},
    {name: "Monthly free minutes", prevId: "monthly_minutes", currId: "monthly_minutes"},
    {name: "Overage cost (per min)", prevId: "overage_cost", currId: "overage_cost"},
    {name: "Audio config", prevId: "audio_mode", currId: "audio_mode"},
    {name: "Number of experts", prevId: "number_of_experts", currId: "number_of_experts"},
    {name: "Account is not active", prevId: "isInactive", currId: "is_inactive"},
    {name: "Is Streaming Mode customizable", prevId: "is_customizable", currId: "is_customizable"},
    {name: "Jobs enabled", prevId: "is_gps_enabled", currId: "is_gps_enabled"},
    {name: "Assign jobs to all users", prevId: "assign_jobs_to_all_users", currId: "assign_jobs_to_all_users"},
    {name: "Scan Asset", prevId: "asset_scan", currId: "asset_scan"},
    {name: "Streaming Medium", prevId: "streaming_medium", currId: "streaming_medium"},
    {name: "Solo Program", prevId: "is_solo_program", currId: "is_solo_program"},
    {name: "Allow Guest Invite", prevId: "guest_user_enabled", currId: "guest_user_enabled"},
    {name: "Screen Share Mode", prevId: "screen_share_mode", currId: "screen_share_mode"},
    {name: "Password Expiry Duration", prevId: "password_expiry_duration", currId: "password_expiry_duration"},
    {name: "Screen Recording Enabled", prevId: "screen_recording_enabled", currId: "screen_recording_enabled"},
    {name: "Zoom Enabled", prevId: "zoom_enabled", currId: "zoom_enabled"},
    {name: "Torch Enabled", prevId: "torch_enabled", currId: "torch_enabled"},
    {name: "Annotation Color Enabled", prevId: "annotation_color_enabled", currId: "annotation_color_enabled"},
    {name: "Annotation Shape Enabled", prevId: "annotation_shape_enabled", currId: "annotation_shape_enabled"},
    {name: "Install By Date", prevId: "install_by_date", currId: "install_by_date", isDate: true},
    {name: "Swap Camera Enabled", prevId: "swap_camera_enabled", currId: "swap_camera_enabled"},
    {name: "Offline Jobs Enabled", prevId: "is_offline_jobs_enabled", currId: "is_offline_jobs_enabled"},
    {name: "Behaviour", prevId: "behaviour", currId: "behaviour"},
    {name: "Expert Can Disconnect User", prevId: "expert_can_disconnect_user", currId: "expert_can_disconnect_user"},
    {name: "User Can Turn Off Video", prevId: "can_turn_off_user_video", currId: "can_turn_off_user_video"},
    {name: "Reset User Password", prevId: "reset_user_password", currId: "reset_user_password"},
    {name: "Download Usage", prevId: "download_usage", currId: "download_usage"},
    {name: "Invite Online User", prevId: "invite_online_user", currId: "invite_online_user"},
    {name: "Allow Rebranding", prevId: "allow_custom_logo", currId: "allow_custom_logo"},
    {name: "Jobs Logo Url", prevId: "jobs_logo_url", currId: "jobs_logo_url"},
    {name: "Custom Report Author", prevId: "allow_company_name_as_report_author", currId: "allow_company_name_as_report_author"},
    {name: "Enable Device Wake Up For Calls", prevId: "wake_up_device_for_calls", currId: "wake_up_device_for_calls"},
    {name: "Enable Silent Recording", prevId: "enable_silent_recording", currId: "enable_silent_recording"},
    {name: "Enable Remote Capture", prevId: "enable_remote_capture", currId: "enable_remote_capture"},
    {name: "Allow IOS Expert Login", prevId: "is_ios_login_allowed", currId: "is_ios_login_allowed"},
    {name: "Company Name", prevId: "company_name", currId: "company_name"},
    {name: "Company Url", prevId: "company_url", currId: "company_url"},
    {name: "Storage Type", prevId: "storage_config", currId: "storage_config"},
    {name: "Enable Asset Class", prevId: "enable_asset_class", currId: "enable_asset_class"},
    {name: "Enable Knowledge Capture", prevId: "enable_knowledge_capture", currId: "enable_knowledge_capture"},
    {name: "Enable Checklist", prevId: "enable_checklist", currId: "enable_checklist"},
    {name: "Mandatory Assignee Jobs", prevId: "mandatory_assignee_jobs", currId: "mandatory_assignee_jobs"},
    {name: "Enable Streaming", prevId: "enable_streaming", currId: "enable_streaming"},
    {name: "Idle Timeout", prevId: "idle_timeout", currId: "idle_timeout"},
    {name: "Session Type", prevId: "session_type", currId: "session_type"},
    {name: "Enable Skip Task", prevId: "enable_skip_task", currId: "enable_skip_task"},
    {name: "Enable Next Task", prevId: "enable_next_task", currId: "enable_next_task"},
    {name: "Enable Job Template", prevId: "enable_job_template", currId: "enable_job_template"},
    {name: "Enable User Video", prevId: "enable_user_video", currId: "enable_user_video"},
    {name: "Enable Auto Connect", prevId: "enable_auto_connect", currId: "enable_auto_connect"},
    {name: "Allow New Evidence", prevId: "allow_new_evidence", currId: "allow_new_evidence"},
    {name: "Allow Technician Job", prevId: "create_scan_job_by_technician", currId: "create_scan_job_by_technician"},
    {name: "Allow Annotation Pointer", prevId: "allow_annotation_pointer", currId: "allow_annotation_pointer"},
    {name: "Enable Job Reviewer", prevId: "enable_job_reviewer", currId: "enable_job_reviewer"},
    {name: "License Key", prevId: "license_key", currId: "license_key", maxSize: "40"}
  ] 

  class DescPopup extends React.Component{
    constructor(props){
      super(props);
      
    }
    componentDidMount(){
      //this.setState({alertPopUp:true});
    }

    getformattedCell = (value, maxChar) => {
      let newVal = value;
      if (value.length > maxChar) {
        newVal = value.slice(0, maxChar-1);
        newVal+= "..."
      }
      return newVal;
    }
    
    render() {
      const {row} = this.props;
      const newWidth = Math.floor((window.innerWidth * 2)/3)
      const bodyStyle = {'width': newWidth+"px", 'align': 'center', 'overflowY': 'auto'}
      const tableStyle = {'width': (newWidth - 40)+"px", 'align': 'center', 'overflowY': 'auto'}
      return (
        <Modal id="approval_modal" size="lg" show={this.props.showDescPopup} onHide={this.props.closeDesModal} style={{maxWidth: window.innerWidth+"px", left: "-13%"}} backdrop="static" keyboard={false}>
        <Modal.Header style={bodyStyle}>
          <Modal.Title>{this.props.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={bodyStyle}>
          <table style={tableStyle} border="2" cellPadding="3" cellSpacing="1">                                                    
            <thead>
            <tr>
              <th>Settings</th>
              <th>Current Settings</th>
              <th>Requested Settings</th>
            </tr>
            </thead>
            <tbody>
              { descColumns.map(col => {
                  return (
                    <tr>
                      <td>{col.name}</td>
                      <td>{ col.isDate && col.isDate === true ?
                            moment(row.account_details[col.prevId]).format("DD MMM,YYYY HH:mm")
                            :
                              col.maxSize ?
                              this.getformattedCell(row.account_details[col.prevId], col.maxSize)
                            :
                              row.account_details[col.prevId] !== undefined && row.account_details[col.prevId] !== null && row.account_details[col.prevId] !== "" ?
                                row.account_details[col.prevId].toString() : ''
                          }</td>
                      <td>{ col.isDate && col.isDate === true ?
                            moment(row[col.currId]).format("DD MMM,YYYY HH:mm")
                            :
                              col.maxSize ?
                              this.getformattedCell(row.account_details[col.prevId], col.maxSize)
                            :
                              row[col.currId] !== undefined && row[col.currId] !== null && row[col.currId] !== "" ?
                                row[col.currId].toString() : ''
                          }</td>
                    </tr>
                  )
                })
              }               
                                                               
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Row style={{width: "100%"}}>
            <Col md="4">
                <button className="btn-green-new" onClick={() => this.props.onApprove(row.slNo, row.approveURL) /**TP-4968*/}>Approve</button>  
              
            </Col>
            <Col md="4">
                <button className="btn-red-new" onClick={() => this.props.onReject(row.slNo, row.rejectURL) /**TP-4968*/}>Reject</button>
            </Col>
            <Col md="4">
              <button onClick={this.props.closeDesModal} className="btn-green-new ">Close</button>
            </Col>
          </Row>
        </Modal.Footer>
        </Modal>
      );
    }
    }
  