//TP-4211 -- Written by Prerana Ray -- 29122022
const en = require('./en/messages.json');
const jaJP = require('./ja-JP/messages.json');
const zhcn = require('./zh-cn/messages.json');
const es = require('./es/messages.json');
const it = require('./it/messages.json');
const de = require('./de/messages.json');
const pt = require('./pt/messages.json');
const fr = require('./fr/messages.json');

export const localizedData = {
    "en": en,
    "ja-JP": jaJP,
    "zh-cn": zhcn,
    "es": es,
    "it": it,
    "de": de,
    "pt": pt,
    "fr": fr
}
