import React from "react";
import PieChart from "../graph/pieChart";
import BarChart from "../graph/barChart";

export default class Licenses extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      barChart:{}
    }
  }

  componentDidMount(){
    this.getBarChart(this.props.licenses.users);
  }

  getBarChart = async (item) => {
    await this.setState({barChart:{}});
    let usersDataSets = [];
    let userDataLabels = [];
    let overageData = [];
    let availableData = [];
    let usedData = [];

    item.sort((s1,s2)=>{
      let usedMinutes1 = +s1.max_monthly_minutes - +s1.available_monthly_minutes;
      let usedMinutes2 = +s2.max_monthly_minutes - +s2.available_monthly_minutes;
      if(usedMinutes1<usedMinutes2){
        return 1;
      }else if(usedMinutes1>usedMinutes2){
        return -1;
      }else return 0;
    }).forEach((user,index)=>{
      if(null!==user.max_monthly_minutes){
        userDataLabels.push(user.first_name);
        overageData.push(+user.overage_monthly_minutes);
        availableData.push(+user.available_monthly_minutes);
        usedData.push(+user.max_monthly_minutes - +user.available_monthly_minutes);
      }
    });
    usersDataSets = [
      {
         label: 'Used',
         data: usedData,
         backgroundColor: "#71a95a",
         barPercentage: 0.5,
         maxBarThickness: 50,
      }, {
         label: 'Available',
         data: availableData,
         backgroundColor: "Gray",
         hoverBackgroundColor: 'rgb(128,128,128, 0.9)',
         barPercentage: 0.5,
         maxBarThickness: 50,
      }, {
         label: 'Overage',
         data: overageData,
         backgroundColor: "RGB(255, 0, 0)",
         barPercentage: 0.5,
         maxBarThickness: 50,
      }
    ];

    let usageOptions = {
      plugins: {
        labels:false,
        datalabels: {
            display: true,
            align: 'center',
            anchor: 'center',
            color:'white',
            font:{
              family:"Titillium Web",
              size:window.matchMedia('(max-width: 992px)').matches?12:16,
            },
            formatter:(value, context) => {if(value>0)return value;else return "";}
         }
      },
      legend: {
          display: false,
          position: 'right',
          labels: {
           ...this.props.barChart.defaultLabelStyle
          }
      },
      tooltips:"",
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          ticks: this.props.barChart.defaultLabelStyle,
          minBarLength:2,
          position: 'right'
          }],
        yAxes: [{
          stacked: true,
          ticks: {
            callback: function(value, index, values) {
              if(index%2===0){
               return value+" min";
              }else{
                return "";
              }
            },
            min: 0,
            ...this.props.barChart.defaultLabelStyle
          }
        }]
      },
      layout: {
        padding: {top: 20}
      },
      'onClick' : (evt, user) => {
        if(user.length>0){
          let data = item[user[0]._index];
          this.props.params.breadcrumbTail("user",data,data.first_name,this.props.licenses.users);
        }
      },
      hover: {
         onHover: function(e){
            var point = this.getElementAtEvent(e);
            if (point.length) e.target.style.cursor = 'pointer';
            else e.target.style.cursor = 'default';
         }
      },
      animation:{
        onProgress:function(animate){
            if(animate.chart.data.datasets[0].data.length>15 && document.getElementById("expandId")){
              document.getElementById("expandId").style.width = animate.chart.data.datasets[0].data.length * 100 + "px";
            }
        }
      }
    }
    if(userDataLabels.length>0){
      this.setState({
        barChart : {
          dataSets: usersDataSets,
          dataLabels:userDataLabels,
          options:usageOptions,
        }
      });
    }
  }

  render(){
    return(
<div className="page">
  <div className="container-fluid">
    <div className="row">
      <div className="col-sm-12 col-lg-6">
        <PieChart width="300" height="100" analyticData={this.props.pieChart1} chartId="byAssignment"/>
        <h5 className="chart-title text-center">By Assignment ({this.props.licenses.credits.length})</h5>
      </div>
      <div className="col-sm-12 col-lg-6">
        <PieChart width="300" height="100" analyticData={this.props.pieChart2} chartId="byRole"/>
        <h5 className="chart-title text-center">
          <div onClick={()=>this.getBarChart(this.props.licenses.users)} className="hyperlink">
            By Role ({this.props.licenses.users.length})
          </div>
        </h5>
      </div>
      {
        this.state.barChart.dataSets && "true" !== sessionStorage.isSmartVision ?
        <>
        <div className="col-sm-12 col-lg-11">
          <BarChart width="800" height="220" analyticData={this.state.barChart} chartId="byUsage"/>
          <h5 className="chart-title text-center m-n1">By Usage</h5>
        </div>
        <div className="col-sm-12 col-lg-1">
          <div className="row">
            <div className="col-sm-3 col-lg-12">
              <span className="user-type-flag chart-title" style={{backgroundColor:'red'}}>&nbsp;</span>
              Overage&nbsp;
            </div>
            <div className="col-sm-3 col-lg-12">
              <span className="user-type-flag chart-title" style={{backgroundColor:'gray'}}>&nbsp;</span>
              Available&nbsp;
            </div>
            <div className="col-sm-3 col-lg-12">
              <span className="user-type-flag chart-title" style={{backgroundColor:'#71a95a'}}>&nbsp;</span>
              Used&nbsp;
            </div>
          </div>
        </div>
        </>:
        <div className="col-sm-12 col-lg-12">
          <h5 className="chart-title text-center">
          <div onClick={()=>this.props.params.breadcrumbTail("users",this.props.licenses.users, "Users", "usersbylicense")} className="hyperlink">
            All Users Information ({this.props.licenses.users.length})
          </div>
          </h5>
        </div>
      }
    </div>
    <div id="chartjs-tooltip"></div>
  </div>
</div>
    )
  }
}
