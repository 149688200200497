import React,{useEffect,useRef} from 'react';
import drawChart from './drawChart';
import './donutChart.css'

const DonutChart = ({data})=>{
    const ref = useRef(null);
    const legRef = useRef(null);

    useEffect(()=>{
        if(ref.current)
        drawChart(ref.current,'donutLegend',data);
    },[data])

    return(
        <div className='container'>
            <div id='donutChart' className ='graph' ref={ref}></div>
            <div id='donutLegend' className ="chart__legend" ref={legRef}></div>
        </div>
    );

};

export default React.memo(DonutChart);