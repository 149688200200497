export const CustomerActionTypes = {
    FETCH_CUSTOMER_START:"FETCH_CUSTOMER_START",
    FETCH_CUSTOMER_END:"FETCH_CUSTOMER_END",
    FETCH_CUSTOMER_SUCCESS:"FETCH_CUSTOMER_SUCCESS",
    FETCH_CUSTOMER_FAILURE:"FETCH_CUSTOMER_FAILURE",
    FETCH_ENTERPRISE_SUCCESS:"FETCH_ENTERPRISE_SUCCESS",
    FETCH_ENTERPRISE_USER_PROFILE_SUCCESS:"FETCH_ENTERPRISE_USER_PROFILE_SUCCESS",
    FETCH_ENTERPRISE_USER_PROFILE_FAILURE:"FETCH_ENTERPRISE_USER_PROFILE_FAILURE",
    DELETE_CUSTOMER_SUCCESS:"DELETE_CUSTOMER_SUCCESS",
    DELETE_CUSTOMER_FAILURE:"DELETE_CUSTOMER_FAILURE",
    UPDATE_USER_KEY_SUCCESS:"UPDATE_USER_KEY_SUCCESS",
    UPDATE_USER_KEY_FAILURE:"UPDATE_USER_KEY_FAILURE",
    UPDATE_PAGE_LOAD_SUCCESS:"UPDATE_PAGE_LOAD_SUCCESS",
    UPDATE_USER_PROFILE_ACTION:"UPDATE_USER_PROFILE_ACTION",
    UPDATE_PROCESSING_START:"UPDATE_PROCESSING_START",
    UPDATE_PROCESSING_END:"UPDATE_PROCESSING_END"
  };