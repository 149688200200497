//TP-4211
import React from 'react'

export default function jobsZipIcon(props) {
    return (
        <span className={props.rowData && props.rowData.reportIconClass ? 'icon wrapper download-report-space '+props.rowData.reportIconClass :"icon wrapper download-report-space"}
            onClick={(e)=>props.openModal(e,true,props.rowData)} /**TP-4210*/ title="Download Report" >
            <i className="fa fa-download fa-2x"></i>
        </span>
    )
}