import { combineReducers } from "redux";
import groupReducer from "./Group/GroupReducer";
import inviteReducer from './Invite/InviteReducer';
import customerReducer from './Customer/CustomerReducer';

const rootReducer = combineReducers({
  groups: groupReducer,
  invites:inviteReducer,
  customers:customerReducer,
});

export default rootReducer;