import React from 'react';
import moment from "moment";
import superagent from "superagent";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import JobDetails from "./jobDetails";
// import JobReport from "./jobsReports";
import generateZip from './generateZip';
import GenerarteJobPDF from "./generateJobPDF";
import LoadThreeDots from '../Utils/LoadThreeDots';
import JobsPdfIcon from './jobsPdfIcon';
import JobsZipIcon from './jobsZipIcon';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

//TP-4210 -- languages supported
const languageArr = [
  {label: "English", value: "en"},
  {label: "中文", value: "zh-CN"},
  {label: "Français", value: "fr"},
  {label: "Español", value: "es"},
  {label: "Italiana", value: "it"},
  {label: "Português", value: "pt"},
  {label: "Deutsch", value: "de"},
  {label: "日本", value: "ja-JP"},
]


const userjobsColumn = [
{name:"#",selector:"id",sortable:true,width:"70px"},
{name:"Job Name",selector:"name",sortable:true,maxWidth:"40vw"},
{name:"Description",selector:"description",sortable:true,maxWidth:"30vw"},
{name:"Created By",selector:"creater_fname",sortable:true,width:"150px"},
{name:"Assigned On",selector:"start_date",sortable:true,width:"130px"},
{name:"Status",selector:"status",sortable:true,width:"100px"},
{name:"Type", selector:"job_type", sortable:true,width:"100px"}, /**TP-2774 */
{name:"Report",selector:"report",sortable:false,width:"100px"},
]

export const constJobStatusFlag = {
  finished:"Finished",
  in_progress:"In Progress",
  created:"Created"
}

export const getDownLoadIcon = (rowData,customerDtls) =>{
  return(
    <span className={rowData && rowData.reportIconClass ? 'icon wrapper download-report-space '+rowData.reportIconClass :"icon wrapper download-report-space"} 
          onClick={()=> onClickDownloadReport(rowData,customerDtls)} title="Download">
          <i className="fa fa-file-pdf-o shrink" aria-hidden="true"></i>
    </span>
  );
}

export const DownloadZipDiv = (rowData,customerDtls,props)=>{
  return( 
    <span  className={rowData && rowData.reportIconClass ? 'icon wrapper download-report-space '+rowData.reportIconClass :"icon wrapper download-report-space"}
      onClick={()=> props.startDownload(rowData,customerDtls)} title="Download Report">
      <i className="fa fa-download fa-2x"></i>
      {/* <i class="fas fa-cloud-download-alt"></i> */}
    </span>
  );
}

const onClickDownloadReport = async (selectedJOb,customerDtls) =>{

  if(selectedJOb && selectedJOb.id){
    if(sessionStorage.userToken) {
      await  superagent
        .get(sessionStorage.getItem("url")+`/api/jobs/getJobReport/`+selectedJOb.id)
        .set("Authorization", "Bearer " + sessionStorage.userToken)
        .then(response => response)
        .then(async data => {
          await GenerarteJobPDF({data,selectedJob:selectedJOb, customer:customerDtls});
      }
    ).catch(error => {
      let msg=null;
      if(undefined===error.status){
        msg = "Internet is not connected, please try again after connecting network";
      }else if(400===error.status){
        msg = "condition not matching so jobs not found";
      }else if(401===error.status){
        msg = "Session token experied, Please re-login";
      }else if(403===error.status){
        msg = "You are not authorized";
      }else if(422===error.status){
        msg = "Invalid parameters sent";
      }else if(500===error.status){
        msg = "Something went wrong, try again later";
      }else{
        console.error(error +msg);
      }
     
    });
  }
  }
}


export default class JobsDiagnostic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userjobsList:[],
      start_date: new Date().setDate(new Date().getDate()-1),
      end_date: new Date(),
      globalHunt:[],
      searchLabel:[],
      selectedOptions:[],
      showViewPage: false,
      selectedJobs:{},
      language: "en", //TP-4210
      selectedJob:null, //TP-4210
      showDateFilter:true,
      minDate:new Date().setDate(new Date().getDate()-1),
      isLoading:false,
      isDownloading:false,
      selectedPageNumber:1,
      isGenerateZipReport: false //TP-4211
    };
  }

  startDateChange = date => {
      this.setState({start_date: date},()=> this.searchJobByDate());
  };

  endDateChange = date => {
    this.setState({end_date: date},()=>this.searchJobByDate());
  };

  componentDidMount = () => {
    this.getJobs();  
  }



componentWillReceiveProps = ({tabIndex})=>{
  if(tabIndex === "2"){
    this.setState({showViewPage:false,showDateFilter:true});
    this.getJobs();
  }
}


copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

  getJobs = () =>{

    let paramVal = {};
    paramVal.userId = this.props.viewDetails.id;
    if(this.props.viewDetails.isexpert)
      paramVal.forExpert = true;

    this.getJobsDetails(paramVal);
   
  }


  getJobsDetails = async (params) =>{
 
      if(sessionStorage.userToken) {
        this.setState({isLoading: true});
        await  superagent
          .post(sessionStorage.getItem("url")+`/api/dash/getJobsOfUsers`)
          .set("Authorization", "Bearer " + sessionStorage.userToken)
          .send(params)
          .then(response => response)
          .then(data => {
            if (null !== data.body) {
             this.setState({userjobsList:this.showRawJson(data.body)},()=>this.feedGlobalHunt());
          }
          this.setState({isLoading: false});
        }
      ).catch(error => {
        let msg=null;
        if(undefined===error.status){
          msg = "Internet is not connected, please try again after connecting network";
        }else if(400===error.status){
          msg = "condition not matching so jobs not found";
        }else if(401===error.status){
          msg = "Session token experied, Please re-login";
        }else if(403===error.status){
          msg = "You are not authorized";
        }else if(422===error.status){
          msg = "Invalid parameters sent";
        }else if(500===error.status){
          msg = "Something went wrong, try again later";
        }else{
          console.error(error);
        }
        this.setState({isLoading: false,serverError:msg});
      });
    }
  }

  changeColorClassName = (obj) =>{

    let todayDate = moment(new Date(), 'DD-MM-YYYY');
    let pastDate = moment(new Date(obj.end_date),'DD-MM-YYYY');

    if(obj.status === "finished"){
      obj.className = "green";
      obj.reportIconClass = "report-green"; 
    }else if (todayDate.isBefore(pastDate) && (obj.status === "in_progress" || obj.status === "created")){
      obj.className = "yellow";
      obj.reportIconClass = "report-yellow"; 
    }else if ((obj.status === "in_progress" || obj.status === "created")){
      obj.className = "red";
      obj.reportIconClass = "report-red";
      
    }
  }
  

  showRawJson = (jobs) => {
    let jobsList = [];
    if(jobs.length>0){
      if(this.props.accountUsers){
        jobs.forEach( (item) => {
          if(this.props.accountUsers){
  
               let assignedObj =  this.props.viewDetails.isexpert ? this.props.accountUsers.find(usr => usr.id === item.technician_id) :this.props.accountUsers.find(usr => usr.id === item.assigned_by); 
          
               if(assignedObj){
                item['assigned_name'] = assignedObj.first_name +' '+assignedObj.last_name;
                item['isadmin'] = assignedObj.isadmin;
                item['isexpert'] = assignedObj.isexpert;
                this.changeColorClassName(item);
                jobsList.push(item);
               } /*TP-2829*/else if (item['asset_name'] !== null) {
                jobsList.push(item);
               }
              }
            });
      }else {
        console.log('User Account Not found');
      }
        
        jobsList.sort( (job1, job2) => {
        if(moment(job2.start_date).isAfter(moment(job1.start_date))){
          return 1;
        }else if(moment(job1.start_date).isAfter(moment(job2.start_date))){
          return -1;
        }else return 0;
      });
    }

    return jobsList;
  }

  feedGlobalHunt = () => {
    this.setState(({globalHunt:[]}),
    ()=>{
    let searchParams = this.state.globalHunt;
    let arrayUniqueByKey = this.state.globalHunt;
    this.state.userjobsList.forEach((job, i) => {
      if(job.name){
        searchParams.push({...job, huntLabel : job.name});
      }
    });

    if(searchParams){
      arrayUniqueByKey = [...new Map(searchParams.map(item =>[item['huntLabel'], item])).values()];
    }

    let minDate = new Date("01 JAN 2001");
    let startDate = new Date().setDate(new Date().getDate()-1)
    if(arrayUniqueByKey.length){
        startDate = arrayUniqueByKey[arrayUniqueByKey.length-1].start_date;
        minDate = startDate;
    }

    this.setState({globalHunt: searchParams,searchLabel:arrayUniqueByKey,minDate:new Date(minDate),start_date:new Date(startDate)});
  });
    
  }

  search = (paramArray) => {
    
    const {globalHunt} = this.state;
    let finalResult = [];

      if(Array.isArray(paramArray) && paramArray.length){
      paramArray.forEach(function (paramData){
        let huntlabel =  paramData.huntLabel  ? paramData.huntLabel : undefined;
        if(huntlabel && huntlabel && globalHunt && finalResult.length === 0){
          finalResult = globalHunt.filter(log=>huntlabel === log.huntLabel && log.id);
        }else if(huntlabel && huntlabel && globalHunt && finalResult.length){
          let tempResult = []; 
          tempResult = globalHunt.filter(log=>huntlabel === log.huntLabel && log.id);
          finalResult.push(...tempResult);
        }else{
          finalResult = this.state.globalHunt;
        }
      });
    }

  let infoPicked = finalResult.length > 0 ? finalResult: globalHunt
  this.setState({userjobsList: infoPicked});
   
  }

 

setSelectedOptions = option => {

  if(this.state.selectedOptions && this.state.selectedOptions.length === 0)
    this.setState({selectedOptions:[...this.state.selectedOptions,...option]},()=> this.search(option));
  else
    this.setState({selectedOptions: option},()=> this.search(option));
   
}

getOptionLabel =  option =>  option.huntLabel;  

handleToggleOption = (selectedOptions,reason) => {
  
  if(selectedOptions  && (reason === "select-option" || reason === "remove-option"))
      this.setSelectedOptions(selectedOptions);
  else if(selectedOptions && reason === "clear")
      this.setSelectedOptions(selectedOptions);
  
}

handleClearOptions = () => this.setState({selectedOptions:[]},()=> this.search());


searchJobByDate(){

  const {globalHunt} = this.state;
  let finalResult = [];

  if(Array.isArray(globalHunt) && globalHunt.length && moment(this.state.start_date).isValid() && moment(this.state.end_date).isValid()){
    let startDate = moment(this.state.start_date).format("DD MMM YYYY");
    let endDate = moment(this.state.end_date).format("DD MMM YYYY");
    //console.log("startDate ----> ", startDate, " endDate ====> ", endDate);
      globalHunt.forEach(function (job){
      let date =  job.created_on &&  moment(job.created_on).isValid()  ? moment(job.created_on).format("DD MMM YYYY"): undefined;
      //console.log("job name -----> ", job.name, " date = ", date);
      if(moment(date,"DD MMM YYYY").isBetween(startDate, endDate))
        finalResult.push(job);
      else if (startDate === date || endDate === date)
        finalResult.push(job);

    });
  }

this.setState({userjobsList: finalResult,selectedPageNumber:1});

}

getJobReport(e,dataParam){
  e.preventDefault();
  this.props.params.breadcrumbTail("jobDetails",dataParam,dataParam.name,'',this.props.customerDtls);
}

searchJobs = (event) => {
  event.preventDefault();
  const criteriaArr = ['name','assignee_name', 'status'];
  let input, filter;
  const { globalHunt } = this.state;
  let jobsSearch, pickedSearch = [];
  const result = [];
  const map = new Map();
  input = document.getElementById("jobSearchBox");
  filter = input.value.toUpperCase();

  for (let i = 0; i < criteriaArr.length; i++) {
      jobsSearch = globalHunt.filter((jobs)=> {
          if (criteriaArr[i] === 'assignee_name') {
              let name = jobs.assigned_name;
              return name.toUpperCase().indexOf(filter) > -1;
          }if (criteriaArr[i] === 'status') {
            let name = jobs.status && jobs.status === "in_progress" && jobs.status.split('_').length > 1 ? (jobs.status.split('_')[0]+' '+jobs.status.split('_')[1]):  jobs.status;
              return name.toUpperCase().indexOf(filter) > -1;
        } else {
              return jobs[criteriaArr[i]].toUpperCase().indexOf(filter) > -1;
          }
          
      });

      if (jobsSearch.length > 0){
          pickedSearch.push(...jobsSearch)
      }

    }

    for (const item of pickedSearch) {
        if(!map.has(item.id)){
            map.set(item.id, true);    // set any value to Map
            result.push(item);
        }
    }

    let infoPicked = result.length > 0 ? result: globalHunt;
    this.setState({userjobsList: infoPicked});
}

  //{/*TP-809*/}
  handleLoadingOnDownload = (value)=>{
    this.setState({isDownloading:value})
  }

  //TP-4211
  startDownload = async () =>{
  const {customerDtls} = this.props;
  const {language, selectedJob} = this.state;
  if(selectedJob && selectedJob.id){
    if(sessionStorage.userToken) {
      const url = selectedJob.job_type === 'checklist' ? 'getCheckListJobReport' : 'getJobReport' //TP-2772
      this.handleLoadingOnDownload(true);
      await  superagent
        .get(sessionStorage.getItem("url")+`/api/jobs/${url}/`+selectedJob.id) //TP-2772
        .set("Authorization", "Bearer " + sessionStorage.userToken)
        .then(response => response)
        .then(async data => {
         await generateZip({data:data.body,selectedJob:selectedJob, customer:customerDtls,callBackFunction:this.handleLoadingOnDownload, language}); //	TP-4211

      }
    ).catch(error => {
      let msg=null;
      if(undefined===error.status){
        msg = "Internet is not connected, please try again after connecting network";
      }else if(400===error.status){
        msg = "condition not matching so jobs not found";
      }else if(401===error.status){
        msg = "Session token experied, Please re-login";
      }else if(403===error.status){
        msg = "You are not authorized";
      }else if(422===error.status){
        msg = "Invalid parameters sent";
      }else if(500===error.status){
        msg = "Something went wrong, try again later";
      }else{
        console.error(error +msg);
      }
      this.handleLoadingOnDownload(false);
    });
  }
  }
} 

//TP-4210 -- to open the language selector Modal
openLanguageModal = (e,isZip,rowData) => {
  e.preventDefault();
  this.setState({ isGenerateZipReport: isZip, showModal: true, selectedJob: rowData });//	TP-4211
} 
//TP-4210
toggleLanguageModal = () => {
  this.setState(prev => ({
    showModal: !prev.showModal
  }))
}

//{/*TP-809*/}
downloadJobReport = async() =>{
  const {language, selectedJob} = this.state;
    if(sessionStorage.userToken && selectedJob.id && language /**TP-4210*/) {
      //TP-2829
      const url = selectedJob.job_type === 'checklist' ? 'downloadCheckListJobReport' : 'downloadJobReport'
      this.handleLoadingOnDownload(true);
      await  superagent
        .get(sessionStorage.getItem("url")+`/api/jobs/${url}/`+selectedJob.id+`/${language}`)//TP-4210
        .set("Authorization", "Bearer " + sessionStorage.userToken)
        .then((response) => response)
        .then((result)=>{
        const data = JSON.parse(result.text);
        const link = document.createElement('a');
        link.href = 'data:application/pdf;base64,' + data.encodedReportdata;
        link.download = `${selectedJob.name}-Report.pdf`;//TP-4210
        link.click(); 
        link.remove();
        this.handleLoadingOnDownload(false);
        this.setState({selectedJob: null});//TP-4210
      }
    ).catch(error => {
      let msg=null;
      if(undefined===error.status){
        msg = "Internet is not connected, please try again after connecting network";
      }else if(400===error.status){
        msg = "job not found/report cannot be generated";
      }else if(401===error.status){
        msg = "Session token experied, Please re-login";
      }else if(403===error.status){
        msg = "You are not authorized";
      }else if(422===error.status){
        msg = "Invalid parameters sent";
      }else if(500===error.status){
        msg = "Something went wrong, try again later";
      }else{
        console.error(error +msg);
      }
      this.handleLoadingOnDownload(false);
      this.setState({selectedJob: null});//TP-4210
    });
  }
} 

updatePage(page){
  this.setState({selectedPageNumber:page});
}
//TP-2774
transformJobType(type) {
  switch(type){
    case "evidence":
        return "Work Instructions";
    case "checklist":
        return "Checklist";
  }
}
//TP-4210
handleLanguageChange = (val) => {
  this.setState({language: val.value}, () => {
    this.toggleLanguageModal();
    //TP-4211
    if (this.state.isGenerateZipReport)
      this.startDownload();
    else
      this.downloadJobReport();
  });
}
//TP-4210
languageSelector = (props) => {
  return(
    <Modal isOpen={this.state.showModal} toggle={this.toggleLanguageModal}>
        <ModalHeader toggle={this.toggleLanguageModal}>Select Language</ModalHeader>
        <ModalBody>
            
          <Select
            value={this.state.language}
            components={makeAnimated()}
            onChange={this.handleLanguageChange}
            placeholder={`Select a language`}
            options={languageArr}
            isSearchable={false}
          />                 
                
        </ModalBody>
    </Modal>
  )
}
   
  render() {

    //To Check if User Is Expert Column Header Name shot get replaced with const value.
    if(this.props.viewDetails.isexpert){
      userjobsColumn[3].name = "Assignee";
      userjobsColumn[4].name = "Created on";
    }else{
      userjobsColumn[3].name = "Created By";
      userjobsColumn[4].name = "Assigned On";
    }
    //TP-2774
    if (this.props.viewDetails.enable_asset && this.props.enableAssetClass) {
      const assetColumn = {name:"Asset", selector:"asset_name", sortable:true,width:"100px"};
      if (userjobsColumn[5].name !== "Asset"){
        userjobsColumn.splice(5, 0, assetColumn);
      }
      userjobsColumn[1].cell=row=>undefined!==row.id && !row.is_deleted? <div className="hyperlink" onClick={event=>this.getJobReport(event,row,this)}>{row.name}</div>:<div className="text-ellipses" onClick={(event)=>this.getJobReport(event,row,this)} style={{textDecoration:'line-through',color:'red'}}>{row.name}</div>
      userjobsColumn[2].cell=row=>undefined!==row.description ? <div className="text-ellipses">{row.description && row.description.length > 50 ? row.description.substring(0,50):row.description}</div>:<div className="text-ellipses"></div>
      userjobsColumn[3].cell=row=>undefined!== this.props.viewDetails.isexpert ? <div className="text-ellipses">{row.assigned_name}</div> : row.creater_fname ? <div className="text-ellipses">{row.creater_fname+' '+row.last_name}</div>:<div className="text-ellipses"></div>
      userjobsColumn[4].cell=row=>null!==row.start_date ? moment(row.start_date).format("DD MMM,YYYY HH:mm"):"NA";
      userjobsColumn[5].cell=row=>undefined!==row.asset_name && null!==row.asset_name ? <div className="text-ellipses">{row.asset_name}</div>:<div className="text-ellipses"></div>
      userjobsColumn[6].cell=row=>undefined!==row.status ? <div className="text-ellipses">{constJobStatusFlag[row.status]}</div>:<div className="text-ellipses"></div>
      // userjobsColumn[6].cell=row=>undefined!==row.id ? <div className="text-ellipses">{getDownLoadIcon(row,this.props.viewDetails)}</div>:<div className="text-ellipses">Jobs Reports</div>
      userjobsColumn[7].cell=row=>undefined!==row.job_type? <div className="text-ellipses">{this.transformJobType(row.job_type)}</div>:<div className="text-ellipses"></div>
      //userjobsColumn[8].cell=row=>undefined!==row.id ? <div className="text-ellipses display-flx">{DownloadZipDiv(row,this.props.customerDtls,this)} <JobsPdfIcon rowData={row} openModal={this.openLanguageModal}/></div>:<div className="text-ellipses">Jobs Reports</div>/**TP-4210*/
      userjobsColumn[8].cell=row=>undefined!==row.id ? <div className="text-ellipses display-flx"><JobsZipIcon rowData={row} openModal={this.openLanguageModal} /><JobsPdfIcon rowData={row} openModal={this.openLanguageModal}/></div>:<div className="text-ellipses">Jobs Reports</div>/**	TP-4211 && TP-4210*/
    } else {
      //TP-2862
      if (userjobsColumn[5].name === "Asset"){
        userjobsColumn.splice(5, 1);
      }
      userjobsColumn[1].cell=row=>undefined!==row.id && !row.is_deleted? <div className="hyperlink" onClick={event=>this.getJobReport(event,row,this)}>{row.name}</div>:<div className="text-ellipses" onClick={(event)=>this.getJobReport(event,row,this)} style={{textDecoration:'line-through',color:'red'}}>{row.name}</div>
      userjobsColumn[2].cell=row=>undefined!==row.description ? <div className="text-ellipses">{row.description && row.description.length > 50 ? row.description.substring(0,50):row.description}</div>:<div className="text-ellipses"></div>
      userjobsColumn[3].cell=row=>undefined!== this.props.viewDetails.isexpert ? <div className="text-ellipses">{row.assigned_name}</div> : row.creater_fname ? <div className="text-ellipses">{row.creater_fname+' '+row.last_name}</div>:<div className="text-ellipses"></div>
      userjobsColumn[4].cell=row=>null!==row.start_date ? moment(row.start_date).format("DD MMM,YYYY HH:mm"):"NA";
      userjobsColumn[5].cell=row=>undefined!==row.status ? <div className="text-ellipses">{constJobStatusFlag[row.status]}</div>:<div className="text-ellipses"></div>
      // userjobsColumn[6].cell=row=>undefined!==row.id ? <div className="text-ellipses">{getDownLoadIcon(row,this.props.viewDetails)}</div>:<div className="text-ellipses">Jobs Reports</div>
      userjobsColumn[6].cell=row=>undefined!==row.job_type? <div className="text-ellipses">{this.transformJobType(row.job_type)}</div>:<div className="text-ellipses"></div>
      //userjobsColumn[7].cell=row=>undefined!==row.id ? <div className="text-ellipses display-flx">{DownloadZipDiv(row,this.props.customerDtls,this)} <JobsPdfIcon rowData={row} openModal={this.openLanguageModal}/></div>:<div className="text-ellipses">Jobs Reports</div> /**TP-4210*/
      userjobsColumn[7].cell=row=>undefined!==row.id ? <div className="text-ellipses display-flx"><JobsZipIcon rowData={row} openModal={this.openLanguageModal} /><JobsPdfIcon rowData={row} openModal={this.openLanguageModal}/></div>:<div className="text-ellipses">Jobs Reports</div> /**TP-4210*/
    }
      return (
      <div>
        { this.state.showDateFilter && 
        <div className="jobs-row-cols">
          <DatePicker id="start_date"
            placeholderText="start date"
            selected={this.state.start_date}
            minDate={new Date(this.state.minDate)}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            onChange={this.startDateChange}/>&nbsp;
          <DatePicker id="end_date"
            placeholderText="end date"
            dateFormat="dd/MM/yyyy"
            minDate={new Date(this.state.minDate)}
            selected={this.state.end_date}
            maxDate={new Date()}
            autoComplete="off"
            onChange={this.endDateChange}/>&nbsp;

            <div id="jobs-search-input" className="inner-addon left-addon absolute">
                <i className="fa fa-search"></i>
                <input type="text" id="jobSearchBox" placeholder={`Search for Jobs...`} onChange={(event) => this.searchJobs(event)} />
            </div>
          </div>

      }

      { this.state.showViewPage  ?
				<JobDetails customerDtls={this.props.customerDtls} selectedJobs={this.state.selectedJobs} constJobStatusFlag={constJobStatusFlag} constDownLoadIcon={getDownLoadIcon} ></JobDetails> :
          <DataTable
          pagination={this.state.userjobsList.length>8}
          paginationPerPage={8}
          onChangePage={page=>this.updatePage(page)}
          paginationDefaultPage={this.state.selectedPageNumber}
          columns={userjobsColumn}
          data={this.state.userjobsList}
          className="jobs-list-class"
          noDataComponent="No jobs found within selected dates!"
          progressPending={this.state.isLoading}
          progressComponent={<div><i className="fa fa-spinner fa-spin" style={{fontSize:"60px",color:"white"}}/></div>}
          persistTableHead
          />
				}
         {this.state.isDownloading && <LoadThreeDots isLoading={this.state.isDownloading}/>} 
         {this.languageSelector(this.props)/**TP-4210*/}
      </div>


    );
  }
}