import React from 'react';
import './LoadThreeDots.css';
import Loader from 'react-loader-spinner';

const  LoadThreeDots = ({isLoading}) => {
  if (!isLoading) return '';
  return ( 
  <div className="download-reports-loader">
      <Loader
          type="ThreeDots"
          color="#23c97d"
          height="70" 
          width="70"
      />
  </div>)
}

export default LoadThreeDots;