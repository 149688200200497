import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './vendorTrial.css';
import  superagent  from 'superagent';

function showLoader(isLoading){
    if (!isLoading) return "";
    else return (<i className="fa fa-spinner fa-spin"></i>)
}

const allowedErrorCode = [
  500,
  410,
  422,
  409,
  405
]

export default class RegisterTrial extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      errorMsg:{
       
      },
      isValidForm:false,
      showModal : false,
			modalTitle : null,
			modalBody : null,
      isLoading:false,
      company_details: "",
      contact_email: "",
      contact_name: "",
      contact_phone: "",
      serial_numbers: [],
      vendor_name: "",
      slNo: "",
      serverErrorCode:null,
      isEmailInvalid:false,
      isPhoneNumberInvalid:false
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  keyPress = (e) => {
    if(!e) e = window.event;
    if(e.keyCode === 13 && e.target.value.length>0){
      this.addSerials();
    }

  }

  addSerials = () => {
    // var patt = new RegExp("^[a-zA-Z0-9!-._*+=]+$");
    // if(patt.test(this.state.slNo)){
      this.setState(({
        serial_numbers: [
          ...this.state.serial_numbers, 
          this.state.slNo
        ],
        slNo:""
      }),()=>this.checkValidation());
    // }
  }

  inputChange = (e) => {
    if(!e.target) return;
    let field = e.target,
    id = field.name, 
    value = field.value,
    errorMessage=null;

    if(id){
      if(""===value && "slNo"!==id){
        errorMessage = field.placeholder + " field is required";
      }else
      switch(id){
        case "contact_name": break;
        case "company_details": break;
        case "vendor_name": break;
        case "contact_email": 
          value = value.toLowerCase();
          // if(value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)){
          //   this.checkIfEmailExists(value,id);
          // }
          break;
        case "contact_phone": 
        
         // const telRegex = new RegExp(/^[\\w\\d._%+-]+@[\\w\\d.-]+\.[\\w]{2,4}$/); //TP-2110
          if(!value.match(/^[0-9\)\(+-\s]+$/g)){
            errorMessage = "Enter a valid "+ field.placeholder.toLowerCase();
            this.setState({isPhoneNumberInvalid:true})
          }else{
            this.setState({isPhoneNumberInvalid:false})
          }
          break;
        case "slNo": 
          if(this.state.serial_numbers.length<1){
            errorMessage = "At least one serial number is required";
          }
          break;
        default : break;
      }

      this.setState(({
        [id]: value,
        errorMsg : {
          ...this.state.errorMsg,
          [id]: errorMessage
        }
      }),()=>this.checkValidation());
    }
  }

  deleteDevice = (i) => {
    let serialNo = this.state.serial_numbers.filter((value, index)=>index!==i)
    this.setState(({
      serial_numbers: serialNo}
    ), () => {
    this.setState({
      errorMsg : {
      ...this.state.errorMsg,
      slNo: serialNo.length<1?"At least one serial number is required":null
    }});
    this.checkValidation()});
  }

  checkValidation = () => {
    var inpObj = document.getElementById("regTrialForm");
    let validFlag = false;
    if (inpObj.checkValidity()) {
      if(this.state.serial_numbers.length>0){
        validFlag = true;
      }
    }

    this.setState({isValidForm:validFlag});
    /*if(!validFlag){
      this.setState({isValidForm:false});
    }else{
      this.setState({isValidForm:true,
      errorMsg:{
          contact_name:'Contact name field is required',
          contact_email:'Contact email field is required',
          contact_phone:'Contact phone field is required',
          vendor_name:'Vendor name field is required',
          company_details:'Company details field is required',
          slNo:'At least one serial number is required'
      }});
    }*/
  }

  purchaseNow = () => {
    let vendor_url="/api/registerTrialAccount", 
    HTTP_STATUS = null,
    modalTitle = null,
    modalBody  = null;

    // vendor_url="";
    // console.log(this.state);
    if (null != sessionStorage.getItem("userToken") && vendor_url) {
      this.setState({isLoading: true,serverErrorCode:null,modalBody:null,showModal:false});
      fetch(sessionStorage.getItem("url") + vendor_url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + sessionStorage.getItem("userToken")//TP-4416
        },
        body: JSON.stringify(this.state)
      }).then((response) => {
          HTTP_STATUS=response.status;
          return response.json();
      }).then(data => {
        if(HTTP_STATUS===200){
          modalTitle="Successfully Registered!!!";
          modalBody="Your free trial registration has been received. You will receive an email shortly with a confirmation and login credentials";
        }else if(HTTP_STATUS===500){
            // modalTitle : "Server Error!!!",
          modalBody="Something went wrong, try again later";
        }else if(HTTP_STATUS===410){
          modalTitle="Check Serial Number!!!";
          modalBody="One or more serial numbers entered are already registered in the system";
        }else if(HTTP_STATUS===422){
          modalTitle="Validation Error!!!";
          modalBody = "";
					data.errors.forEach(error=>{
						modalBody = modalBody + error.param + " : " + error.msg + "\n";
					})
        }else if(HTTP_STATUS===405){
          modalTitle="Wrong Parameters!!!";
          modalBody=data+"";
        }else if(HTTP_STATUS===409){
          modalBody="The email provided is already signed up for paid purchases. You can log into the paid account and edit the relevant user details to attach the device serial number to one of the registered users on the platform. You may also use a different email-id to request the new device purchase trial";
        }
        this.setState({
          showModal:true, 
          isLoading: false,
          modalTitle: modalTitle,
          modalBody: modalBody,
          serverErrorCode:HTTP_STATUS
        });
      }).catch(error => {
        console.log(error);
        this.setState({isLoading: false});
      });
    }
  }

  checkIfEmailExists = async (contactEmailVal,id) =>{

    if(contactEmailVal){
     await superagent
      .get(sessionStorage.getItem('url')+`/api/checkEmailExists/${contactEmailVal}?type=standard`)
      .set("Authorization", "Bearer " + sessionStorage.getItem("userToken")) //TP-4416
      .then(response=>response)
      .then(data=>{
        // if(data.body) booleanVal = true;
        if(data.body){
          this.setState(({
            isEmailInvalid:true,
            [id]: contactEmailVal,
            errorMsg : {
              ...this.state.errorMsg,
              [id]: 'This email is already in use'
            }
          }),()=>this.checkValidation());
        }
      })
    }
  }  

  render(){
    return(
      <div className="page">
            <Form validated="true" id="regTrialForm" onSubmit={event=>this.handleSubmit(event)}>
              <div className="column">
              <Form.Group controlId="controlInput1">
                <Form.Row>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control type="text" name="contact_name" className="col-md-8 col-lg-8"
                                onChange={this.inputChange} value={this.state.contact_name}
                                isInvalid={false} placeholder="Contact Name" required/>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control.Feedback type="invalid" className="col-md-8 col-lg-8">
                    {this.state.errorMsg.contact_name}
                  </Form.Control.Feedback>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="controlInput5">
                <Form.Row>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control type="text" name="company_details" className="col-md-8 col-lg-8"
                                onChange={this.inputChange} value={this.state.company_details}
                                isInvalid={false} placeholder="Company Details" required/>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control.Feedback type="invalid" className="col-md-8 col-lg-8">
                    {this.state.errorMsg.company_details}
                  </Form.Control.Feedback>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="controlInput2">
                <Form.Row>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control type="email" name="contact_email" className="col-md-8 col-lg-8"
                                onChange={this.inputChange} value={this.state.contact_email}
                                isInvalid={false} placeholder="Contact Email"
                                pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required/>
                  <div className="col-md-4 col-lg-4"></div>
                  {this.state.errorMsg.contact_email && 
                    <Form.Control.Feedback type="invalid" className="col-md-8 col-lg-8">
                      {this.state.errorMsg.contact_email}
                    </Form.Control.Feedback>
                  }
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="controlInput3">
                <Form.Row>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control type="text" name="contact_phone" className="col-md-8 col-lg-8"
                                onChange={this.inputChange} value={this.state.contact_phone}
                                pattern = {"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"}
                                placeholder="Contact Phone"
                                isInvalid={this.state.isPhoneNumberInvalid} required/>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control.Feedback type="invalid" className="col-md-8 col-lg-8">
                    {this.state.errorMsg.contact_phone}
                  </Form.Control.Feedback>
                </Form.Row>
              </Form.Group>
              <Form.Group controlId="controlInput4">
                <Form.Row>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control type="text" name="vendor_name" className="col-md-8 col-lg-8"
                                onChange={this.inputChange} value={this.state.vendor_name}
                                isInvalid={false} placeholder="Vendor Name" required/>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control.Feedback type="invalid" className="col-md-8 col-lg-8">
                    {this.state.errorMsg.vendor_name}
                  </Form.Control.Feedback>
                </Form.Row>
              </Form.Group>
              </div>
              <div className="column">
              <Form.Group controlId="controlInput6">
                <Form.Row>
                  <div className="col-md-4 col-lg-4"></div>
                  <Form.Control type="text" name="slNo" className="col-md-7 col-lg-7"
                                onChange={this.inputChange} value={this.state.slNo}
                                onKeyDown={(e)=>{this.keyPress(e)}} placeholder="Serial Number"
                                isInvalid={this.state.serial_numbers<1}/>
                  <div className="col-md-1 col-lg-1">
                  <Button type="button" className="btn-plus" onClick={this.addSerials}
                          disabled={!this.state.slNo}>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </Button>
                  </div>
                  <div className="col-md-4 col-lg-4">
                    <Form.Control.Feedback type="invalid" className="col-md-8 col-lg-8">
                      {this.state.errorMsg.slNo}
                    </Form.Control.Feedback>
                  </div>
                  <div className="mt-2 col-md-7 overflow-auto" style={{height:'50vh'}}>
                  {this.state.serial_numbers.map((value, index) => {
                    return <div className="row col-md-12 col-lg-12" key={index}>
                    <Form.Label className="p-1 user-type-flag text-right" key={index}>
                    {value} <i className="fa fa-times-circle pointer close-icon" onClick={()=>this.deleteDevice(index)}/>
                    </Form.Label></div>
                  })}
                  </div>
                </Form.Row>
              </Form.Group>
              </div>
              <div className="row col">
                <Button type="button" className="__btn m-auto" 
                disabled={!this.state.isValidForm || this.state.isLoading} 
                onClick={this.purchaseNow}>
                {showLoader(this.state.isLoading) }Purchase Now</Button>
              </div>
              {this.state.showModal &&
              <ModalPopUp modalTitle={this.state.modalTitle} modalBody={this.state.modalBody} errorCode={this.state.serverErrorCode}/>}
        </Form>
      </div>
    )
  }

}

class ModalPopUp extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      showModal: false
    }
  }

  componentDidMount(){
    this.setState({showModal:true});
  }

  close = () => {
    this.setState({showModal:false});
    if(allowedErrorCode.includes(this.props.errorCode)) return;
      window.location.reload();
  }

  render() {
    return (
        <Modal show={this.state.showModal} onHide={this.close} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>{this.props.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.modalBody}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close} className="btn-green">OK</Button>
          </Modal.Footer>
        </Modal>
    )
  }
}
