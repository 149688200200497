import { InviteActionTypes } from "./InviteType";
import moment from "moment";


export const fetchInvitesStart = () => ({
  type: InviteActionTypes.FETCH_INVITES_START
});

export const fetchInvitesSuccess = invites => ({
  type: InviteActionTypes.FETCH_INVITES_SUCCESS,
  payload: invites
});

export const fetchInvitesFailure = error => ({
  type: InviteActionTypes.FETCH_INVITES_FAILURE,
  payload: error
});

export const fetchAllInvitesList = () => {
  return dispatch => {
    dispatch(fetchInvitesStart());
    if(sessionStorage.getItem("userToken")){
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', "Bearer " + sessionStorage.getItem("userToken"));
        fetch(sessionStorage.getItem("url") + "/api/dash/getUnregisteredInvitees",{ method: 'GET', headers: myHeaders})
        .then((response) => response.json())
        .then((data) => {
            data.sort((d1, d2) => {
                if (moment(d1.last_location_report_on).isAfter(moment(d2.last_location_report_on))) {
                  return 1;
                } else if (moment(d1.last_location_report_on).isAfter(moment(d2.last_location_report_on))) {
                  return -1;
                } else return 0;
              });

          dispatch(fetchInvitesSuccess(data));
        })
        .catch((error) => {
          dispatch(fetchInvitesFailure(error))
        });
      }
  };
};