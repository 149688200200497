import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./loginPage.js";
import DashBoard from "./dashboard.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "font-awesome/css/font-awesome.min.css";
import "./index.css";
import "typeface-titillium-web";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const {version} = require('../package.json');

export class SinglePageApplication extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            {null == sessionStorage.getItem("userToken") ? (
              <LoginPage version={version} />
            ) : (
              <Provider store={store}>
                <DashBoard />
              </Provider>
            )}
          </Route>
        </Switch>
      </Router>
    );
  }
}

ReactDOM.render(<SinglePageApplication />, document.getElementById("root"));
