import React,{useEffect,useRef} from 'react';
import drawChart from '../StackBar/drawAnalyticsStackChart';
import './analyticsStackStyle.css';

const StackChart = ({data}) =>{
    const ref =  useRef(null);

    useEffect(()=>{
        if(ref.current){
            drawChart(ref.current,data);
        }
    },[data])

    return(
        <div id='stackBarChart' className='stack-bar-chart-container'>
                <div className='stack-title-txt' >User month wise usage data for {data.selectedGrpName}</div>
               <div id='stackChartBox' className='stack-bar-box' ref={ref}></div> 
        </div>
    )
}

export default React.memo(StackChart);