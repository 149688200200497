import React from 'react';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {   };
  }
   render() {
      return (
        <div>
               <footer>
                   {/*<div className="row no-gutters align-items-center">
                       <div className="col-3 text-center">
                           <a href="https://www.care4d.com" target="_blank" rel="noopener noreferrer">
                               <img alt="Go to CARE4D!" src={care4dLogo} style={{width: '30%'}}/>
                           </a>
                       </div>
                       <div className="col-6 text-center">
                           <a href="https://dev.telepresenzdemo.com/CARE4D_privacy_policy.pdf" target="_blank" rel="noopener noreferrer" style={{color: '#ffffff'}}>
                            Privacy Policy
                           </a>
                       </div>
                       <div className="col-3 text-center">
                           <a href="https://www.telepresenz.com" target="_blank" rel="noopener noreferrer">
                               <img alt="Go to Telepresenz!" src={learnMore} style={{width: '35%'}}/>
                           </a>
                       </div>
                   </div>
                   <div>
                       <LangSelect/>
                   </div>*/}
               </footer>
            </div>
      );
   }
}
