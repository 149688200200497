import React from 'react';


export default class ValdationBulletPoints extends React.Component{
    render(){
        return(
            <div id="file-upld-valiation" className="file-upld-valiation" style={{marginLeft:'50px'}}>
                <div className="file-upload-div" >File Upload Preference :</div>
                <ul>
                    <li className="ddtwo">File size should not exceed 1 MB.</li>
                    <li className="ddtwo">File types allowed: .jpg, .png, .jpeg</li>
                    <li className="ddtwo">Only single file upload allowed.</li>
                    <li className="ddtwo">Only image files with white background.</li>
                    <li className="ddtwo">Maximum dimensions is allowed : 1200 pixels (width) & 644 pixels (height).</li>
                </ul>
            </div>
        );
    }
}