import React from 'react';
import moment from "moment";
import DataTable from "react-data-table-component";
import "react-dropdown-tree-select/dist/styles.css";
import "./groups.css";
import {DownloadGroupReport} from './downloadGroupReport';
import StreamDropDown from './streamDropDown';
import OnlineIndicator from './GroupOnlineIndicator';//TP-749
import LayoutGroups from '../layouts/layoutGroups';
import { trimText } from '../Utils/formatUtils';
import SearchGroupAppBar from './topNabBar';

let groupColumnNonAdmin = [
  {name:"Name",selector:"group_name",sortable:true},
  {name:"Description",selector:"description",sortable:true,maxWidth:"200px"},//TP-749
  {name:"Created On",selector:"created_on",sortable:true},
  {name:"Last Used On",selector:"last_active_on",sortable:true},
  {name:"Primary Expert",selector:"primary_name",sortable:true,maxWidth:"150px"},//TP-749
  {name:"Expert(s)",selector:"experts",sortable:true,maxWidth:"150px"},//TP-749
  {name:"Technician(s)",selector:"techs",sortable:true,maxWidth:"130px"},//TP-749
  {name:"Session Id",selector:"sessionid",sortable:true},
  {name:"Codec",selector:"codec_type",sortable:true,maxWidth:"100px"},//TP-749
  {name:"Stream Type",selector:'stream_type',sortable:false,maxWidth:"120px"},//TP-749
]

let groupColumnsAdmin = [
  {name:"Name",selector:"group_name",sortable:true,maxWidth:"200px"},
  {name:"Description",selector:"description",sortable:true,maxWidth:"200px"},//TP-749
  {name:"Created On",selector:"created_on",sortable:true,maxWidth:"200px"},
  {name:"Last Used On",selector:"last_active_on",sortable:true,maxWidth:"200px"},
  {name:"Primary Expert",selector:"primary_name",sortable:true,maxWidth:"150px"},//TP-749
  {name:"Expert(s)",selector:"experts",sortable:true,maxWidth:"100px"},//TP-749
  {name:"Technician(s)",selector:"techs",sortable:true,maxWidth:"130px"},//TP-749
  {name:"Session Id",selector:"sessionid",sortable:true,maxWidth:"200px"},
  {name:"Codec",selector:"codec_type",sortable:true,maxWidth:"100px"},//TP-749
  {name:"Stream Type",selector:'stream_type',sortable:false},//TP-749 
  {name:"Action",selector:"action",sortable:false,}//TP-749 
]

const sortColumns = [
  {name:"Last Used On",value:"last_active_on",isDefault:true},
  {name:"Created On",value:"created_on"},
  {name:"Group Name",value:"group_name"},
  {name:"Session ID",value:"sessionid"},
  {name:"Description",value:"description"},
  {name:"Codec Type",value:"codec_type"},
  {name:"Stream Type", value:"stream_type"},
  {name:"No of Experts",value:"experts"},
  {name:"No of Technicians",value:"techs"}
];

var tree=[];
function removeDuplicates(array) {
  array.splice(0, array.length, ...(new Set(array)));
}
//TP-749
const ShowOnlineCheckbox = (props) => {
  return( <div id="show-online-container">
    <label className="col-xs-3 non-pointer" htmlFor={props.id}>{props.label}&nbsp;</label>
    <label className="col-xs-4 col-xs-offset-2 switch">
      <input type="checkbox" id={props.id}
      name={props.id}
      onChange={props.toggleGroup}
      checked={props.value}/>
      <span className="slider round"></span>
    </label>
  </div>
  )
}

function setType(item) {
  const currdate = new Date();
  const dateOneMonth = new Date(currdate.setMonth(currdate.getMonth() - 1, 1));
  const dateOneYear = new Date(currdate.setFullYear(currdate.getFullYear() - 1));
  let comparingDate = new Date(item.last_active_on);
  //console.log(comparingDate);
  //console.log(dateOneMonth, dateOneYear)
  if(comparingDate > dateOneMonth){
    return "Most Recent";
  }else if (comparingDate > dateOneYear/*  && comparingDate < dateOneMonth */){
    return "Moderately Recent";
  }else{
    return "Very Old"
  }
}

export default class GroupsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups:[],
      isAdmin:false,
      showOnlineGroup:{
        value: false,
        label:"Show Online Groups",
        id:"groups-online-groups"
      },
      globalGroupHunt:[],
      fixedGroupData:[],
      globalUniqueData:[],
      hiddenColumn:[
        'sessionid',
        'primary_name'
      ],
      tableViewContainer:true, //TP-6405
      gridViewContainer:false, //TP-6405
      sortColumn: "last_active_on",
      sortDirection: "asc",
      showLegends: false
    }
  }

  setSortColumn = (value) => {
    this.customSortCards(value, this.state.sortDirection);
    this.setState({ sortColumn: value });
  }

  setSortDir = (value) => {
    this.customSortCards(this.state.sortColumn, value);
    this.setState({ sortDirection: value });
  }

  customSortCards = (field, direction) =>{
    //console.log(field, direction);
    if(null===field) field="last_active_on";
    if("stream_type"===field)field="streaming_medium";    
    let grpPicked = this.state.groups.filter(row=>null!==row[field]);
    let grpNullPicked = this.state.groups.filter(row=>null===row[field]);
    let mul=-1;
    if("asc"===direction){
      mul=1;
    }else if("desc"===direction){
      mul=-1;
    }
      if("last_active_on"===field||"created_on"===field){
        grpPicked.sort((c1,c2)=>{
          if(moment(c1[field]).isAfter(moment(c2[field]))){
            return -1*mul;
          }else if(moment(c1[field]).isBefore(moment(c2[field]))){
            return 1*mul;
          }else return 0;
        });
      }else if("group_name"===field||"sessionid"===field||"description"===field||"codec_type"===field){
        grpPicked.sort((a, b)=>{
          if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
          else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
          return 0;
         })
      }else if("experts"===field||"techs"===field){
        grpPicked.sort((a, b)=>{
          if(a[field] < b[field]) return -1*mul;
          else if(a[field] > b[field]) return 1*mul;
          return 0;
         })
      } else if("stream_type"===field){
        grpPicked.sort((a, b)=>{
          if(a.accountInfo[field].toLowerCase() < b.accountInfo[field].toLowerCase()) return -1*mul;
          else if(a.accountInfo[field].toLowerCase() > b.accountInfo[field].toLowerCase()) return 1*mul;
          return 0;
         })
      }
    //return [...grpPicked, ...grpNullPicked];
    this.setState({ groups: [...grpPicked, ...grpNullPicked]}, () => {
      this.setState({ isSorted: true }, () => {
        this.setState({ isSorted: false });
      })
    });
  }

  customSort = (rows, field, direction) =>{
    if(null===field) field="last_active_on";
    let grpPicked = rows.filter(row=>null!==row[field]);
    let grpNullPicked = rows.filter(row=>null===row[field]);
    let mul=-1;
    if("asc"===direction){
      mul=1;
    }else if("desc"===direction){
      mul=-1;
    }
    // console.log(rows, field, direction);
      if("last_active_on"===field||"created_on"===field){
        grpPicked.sort((c1,c2)=>{
          if(moment(c1[field]).isAfter(moment(c2[field]))){
            return -1*mul;
          }else if(moment(c1[field]).isBefore(moment(c2[field]))){
            return 1*mul;
          }else return 0;
        });
      }else if("group_name"===field||"sessionid"===field||"description"===field||"codec_type"===field){
        grpPicked.sort((a, b)=>{
          if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
          else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
          return 0;
         })
      }else if("experts"===field||"techs"===field){
        grpPicked.sort((a, b)=>{
          if(a[field] < b[field]) return -1*mul;
          else if(a[field] > b[field]) return 1*mul;
          return 0;
         })
      }
    return [...grpPicked, ...grpNullPicked];
  }
//TP-749
  componentDidMount() {
    this.setGroupsList();
    this.setGlobalHuntGroup();
    if(document.getElementById('dashIconCLs-refresh'))
      document.getElementById('dashIconCLs-refresh').style.display = 'none'
    window.addEventListener("resize", this.reDrawGroups);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reDrawGroups);
  }

  reDrawGroups = () => {
    this.setState({ isSorted: true }, () => {
      this.setState({ isSorted: false });
    })
  }

//TP-749
  setGroupsList = () => {
    let groupsName = [];
    let emails = [];
    let accounts = [];
    let {groups} = this.props;
    let {showOnlineGroup} = this.state;

    if(showOnlineGroup.value){
      groups = groups.filter((grp)=> grp.isongoingnow);  //filter out data to show ongoing now
    }

    groups.forEach((group)=>{
      group.users.forEach((user)=>{
        emails.push(user.email);
        accounts.push(user.account_id);
      })
      groupsName.push(group.group_name);
    });
    removeDuplicates(groupsName);
    removeDuplicates(emails);
    removeDuplicates(accounts);
    tree=[{ "type": "", "label": "All Groups", "value": "reset"}];
    this.setTreeChildren(groupsName, "By Group Name", "By Group Name", "");
    this.setTreeChildren(emails, "By Emails", "By Emails", "");
    this.setTreeChildren(accounts, "By Account", "By Account", "");
    this.createTagItem();
    //added to get account information based on group account id //TP-749
    groups = this.addAccountInfoIntoGroup(groups,this.props.params.state.allCustomers);
    this.setState({groups:groups,fixedGroupData:groups}, () => {
      if(this.state.groups.length > 0)
          this.setState({ showLegends: true })
        else
          this.setState({ showLegends: false });
      this.customSortCards(this.state.sortColumn, this.state.sortDirection);
      /* this.setState({ isSorted: true }, () => {
        this.setState({ isSorted: false });
      }) */
    });
    this.setState({isAdmin:sessionStorage.getItem('userDbRole') === 'admin' ? true:false});
  }
//TP-749
  addAccountInfoIntoGroup = (groupArr,customerArr) =>{
    let outGroupArrInfo = [];
    for(let i =0;i<groupArr.length;i++){
      let group = groupArr[i];
      group.accountInfo = customerArr.filter((cust) => cust.account_id === group.account_id).pop();
      group.primary_name = group.primary_expert_id && group.isongoingnow ?  group.accountInfo.users.filter((usr)=> usr.id === group.primary_expert_id).map((user)=>user.first_name + " "+user.last_name+' ('+user.id)+')' : '';
      group.primary_account_info = group.primary_expert_id && group.isongoingnow ? group.accountInfo.users.filter((usr)=> usr.id === group.primary_expert_id).pop():null;
      outGroupArrInfo.push(group);
    }
    return outGroupArrInfo;
    
  } 

  copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  treeSelect = (cn,sn) => {
    let list = [];
    let searchedField = document.getElementById("byGroups");

    if(""===cn.value){
      list = this.state.groups;
      cn.value = searchedField.innerText;
    }else if("reset"===cn.value){
      list = this.props.groups;
      cn.value = "Search";
    }else{

      list = this.props.groups.filter(function(group){
        if("By Group Name"===cn.type){
          return cn.value===group.group_name;
        }else if("By Emails"===cn.type){
          return undefined!==group.users.find(user=>cn.value===user.email);
        }else if("By Account"===cn.type){
          return cn.value===group.account_id;
        }else return false;
      });
    }

    if(null===document.getElementById("byGroups")){
      this.createTagItem();
    }
    searchedField.innerText=cn.value;
    this.setState({groups:list});
  }

  getParamsList = (list,type) => {
    let tempList = [];
    list = list.filter(li => li);
    list.forEach((item, i) => {
      tempList.push({
        "type":type,
        "label": item.substring(0,20),
        "value": item,
        "disabled" : false,
      });
    });

    return tempList
  }

  setTreeChildren = (list, type, label, value) => {
    if(list.length>1){
      tree.push({
        "type": type,
        "label": label,
        "value": value,
        "expanded" : true,
        "disabled" : true,
        "children": this.getParamsList(list,label)
      })
    }
  }

  createTagItem = () =>{
    let li = document.createElement('li');
    li.setAttribute('class', 'tag-item');
    let span = document.createElement('span');
    span.setAttribute('class', 'tag');
    span.setAttribute('id', 'byGroups');
    span.textContent = "Search";
    li.appendChild(span);
    if(document.getElementsByClassName("tag-list").length>0){
      document.getElementsByClassName("tag-list")[0].appendChild(li);
    }
  }
  //TP-749
  toggleGroup = () => {
    this.setState(({ showOnlineGroup : {
        ...this.state.showOnlineGroup,
        value : !this.state.showOnlineGroup.value
      }
    }),()=>this.setGroupsList());

  }

 // TP-749
  setGlobalHuntGroup = ()=>{
    this.setState({globalGroupHunt:[]},()=>{
       const searchQuerys = this.state.globalGroupHunt;
       const map = new Map(); 
       let uniqueArrayData = [],groupNameArr =[],userEmailArr = [],groupAccountArr= [];
       this.state.groups.forEach((group)=>{
         if(group.group_name){
            searchQuerys.push({...group,huntLabel:group.group_name});
          if(!map.has(group.group_name)){
             map.set(group.group_name, true);
             groupNameArr.push({...group,huntLabel:group.group_name}); 
          }
         }
         if(group.users){
            group.users.forEach((usr)=>{
              searchQuerys.push({...group,huntLabel:usr.email});
              if(!map.has(usr.email)){
                map.set(usr.email, true);
                userEmailArr.push({...group,huntLabel:usr.email}); 
             }
            })
         }
        if(group.account_id){
          searchQuerys.push({...group,huntLabel:group.account_id});
          if(!map.has(group.account_id)){
            map.set(group.account_id, true);
            groupAccountArr.push({...group,huntLabel:group.account_id}); 
         }
        }
       })
      
       groupAccountArr = groupAccountArr.sort((x,y)=> (x.huntLabel > y.huntLabel) ? 1:-1);
       groupNameArr = groupNameArr.sort((x,y)=> (x.huntLabel > y.huntLabel) ? 1:-1);
       userEmailArr = userEmailArr.sort((x,y)=> (x.huntLabel > y.huntLabel) ? 1:-1);
       uniqueArrayData.push(...groupAccountArr,...groupNameArr,...userEmailArr);
       this.setState({globalGroupHunt:searchQuerys,globalUniqueData:uniqueArrayData}); 
    })
  }


  searchGroup = (event,data) =>{
    event.preventDefault();
    let  filter; 
    const { globalGroupHunt,fixedGroupData } = this.state;
    let groupSearch = [];
    const result = [];
    const map = new Map();
    filter =  data && data.huntLabel ?  data.huntLabel.toUpperCase():
              event.target.outerText ? event.target.outerText.toUpperCase(): '';

    if(filter)
      groupSearch = globalGroupHunt.filter((group)=> group.huntLabel ? group.huntLabel.toUpperCase().includes(filter):false);
 
      if(groupSearch && groupSearch.length>0){
        for (const item of groupSearch) {
            if(!map.has(item.group_id)){
                map.set(item.group_id, true);    // set any value to Map
                result.push(item);
            }
        }
      }
      let infoPicked = result.length > 0 ? result: fixedGroupData;
      this.setState({groups:infoPicked}, () =>{
        if (this.state.gridViewContainer) {
          if (this.state.sortColumn === "last_accessed_on" && this.state.sortDirection === "asc")
            this.customSortCards("last_accessed_on", "asc");
          else
            this.customSortCards(this.state.sortColumn, this.state.sortDirection);            
        }
        else if (this.state.tableList !== undefined){   //TP-6405
          this.customSort(this.state.tableList,"last_accessed_on", "asc");
        }
        if(this.state.groups.length > 0)
          this.setState({ showLegends: true })
        else
          this.setState({ showLegends: false });
      });
  }

  getOptionLabel =(option)=>{
    return  option.huntLabel ? option.huntLabel:'';
  }
//{/*TP-809 */}   
  filterOutColumn = (col)=>{
    return this.state.hiddenColumn.indexOf(col.selector) === -1 
  }

  toggleContainer = () => {
      this.setState({
        tableViewContainer: !this.state.tableViewContainer,
        gridViewContainer:!this.state.gridViewContainer,
      }); //TP-1347
  }

  render() {
     
    groupColumnNonAdmin[0].cell=row=><div data-toggle="tooltip" title="View Details" className="hyperlink" id="groupName" key={'group-'+row.group_id}
                                onClick={()=>this.props.params.breadcrumbTail("group",row,row.group_name)}>{ (row.isongoingnow ) ? 
                                <OnlineIndicator isCardView={this.state.gridViewContainer} keyIndex={row.group_id} />: ''} {null!==row.group_name&&row.group_name.length>10?trimText(row.group_name, 12): row.group_name} 
                                {null!==row.group_id?"("+row.group_id+")":""}</div>
    groupColumnNonAdmin[1].cell=row=><div className="group-col-16" >{row.description ? row.description.length>20?<div data-toggle="tooltip" title={row.description}>{trimText(row.description, 20)}</div> : row.description:"NA"}</div>                             
    if (this.state.gridViewContainer === true) {
      groupColumnNonAdmin[2].cell=row=>null!==row.created_on ? <div className="group-col-16">{moment(row.created_on).format("DD MMM,YYYY")}</div>:"NA";
      groupColumnNonAdmin[3].cell=row=>null!==row.last_active_on ?<div>{moment(row.last_active_on).format("DD MMM,YYYY HH:mm")}</div>:"NA";
    } else {
      groupColumnNonAdmin[2].cell=row=>null!==row.created_on ? <div className="group-col-16">{moment(row.created_on).format("DD MMM,YYYY HH:mm")}</div>:"NA";
      groupColumnNonAdmin[3].cell=row=>null!==row.last_active_on ?<div>{moment(row.last_active_on).format("DD MMM,YYYY HH:mm")}</div>:"NA";
    }
    groupColumnNonAdmin[4].cell=row=><div data-toggle="tooltip" title={row.primary_name} className="hyperlink" onClick={()=>this.props.params.breadcrumbTail("user",row.primary_account_info,row.primary_name)}>{row.primary_name}</div>;
    groupColumnNonAdmin[5].cell=row=><div data-toggle="tooltip" title={row.expertNames}>{row.experts.length}</div>;
    groupColumnNonAdmin[6].cell=row=><div data-toggle="tooltip" title={row.techNames}>{row.techs.length}</div>;
    groupColumnNonAdmin[7].cell=row=><div className="hoverClipCopy">
                                 <div>{row.sessionid}</div>
                                 <div className="tooltiptext small">
                                    <i className="fa fa-copy copySelection" id={"sessionid"+row.id}
                                    onClick={(e) => this.copyToClipboard(row.sessionid)}> Copy</i>
                                 </div>
                              </div>
    groupColumnNonAdmin[8].cell=row=>null!==row.codec_type ? <div className="group-col-16">{row.codec_type}</div>:"NA"; 
    groupColumnNonAdmin[9].cell=row=><div className="group-col-16">{row.is_onprem === true ? "OWRTC":"OT"}</div>;
    if (this.state.gridViewContainer) {
      groupColumnNonAdmin.push({name:"Group Type",selector:"group_type",sortable:false,width:"120px"})
      groupColumnNonAdmin[10].cell=row=>null!==row.last_active_on?setType(row):"NA";
    } else {
      //if (groupColumnNonAdmin.length > 9) 
      if(groupColumnNonAdmin[groupColumnNonAdmin.length-1].selector==="group_type")
        groupColumnNonAdmin = groupColumnNonAdmin.filter(gr => gr.selector !== "group_type")
    }
    //TP-880 
    if(!this.state.showOnlineGroup.value){
      groupColumnNonAdmin[2].maxWidth = '300px';
      groupColumnNonAdmin[5].maxWidth = '300px';
      groupColumnNonAdmin[6].maxWidth = '300px';
      groupColumnNonAdmin[8].maxWidth = '250px';  
    }                              
    
     //TP-880                                               
    if(!this.state.showOnlineGroup.value){
      groupColumnsAdmin[2].maxWidth = '250px';
      groupColumnsAdmin[5].maxWidth = '200px';
      groupColumnsAdmin[6].maxWidth = '200px';
      
    }                          
    groupColumnsAdmin[0].cell=row=><div data-toggle="tooltip" title="View Details" className="hyperlink" id="groupName" key={'group-'+row.group_id}
                              onClick={()=>this.props.params.breadcrumbTail("group",row,row.group_name)}>{ (row.isongoingnow) ? 
                              <OnlineIndicator isCardView={this.state.gridViewContainer} keyIndex={row.group_id} />: ''} {null!==row.group_name&&row.group_name.length>10?trimText(row.group_name, 12) : row.group_name} 
                              {null!==row.group_id?"("+row.group_id+")":""}</div>
    groupColumnsAdmin[1].cell=row=><div className="group-col-16" >{row.description ? row.description.length>20?<div data-toggle="tooltip" title={row.description}>{trimText(row.description, 20)}</div> : row.description:"NA"}</div>                              
    if (this.state.gridViewContainer === true) {
      groupColumnsAdmin[2].cell=row=>null!==row.created_on ? <div className="group-col-16">{moment(row.created_on).format("D.MM.YYYY")}</div>:"NA";
      groupColumnsAdmin[3].cell=row=>null!==row.last_active_on ?<div>{moment(row.last_active_on).format("D.MM.YYYY HH:mm")}</div>:"NA";
    } else {
      groupColumnsAdmin[2].cell=row=>null!==row.created_on ? <div className="group-col-16">{moment(row.created_on).format("DD MMM,YYYY HH:mm")}</div>:"NA";
      groupColumnsAdmin[3].cell=row=>null!==row.last_active_on ?<div>{moment(row.last_active_on).format("DD MMM,YYYY HH:mm")}</div>:"NA";
    }
    groupColumnsAdmin[4].cell=row=><div data-toggle="tooltip" title={row.primary_name} className="hyperlink group-col-16" onClick={()=>this.props.params.breadcrumbTail("user",row.primary_account_info,row.primary_name)}>{row.primary_name}</div>;
    groupColumnsAdmin[5].cell=row=><div data-toggle="tooltip" title={row.expertNames}>{row.experts.length}</div>;
    groupColumnsAdmin[6].cell=row=><div data-toggle="tooltip" title={row.techNames}>{row.techs.length}</div>;
    groupColumnsAdmin[7].cell=row=><div className="hoverClipCopy">
                                 <div>{row.sessionid}</div>
                                 <div className="tooltiptext small">
                                    <i className="fa fa-copy copySelection" id={"sessionid"+row.id}
                                    onClick={(e) => this.copyToClipboard(row.sessionid)}> Copy</i>
                                 </div>
                              </div>
    groupColumnsAdmin[8].cell=row=>null!==row.codec_type ? <div className="group-col-16">{row.codec_type}</div>:"NA";                         
    groupColumnsAdmin[9].cell=row=> row.accountInfo.streaming_medium === "both"  ? <StreamDropDown propParam={row} key={row.group_id} />: 
                                    row.is_onprem === true ? "OWRTC":"OT";
    groupColumnsAdmin[10].cell=row=>row.group_name ? <DownloadGroupReport propData={row} iconSize={25} name={row.group_name} 
                              viewType={this.state.tableViewContainer? "table" : "cards"} color="" location="in-cards" />:null;

    if (this.state.gridViewContainer) {
      groupColumnsAdmin.push({name:"Group Type",selector:"group_type",sortable:false,width:"120px"})
      groupColumnsAdmin[11].cell=row=>null!==row.last_active_on?setType(row):"NA";
    } else {
      //if (groupColumnsAdmin.length > 10) 
      if(groupColumnsAdmin[groupColumnsAdmin.length-1].selector==="group_type") {
        groupColumnsAdmin = groupColumnsAdmin.filter(gr => gr.selector !== "group_type")
      }
    }
    
    

    return (
    <div className="page">
    <div className="media">
      <h3>Please rotate your device to landscape mode</h3>
    </div>
    <div className="container-fluid media-show">
    {/* TP-6461 */}
    { this.state.gridViewContainer ?
   <div className='cardposition' onClick={this.toggleContainer}>
     <i className="fa fa-table switch-icons">
     </i>
     </div>
      :  
     <div className='cardposition' onClick={this.toggleContainer}>
      <i className="fa fa-id-card switch-icons">
      </i>
     </div>  
     }
      <SearchGroupAppBar 
        gridViewContainer={this.state.gridViewContainer} 
        toggleContainer={this.toggleContainer}
        {...this.state.showOnlineGroup} 
        toggleGroup={this.toggleGroup} 
        isAdmin={this.state.isAdmin} 
        groups={this.state.groups} 
        globalUniqueData={this.state.globalUniqueData} 
        searchGroup={this.searchGroup}
        getOptionLabel={this.getOptionLabel} 
        setSortDir={this.setSortDir}
        setSortColumn={this.setSortColumn}  
        sortcolumns={sortColumns} tree={tree}
       />
      <div className="row">
        <div className="col-sm-12 col-lg-12">
          { this.state.tableViewContainer === true ?
              <DataTable className="group-participants"
                pagination={this.state.groups.length>this.props.pagination}
                paginationPerPage={this.props.pagination}
                columns={this.state.isAdmin && !this.state.showOnlineGroup.value ? groupColumnsAdmin.filter(this.filterOutColumn):
                      this.state.isAdmin && this.state.showOnlineGroup.value ? groupColumnsAdmin: !this.state.showOnlineGroup.value ?
                      groupColumnNonAdmin.filter(this.filterOutColumn):groupColumnNonAdmin}
                data={this.state.groups}
                sortFunction={(rows, field, direction)=>this.customSort(rows, field, direction)}
              />
            :
              <LayoutGroups isSorted={this.state.isSorted} data={this.state.groups} columns={this.state.isAdmin && !this.state.showOnlineGroup.value ? groupColumnsAdmin.filter(this.filterOutColumn):
                this.state.isAdmin && this.state.showOnlineGroup.value ? groupColumnsAdmin: !this.state.showOnlineGroup.value ?
                groupColumnNonAdmin.filter(this.filterOutColumn):groupColumnNonAdmin} 
                nodataMessage="There is no records to display"
              />
          }
          { this.state.gridViewContainer && this.state.showLegends === true ?
            <div className='legend-cls'><span className="user-type-flag text-shadox" style={{backgroundColor:"#6fc566",margin:'5px', fontSize: '16px', padding:'6px'}}>Most Recent</span>
              <span className="user-type-flag text-shadox" style={{backgroundColor:"#e6e635",margin:'5px', fontSize: '16px', padding:'6px'}}>Moderately Recent</span>
              <span className="user-type-flag text-shadox" style={{backgroundColor:"#FF0000",margin:'5px', fontSize: '16px', padding:'6px'}}>Very Old</span>
              <span className="user-type-flag text-shadox" style={{backgroundColor:"#777",margin:'5px', fontSize: '16px', padding:'6px'}}>NA</span>
            </div>
            :
            ''
          }
        </div>
      </div>
    </div>
    </div>
    )
    
  }
}
