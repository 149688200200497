import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DropdownTreeSelect from "react-dropdown-tree-select";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import Autocomplete from '@mui/material/Autocomplete';//TP-749
import TextField from '@mui/material/TextField';//TP-749
import SelectAutoWidth from '../Utils/DropdownStandard';
import DirectionIcon from '../Utils/DirectionIcon';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import '../Utils/dropdownTree.css';
import { Grid } from '@mui/material';

const darkTheme = createTheme({
    palette: {
      primary: {
        main: '#384571',
      },
    },
    typography: {
      fontFamily: "Titillium Web",
    },
    overrides: {
    MuiList: {
      root:{
      width: 'fit-content'
      }
    }
  }
});

const ShowOnlineCheckbox = (props) => {
    return( <div id="show-online-user-container" >
      <label className="col-xs-3 disable-pointer-event"  htmlFor={props.id}>{props.label}&nbsp;</label>
      <label className="col-xs-4 col-xs-offset-2 switch">
        <input type="checkbox" id={props.id} disabled={props.isDisabled}
        name={props.id}
        key={props.id}
        onChange={props.toggleHandler}
        checked={props.value}
        />
        <span className="slider round"  title={props.isDisabled ? "Disabled for this View":""} ></span>
      </label>
    </div>
    )
  }

export default function SearchUserAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{  
          style: {  
            width: 400,  
          },  
      }}
    >
      <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
        <select id="onlyFilteredUsers" value={props.pickedUser} className="drop-down-class" onChange={e=>props.pickUser(e)}>
          {props.userOptList.map(opt=><option className="select-options" value={opt.value} key={opt.value}>{opt.label}</option>)}
        </select> 
      </MenuItem>
      <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
        <DropdownTreeSelect
          texts={{ placeholder: 'All Type' }}
          data={props.tree}
          id="radio-select-drop-down"
          onChange={(cn,sn)=>props.treeSelect(cn,sn)}
          //  onFocus={this.onTreeSelectFocus}
          className="mdl-demo" 
          mode="radioSelect"/>
      </MenuItem>
      { (sessionStorage.getItem("userDbRole") === "admin") && props.userLabel !== "Invitees" && props.filterBy !== "usersbylicense" &&
        <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none'}, '&:hover': {backgroundColor: 'transparent'}}}>
            <div className="align-self-center" style={{margin:'20px'}}>
              <Button className={!props.isAnyUserLocked ? 'disabled-all-lock-icon':'enable-all-lock-icon'} style={{borderRadius:'25px'}}
                onClick={(e)=>props.handleUnloackUser(e)} disabled={!props.isAnyUserLocked}>UnLock All</Button>
            </div>                   
        </MenuItem>
      }
      { props.gridViewContainer && 
        <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
          <div className="align-self-center" style={{margin:'20px'}}>
            <Button className={'enable-all-lock-icon'} 
                onClick={(e)=>props.copyEmails(e)}>Copy All Emails</Button>
          </div>
        </MenuItem>
      }
      <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
        <ShowOnlineCheckbox label="Show Online Users" {...props.showOnlineToggleObj} toggleHandler={props.toggleHandler} 
          isDisabled={(props.userLabel === 'Invitees'  || props.blockedLabelArr.indexOf(props.pickedUser) !== -1)}/>                
      </MenuItem>
      { props.gridViewContainer && 
        <>
          <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'none', lg: 'block', xl: 'block'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={10} md={10}>
                <SelectAutoWidth  
                //sortFunction={(field, direction)=>props.customSortCards(field, direction)} 
                setSortColumn={props.setSortColumn} 
                columns={props.sortcolumns} labelText="Sort By"
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <DirectionIcon  
                  setSortDir={props.setSortDir}
                />
              </Grid>
            </Grid>
          </MenuItem>          
        </>

      }
    </Menu>
  );

    return (
      <Box sx={{ flexGrow: 1, marginBottom: 1, ml: 1, mr: 1, zIndex:"10" }}>
        <ThemeProvider theme={darkTheme}>
          <AppBar position="static" color="primary" sx={{borderRadius: "8px" }}>
              <Toolbar>        
              <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 0.1, display: { xs: 'block', sm: 'block' }, paddingTop: "6px" }}
              >
                { props.gridViewContainer ?
                    <div title="Switch to Tabular View" onClick={()=>props.toggleContainer()}>
                        <i className="fa fa-table switch-icons" style={{color: 'white'}}></i>
                    </div>
                    :
                      props.needMap?
                        <div title="Switch to Map View" style={{display:props.needMap?'inline-block':'none'}} onClick={()=>props.toggleContainer()}> 
                            <i className="fa fa-map-marker switch-icons" style={{color: 'white'}}></i>
                        </div>
                        :
                        <div title="Switch to Card View" onClick={()=>props.toggleContainer()}>
                            <i className="fa fa-id-card switch-icons" style={{color: 'white'}}></i>
                        </div>
                }
              </Typography>
              <Typography 
                  sx={{ flexGrow: props.gridViewContainer ? 0.25 : 0.5, display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' }, height: "20px" }}
                  component="div"
              >
                  <select id="onlyFilteredUsers" value={props.pickedUser} className="drop-down-class" onChange={e=>props.pickUser(e)}>
                    {props.userOptList.map(opt=><option className="select-options" value={opt.value} key={opt.value}>{opt.label}</option>)}
                  </select>                   
              </Typography>
              <Typography sx={{ flexGrow: 0.25, display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' }, marginLeft: "10px", marginBottom: "6px" }}
                  component="div">
                  <DropdownTreeSelect
                    data={props.tree}
                    id="radio-select-drop-down"
                    onChange={(cn,sn)=>props.treeSelect(cn,sn)}
                    //  onFocus={this.onTreeSelectFocus}
                    className="mdl-demo" 
                    mode="radioSelect"/>
              </Typography>
              <Typography sx={{ flexGrow: props.gridViewContainer ? 0.25 : 0.5, display: { xs: 'block', sm: 'block' } }}
                  component="div">
                    <Autocomplete
                    id="globalUserHuntId"
                    options={props.globalUniqueData}
                    onChange={(e, data) => props.searchUser(e, data)}
                    getOptionLabel={props.getOptionLabel}
                    className="globalUserHuntCls"
                    renderInput={params => (
                        <TextField {...params} placeholder="Search Users..."/>
                    )} />
                   {/*  <SearchBox keyPressFn={props.keyPress} labelText="Search Users"/> */}
              </Typography>
              <Typography sx={{display: {xs: 'none', sm: 'none', md: 'none', lg: 'block'}}}>
                  {(sessionStorage.getItem("userDbRole") === "admin") && props.userLabel !== "Invitees" && props.filterBy !== "usersbylicense" &&
                  <div className="align-self-center" style={{margin:'20px'}}>
                        <Button className={!props.isAnyUserLocked ? 'disabled-all-lock-icon':'enable-all-lock-icon'} style={{borderRadius:'25px'}}
                        onClick={(e)=>props.handleUnloackUser(e)} disabled={!props.isAnyUserLocked}>UnLock All</Button>
                        </div>
                   }
              </Typography>
              <Typography sx={{display: {xs: 'none', sm: 'none', md: 'none', lg: 'block'}}}>
                { props.gridViewContainer ?
                      <div className="align-self-center" style={{margin:'20px'}}>
                        <Button className={'enable-all-lock-icon'} 
                            onClick={(e)=>props.copyEmails(e)}>Copy All Emails</Button>
                      </div>
                      :
                      ''
                  }
              </Typography>
              <Typography 
                  sx={{ flexGrow: 0.05, display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' }, paddingTop: "9px"}}
                  component="div"
              >
                  <ShowOnlineCheckbox label="Show Online Users" {...props.showOnlineToggleObj} toggleHandler={props.toggleHandler} 
                    isDisabled={(props.userLabel === 'Invitees'  || props.blockedLabelArr.indexOf(props.pickedUser) !== -1)}/>                
              </Typography>
  
               { props.gridViewContainer ?
                  <>
                    <Typography 
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'block', lg: 'none', xl: 'none' }, marginLeft: "5px", marginBottom: "12px" }}
                        component="div"
                    >
                        <SelectAutoWidth  
                            //sortFunction={(field, direction)=>props.customSortCards(field, direction)} 
                            setSortColumn={props.setSortColumn} 
                            columns={props.sortcolumns} labelText="Sort By"
                        />
                    </Typography>
                    <Typography 
                        sx={{ flexGrow: 0.1, display: { xs: 'none', sm: 'none', md: 'block', lg: 'none', xl: 'none' }/* , paddingTop: "3px" */}}
                        component="div"
                    >
                        <DirectionIcon  
                            setSortDir={props.setSortDir}
                        />
                    </Typography>                  
                  </>
                    :
                  ''
               }
                <Box sx={{ display: props.gridViewContainer ? { xs: 'flex', md: 'flex', lg: 'flex', xl: 'flex' } : { xs: 'flex', md: 'flex', lg: 'flex', xl: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </Box>
              </Toolbar>
          </AppBar>
          {renderMobileMenu}
        </ThemeProvider>
      </Box>
    );
}