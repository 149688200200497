import React ,{useState,useEffect} from "react";
import superagent from 'superagent';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import './customerAnalyticsStyle.css';
import LoadingThreeDots from '../Utils/LoadThreeDots';
import DonutChartModal from './Donut/analyticsDonutChart';
import StackChartModal from './StackBar/analyticsStackChart';
import {getMinutesDifference,formatDonutData,getGroupStackData,getUserName} from './analyticsHelper';

const todayDate =  new Date();
const backThreeMonthDate = new Date(todayDate.setMonth(todayDate.getMonth()-3));

const CustomerAnalytics = (props) =>{
    const [isLoading,setLoadingState] = useState(false);
    const [startDate,setStartDate] = useState(backThreeMonthDate);
    const [endDate,setEndDate] = useState(new Date());
    const [donutUsageData,setDonutUsageData] = useState([]);
    const [allCustomerUsageDeta,setCustomerUsageData] = useState([]);
    const [stackChartUsageData,setStackChartUsageData] = useState({});
    const [showStackChart,setStackChartFlag] = useState(false);
    const [accountUsers,setUserData] = useState(props?.customerInfo?.users);
    const [selectedGroupId,setGroupId] = useState(null);
    const [uniqueGroupName,setUniqueGroupName] = useState([]);
    
    useEffect(()=>{

        getCustomerUsageDetails();

    },[startDate,endDate])

    const getCustomerUsageDetails = async() => {

        const  apiUrl = '/api/dash/getAccountUsageData';
        let param = {
            account_id:props.customerInfo.account_id,
            from_date:moment(startDate).format('YYYY-MM-DD'),
            to_date:moment(endDate).format('YYYY-MM-DD')
        }
        
        if(sessionStorage.getItem('userToken')){
            setLoadingState(true);
            await superagent.post(sessionStorage.getItem("url") + apiUrl)
            .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
            .accept("application/json")
            .send(param)
            .then(response=> response)
            .then(data=>{
                if(data.body && data.status === 200){
                     setLoadingState(false);
                    let records = data.body,uniqueGrpVal=[];//.filter(item=>item.first_name && item.id); dataArray.sort((a,b)=> new Date(a.start_time) - new Date(b.start_time));
                        records =  records.map((obj)=>({...obj,diffMins:getMinutesDifference(obj),userName:getUserName(obj,accountUsers)})).sort((a,b)=> new Date(a.start_time) - new Date(b.start_time));
                        uniqueGrpVal = [...new Map(records.map(rec=>[rec['group_id'],rec])).values()]
                        setUniqueGroupName(uniqueGrpVal);
                        setCustomerUsageData(records);
                        setDonutUsageData(formatDonutData(records,uniqueGrpVal));
                        getStackChartData(selectedGroupId);        
                        
                }
            }).catch((error)=>{
                let errorMsg = null;
                setLoadingState(false);
                if(error.status === undefined){
                    errorMsg = 'May be internet is not connected, try after sometime';
                }else if(error.status === 400){
                    errorMsg = 'Data is not updated';
                }else if(error.status === 401){
                    errorMsg = 'Session token expired, Please re-login';
                }else if(error.status === 403){
                    errorMsg = 'You are not authorized';
                }else if(error.status === 422){
                    errorMsg = 'Invalid Value'
                }else if(error.status === 500){
                    errorMsg = 'Something went wrong, try again later';
                }
                window.flash(errorMsg,'error');
            })

        }
    }

   const updateShowStackChart = (groupId) =>{
        if(!groupId)
            return false;

        setGroupId(groupId);
        getStackChartData(groupId);
       
    }

    const getStackChartData = (selectedGrpId) => {
        if(!selectedGrpId)
            return false;

        const selectedGroupName = uniqueGroupName.find((lst)=>lst.group_id===selectedGrpId).group_name;
        const data = getGroupStackData(selectedGrpId,allCustomerUsageDeta,selectedGroupName);
        setStackChartUsageData(data);
        setStackChartFlag(true);

    }
   
    
    return(
        <div id='chart-analytics-conatiner' className="main-div-conatiner">
            <div className="chart-handler-div">
                <DatePicker id='analytics_start_date'
                        placeholderText={'Start date'}
                        selected={startDate}
                        dateFormat='dd/MM/yyyy'
                        maxDate={new Date()}
                        autoComplete='off'
                        onChange={(date)=>setStartDate(date)}
                    />
                <DatePicker id='analytics_end_date'
                    placeholderText={'End date'}
                    dateFormat='dd/MM/yyyy'
                    selected={endDate}
                    maxDate={new Date()}
                    autoComplete='off'
                    onChange={(date)=>setEndDate(date)}
                    />
                </div>
                <div className={showStackChart ? "analytics-chart-container-divided":"analytics-chart-container-center"}>

                     {donutUsageData.length > 1 && <DonutChartModal data={donutUsageData} stackChartUpdateFunc={updateShowStackChart}/>} 
                     {(showStackChart === true && stackChartUsageData.data) && <StackChartModal data={stackChartUsageData} />}

                </div>
             {isLoading && <LoadingThreeDots isLoading={isLoading}/> } 
        </div>
    )

}

export default CustomerAnalytics;