import React,{useEffect,useRef} from 'react';
import drawChart from './drawAnalyticsDonutChart';
import './analyticsDonutStyle.css'

const DonutChart = ({data,stackChartUpdateFunc})=>{
    const ref = useRef(null);
    const legRef = useRef(null);

    useEffect(()=>{
        if(ref.current)
        drawChart(ref.current,'donutLegendbox',data,stackChartUpdateFunc);
    },[data,stackChartUpdateFunc])

    return(
        <div id="donut-chart-box" className='donut-container-box-div'>
            <div>Group wise usage data</div>
            <div id='donutChartbox' className ='graphbox' ref={ref}></div>
            <div id='donutLegendbox' className ="chart__legend_box" ref={legRef}></div>
        </div>
    );

};

export default React.memo(DonutChart);