/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import './multiSelect.css';

const MultiSelect = ({
  items,
  selectedValues,
  label,
  className,
  placeholder,
  noOptionsText,
  limitTags,
  onToggleOption,
  onClearOptions,
  getOptionLabel,
  muiInputClass,
}) => {


  const handleChange = (event, selectedOptions, reason) => {

    const selectedOptns = selectedOptions.filter(data => data.huntLabel);

    if (reason === "select-option" || reason === "remove-option") {
      if (selectedOptns.find(option => option.huntLabel === "select-all")) {
        //handleToggleSelectAll(); if need we can use it
      } else if(reason === "select-option" && selectedOptns) {
        onToggleOption && onToggleOption(selectedOptns,reason);
      }else if(reason === "remove-option" && selectedOptns) {
        onToggleOption && onToggleOption(selectedOptns,reason);
      }
    } else if (reason === "clear") {
      onClearOptions && onClearOptions(selectedOptns,reason);
    }
  };
  const optionRenderer = (option, { selected }) => {

    return (
      <>
        {getOptionLabel(option)}
      </>
    );
  };
  const inputRenderer = params => (
    <TextField {...params} label={label} className={muiInputClass} placeholder={selectedValues.length === 0 ? placeholder:""} />
  );
  const getOptionSelected = (option, anotherOption) => option.huntLabel === anotherOption.huntLabel;

 
const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      size="small"
      className={className ? className:'default'}
      limitTags={limitTags}
      options={items}
      value={selectedValues && selectedValues}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      noOptionsText={noOptionsText}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
};

MultiSelect.defaultProps = {
  limitTags: 2,
  items: [],
  selectedValues: [],
  getOptionLabel: option =>  option.huntLabel,
};


export default MultiSelect;
