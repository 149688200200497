import React from 'react';
import goSubmit from './care4dImg/go_check.png';
import telepresenzLogo from './care4dImg/white_logo_transparent_background.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {encryptData} from './cryptography';


function showLoader(isLoading){
    if (!isLoading) return "";
    else return (<div className="loader"><i className="fa fa-spinner fa-spin" style={{fontSize:"60px",color:"white"}}></i></div>)
}
export default class LoginPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {}
  }
   render() {
      return (<div className="home">
        <LoginHeader version={this.props.version}/>
        <div className="center">
          <img alt="Log into telepresenz!" width="150" height="110" src={telepresenzLogo}/>
        </div>
        <main className="black-80 relative">
            <LoginForm/>
        </main>
        </div>);
   }
}

const LoginHeader = (props) =>{
  return(
    <div className="topnav">
      <span className="version-badge">{props.version}</span>
    </div>
  );
}


const smartVisionCaseCheck = {
  isadmin: true,
  behaviour: "smartvision",
  dashboard_role:["write_ent","write_my_account","read_my_account"]
};

const _isValidSmartVisionUser = (caseObj, dataParms) => {
  return caseObj["behaviour"] === dataParms["behaviour"] && caseObj["dashboard_role"].includes(dataParms["dashboard_role"]) ? true:false;
};

export class LoginForm extends React.Component{

  constructor(props) {
    super(props);
    this.state={
      userName:'',
      passKey:'',
      errorMessage:null,
      environment : [
        { value:'ANZ', url:"https://anz.telepresenz.com"},
        { value:'DEMO', url:"https://demo.telepresenz.com"},
        { value:'DEV', url:"https://dev.telepresenzdemo.com"},
        { value:'PROD', url:"https://app.telepresenz.com"},
        { value:'QA', url:"https://qa.telepresenzdemo.com"},
        { value:'STAGING', url:"https://staging.telepresenzdemo.com"},
        { value:'FDA', url:"https://honeywell.telepresenz.com"}, /**TP-6364 */
        { value:'PGCS', url:"https://pgcs.telepresenz.com"},
        { value:'HPS', url:"https://hps.telepresenz.com"},
        { value:'Fort Liberty', url:"https://fortliberty.telepresenz.com"}        
        /* { value:'SGP', url:"https://sgp.telepresenz.com"}, */
        // {value:"SV",url:"https://sv.telepresenzdemo.com"}
        /* { value:'ONPREM', url:"https://onprem.telepresenzdemo.com"}, */
      ],
      demoEnvironmnet:[
        /*	TP-3537 -- { value:'ANZ', url:"https://anz.telepresenz.com"}, TP-2860 */
        { value:'DEMO', url:"https://demo.telepresenz.com"}, /**TP-2860 */
        // { value:'HW-PBI', url:"https://honeywell.telepresenz.com"}, /**TP-6364 */
        { value:'DEV', url:"https://dev.telepresenzdemo.com"},
        { value:'QA', url:"https://qa.telepresenzdemo.com"},
        { value:'STAGING', url:"https://staging.telepresenzdemo.com" } 
      ],

      selectedEnv : "",
      isLoading:false,
      isSmartVisionUrl:false,
      isTelepresenzDemoURL:false,
      isAllreadySignIn:false,//TP-1156
      dialogMsg:null
    };
    this.callSignIn = this.callSignIn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.routeChange = this.routeChange.bind(this);
    this.showAllreadyLoginModel = this.showAllreadyLoginModel.bind(this);
    this.onForceLogmeIn =  this.onForceLogmeIn.bind(this);
  }
  componentDidMount() {
    if(/* 1==1 */ window.location.href.indexOf("smartvision") > 0){
      this.setState({isSmartVisionUrl:true});
      this.setState({selectedEnv:'https://pubsmartvision.com'});
    }else if(window.location.href.indexOf("telepresenzdemo") > 0){
      this.setState({isTelepresenzDemoURL:true});
      
      //this.setState({selectedEnv:'https://dev.telepresenzdemo.com'});
    }
  }

  routeChange(event) {
    if("none"===sessionStorage.getItem("userDbRole")){
      this.setState({showModal:true});
    	this.setState({modalTitle:"Logging out"});
    	this.setState({modalBody:"Sorry, No permission to dashboard"});
    }else{
      window.location.reload();
    }
  }

 
 callSignIn(event) {
   if(event !== "logmeIn")
    event.preventDefault();
   // const data = new LoginForm(event.target);
  // let isEncryptedENV =  this.state.environment.some(el => el.url === this.state.selectedEnv && el.value ==="DEV");
 
  //let isEncryptedENV = this.isEncryptableENV(notEncryptedENV,this.state.selectedEnv);

   if(''!==this.state.userName && ''!==this.state.passKey){
      //Commented out this part due to changes for TP-6384
      /* var params = {
        "geoLocation": "",
        "email": this.state.userName,
        "password": this.state.passKey,
        "isencrypted": false 
      }
      if (this.state.isSmartVisionUrl === true) { */
        let params = {
          "geoLocation": "",
          "email": encryptData(this.state.userName.toLowerCase()),
          "password": encryptData(this.state.passKey),
          "isencrypted": true 
        }
      //}
     
     this.setState({isLoading: true});
     fetch(this.state.selectedEnv+'/api/signin', {
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
         },
          body: JSON.stringify(params)
     }).then(response => {
        if (response.status === 200){
          return response.json();
        }else{
          throw response;
        }
      })
      .then(({token, user}) =>{
       this.setState({isLoading: false});
       //if(data.token && data.user){
          console.log("Login Successfully");
          sessionStorage.setItem('userToken', token);
          sessionStorage.setItem('userEmail', user.email);
          sessionStorage.setItem('userId', user.id);
          sessionStorage.setItem('url', this.state.selectedEnv);
          sessionStorage.setItem('userDbRole', user.dashboard_role);          
          sessionStorage.setItem('userBehaviour',user.behaviour);
          sessionStorage.setItem('accountId',user.accountid);
          sessionStorage.setItem("isSmartVision",_isValidSmartVisionUser(smartVisionCaseCheck,user));
          sessionStorage.setItem("allowLogMeIn",this.state.isAllreadySignIn);
          sessionStorage.setItem("lastAccessedOn",user.last_accessed_on)
          document.cookie="email="+user.email;
          document.cookie="id="+user.id;
          document.cookie="token="+token;
          this.routeChange();
       //}else{
        
       //}

      }).catch(error => {
          //console.log(error.status);

          let msg = "Invalid Username or Password"
          if(error.status === undefined){
            msg = "Internet is not connected";
          }else if(error.status === 409){
            //msg = "User is already logged in to Telepresenz";
            this.showAllreadyLoginModel();
            this.setState((prevState) => ({isLoading:!prevState.isLoading}));
            return;                   
            
          }else if(error.status === 403){
            msg = "Your Password has expired";
          }else if(error.status === 423){
            msg = "Your account is locked. Please contact your system administrator to unlock your account";
          }else if(error.status === 503){
            msg = "This account is temporarily suspended";
          }

          this.setState({errorMessage: msg,isLoading: false});
            setTimeout(() =>{this.setState({errorMessage: null});},5000);

    });
   }

  }

  isEncryptableENV(arr, env,) {
      var count = arr.length, matchFound = true;

      for(let i = 0; i < count; i++) {
          if (arr[i].url === env) {
              matchFound = false;
              break;
          }
      }
      return matchFound;
  }

  handleChange(event) {
    if(event.target.name==='email'){
      this.setState({userName: event.target.value});
    }else if(event.target.name==='password'){
      this.setState({passKey: event.target.value});
    }else if(this.state.isSmartVisionUrl === true) {
      this.setState({selectedEnv: "SMARTVISION", errorMessage: null})
    }else if(!this.state.isSmartVisionUrl && event.target.name==='environment'){
      this.setState({selectedEnv: event.target.value, errorMessage: event.target.value === "" ? "Select Environment" : null});
    } 
  }
//TP-1156
  showAllreadyLoginModel(){
    this.setState((prevState)=>({
      isAllreadySignIn:!prevState.isAllreadySignIn
    }),()=>{
      if(this.state.isAllreadySignIn){
        const errorMsg = "User is already logged in to Telepresenz.Do you want to log in now?"
        this.setState({dialogMsg:errorMsg});
      }else{
        const errorMsg = "User is already logged in to Telepresenz"
        this.setState({errorMessage:errorMsg});
      }
    })
  }

  onForceLogmeIn = () => {
    const params = {};
    let encryptedEmail = encryptData(this.state.userName.toLowerCase())
    let encryptedPassword = encryptData(this.state.passKey);
    params.email = encryptedEmail;
    params.password = encryptedPassword;
  
		fetch(this.state.selectedEnv+'/api/updateSigninFlag', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
       body: JSON.stringify({email: encryptedEmail,password: encryptedPassword})
    })
		.then(response => {
			this.setState({ status: response.status });
			if(response.status === 200) 
				return response.json();
			else
				throw response;
		})
		.then((res) => {
			this.setState({errorMsg: ''});
      this.callSignIn("logmeIn");
		
		})
		.catch((error) => {
			this.setState({ errorMsg: 'Invalid Username or Password'});
			
		})
	}

render(){
  return(
    <div className="signin-wrapper center">
    {!this.state.isAllreadySignIn ?
    <form id="loginForm" onSubmit={this.callSignIn}>
            { this.state.isSmartVisionUrl === false ?
                <div style={{display:(this.state.isSmartVisionUrl) ? "none":"block"}}>
                  <select value={this.state.selectedEnv} id="environment"
                  className="signin-select-field fas fa-caret-down" name="environment"
                    onChange={this.handleChange} required={this.state.isSmartVisionUrl || this.state.isTelepresenzDemoURL ? false: true}>
                    <option value="">Select Environment</option>
                    { this.state.isTelepresenzDemoURL ? 
                    this.state.demoEnvironmnet.map((env) => <option key={env.url} value={env.url}>{env.value}</option>):
                    this.state.environment.map((env) => <option key={env.url} value={env.url}>{env.value}</option>)
                    }
                  </select>
                  <div style={{color: 'red', marginTop: '5px'}}>
                    {this.state.validationError}
                  </div>
                </div>
                :
                <div className="sigin-text-field">
                  SmartVision Dashboard
                </div>
            }
            
            <div>
              <input className="signin-input-field usernameIcon" data-testid="email-input-field"
              type="email" name="email" id="email-address" required
              placeholder="Username" value={this.state.userName}
              onChange={this.handleChange}/>
            </div>
            <div>
              <input className="signin-input-field password-icon" data-testid="password-input-field"
              type="password" name="password" id="password" required
              placeholder="Password" value={this.state.passKey}
              onChange={this.handleChange}/>
            </div>
        <div className="relative flex-center">
            <div className="signin-go-text">Go</div>
            <input type="image" data-testid="go-login-button" src={goSubmit} id="loginButton" alt="Submit"
             className="signin-check-icon" disabled={!(this.state.userName && this.state.passKey && this.state.selectedEnv)}/>
        </div>
        { showLoader(this.state.isLoading) }
        { this.state.errorMessage ? <ErrorMessage errorMessage={this.state.errorMessage}/> : null }
        {this.state.showModal?<ModalPopUp modalTitle={this.state.modalTitle} modalBody={this.state.modalBody}/>:''}
    </form>:
        
        <div className="logMeIn-container">
          <div className="logmeIn-text-div">User is already logged in to Telepresenz. Do you want to log in now?</div>
          <div className="logmeIn-footer">
            <button className="btn-logmeIn-green" onClick={this.onForceLogmeIn}>Yes</button>
            <button className="btn-logmeIn-red" onClick={this.showAllreadyLoginModel}>No</button>
          </div>
        </div>

    }
    </div>
    );
}
}


class ErrorMessage extends React.Component{
  render(){
    return(
      <div className="alert alert-danger">{this.props.errorMessage}</div>
    );
  }
}


class ModalPopUp extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      showModal: false
    }
    this.close = this.close.bind(this);
  }

  close() {
    this.setState({showModal:false});
    callLogOut();
  }

  componentDidMount(){
    this.setState({showModal:true});
  }

  render() {
    return (
        <Modal show={this.state.showModal} onHide={this.close} backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>{this.props.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.modalBody}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close} className="btn-green">OK</Button>
          </Modal.Footer>
        </Modal>
    );
  }
}


function callLogOut() {
  if (null != sessionStorage.getItem("userToken")) {
    var params = {
      email: encryptData(sessionStorage.getItem("userEmail").toLowerCase()),
      groupid:null,
      id: sessionStorage.getItem("userId"),
      isencrypted: true
    };

    fetch(sessionStorage.getItem("url") + "/api/signout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + sessionStorage.getItem("userToken")//TP-2411
      },
      body: JSON.stringify(params)
    })
      .then(response => response.json())
      .then(data => {
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
      })
      .catch(error => {
        console.log(error);
      });
  }
}
