import React from 'react';
import moment from "moment";
import Edit from "../editForm";
import ReverseGeocode, { ILocation, IGeocode } from "bigdatacloud-reverse-geocoding";
import TokBox from '../tokbox/TokBox';
import superagent from "superagent";
import {Modal,Button} from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Diagnostic from "./diagnostic.js";
import {encryptData} from '../cryptography';
import AccessList from './accessList';
import Jobs from '../jobTabs/jobs';
import {connect} from 'react-redux';
import {updatePasswordLock,updateUserProfile} from '.././redux/Customer/CustomerActions';
import {FaEdit} from 'react-icons/fa'
import EditGroupAdmin from './EditGroupForm';
import UsageDetails from './usageDetails';
import "./newUserDetails.css";  /* TP-6476 */

const otClientUrl = 'https://static.opentok.com/v2/js/opentok.min.js';
const userGroupsColumns = [
  {name:"#",selector:"index",sortable:true, width:"50px"},
  {name:"Group Name",selector:"group_name",sortable:true},
  {name:"Group Description",selector:"description",sortable:true},
  {name:"Expert(s)",selector:"experts",sortable:true},
  {name:"Technician(s)",selector:"techs",sortable:true},
  {name:"Status",selector:"isongoingnow",sortable:true},
];
function checkString(item){
  if(undefined===item||null===item||""===item)
    return "NA";
  else return item;
}

const UserInfo = ({
  device_manufacturer,device_model,
  browser_name,browser_version,
  os_name,os_version,
  kernel_version,build_number,
  ip,coordinates,
  serial_number,apk_version,
  technician}) => (
    /* TP-6476 */
<div className="userInfoDiv">  
  <div className="small userInfo">
    Device: {checkString(device_manufacturer)
      +" "+(undefined===device_model?"":getAndroidVersion(device_model))
    }
  </div>
  <div className="small userInfo">
    S/N: {checkString(serial_number)}
  </div>
  <div className="small userInfo">
    OS: {checkString(os_name)+" "+(undefined===os_version?"":os_version)}
  </div>
  <div className="small userInfo">
    {
      technician
      ?"Build No: "+checkString(build_number)
      :"Browser: "+checkString(browser_name)+" "+(undefined===browser_version?"":checkString(browser_version).substring(0,5))
    }
  </div>
  <div className="small userInfo">
    IP: {checkString(ip)}
  </div>
  <div className="small userInfo">
    APK Version: {checkString(apk_version)}
  </div>
  <div className="small userInfo">
    Coordinates: {checkString(coordinates)}
  </div>
</div>
);

/* TP-6476 */
function roleNameColor(item){
  return item.isadmin
  ? item.isexpert
    ?<><strong>Admin/Expert:&nbsp;</strong> <span className="user-type-flag" style={{backgroundColor:"#89783f"}}>
      {item.first_name+" "+item.last_name}
    </span></>
    :<><strong>Admin:&nbsp;</strong> <span className="user-type-flag" style={{backgroundColor:"#89783f"}}>
      {item.first_name+" "+item.last_name}
    </span></>
  : item.isexpert
    ?<><strong>Expert:&nbsp;</strong> <span className="user-type-flag" style={{backgroundColor:"#2d3858"}}>
      {item.first_name+" "+item.last_name}
    </span></>
    :!item.isadmin&&!item.isexpert
      ?<><strong>Technician:&nbsp;</strong> <span className="user-type-flag" style={{backgroundColor:"#71a95a"}}>
        {item.first_name+" "+item.last_name}
      </span></>
      :"";
}

function getAndroidVersion(manuf){
  if(parseInt(manuf)>28){
    return "10";
  }else if(parseInt(manuf)>27){
    return "9";
  }else if(parseInt(manuf)>26){
    return "8.1";
  }else if(parseInt(manuf)>25){
    return "8";
  }else {
    return manuf;
  }
}
 class UserDetails extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      address:"NA",
      modalBody:null,
      modalTitle:null,
      showModal:false,
      isLoading:false,
      isScreenSharing:false,
      disableSS:false,
      config : {
        apiKey: null,
        apiSecret: null,
        groupId: null,
        otClientUrl : otClientUrl,
        sessionId: null,
        token: null,
      },
      serverError:null,
      userGroups:[],
      userDiagonosticList:[],
      pwdLock:false,
      canTurnOffVideo:false,
      userAllowNewEvidence:false,
      allowAutoConnect:false,
      myCameraOn: false, //TP-3554
      streamingQuality: 'low', //TP-3554
      imageQuality: 'low', //TP-3554
      disableNotifications: true, //TP-3554
      canCancelUserFromSession:false,
      isFirstLogin:false,
      customerDtls:null,
      serverStatus:null,
      canResetUserPassword:false,
      showPopUp: false,
      dashboardRole:'none',
      accountUsers:[],
      tabIndex:"1",
      date:new Date(),
      iosLoginAllowed:false,
      isCreateScanJobByTechnician: false,
      enableJobScheduler: false,
      enableAssetClass: false, //TP-2730
      lastUpdatedChildValue:null,
      userViewDetails:null,
      showEditGroupAdmin:false,
      showUserDetailsPage:"flex",  /* TP-6476 */
      showUserDetailsPage2component:"none",   /* TP-6476 */
    };

    this.handleEditGroupAdminView = this.handleEditGroupAdminView.bind(this);
    this.confirmationPopup = this.confirmationPopup.bind(this);

  }

  static getDerivedStateFromProps(nextProps, prevState){

    if(nextProps.customerDtls!==prevState.customerDtls || nextProps.viewDetails!==prevState.userViewDetails){
        return { customerDtls: nextProps.customerDtls , userViewDetails: nextProps.viewDetails};
    }else{
          return null;
      }

  }
  getAddress = async (coordinates) => {
    let coordinate = coordinates.split(",")
    let address = "NA";
    if(coordinate.length>1 && "0.0"!==coordinate[0]){
      let location: ILocation = { lat: coordinate[0], long: coordinate[1]};
      let place: IGeocode = await new ReverseGeocode().locate(location);
      address = place.locality+", "+place.principalSubdivision+" "+place.countryName;
    }
    this.setState({address:address});
  }

  componentDidMount(){
    const { socket,viewDetails,customerDtls,filterValue} = this.props;
    if(viewDetails && viewDetails.user_info
      && viewDetails.user_info.includes("coordinates")){
      this.getAddress(JSON.parse(viewDetails.user_info).coordinates);
    }
    if(viewDetails){
      this.getUserProfileSettings(viewDetails.id); //TP-3554
      this.setState({pwdLock:viewDetails.pwd_lock});
      this.setState({canCancelUserFromSession:viewDetails.can_disconnect_user});
      this.setState({canTurnOffVideo:viewDetails.can_turnoff_video});     
      this.setState({userAllowNewEvidence:viewDetails.user_allow_new_evidence});
      this.setState({enableTemplates:viewDetails.enable_job_template}) /**TP-3622*/
      this.setState({isFirstLogin:viewDetails.is_first_login});
      this.setState({canResetUserPassword:viewDetails.can_reset_user_password});
      this.setState({dashboardRole:viewDetails.dashboard_role});
      this.setState({iosLoginAllowed:viewDetails.user_ios_login_allowed});
      this.setState({isCreateScanJobByTechnician:viewDetails.user_create_scan_job_by_technician});
      this.setState({enableJobScheduler:viewDetails.user_enable_job_scheduler});
      this.setState({enableAssetClass: viewDetails.enable_asset}) //TP-2730
    }
    if(customerDtls){
      this.setState({customerDtls:customerDtls,userViewDetails:viewDetails});
    }
    if(filterValue && filterValue.length > 0)
    this.setState({accountUsers:filterValue});

    // socket.on('connect-the-call', this.subscriberCallConnect);
    socket.on('respond-to-the-call', this.publisherCallResponse);
    this.getDashGroup();
  }

  childCommunicator = (lastUpdatedKey) => {

    const prevUserDetls = this.state.userViewDetails;
    
    if(this.props.params.state.breadcrumbItems.length <= 2 && lastUpdatedKey.keyName){
      const keyName = lastUpdatedKey.keyName;
      if(keyName === "available_monthly_minutes"){
        prevUserDetls[keyName] += lastUpdatedKey[keyName];
        prevUserDetls["max_monthly_minutes"] += lastUpdatedKey[keyName];
      }else if(keyName === "email"){
        prevUserDetls[keyName] = lastUpdatedKey["new_email"];
      }else{
        prevUserDetls[keyName] = lastUpdatedKey[keyName];
      }
    }
    
    this.setState({
      ...this.state,
      userViewDetails:prevUserDetls,
      lastUpdatedChildValue:lastUpdatedKey
    })
  }

  toggleScreenShare = () => {
    if(this.state.isScreenSharing){
      this.clearGuestSession();
    }
    this.setState({isScreenSharing:!this.state.isScreenSharing});
  }

  connectScreenShareCall = (user, groupId) => {
    try {
      // Emitting a new message. It will be consumed by the client
      if(user.email && groupId){
        let call = this.props.socket.emit('connect-the-call', {
            recipientEmail: user.email,
            callerName: "Tech-Support",
            isCancelled: false,
            group_id: groupId
        });
        let msg = "Calling "+user.first_name+" "+user.last_name+"...";
        this.setState({modalTitle:"Calling",modalBody:msg, showModal:true});
        console.log(call.io);
      }
    } catch (error) {
      console.error(error);
    }
  }

  subscriberCallConnect = (data) => {
    let msg = null;
    if(!data.isCancelled){
      msg="User is trying to reach you";
    }else if(undefined===data.isCancelled || data.isCancelled){
      msg="Call is cancelled by user";
    }
    // alert(msg);
    console.log(msg+": "+data);
    // this.setState({modalBody:msg, disableSS:true});
  }

  updateUserProfile = async (e,params) =>{
    // Temporary Code start here
    // if(("true" === sessionStorage.isSmartVision || sessionStorage.url.indexOf('smartvision') >-1) && (e.target.id && e.target.id === "isFirstLogin")){
    //   this.setState({serverError:"You cannot perform this action"});
    //   return;
    // }
    // Temporary code end here
    let apiUrl =  "/api/dash/setUserConfiguration";
    let successObj = {},errorObj = {},msg = null;
    //console.log(params);
    successObj[e.target.id] = (e.target.value  && e.target.value === "true") ? true: false;
    errorObj[e.target.id] = (e.target.value  && e.target.value === "true") ? !true: !false;

      if (null!==sessionStorage.getItem("userToken")
          && null!==params && null!==apiUrl) {
          this.setState({isLoading:true});
           await superagent.post(sessionStorage.getItem("url") + apiUrl)
          .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
          .accept("application/json")
          .send(params)
          .then(response => response)
          .then(data => {
            if(200===data.status){
              msg = "Data updated successfully";
              this.setState({isLoading: false,serverError:msg,serverStatus:Object.keys(successObj).indexOf('isFirstLogin') !==-1 ? null:data.status});
              this.setState(successObj);
              this.props.updateUserProfile(this.state.userViewDetails,params)
            }
          }).catch(error => {
            if(Object.keys(successObj).indexOf('isFirstLogin') === -1){ 
              this.setState(errorObj);
            }   
            if(undefined===error.status){
              msg = "May be internet is not connected, try again sometime";
            }else if(400===error.status){
              msg = "Data not updated";
            }else if(401===error.status){
              msg = "Session token experied, please re-login";
            }else if(403===error.status){
              msg = "You are not authorized";
            }else if(500===error.status){
              msg = "Something went wrong, try again sometime";
            }
            this.setState({isLoading: false,serverError:msg});
          });
      }else{
          this.close();
      }
    }
  
    /**TP-3554*/
    getUserProfileSettings = async (id) => {
      let url = "/api/user/getProfileData/";
        await superagent
          .get(sessionStorage.getItem("url") + url + id)
          .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
          .accept("application/json")
          .then((response) => response)
          .then((data) => {
            if (null != data.body) {
              //TP-3554
              this.setState({
                allowAutoConnect:data.body.allow_auto_connect,
                myCameraOn: data.body.my_camera_on,
                streamingQuality: data.body.streaming_quality,
                imageQuality: data.body.image_quality,
                disableNotifications: data.body.disable_notifications
              });
            }
          })
          .catch((error) => {
            if (undefined !== error.status) {
              let msg = id + " user not found";
              this.setState({serverError:msg});
            }
          });
    };
  
   updateUserProfileConfiguration = async (e,params) => {
    let apiUrl =  "/api/user/setProfileData";
    let successObj = {},errorObj = {},msg = null;
    //console.log(params);
    successObj[e.target.id] = (e.target.value  && e.target.value === "true") ? true: false;
    errorObj[e.target.id] = (e.target.value  && e.target.value === "true") ? !true: !false;
    if (null!==sessionStorage.getItem("userToken")
          && null!==params && null!==apiUrl) {
            this.setState({isLoading:true});
            await superagent.put(sessionStorage.getItem("url") + apiUrl)
           .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
           .accept("application/json")
           .send(params)
           .then(response => response)
           .then(data => {
             if(200===data.status){
               msg = "Data updated successfully";
               this.setState({isLoading: false,serverError:msg,serverStatus:Object.keys(successObj).indexOf('isFirstLogin') !==-1 ? null:data.status});
               this.setState(successObj);
               this.props.updateUserProfile(this.state.userViewDetails,params)
             }
           }).catch(error => {
             if(Object.keys(successObj).indexOf('isFirstLogin') === -1){ 
               this.setState(errorObj);
             }   
             if(undefined===error.status){
               msg = "May be internet is not connected, try again sometime";
             }else if(400===error.status){
               msg = "Data not updated";
             }else if(401===error.status){
               msg = "Session token experied, please re-login";
             }else if(403===error.status){
               msg = "You are not authorized";
             }else if(500===error.status){
               msg = "Something went wrong, try again sometime";
             }
             this.setState({isLoading: false,serverError:msg});
           });

    } else {
      this.close();
    }
    }

     setUserPassword = async (e,params) =>{
    let apiUrl =  "/api/dash/setUserPassword";
    let msg = null;
      if (null!==sessionStorage.getItem("userToken")
          && null!==params && null!==apiUrl) {
          this.setState({isLoading:true});
           await superagent.put(sessionStorage.getItem("url") + apiUrl)
          .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
          .accept("application/json")
          .send(params)
          .then(response => response)
          .then(data => {
            if(200===data.status){
              msg = "Data updated successfully";
              this.setState({isLoading: false,serverError:msg});
            }
          }).catch(error => {
            
            if(undefined===error.status){
              msg = "May be internet is not connected, try again sometime";
            }else if(400===error.status){
              msg = "Data not updated";
            }else if(403===error.status){
              msg = "You are not authorized to edit the user password";
            }else if(422===error.status){
              msg = "In valid requested data";
            }else if(500===error.status){
              msg = "Something went wrong, try again sometime";
            }
            this.setState({isLoading: false,serverError:msg});
          });
      }else{
          this.close();
      }
    }

  publisherCallResponse = ({ action, groupName, groupId, recipient, userName }) => {
    let msg = null;
  	if (action === 'accepted' || action === 'in_session') {
  		//For starting the screen share
      msg = "Call accepted by user";
      this.setState({modalTitle:"Accepted", modalBody:msg, showModal:false, serverError: null});
      this.toggleScreenShare();
  	} else if(action === 'declined') {
  		//When call being declined by the recipient
      msg = "Call declined by user, try again";
      if(this.state.isScreenSharing){
        this.toggleScreenShare();
      }
      // alert(msg);
      this.setState({modalTitle:"Declined", modalBody:msg, serverError: null});
  	}else msg=action;
    console.log(action, groupName, groupId, recipient, userName);
  }

  inviteGuestUser = () => {
    const {viewDetails} = this.props;
    const {isScreenSharing,config} = this.state;

    if(isScreenSharing){
      this.toggleScreenShare();
    }else if(viewDetails.email===sessionStorage.userEmail){
      this.setState({serverError:"Not able to invite yourself, try requesting other user"});
    }else if(undefined===this.state.config.token){
      this.setState({serverError:"Not able to start, please delete the group to proceed"});
    }else if(config.groupId){
        this.connectScreenShareCall(viewDetails,config.groupId);
    }else if(!config.groupId) {
      this.setUpScreenShareSession({
        email: viewDetails.email,
        isexpert: !viewDetails.isadmin&&!viewDetails.isexpert
      },"/api/dash/setUpScreenShareSession");
    }
  }

  setUpScreenShareSession = async (params,url) => {
      if(params.email && null != sessionStorage.userToken) {
        this.setState({isLoading: true});
        await superagent.post(sessionStorage.url+ url)
          .set("Authorization", "Bearer " + sessionStorage.userToken)
          .send(params)
          .accept("application/json")
          .then(response => response)
          .then(data => {
            if (null !== data.body) {
              sessionStorage.setItem("apiKey",data.body.apiKey);
              sessionStorage.setItem("token_"+data.body.groupId,data.body.token);
              this.setState({config:{...this.state.config, ...data.body}});
              this.connectScreenShareCall(this.state.userViewDetails,this.state.config.groupId);
            }
            this.setState({isLoading: false});
          }
        ).catch(error => {
          let msg=null;
          if(undefined===error.status){
            msg = "Internet is not connected, please try again after connecting network";
          }else if(401===error.status){
            msg = "Session token experied, Please re-login";
          }else if(403===error.status){
            msg = "You are not authorized";
          }else if(404===error.status){
            msg = "The email id provided for the guest does not exist";
          }else if(409===error.status){
            msg="Not able to start, please delete the group to proceed";
          }else if(444===error.status){
            msg = "A valid session id could not be obtained";
          }else if(500===error.status){
            msg = "Something went wrong, try again later";
          }else{
            console.error(error);
          }
          this.setState({isLoading: false,serverError:msg});
        });
      }
  }

  clearGuestSession = () => {
    const {config, userGroups} = this.state;

    sessionStorage.removeItem("token_"+config.groupId);
    this.setState({
      config:{
        apiKey: null,
        apiSecret: null,
        groupId: null,
        otClientUrl : otClientUrl,
        sessionId: null,
        token: null,
      },
      userGroups:userGroups.filter(group=>config.groupId!==group.group_id)
    });
  }

  getDashGroup = () => {
    const {groups, viewDetails} = this.props;
    let dashGroupDtls = {};
    let userGroups = groups.filter(group=>viewDetails.accountid===group.account_id&&
                                  group.users.find(user=>viewDetails.email===user.email));
    userGroups.forEach((group,index)=>{
      if("Dashboard"===group.description){
        group.users.forEach(user=>{
          if(viewDetails.email===user.email){
            //Always picks the last group
            dashGroupDtls = group;
          }
        })
      }
      //Set Index for group table
      group.index=index+1;
    });
    if (dashGroupDtls.sessionid) {
      this.setState({
        config:{
          apiKey: sessionStorage.apiKey,
          apiSecret: null,
          groupId: dashGroupDtls.group_id,
          otClientUrl : otClientUrl,
          sessionId: dashGroupDtls.sessionid,
          token: sessionStorage["token_"+dashGroupDtls.group_id],
        },
      });
    }
      this.setState({userGroups:userGroups});
  }

  deleteGuest = (group, url, msg) => {
    if(null!==group.group_id && null !==sessionStorage.userToken){
      url = "/api/deletegroup/"+group.group_id;
      msg = null;
      this.setState({isLoading: true});
      superagent.del(sessionStorage.url+ url)
      .set("Authorization", "Bearer " + sessionStorage.userToken)
      .accept("application/json")
      .end((err, res)=>{
        if(undefined===res.status){
          msg = "Internet is not connected, please try again after connecting network";
        }else if(200===res.status){
          this.clearGuestSession();
        }else if(400===res.status){
          msg = "Group Id: "+group.group_id + " not found, try page refreshing";
        }else if(401===res.status){
          msg = "Session token experied, Please re-login";
        }else if(403===res.status){
          msg = "You are not authorized";
        }else if(500===res.status){
          msg = "Something went wrong, try again later";
        }else{
          console.log(err,res);
        }
        this.setState({isLoading: false, serverError:msg});
      });
    }
  }

  disableSSBtn = (flag) =>{
    this.setState({disableSS:flag})
  }

  closeCaller = () => {
    this.setState({showModal:false});
  }

  closeAlert = () => {
    this.setState({serverError:null});
  }

  userTooltipOff = () =>{
    document.getElementById("user-participant-tooltip").style.visibility = "hidden";
  }

  showUserTooltip = (e, users) =>{
    let content = "<div>";
    let tooltip = document.getElementById("user-participant-tooltip");
    var x = e.clientX, y = e.clientY;
    if(!tooltip) return;

    content+="<table border='1'><tr><th>Name</th> <th>Email</th></tr>";
    users.forEach(user=>{
      content+="<tr><td>"+user.first_name+" "+user.last_name+"</td>";
      content+="<td>"+user.email+"</td></tr>";
    });
    content+="</table></div>";
    tooltip.style.top = y + 'px';
    tooltip.style.left = x + 'px';
    tooltip.innerHTML = content;
    tooltip.style.visibility = "visible";
  }


changeUserStatus = (userInfo) => {

    if(userInfo && userInfo.email === sessionStorage.userEmail){
      this.setState({serverError:"You cannot Lock your own account"})
    }else{
      this.props.updatePasswordLock(userInfo);
      this.setState({pwdLock:!this.state.pwdLock});
    }    

}

isUserChangeAccess = () => {
  switch(sessionStorage.userDbRole){
    case "admin":
    case "write_all":
    case "write_ent":
    case "write_my_account":
    case "group_admin":  return true;
    default : return false;
  }
}
//RS:Added for FQ3-878
confirmationPopup = () =>{
  this.setState({showPopUp:true, dashboardRole:document.getElementById('select-user-role-type').value,modalTitle:"Confirm Alert",modalBody:"Are you sure you want to change access type for this account?"});
}

closeAlertPopUp = () => {
  this.setState({showPopUp:false,dashboardRole:this.state.userViewDetails.dashboard_role});
}

updateDashboardRole = async () =>{
  
  let apiUrl =  "/api/dash/setUserConfiguration";
  let params = {}, msg = null ;
  const {userViewDetails,dashboardRole} =  this.state;
      params.dashboard_role = dashboardRole;
      params.user_id = userViewDetails.id;
      params.keyName = "dashboard_role";
  
    if (null!==sessionStorage.getItem("userToken")
        && null!==params && null!==apiUrl) {
        this.setState({isLoading:true});
         await superagent.post(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .send(params)
        .then(response => response)
        .then(data => {
          if(200===data.status){
            msg = "Data updated successfully";
            let previousDashboardRole = userViewDetails.dashboard_role;
            userViewDetails.dashboard_role = dashboardRole
            this.setState({isLoading: false,serverError:msg,showPopUp:false,userViewDetails},  ()=>
            this.handleSelectionOAccessList(userViewDetails));
            this.props.updateUserProfile(userViewDetails,params);
            if(previousDashboardRole === 'group_admin'){
              this.getUserGroupsMapped();
            }
          }
        }).catch(error => {
          
          if(undefined===error.status){
            msg = "May be internet is not connected, try again sometime";
          }else if(400===error.status){
            msg = "Data not updated";
          }else if(401===error.status){
            msg = "Session token experied, please re-login";
          }else if(403===error.status){
            msg = "You are not authorized";
          }else if(500===error.status){
            msg = "Something went wrong, try again sometime";
          }
          this.setState({isLoading: false,serverError:msg,showPopUp:false});
        });
    }else{
        this.close();
    }
  }


  handleSelect(event) {
    event.preventDefault();
    let key = event.target ? event.target.getAttribute("selectedindex") : "";
    if (key === "1" ||key === "2" || key === "3"){
        this.setState({ tabIndex:key,date: new Date()});
    }

}

  handleEditGroupAdminView(paramValue){
    this.setState({showEditGroupAdmin:paramValue})
  }

handleSelectionOAccessList({dashboard_role}){

  if(dashboard_role === "group_admin"){
      this.handleEditGroupAdminView(!this.state.showEditGroupAdmin)
  }else{
    if(this.state.showEditGroupAdmin){
      this.handleEditGroupAdminView(!this.state.showEditGroupAdmin)
    }
  }  
}


updateUserConfiguration = async(mappedGroupRes)=>{
  
  const {userViewDetails} =  this.state;  
  let apiUrl = '/api/dash/setUserConfiguration',params = {};
  
  params.user_id = userViewDetails.id;
  params.action_type = "group_admin";
  params.add_group_ids =  JSON.stringify([]);
  params.remove_group_ids = JSON.stringify(mappedGroupRes);

  if(sessionStorage.getItem('userToken') && params && apiUrl){
    await superagent.post(sessionStorage.getItem("url") + apiUrl)
    .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
    .accept("application/json")
    .send(params)
    .then(response => response)
    .then(data => {
      if(data.status === 200){
        console.log('Successfully removed the assigned group from user list')
      }
    }).catch(error =>{
      if(error.status === undefined){
        console.log('May be internet is not connected, try again sometime');
      }else if (error.status === 400){
        console.log('Data is not updated');
      }else if(error.status === 401){
        console.log('Session Token Expired, Please re-login');
      }else if(error.status === 403){
        console.log('You are not authorized');
      }else if(error.status === 500){
        console.log('Something went wrong,try again sometime');
      }else{
        console.log('error accoured while updateing user configuration')
      }
    })
  }
}


getUserGroupsMapped = async()=>{
  const {userViewDetails} =  this.state;
  let apiUrl =  '/api/dash/getUserDetails',params={},msg = null;
      params = { user_id:userViewDetails.id, user_detail_type:"group_admin"}
  if(sessionStorage.getItem('userToken') && apiUrl){
      await superagent.post(sessionStorage.getItem('url')+apiUrl)
      .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
      .accept("application/json")
      .send(params)
      .then(response => response)
      .then(data => {
          if(200 === data.status && data.body){
            let response = data.body;
            response = response.map(usr=>usr.group_id);
            this.updateUserConfiguration(response);
          }
      }).catch(error=>{
        let msg = null;
        if(undefined===error.status){
          msg = "Internet is not connected, please try again after connecting network";
        }else if(400===error.status){
          msg = "condition not matching so User Deatils not found";
        }else if(401===error.status){
          msg = "Session token experied, Please re-login";
        }else if(403===error.status){
          msg = "You are not authorized";
        }else if(422===error.status){
          msg = "Invalid parameters sent";
        }else if(500===error.status){
          msg = "Something went wrong, try again later";
        }else{
          msg = error.message ? error.message : 'Something went wrong'
        }
        console.log(msg);
        
      })
  }
}
  
/* TP-6476 */
displayUserDetail = () => {
  this.setState({
    showUserDetailsPage:"flex",
    showUserDetailsPage2component:"none",
  })
}

displayUserDetailpage2 = () => {
  this.setState({
    showUserDetailsPage:"none",
    showUserDetailsPage2component:"block",
  })
}

  render(){
    //userGroupsColumns[1].cell=row=>"Dashboard"!==row.description ? <span className="hyperlink d-inline" onClick={()=>this.props.params.breadcrumbTail('group',row,row.group_name)} >{row.group_name}</span>: <>{row.group_name}<button onClick={()=>this.deleteGuest(row)} data-toggle="tooltip" title="Delete this group" className="fa fa-trash btn" style={{color:'red'}} /></>
    userGroupsColumns[1].cell=row=>row.group_name ? <span className="hyperlink d-inline" onClick={()=>this.props.params.breadcrumbTail('group',row,row.group_name)}>{row.group_name}</span>:<>{row.group_name}</>  // Removed  delete button from previous statement and added group name. Jira ticket TP-1026 
    userGroupsColumns[3].cell=row=>row.experts.length>0?<div className="text-hover-cursor" onMouseEnter={(e)=>this.showUserTooltip(e, row.experts)} onMouseLeave={()=>this.userTooltipOff()}>{row.experts.length}</div>:0;
    userGroupsColumns[4].cell=row=>row.techs.length>0?<div className="text-hover-cursor" onMouseEnter={(e)=>this.showUserTooltip(e, row.techs)} onMouseLeave={()=>this.userTooltipOff()}>{row.techs.length}</div>:0;
    userGroupsColumns[5].cell=row=>row.isongoingnow
    ?<div className='ring-container position-static'><div className='ringring'></div><div className='circle'></div></div>
    :null!==row.last_active_on?moment(row.last_active_on).format("DD MMM,YYYY HH:mm"):"NA";
    return(
      /* TP-6476 */
  <>
<div className='userDetailsselectBar'>
  <Button onClick={(event)=> this.displayUserDetail(event)}> User Details</Button>
  <Button onClick={(event)=> this.displayUserDetailpage2(event)}>More Info</Button>
</div>

   <div className="">
    <div className="container-fluid">
      <div className="">
      <div className="userDetailContainer" style={{display:this.state.showUserDetailsPage}}>
        {/* <div className=" userDetaillabels">
          <h5 className="center">User Details</h5>
        </div> */}
        <div className="userDetaillabels">
          <strong>Id: </strong>
          <span>{this.state.userViewDetails.id}</span>
        </div>
        <div className="userDetaillabels">
          {roleNameColor(this.state.userViewDetails)}
        </div>
        <div className="userDetaillabels">
          <strong>Email: </strong>
          {/* <span>
          {this.props.viewDetails.email}
          </span> */}
          <Edit fieldValue={this.state.userViewDetails.email} params={this} fieldName="Email"  parentCommunicator={this.childCommunicator}/>
        </div>
        <div className="userDetaillabels">
          <strong>Phone: </strong>
          <span>
          {this.state.userViewDetails.phonenumber ? this.state.userViewDetails.phonenumber+"":""}
          </span>
          {/* {"admin"===sessionStorage.userDbRole &&
          <button onClick={this.inviteGuestUser} className="screen-share"
          style={{backgroundColor:this.state.isScreenSharing?'red':'#23c97d',display:("true" !== sessionStorage.isSmartVision) ? 'block':'none'}}
          disabled={this.state.disableSS}>
            {this.state.isScreenSharing?"Stop":"Request"} screen sharing
          </button>
          } */   /* TP-6476 */}
        </div>
        <div className="userDetaillabels">
          <strong>A/C Id: </strong>
          <span className={sessionStorage.getItem('userDbRole') !== "group_admin" ? "hyperlink d-inline":"disable-span-link"} style={{display: 'contents'}} onClick={()=> this.props.params.breadcrumbTail("customer",this.props.customerDtls,null!==this.props.customerDtls.company_name?this.props.customerDtls.company_name:this.props.customerDtls.account_id)}>
          {this.state.userViewDetails.company_name ? this.state.userViewDetails.accountid +' ('+this.state.userViewDetails.company_name+')':this.state.userViewDetails.accountid}
          </span>
        </div>
        {"admin"===sessionStorage.userDbRole &&
        <>
          <div className="userDetaillabels">
            <strong style={{whiteSpace:'pre-wrap'}}>Available Minutes: </strong>
            <span>
              <Edit fieldValue={null===this.state.userViewDetails.available_monthly_minutes?'NA':this.state.userViewDetails.available_monthly_minutes} 
              params={this} fieldName="Additional minutes" parentCommunicator={this.childCommunicator} />
            </span>          
          </div>
          <div className="userDetaillabels">
              <strong>Max Monthly Minutes: </strong>
                <span>
                  {null===this.state.userViewDetails.max_monthly_minutes?'NA':this.state.userViewDetails.max_monthly_minutes} 
                </span>
            </div>
          </>
          }
        <div className="userDetaillabels">
          <strong>License Id: </strong>
          <span>
          {this.state.userViewDetails.credit_id}
          </span>
        </div>
        { sessionStorage.getItem("userDbRole") === "admin" &&   
        <div className="userDetaillabels">
          <strong className='toolAccesslabel'>Tool Access: </strong>
            <span className='toolAccessCl'>
            {/* {this.props.userRole[this.props.viewDetails.dashboard_role]} */}
            {/* RS:Added for T32-18 */}
            <AccessList selectedVal={this.state.dashboardRole} userInfo={this.state.userViewDetails} confirmationPopup={this.confirmationPopup} ></AccessList>
            
            { (this.state.userViewDetails && this.state.userViewDetails.dashboard_role === "group_admin") &&
              <FaEdit className='group-admin-edit' onClick={()=>this.handleEditGroupAdminView(!this.state.showEditGroupAdmin)}></FaEdit> 
            }
            
            </span>
        </div>
        }
        <div className="userDetaillabels">
          <strong>Log Level: </strong>
          <span>
            <Edit fieldValue={null===this.state.userViewDetails.log_level?'NA':this.state.userViewDetails.log_level}
            params={this}
            fieldName="Log Level" parentCommunicator={this.childCommunicator} />
          </span>
          <button id={"isFirstLogin"} value={true} style={{display: (this.state.customerDtls && (("smartvision" === this.state.customerDtls.behaviour && "write_my_account"===sessionStorage.userDbRole) || ("admin"===sessionStorage.userDbRole && "smartvision" === this.state.customerDtls.behaviour) || ("true" === sessionStorage.isSmartVision && "smartvision" === this.state.customerDtls.behaviour))) ? "block":"none"}} 
          onClick = {(e) => this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,is_first_login:true,keyName:"is_first_login"})} className="first-login button-user">
            Trigger First Login
          </button>
        </div>
        { ("write_my_account" !==sessionStorage.userDbRole || "read_my_account"===sessionStorage.userDbRole) &&
        <div className="userDetaillabels">
          <strong>Display Speed: </strong>
          <span>
            <Edit fieldValue={this.state.userViewDetails.showuploadspeed?'Yes':'No'}
            params={this} fieldName="Display Speed" parentCommunicator={this.childCommunicator} />
          </span>
        </div>
        }
        <div className="userDetaillabels">
          <strong>Created On: </strong>
          <span>
          {this.state.userViewDetails.created_on != null
          ? moment(
          this.state.userViewDetails.created_on
          ).format("DD MMM,YYYY HH:mm")
          : ""}
          </span>
        </div>
        <div className="userDetaillabels">
          <strong>Last Seen: </strong>
          <span>
          {this.state.userViewDetails.last_accessed_on != null
          ? moment(
          this.state.userViewDetails.last_accessed_on
          ).format("DD MMM,YYYY HH:mm")
          : ""}
          </span>
        </div>
        <div className="userDetaillabels">
          <strong>Status:</strong>
          <Edit fieldValue={this.state.userViewDetails.is_loggedin?'Online':'Offline'} params={this} 
          fieldName="Status" parentCommunicator={this.childCommunicator}/>
          &nbsp;
          {this.state.userViewDetails.is_loggedin
          ?<div className="ring-container d-inline">
            <div className="ringring"></div>
            <div className="circle" id="indicator1"></div>
          </div>
          :this.state.userViewDetails.is_insession
          ?<span className="spinner-grow text-success"></span>
          :<span className='dot bg-danger'></span>
          }
          
          {/* <button id={"passwordReset"} value={true} style={{display: (this.state.customerDtls && this.state.customerDtls.email !== this.state.userViewDetails.email  && (("smartvision" === this.state.customerDtls.behaviour && "write_my_account"===sessionStorage.userDbRole) || ("admin"===sessionStorage.userDbRole))) ? "block":"none"}} 
          onClick = {(e) => { (window.confirm("Are you sure to you wish to reset the below user's password? \n"+this.state.userViewDetails.email)) && this.setUserPassword(e,{user_id:this.state.userViewDetails.id})}}  */}
          { /* ConfirmModalPopUp */ }
            {/* className="password-reset button-user"> Generate New Password
          </button> */  /* TP-6476 */}

        </div>  
        <div className="userDetaillabels">
          <strong>User Lock Status:</strong>
          {
          (null != this.state.pwdLock && this.state.pwdLock) 
          ? <a title={this.state.customerDtls.isInactive ? "This account is inactive" : "" } onClick={(this.isUserChangeAccess() && (this.state.customerDtls && !this.state.customerDtls.isInactive)) ? () => this.changeUserStatus(this.state.userViewDetails): ""} style={{display:'inline-block'}}><i className="fa fa-lock fa-lg" style={{color: "#ff0a09", paddingLeft: "5px"}} /></a>
          : <a title={this.state.customerDtls.isInactive ? "This account is inactive" : "" } onClick={(this.isUserChangeAccess() && (this.state.customerDtls && !this.state.customerDtls.isInactive)) ? () => this.changeUserStatus(this.state.userViewDetails): ""} style={{display:'inline-block'}}><i className="fa fa-unlock fa-lg" style={{color: "#2ac878",paddingLeft: "5px"}} /></a> 
          }
        </div>
        {/**TP-4644 & TP-4288 TP-5754*/}
        <div className="userDetaillabels" /* "disable-label"*/ /*: "col-sm-12 col-lg-12" */ style={{display:(this.state.userViewDetails && !this.state.userViewDetails.isexpert && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole) /* && "false" === sessionStorage.isSmartVision */ && this.state.customerDtls.allow_new_evidence !== "disabled") ? "flex":"none"}}>
          <strong style={{color:(this.state.customerDtls.allow_new_evidence === "enabled_for_all") && "gray"}}>Allow New Evidence: </strong>
          <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"userAllowNewEvidence"} /* disabled={this.state.customerDtls.allow_new_evidence === "enabled_for_all" ? true : false} */ 
            checked={this.state.customerDtls.allow_new_evidence === "enabled_for_all" ? true : this.state.userAllowNewEvidence} 
            value={this.state.customerDtls.allow_new_evidence === "enabled_for_all" ? true : !this.state.userAllowNewEvidence} 
            name="isuserallownewevidence" 
            onChange = {(this.state.customerDtls.allow_new_evidence === "enabled_for_all") ? (e) => {return;} : (e) =>this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,user_allow_new_evidence:!this.state.userAllowNewEvidence,keyName:"user_allow_new_evidence"})} 
            />
            
            <span className="slider round" style={{cursor:(this.state.customerDtls.allow_new_evidence === "enabled_for_all") && "default"}}/></label>
            <label htmlFor="isuserallownewevidence">&nbsp;{/* this.state.customerDtls.allow_new_evidence === "enabled_for_all" ? 'Yes' : */(((this.state.customerDtls.allow_new_evidence === "enabled_for_all") || (this.state.userAllowNewEvidence)) ? 'Yes':'No')}</label>
            
        </div>
        {/**TP-4288*/}
        <div className="userDetaillabels" style={{display:(this.state.userViewDetails && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole ) && "false" === sessionStorage.isSmartVision && /*TP-2872*/"false" === sessionStorage.isSmartVision && this.state.customerDtls.enable_auto_connect /**TP-3554*/ && this.state.customerDtls.enable_streaming)  ? "flex":"none"}}>
            <strong>Allow Auto Connect: </strong>
            <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"allowAutoConnect"} checked={this.state.allowAutoConnect} value={!this.state.allowAutoConnect} name="allowAutoConnect" 
              onChange = {(e) => this.updateUserProfileConfiguration(e,
                {
                  user_id:this.props.viewDetails.id,
                  my_camera_on:this.state.myCameraOn,//TP-3554
                  allow_auto_connect:!this.state.allowAutoConnect,
                  streaming_quality:this.state.streamingQuality, //TP-3554
                  image_quality:this.state.imageQuality, //TP-3554
                  disable_notifications:this.state.disableNotifications, //TP-3554
                  first_name:this.props.viewDetails.first_name, 
                  last_name:this.props.viewDetails.last_name,
                }
              )} />
            <span className="slider round" /></label>
            <label htmlFor="allowAutoConnect">&nbsp;{(this.state.allowAutoConnect) ? 'Yes' : 'No'}</label>
        </div>        
        {/**TP-4644  TP-5754*/}
        <div className="userDetaillabels" /*"disable-label"*/ /*: "col-sm-12 col-lg-12" */ style={{display:(this.state.userViewDetails && this.state.userViewDetails.isexpert && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole) /* && "false" === sessionStorage.isSmartVision */ && this.state.customerDtls.can_turn_off_user_video !== "disabled") ? "flex":"none"}}>
          <strong style={{color:(this.state.customerDtls.can_turn_off_user_video === "enabled_for_all") && "gray"}}>Allow Expert to turn off user video: </strong> 
          <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"canTurnOffVideo"} /* disabled={this.state.customerDtls.can_turn_off_user_video === "enabled_for_all" ? true : false} */ checked={this.state.customerDtls.can_turn_off_user_video === "enabled_for_all" ? true : this.state.canTurnOffVideo} value={this.state.customerDtls.can_turn_off_user_video === "enabled_for_all" ? true : !this.state.canTurnOffVideo} name="isallowedturnoffuservideo" 
            onChange = {(this.state.customerDtls.can_turn_off_user_video === "enabled_for_all") ? (e) => {return;} :(e) =>this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,can_turnoff_video:!this.state.canTurnOffVideo,keyName:"can_turnoff_video"})} 
            />
            <span className="slider round" style={{cursor:(this.state.customerDtls.can_turn_off_user_video === "enabled_for_all") && "default"}}/></label>
            <label htmlFor="isallowedturnoffuservideolabel">&nbsp;{/* this.state.customerDtls.can_turn_off_user_video === "enabled_for_all" ? 'Yes' : */   ((this.state.customerDtls.can_turn_off_user_video === "enabled_for_all")|| (this.state.canTurnOffVideo )) ? 'Yes':'No'} </label>
            
        </div>
        {/**TP-4644 TP-5754*/}
        <div className="userDetaillabels" /*"disable-label"*/ /*: "col-sm-12 col-lg-12" */ style={{display:(this.state.userViewDetails && this.state.userViewDetails.isexpert && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole ) && "false" === sessionStorage.isSmartVision && this.state.customerDtls.expert_can_disconnect_user !== "disabled")  ? "flex":"none"}}>
          <strong style={{color:(this.state.customerDtls.expert_can_disconnect_user === "enabled_for_all") && "gray"}}>Allow Expert to disconect users: </strong>
          <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"canCancelUserFromSession"} /* disabled={this.state.customerDtls.expert_can_disconnect_user === "enabled_for_all" ? true : false} */ checked={this.state.customerDtls.expert_can_disconnect_user === "enabled_for_all" ? true : this.state.canCancelUserFromSession} value={this.state.customerDtls.expert_can_disconnect_user === "enabled_for_all" ? true : !this.state.canCancelUserFromSession} name="isallowedtocancelfromsession" 
            onChange = {(this.state.customerDtls.expert_can_disconnect_user === "enabled_for_all") ? (e) => {return;} : (e) => this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,can_disconnect_user:!this.state.canCancelUserFromSession,keyName:"can_disconnect_user"})} />
            <span className="slider round" style={{cursor:(this.state.customerDtls.expert_can_disconnect_user === "enabled_for_all") && "default"}} /></label>
            <label htmlFor="isallowedtocancelfromsessionLabel">&nbsp;{ ((this.state.customerDtls.expert_can_disconnect_user === "enabled_for_all") || (this.state.canCancelUserFromSession) ? 'Yes' : 'No' )}</label>
        </div>
          {/* //RS:Added for T32-18 */}
          <div className="userDetaillabels" style={{display:(this.state.userViewDetails && this.state.userViewDetails.isexpert && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole ) && "false" === sessionStorage.isSmartVision && this.state.customerDtls.reset_user_password && this.state.customerDtls.reset_user_password !=="none")  ? "flex":"none"}}>
            <strong>{(this.state.customerDtls.reset_user_password && this.state.customerDtls.reset_user_password === "reset_password_with_qr") ? "Allow reset password with display QR: ":"Allow reset password without display QR: "}</strong>
            <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"canResetUserPassword"} checked={this.state.canResetUserPassword} value={!this.state.canResetUserPassword} name="isallowedtResetUserPassword" onChange = {(e) => this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,can_reset_user_password:!this.state.canResetUserPassword,keyName:"can_reset_user_password"})} />
            <span className="slider round" /></label>
            <label htmlFor="isallowedtResetUserPasswordLabel">&nbsp;{(this.state.canResetUserPassword) ? 'Yes' : 'No'}</label>
          </div>
          {/**TP-4051*/}
          <div  className="userDetaillabels" /*"disable-label"*/ /* : "col-sm-12 col-lg-12" */ style={{display:(this.state.userViewDetails && this.state.userViewDetails.isexpert && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole ) && "false" === sessionStorage.isSmartVision && this.state.customerDtls.is_ios_login_allowed !== "ios_login_disabled")  ? "flex":"none"}}>
            <strong style={{color:(this.state.customerDtls.is_ios_login_allowed === "ios_login_enabled_all") && "gray"}}>Allow iOS Login : </strong>
            <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"iosLoginAllowed"} /* disabled={this.state.customerDtls.is_ios_login_allowed === "ios_login_enabled_all" ? true : false} */ checked={this.state.customerDtls.is_ios_login_allowed === "ios_login_enabled_all" ? true : this.state.iosLoginAllowed} value={this.state.customerDtls.is_ios_login_allowed === "ios_login_enabled_all" ? true : !this.state.iosLoginAllowed} name="iosLoginAllowed" 
            onChange = {(this.state.customerDtls.is_ios_login_allowed === "ios_login_enabled_all") ? (e) => {return;} : (e) => this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,user_ios_login_allowed:!this.state.iosLoginAllowed,keyName:"user_ios_login_allowed"})} />
            <span className="slider round" style={{cursor:(this.state.customerDtls.is_ios_login_allowed === "ios_login_enabled_all") && "default"}}/></label>
            <label htmlFor="iosLoginAllowedLabel">&nbsp;{/* this.state.customerDtls.is_ios_login_allowed === "ios_login_enabled_all" ? 'Yes' :  */ ((this.state.customerDtls.is_ios_login_allowed === "ios_login_enabled_all") || (this.state.iosLoginAllowed)) ? 'Yes' : 'No'}</label>
          </div>
          {/**TP-4644 TP-2730 TP-5754*/}
          <div className="userDetaillabels" /*"disable-label"*/ /* : "col-sm-12 col-lg-12" */ style={{display:(this.state.userViewDetails /* TP-2862 -- && this.state.userViewDetails.isexpert */ && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole ) && "false" === sessionStorage.isSmartVision && /*TP-2872*/"false" === sessionStorage.isSmartVision && this.state.customerDtls.enable_asset_class !== "disabled" && this.state.customerDtls.is_gps_enabled)  ? "flex":"none"}}>
            <strong style={{color:(this.state.customerDtls.enable_asset_class === "enabled_for_all") && "gray"}}>Enable Assets: </strong>
            <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"enableAssetClass"} /* disabled={this.state.customerDtls.enable_asset_class === "enabled_for_all" ? true : false} */ checked={this.state.customerDtls.enable_asset_class === "enabled_for_all" ? true : this.state.enableAssetClass} value={this.state.customerDtls.enable_asset_class === "enabled_for_all" ? true : !this.state.enableAssetClass} name="enableAssetClass" 
            onChange = {(this.state.customerDtls.enable_asset_class === "enabled_for_all") ? (e) => {return;} : (e) => this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,enable_asset:!this.state.enableAssetClass,keyName:"enable_asset"})} />
            <span className="slider round" style={{cursor:(this.state.customerDtls.enable_asset_class === "enabled_for_all") && "default"}}/></label>
            <label htmlFor="enableAssetClassLabel">&nbsp;{/* this.state.customerDtls.enable_asset_class === "enabled_for_all" ? 'Yes' :  */ ((this.state.customerDtls.enable_asset_class === "enabled_for_all")||(this.state.enableAssetClass)) ? 'Yes' : 'No'}</label>
          </div>
          {/**TP-4644 TP-3162 TP-5754*/}
          <div className="userDetaillabels" /*"disable-label"*/ /* : "col-sm-12 col-lg-12" */ style={{display:(this.state.userViewDetails && (this.state.userViewDetails.isexpert || this.state.userViewDetails.isadmin) && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole ) && "false" === sessionStorage.isSmartVision && /*TP-2872*/"false" === sessionStorage.isSmartVision && this.state.customerDtls.enable_job_template !== "disabled" && this.state.customerDtls.is_gps_enabled)  ? "flex":"none"}}>
            <strong style={{color:(this.state.customerDtls.enable_job_template === "enabled_for_all") && "gray"}}>Enable Templates: </strong>
            <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"enableTemplates"} /* disabled={this.state.customerDtls.enable_job_template === "enabled_for_all" ? true : false} */ checked={this.state.customerDtls.enable_job_template === "enabled_for_all" ? true : this.state.enableTemplates} value={this.state.customerDtls.enable_job_template === "enabled_for_all" ? true : !this.state.enableTemplates} name="enableTemplates" 
            onChange = {(this.state.customerDtls.enable_job_template === "enabled_for_all") ? (e) => {return;} : (e) => this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,enable_job_template:!this.state.enableTemplates,keyName:"enable_job_template"})} />
            <span className="slider round" style={{cursor:(this.state.customerDtls.enable_job_template === "enabled_for_all") && "default"}}/></label>
            <label htmlFor="enableTemplatesLabel">&nbsp;{/* this.state.customerDtls.enable_job_template === "enabled_for_all" ? 'Yes' :  */ ((this.state.customerDtls.enable_job_template === "enabled_for_all") || (this.state.enableTemplates)) ? 'Yes' : 'No'}</label>
          </div>
          {/**TP-4644 & TP-4454 TP-5754*/}
          <div className="userDetaillabels" /*"disable-label"*/ /* : "col-sm-12 col-lg-12" */  style={{display:(this.state.userViewDetails && !this.state.userViewDetails.isexpert && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole ) && "false" === sessionStorage.isSmartVision && this.state.customerDtls.create_scan_job_by_technician !== "disabled")  ? "flex":"none"}}>
            <strong style={{color:(this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all") && "gray"}}>Create Job by Technician: </strong>
            <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"isCreateScanJobByTechnician"} /* disabled={this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all" ? true : false} */ checked={this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all" ? true : this.state.isCreateScanJobByTechnician} value={this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all" ? true : !this.state.isCreateScanJobByTechnician} name="isCreateScanJobByTechnician" 
            onChange = {(this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all") ? (e) => {return;} : (e) => this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,user_create_scan_job_by_technician:!this.state.isCreateScanJobByTechnician,keyName:"user_create_scan_job_by_technician"})} />
            <span className="slider round" style={{cursor:(this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all") && "default"}}/></label>
            <label htmlFor="isCreateScanJobByTechnicianLabel">&nbsp;{/* this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all" ? 'Yes' :  */ ((this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all") || (this.state.isCreateScanJobByTechnician)) ? 'Yes' : 'No'}</label>
          </div>
          <div className="userDetaillabels" /*"disable-label"*/ /*: "col-sm-12 col-lg-12" */ style={{display:(this.state.userViewDetails && this.state.userViewDetails.isexpert && ("write_my_account"===sessionStorage.userDbRole || "admin"===sessionStorage.userDbRole ) && "false" === sessionStorage.isSmartVision && this.state.customerDtls.enable_job_scheduler !== "disabled")  ? "flex":"none"}}>
            <strong style={{color:(this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all") && "gray"}}>Job Recurrence : </strong>
            <label className="col-xs-4 col-xs-offset-2 switch newswitch">
            <input type="checkbox" id={"enableJobScheduler"} /* disabled={this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all" ? true : false} */ checked={this.state.customerDtls.enable_job_scheduler === "enabled_for_all" ? true : this.state.enableJobScheduler} value={this.state.customerDtls.enable_job_scheduler === "enabled_for_all" ? true : !this.state.enableJobScheduler} name="enableJobScheduler" 
            onChange = {(this.state.customerDtls.enable_job_scheduler === "enabled_for_all") ? (e) => {return;} : (e) => this.updateUserProfile(e,{user_id:this.state.userViewDetails.id,user_enable_job_scheduler:!this.state.enableJobScheduler,keyName:"user_enable_job_scheduler"})} />
            <span className="slider round" style={{cursor:(this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all") && "default"}}/></label>
            <label htmlFor="enableJobSchedulerLabel">&nbsp;{/* this.state.customerDtls.create_scan_job_by_technician === "enabled_for_all" ? 'Yes' :  */ ((this.state.customerDtls.enable_job_scheduler === "enabled_for_all") || (this.state.enableJobScheduler)) ? 'Yes' : 'No' }</label>
          </div>
          {sessionStorage.getItem("userDbRole") !== 'group_admin' && 
          <div className="userDetaillabels">
            <strong>Last Location:&nbsp;</strong>
            {this.state.address}
          </div>
          }
      </div>
      <div className='lastblock' style={{display:this.state.showUserDetailsPage}}>
        {sessionStorage.getItem("userDbRole") !== 'group_admin' && 
        <div className="userDetaillabels">
          <strong className='userDetailInfo'>User Info: </strong>
          {/*TP-712 Added subscription_status to verify if customer is not vendor trail*/}
          { this.state.userViewDetails.user_info && typeof this.state.userViewDetails.user_info === 'string' && /^[\],:{}\s]*$/.test(this.state.userViewDetails.user_info.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, '')) ? 
            <UserInfo
              {...JSON.parse(this.state.userViewDetails.user_info)} technician={!this.state.userViewDetails.isadmin && !this.state.userViewDetails.isexpert}/>
              :this.state.userViewDetails.user_info && typeof this.state.userViewDetails.user_info !== 'string' ? this.state.userViewDetails.user_info:"NA" 
          }
        </div>
        }
          {"admin"===sessionStorage.userDbRole &&
        /* TP-6476 */
        <div className="userDetaillabels userDetailbtn">
          <button onClick={this.inviteGuestUser} className="screen-share"
          style={{backgroundColor:this.state.isScreenSharing?'red':'#23c97d',display:("true" !== sessionStorage.isSmartVision) ? 'block':'none'}}
          disabled={this.state.disableSS}>
            {this.state.isScreenSharing?"Stop":"Request"} screen sharing
          </button>
          <button id={"passwordReset"} value={true} style={{display: (this.state.customerDtls && this.state.customerDtls.email !== this.state.userViewDetails.email  && (("smartvision" === this.state.customerDtls.behaviour && "write_my_account"===sessionStorage.userDbRole) || ("admin"===sessionStorage.userDbRole))) ? "block":"none"}} 
           onClick = {(e) => { (window.confirm("Are you sure to you wish to reset the below user's password? \n"+this.state.userViewDetails.email)) && this.setUserPassword(e,{user_id:this.state.userViewDetails.id})}} 
           /* ConfirmModalPopUp */  /* TP-6476 */
            className="password-reset button-user"> Generate New Password
          </button>
          </div>
          }    
          
          </div>
      { this.state.showEditGroupAdmin === false ? 
      <div className="col-sm-12 col-lg-12 panelDiv panelDiv2" style={{display:this.state.showUserDetailsPage2component}}>
        <div className="col-sm-12 col-lg-12 p-0">
          {this.state.isScreenSharing 
          ? <TokBox
            apiKey={this.state.config.apiKey}
            sessionId={this.state.config.sessionId}
            token={this.state.config.token}
            opentokClientUrl={this.state.config.otClientUrl}
            subscriberCallConnect={this.subscriberCallConnect}
            publisherCallResponse={this.publisherCallResponse}
            socket={this.props.socket}
            disableSSBtn={this.disableSSBtn}/> 
            :<Tabs>
            <TabList>
              <Tab selectedindex={1} onClick={(event)=> this.handleSelect(event)}>Groups</Tab>
              {sessionStorage.getItem("userDbRole") !== "group_admin" && 
                <Tab selectedindex={2} onClick={(event)=>this.handleSelect(event)} >Jobs</Tab>
              }{sessionStorage.getItem("userDbRole") !== "group_admin" && 
              <Tab selectedindex={3} onClick={(event)=>this.handleSelect(event)}>Logs</Tab>
              }
              <Tab selectedindex={4} onClick={(evt)=> this.handleSelect(evt)}>Usage Details</Tab>
              
            </TabList>
        
            <TabPanel>
              {
                <DataTable
                  pagination={this.state.userGroups.length>5}
                  paginationPerPage={5}
                  columns={userGroupsColumns}
                  data={this.state.userGroups}
                  className="group-participants"
                  noDataComponent="User is not part of any group"
                  keyField={"group_id"}
                  />
              }
              <div id="user-participant-tooltip"></div>
              
            </TabPanel>
            {sessionStorage.getItem("userDbRole") !== 'group_admin' &&  
              <TabPanel>
                {<Jobs 
                viewDetails={this.state.userViewDetails} 
                accountUsers={this.state.accountUsers}
                customerDtls={this.props.customerDtls} 
                tabIndex={this.state.tabIndex} 
                params={this.props.params}
                enableAssetClass={this.state.customerDtls.enable_asset_class} /*TP-2774*//>} 
              </TabPanel>
            }
           
            {sessionStorage.getItem('userDbRole') !== 'group_admin' &&
            <TabPanel>
              {<Diagnostic 
              viewDetails={this.state.userViewDetails} 
              tabIndex={this.state.tabIndex} />}
            </TabPanel>
            }
            <TabPanel>
              {
                // <ChartGraph userDetail={this.state.userViewDetails} />
                <UsageDetails userData={this.state.userViewDetails} />
              }
            </TabPanel>

          </Tabs>
          } 
        </div>
      </div>:
      <div className="col-sm-12 col-lg-8 panelDiv">
          <div className="col-sm-12 col-lg-12 p-0">
            <EditGroupAdmin allGroupList={this.props.groups} 
            currentUserInfo={this.state.userViewDetails} 
            currentUserGroupInfo={this.state.userGroups}  
            setUserConfigurationFunc={this.updateUserProfile}
            CloseEditGroupFunc={this.handleEditGroupAdminView} />
          </div>
        </div>
      }
        {this.state.showModal
          &&<Notification modalTitle={this.state.modalTitle}
            modalBody={this.state.modalBody}
            closeCaller={this.closeCaller}/>}

          {this.state.serverError
          &&<ModalPopUp modalTitle="Alert!!!" 
            modalBody={this.state.serverError} 
            closeAlert={this.closeAlert}
              serverStatus={this.state.serverStatus}
            />}

          {this.state.showPopUp
          &&<ConfirmationPopUp modalTitle={this.state.modalTitle} 
            modalBody={this.state.modalBody} 
            closeAlert={this.closeAlertPopUp} 
            onConfirm={this.updateDashboardRole}
            />}
      </div>
    </div>
   </div>
   </>
   );
  }
}

const mapDispatchToProps = dispatch => ({
  updatePasswordLock: (userInfo) => dispatch(updatePasswordLock(userInfo)),
  updateUserProfile:(accountParam,userParam) => dispatch(updateUserProfile(accountParam,userParam))
});

export default connect(null,mapDispatchToProps)(UserDetails);

class Notification extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      showModal: false,
      btnEnable: false
    }
  }

  close = () => { this.props.closeCaller(); }

  componentDidMount(){
    this.setState({showModal:true});
    setTimeout(()=>this.setState({btnEnable:true}), 20000);
  }

  render() {
    return (
        <Modal show={this.state.showModal} onHide={this.close} backdrop="static" keyboard={false}>
          <Modal.Body>
            <p>{this.state.btnEnable?"No response, try again":this.props.modalBody}</p>
          </Modal.Body>
          {this.state.btnEnable||"Declined"===this.props.modalTitle?<Modal.Footer>
            <Button onClick={this.close} className="btn-green">Close</Button>
          </Modal.Footer>:""}
        </Modal>
    );
  }
}

class ModalPopUp extends React.Component{
  constructor(props){
    super(props);
    this.state = { showModal: false }
  }

  close = () => {this.props.closeAlert()}

  componentDidMount(){
    this.setState({showModal:true});
  }
  render() {
    return (
        <Modal show={this.state.showModal} onHide={this.close} backdrop={false} keyboard={false}>
          <Modal.Header>
            <Modal.Title>{this.props.modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.modalBody}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close} className="btn-green">OK</Button>
          </Modal.Footer>
        </Modal>
    );
  }
}

class ConfirmationPopUp extends React.Component{
	constructor(props){
    super();
      this.state = {
        alertPopUp: false
      }
	    this.close = this.close.bind(this);
	}
  
	close() {
	  this.setState({alertPopUp:false});
	  this.props.closeAlert();
	}
  
	componentDidMount(){
	  this.setState({alertPopUp:true});
	}
  
	render() {
	  return (
		  <Modal show={this.state.alertPopUp} onHide={this.close} backdrop="static" keyboard={false}>
			<Modal.Header>
			  <Modal.Title>{this.props.modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  {this.props.modalBody}
			</Modal.Body>
			<Modal.Footer>
			  <Button onClick={this.props.onConfirm} >OK</Button>
        <Button onClick={this.close}>Cancel</Button>
			</Modal.Footer>
		  </Modal>
	  );
	}
} 