import * as d3 from "d3";

const drawChart = (element,elementLeg, data) => {
  const colors = ["#05BBD2", "#2070C4", "#EB80F1", "#F5C842", "#37D400",'#2ECC71','#D98880','#D98880','#D98880','#D98880','#D98880'];
  const boxSize = 500;

  d3.select(element).select("svg").remove(); // Remove the old svg

  const d3SvgElement = document.getElementById(elementLeg).getElementsByTagName("div");
				if(d3SvgElement.length > 1){
					for (let index = d3SvgElement.length - 1; index >= 0; index--) {
						d3SvgElement[index].parentNode.removeChild(d3SvgElement[index]);
					}
				}
  // Create new svg
    const svg = d3
        .select(element)
        .append("svg")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("height", "100%")
        .attr("width", "100%")
        .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
        .append("g")
        .attr("transform", `translate(${boxSize / 2}, ${boxSize / 2})`);

    const arcGenerator = d3.arc().cornerRadius(10).padAngle(0.02).innerRadius(100).outerRadius(250);

    const pieGenerator = d3.pie().value((d) => d.value);

    const arcs = svg.selectAll().data(pieGenerator(data)).enter();
  
    arcs.append("path")
        .attr("d", arcGenerator)
        .style("fill", (d, i) => colors[i % data.length])
        .on("mouseover", function(d, pointer) {
           // console.log(d);
            svg.append("text")
              .attr("dy", ".5em")
              .style("text-anchor", "middle")
              .style("font-size", 22)
              .attr("class","label")
              .style("fill", function(d,i){return "white";})
              .text(`${pointer.data.count} min`)
              .style("left", d.x + "px")		
							.style("top", d.y + "px");	
            
        })
        .on("mouseout", function(d) {
          svg.select(".label").remove();
          document.querySelectorAll(".tooltip").forEach(el => el.remove());
        });

    //Append text labels
    arcs.append('text')
        .attr('text-anchor','middle')
        .text((d)=> `${d.data.value}%`)// label text
        .style('fill','#fff') // label color
        .style('font-size','30px')
        .attr('transform',(d)=>{
            const [x,y] = arcGenerator.centroid(d);
            return `translate(${x},${y})`;
        })
        .style('font-size',0)
        .transition()
        .duration(700)
        .style('font-size','22px')

     //Add inner border
     svg.append('circle')
     .attr('cx',0)
     .attr('cy',0)
     .attr('r',100)
     .attr('stroke','transparent')
     .transition()
     .duration(700)
     .attr('stroke-width',8)

     //Legend
  const legend = d3.select(".chart__legend").selectAll("div")
  .data(data)
  .enter();
  //legend__item
  const legendItem = legend.append("div")
    .attr("class","legend__item legend__item--inline");
  //legend__item-badge
  legendItem.append("div")
    .attr("class","legend__item-badge")
    .attr("width", 10)
    .attr("height", 10)
    .attr("style", function(d,i) {
      return ("background-color:"+colors[i]);//
    });
  //legend__item-value
  legendItem.append("div")
    .attr("class","legend__item-value")
    .text(function(d){
    return d.value + "%";
  });
  //legend__item-text
  legendItem.append("div")
    .attr("class","legend__item-text")
    .text(function(d){
    return `${d.label} - ${d.count} min`;
  });

};

export default drawChart;