import * as React from 'react';
import {InputLabel, MenuItem, FormControl, Select, Box, Button, Grid}  from '@mui/material';
import './DropdownStandard.css';

export default function SelectAutoWidth(props) {
  const [sort_by, setValue] = React.useState('');  
  
  const handleChange = (event) => {
    //const valArr = event.target.value;
    setValue(event.target.value);
    props.setSortColumn(event.target.value);
    //props.sortFunction(event.target.value, dir);
  };

  
/* TP-6478 */
  return (
    <>
      <label className="col-xs-3 disable-pointer-event" htmlFor="simple-select-standard" style={{ height: 42, paddingTop: "5px", paddingBottom: "5px", paddingRight: "5px"}}>{props.labelText}</label>  
        <label className="col-xs-4 col-xs-offset-2 switch">
          <Box bgcolor={"white"} sx={{ width: "fit-content", height: 40, borderRadius: 2 }}>
            <FormControl variant="filled" sx={{ m: 0, minWidth: 220, height: 40 }} fullWidth>
              <Select
                labelId="simple-select-standard-label"
                id="simple-select-standard"
                value={sort_by? sort_by : props.columns[0].value}
                onChange={handleChange}
                autoWidth
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ margin: 1, height: 36 }}
                className="simple-select-standard"
              >
                { props.columns && props.columns.map(col => {
                    /* if(col.isDefault)
                      return <MenuItem value={col.value}><em>{col.name}</em></MenuItem>
                    else */
                      return <MenuItem value={col.value}>{col.name}</MenuItem>
                })

                }
                
              </Select>
            </FormControl>
          </Box>
        </label>
    </>         
    
  );
}