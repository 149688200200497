import React from "react";
import Chart from "chart.js";

export default class PieChart extends React.Component{

constructor(props){
  super(props);
  this.state = {};
  this.myRef = React.createRef();
}

componentDidMount(){
    this.instantiateCharts();
}

instantiateCharts = () => {
  const {
    dataSets, options,
    dataLabels, colorCombos
  } = this.props.analyticData;

  new Chart(this.myRef.current, {
      type: 'pie',
      data: {
          labels: dataLabels,
          datasets: [{
              label: dataLabels,
              data: dataSets,
              backgroundColor: colorCombos,
          }]
      },
      options: options
  });
}

  render(){
    return (
      <canvas ref={this.myRef} id={this.props.chartId} width={this.props.width} height={this.props.height} role="img">
      </canvas>
      );
  }
}
