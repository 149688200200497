import React from 'react'
import { FaRegFilePdf } from "react-icons/fa";

export default function jobsPdfIcon(props) {
    return (
        <div>
            <FaRegFilePdf size={20} className="pdf-icon-fa" onClick={(e)=>props.openModal(e,false,props.rowData)} /**TP-4211 & TP-4210*/ title="Download Pdf Report" ></FaRegFilePdf>
        </div>
    )
}
