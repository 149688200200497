import React from 'react'
import { FaFileCsv } from 'react-icons/fa';
import {FaLongArrowAltDown} from 'react-icons/fa';

export const DownloadGroupReport = ({propData,iconSize,name,viewType,location,color}) => {
    return(
        <div id="download-group-report" className={viewType === "cards" && color === undefined ? "col-sm-1 download-csv-report":"col-sm-1"}>
            <span className="fa-stack-display" onClick={(e)=>GenerateReportCSV(e,propData)} title={name ? 'Download users in '+name:"Download all users data"}>
                { viewType === "cards" ? 
                    <>
                        <FaFileCsv size={location === "top-bar" ? iconSize+10 : iconSize-3} color={color ? color:''} style={{/*marginLeft:'-11px' , marginTop: '20px' */}}/>
                        {/* <FaLongArrowAltDown size={iconSize-2} style={{marginLeft:'8px', marginTop: '-30px'}}/>*/}
                    </>
                    :
                    <>
                        <FaFileCsv size={location === "top-bar" ? iconSize : iconSize-10} color={color ? color:''} style={{marginLeft:'-11px'/* , marginTop: '-12px' */}}/>
                        {/* <FaLongArrowAltDown size={iconSize-2} style={{marginLeft:'-4px'}}/> */}
                    </>
                }
            </span>
        </div>
    ) 
}


const  GenerateReportCSV = (e,groupInfo) =>{
    e.preventDefault();

    if(!groupInfo || groupInfo.length < 1){
        console.log("No Data Found")
        return false;
    }
    
    let header = {
        group_name:"Group",
        description:"Description",
        user:"User",
        role:"Role",
        email:"Email"
    }

    let infoItems = [],fileTitle,timeStamp=new Date().toISOString(),groupObject,usersInfoArray,userDataItem;

        if(groupInfo instanceof Array){
            header =  Object.assign({'company_name':"Account Name"},header)
            for(let i = 0;i < groupInfo.length; i++){
                 groupObject = groupInfo[i];
                 usersInfoArray = groupObject.users;
                 userDataItem = getUserData(usersInfoArray,groupObject,groupObject.company_name);
                 infoItems.push(...userDataItem); 
            }
        }else if(groupInfo instanceof Object){
            usersInfoArray = groupInfo.users;
            infoItems = getUserData(usersInfoArray,groupInfo);
        }else{
            console.log('Data not found');
        }
        

        infoItems =  infoItems.sort((x,y) => x.group_name === y.group_name ? 0 : x.group_name < y.group_name ? -1 : 1);
        fileTitle = `Group Details ${timeStamp}`;
        exportFileCSV(header,infoItems,fileTitle)
}

// getting data from array
const getUserData = (usersInfoArray,groupObject,companyName) =>{
    let infoItems = [];
    for(let j = 0; j < usersInfoArray.length;j++){
        let user = usersInfoArray[j],rowData={};
        if(companyName)
        rowData['company_name'] = companyName.replace(',','');
        rowData['group_name'] = groupObject['group_name'];
        rowData['description'] = groupObject['description'];
        rowData['user'] = user['first_name'] && user['last_name'] ? user['first_name'] +' '+user['last_name']:user['first_name'];
        rowData['role'] = user['isexpert'] ? 'Expert':'Technician';
        rowData['email'] = user['email'];
        infoItems.push(rowData);
    }
    return infoItems;
}

function exportFileCSV(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    let jsonObject = JSON.stringify(items);

    let csv = convertToCSV(jsonObject);

    let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        let link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

// Converting the jSON to CSV format
function convertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}
