import * as d3 from 'd3';

const drawChart =  (element,paramData) =>{
    const {data,columns,users} = paramData;

    d3.select(element).select('svg').remove(); // remove the olf SVG
    const margin = {top:10,right:30,bottom:20,left:30},
          width = 620 - margin.left -margin.right,
          height = 460 - margin.top - margin.bottom;

    const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'd3-tooltip')
    .style('position', 'absolute')
    .style('z-index', '10')
    .style('visibility', 'hidden')
    .style('padding', '10px')
    .style('background', 'rgba(0,0,0,0.6)')
    .style('border-radius', '4px')
    .style('color', '#fff')
    .text('a simple tooltip');
            
          
    const svg =  d3.select(element)
                 .append('svg')
                 .attr('width',width+margin.left + margin.right)
                 .attr('height',height + margin.top + margin.bottom)
                 .append('g')
                 .attr('transform','translate('+margin.left +','+margin.top+')');

                // data.push(columns);            
      
    const subGroups = columns; 
    // console.log('subGroups',subGroups);
    //List of groups = species here = value of the first column called groups -> I show them on the X axis
    // const groups = d3.map(data, function(d){return(d.group)}).keys();
    const groups = data.map(item=>item.group);
    // console.log('groups',groups);
    //Added X Axis
    const X = d3.scaleBand()
             .domain(groups)
             .range([0,width])
             .padding([0.2])
          
         svg.append('g').attr('transform','translate(0,'+height+')') 
            .call(d3.axisBottom(X).tickSizeOuter(0)); 

    // Added Y Axis

    const Y = d3.scaleLinear()
              //.domain([0,60])
              .domain([0,90])
              .range([height,0]);
              
          svg.append('g').attr('id','stack-y-axis').call(d3.axisLeft(Y));
          
          
     // color paletee = one color per subgroup
    const color = d3.scaleOrdinal().domain(subGroups).range(["#8DD3C7", "#FBF7B4", "#BFBBD9", "#E87D74",
                  "#81B1D2",'#B3DE69','#BC82BD','#377eb8','#4daf4a','#D98880','#D98880','#D35400']);
     
     // stack the data ? --? stack per subgroup
     const stackedData =  d3.stack().keys(subGroups)(data);
    // const stackedData = getStackedData(subGroups,data);

    // console.log('data',data);
    // console.log('stackedData',stackedData);
       
    // Show the Bars 
    svg.append("g")
    .selectAll("g")
    // Enter in the stack data = loop key per key = group per group
    .data(stackedData)
    .join("g")
    .attr("fill", d => color(d.key))
    .selectAll("rect")
    // enter a second time = loop subgroup per subgroup to add all rectangles
    .data(d => d)
    .join("rect")
        .attr("x", d => X(d.data.group))
        .attr("y", d => Y(d[1]))
        .attr("height", d => Y(d[0]) - Y(d[1]))
        .attr("width",X.bandwidth())
        .attr('stroke','grey')     //Tooltip 
        .on('mouseover', function (d, item) {
            const subgroupName = d3.select(this.parentNode).datum().key;
            const userName = users[subgroupName];
            const subgroupValue = item.data[subgroupName];
            tooltip.html(`<div>Users: ${userName}</div><div>Duration (mins): ${subgroupValue}</div>`)
            .style('visibility', 'visible')
            .style("left", d.x +10 + "px")		
			.style("top", d.y + "px")
            .style('display','block')
            d3.select(this).transition().attr('fill', '')
        })
        .on('mousemove', function () {
            tooltip.style('top', d3.pointer.pageY - 10 + 'px')
                .style('left', d3.pointer.pageX + 10 + 'px');
        })
        .on('mouseout', function () {
            tooltip.html(``).style('visibility', 'hidden').style('display','none');
            d3.select(this).transition().attr('fill', '');
        }); 


}




export default drawChart;