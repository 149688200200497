import React from "react";
import moment from "moment";
// import Edit from "../editForm";
import DataTable from "react-data-table-component";
import {Button, Modal} from 'react-bootstrap';
import superagent from "superagent";
import LoadThreeDots from '../Utils/LoadThreeDots'; //TP-729
import {connect} from 'react-redux';
import {deleteCustomerSuccess} from '../redux/Customer/CustomerActions';
import "./customers.css";
import {DiGoogleAnalytics} from 'react-icons/di';


const purchaseColumns = [
  {name:"Plan Id",selector:"plan_id",sortable:true},
  {name:"Stripe Invoice Id",selector:"stripe_invoice_id",sortable:true},
  {name:"(Available / Total) Licenses",cell:""},
  {name:"Invitees Count",cell:""},
  {name:"Created On",selector:"created_on",cell:"",sortable:true},
  {name:"Cancelled On",selector:"cancelled_on",cell:"",sortable:true},
  {name:"Payment Status",selector:"payment_status",sortable:true},
  {name:"Status",selector:"status",sortable:false},
  {name:"Comment",selector:"comment",sortable:true}
];

const AUDIO_MODE = {
  "tech2Tech":"Tech to Tech",
  "expert2Tech":"Expert to Tech",
}


 class CustomerDtlsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseDetails: [],
      groups:[],
      showEntSettings:false,
      showPopUp: false,
      modalTitle:null,
			modalBody:null,
      showMsg:null,
      serverMsg:null,
      adminUserInfo:null,
      isLoading:false,
    };
  }

  componentDidMount() {
    this.setCustomerDetail();
  }

  isPrepaidAC = (viewDetails) =>{
    let accountLogic = viewDetails.account_id.substr(2,2);
    if("00"===accountLogic && (viewDetails.plan_id===2 || viewDetails.plan_id===3)){
      return true;
    }else return false;
  }

  setCustomerDetail = () =>{
    const {state} = this.props.params;
    const {viewDetails} = this.props;
    let showEntSettings = this.state.showEntSettings;
    let prepaidAccount = this.isPrepaidAC(viewDetails);
    //TP-729
    let custInvitees = [], credits = [], users = [], groups = [], purchaseDetails = [], invitees = [] , adminUserInfo;

    custInvitees = state.inviteList.filter(invite=>viewDetails.account_id === invite.account_id);
    groups = state.groupList.filter(group=>viewDetails.account_id === group.account_id && group.group_id);
    adminUserInfo = viewDetails.users ? viewDetails.users.filter((user) => user.isadmin).pop() : null;
    //{/*TP-809 */}   
    if(!adminUserInfo && custInvitees){
      adminUserInfo = custInvitees.pop();
    }
    viewDetails.purchases.forEach((purchase, i) => {
      users = []; invitees = [];
      credits = viewDetails.credits ? viewDetails.credits.filter(credit=>credit.purchase_id===purchase.id):[];
      credits.forEach((credit, i) => {
        if(viewDetails.users)
          users.push(...viewDetails.users.filter(user => credit.purchase_id===user.purchase_id));
          if(custInvitees && custInvitees.length)
        invitees.push(...custInvitees.filter(invite => credit.id===invite.credit_id));
      });
      //TP-729
      purchase.purchase_id = credits.length > 0 &&  credits[0].purchase_id ? credits[0].purchase_id :"";
      purchase.users = [...new Set(users)];
      purchase.credits = credits;
      purchase.invitees = invitees;
      purchaseDetails.push(purchase);
    });
    if(viewDetails.entAccess || prepaidAccount ){
      showEntSettings = true;
    }
    this.setState({
      purchaseDetails: purchaseDetails,
      groups: groups,
      showEntSettings: showEntSettings,
      prepaidAccount: prepaidAccount,
      adminUserInfo:adminUserInfo
    });
  }

  copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  customSort = (rows, field, direction) =>{
    if(null===field) field="created_on";
    let purPicked = rows.filter(row=>null!==row[field]);
    let purNullPicked = rows.filter(row=>null===row[field]);
    let mul=-1;
    if("asc"===direction){
      mul=1;
    }else if("desc"===direction){
      mul=-1;
    }

      if("created_on"===field||"cancelled_on"===field){
        purPicked.sort((c1,c2)=>{
          if(moment(c1[field]).isAfter(moment(c2[field]))){
            return -1*mul;
          }else if(moment(c1[field]).isBefore(moment(c2[field]))){
            return 1*mul;
          }else return 0;
        });
      }else if("stripe_invoice_id"===field||"payment_status"===field||"comment"===field){
        purPicked.sort((a, b)=>{
          if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
          else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
          return 0;
         })
      }else if("plan_id"===field){
        let purNANpicked=[], purDigitPicked = [];
        purPicked.forEach((item, i) => {
          if(""===item[field]||isNaN(item[field]))
            purNANpicked.push(item);
          else
            purDigitPicked.push(item);
        });

        purDigitPicked.sort((a, b)=>{
          if(+a[field] < +b[field]) return -1*mul;
          else if(+a[field] > +b[field]) return 1*mul;
          return 0;
        });
        purPicked=[...purDigitPicked,...purNANpicked];
      }else if("role"===field){
         let adminL = [], expertL = [], techL = [], unregL = [];
         purPicked.forEach((item, i) => {
           if(undefined!==item.invitehash){
             unregL.push(item);
           }else if(item.isadmin){
             adminL.push(item);
           }else if(item.isexpert){
             expertL.push(item);
           }else if(!item.isadmin && !item.isexpert){
             techL.push(item);
           }
         });
         if("asc"===direction){
           purPicked=[...adminL,...expertL,...techL, ...unregL];
         }else if("desc"===direction){
           purPicked=[...unregL,...techL,...expertL,...adminL];
         }
      }
    return [...purPicked, ...purNullPicked];
  }
  //TP-729
  showConfirmationPopup = () =>{
    this.setState({showPopUp:true, modalTitle:"Confirm Delete",modalBody:"Are you sure you want to permanently delete this account?"});
  };
  
  closeAlert = () => {
    this.setState({showPopUp:false});
  }

  closeAlertPopUpMoveBacK = () => {
    this.setState({serverMsg:null});
    let secondLastNo =  this.props.params.state.breadcrumbItems.length - 2;
    let breadCrumbId  = this.props.params.state.breadcrumbItems[secondLastNo].id
    this.props.params.breadcrumbTail(breadCrumbId,undefined,undefined,undefined,this.props.viewDetails.account_id);
    //window.location.reload();
  }


//TP-729
 deletePrepaidAccount = async () =>{
    
    const {purchaseDetails,adminUserInfo} = this.state;

    if(!adminUserInfo || !purchaseDetails){
      this.closeAlert();
      return false;
    } 

    let result = purchaseDetails.reduce((accumlatorPromise,nextObj)=>{
      return accumlatorPromise.then(()=>{
          this.setState({isLoading:true});
         return this.cancelSubscriptionPromise(nextObj,adminUserInfo)
        });
    },Promise.resolve())
     
    result.then(() => {
      this.deletePrepaidAccountById();
      this.setState({isLoading:false});
    })
 }
//TP-729
cancelSubscriptionPromise(purchaseObj,adminObj) {

  return new Promise((resolve,reject)=>{
    // if purchaseObj will not able to find purchase obj or admin obj then it it will skip and run next purchase entry
    // or else if will resolve and move for next iterration
    if(purchaseObj && purchaseObj.purchase_id && adminObj && adminObj.id){
      let url = '/api/cancelSubscriptionByPurchaseId';
      let params = {
        purchaseId:purchaseObj.purchase_id,
        adminUserId:adminObj.id,
        accountId:adminObj.account_id
      }
      
      if(sessionStorage.userToken) {
        superagent.post(sessionStorage.url+ url)
        .set("Authorization", "Bearer " + sessionStorage.userToken)
        .send(params)
        .accept("application/json")
        .then(response => response)
        .then(data => {
          if (null !== data.body) {
            console.log('Cancelled Subscription successfully');
            resolve(data.body.id);
          }
        }).catch(error => {
            console.error(error);
            this.closeAlert();
            this.setState({isLoading:false});
            this.setState({serverMsg:"Deletion unsuccessful, unable to delete this account!",showPopUp:false,modalTitle:"Alert"});
            return error;
        })
      }
    }else{
      resolve();
    }
  })
}


deletePrepaidAccountById = async () => {

  if(!this.props.viewDetails.account_id)
    return false;

  let apiUrl = "/api/deleteAccount/" + this.props.viewDetails.account_id;
  let headers = new Headers();
  headers.append("Authorization","Bearer " + sessionStorage.getItem("userToken"));
  let msg = null;

  fetch(sessionStorage.getItem("url") + apiUrl, { method: "DELETE",headers: headers})
    .then((response) => {
      if (200 === response.status) {
        msg = "Account deleted successfully ";
        this.setState({serverMsg:msg,showPopUp:false,modalTitle:"Alert"});
        if(this.props.deleteCustomerSuccess)
           this.props.deleteCustomerSuccess(this.props.viewDetails.account_id);
      } else {
        console.log('Error accour while deleting account!');
        this.closeAlert();
      }
    })
    .catch((error) => {
      if (undefined === error.status) {
        msg = "May be internet is not connected, try again sometime";
      } else if (400 === error.status) {
        msg = "Data not Deleted";
      } else if (403 === error.status) {
        msg = "You are not authorized to delete the customer";
      } else if (404 === error.status) {
        msg = "Customer cannot be found using this account id";
      } else if (422 === error.status) {
        msg = "Invalid request data";
      } else if (500 === error.status) {
        msg = "Something went wrong, try again sometime";
      }
      this.setState({serverMsg:msg,showPopUp:false,modalTitle:"Alert"});
    });
};

//TP-729
deleteCustomerAccountId = async () => {

  if(!this.props.viewDetails.account_id)
    return false;

    let apiUrl =
    "/api/dash/deleteCustomerByAccountId/" + this.props.viewDetails.account_id;
  let headers = new Headers();
  headers.append(
    "Authorization",
    "Bearer " + sessionStorage.getItem("userToken")
  );
  let msg = null;

  fetch(sessionStorage.getItem("url") + apiUrl, {
    method: "DELETE",
    headers: headers,
  })
    .then((response) => {
      if (200 === response.status) {
        msg = "Account deleted successfully ";
        this.setState({serverMsg:msg,showPopUp:false,modalTitle:"Alert"});
        if(this.props.deleteCustomerSuccess)
           this.props.deleteCustomerSuccess(this.props.viewDetails.account_id)
      } else {
        this.closeAlert();
      }
    })
    .catch((error) => {
      if (undefined === error.status) {
        msg = "May be internet is not connected, try again sometime";
      } else if (400 === error.status) {
        msg = "Data not Deleted";
      } else if (403 === error.status) {
        msg = "You are not authorized to delete the customer";
      } else if (404 === error.status) {
        msg = "Customer cannot be found using this account id";
      } else if (422 === error.status) {
        msg = "Invalid request data";
      } else if (500 === error.status) {
        msg = "Something went wrong, try again sometime";
      }
      this.setState({serverMsg:msg,showPopUp:false,modalTitle:"Alert"});
    });

};


  render() {
    purchaseColumns[1].cell=row => null===row.stripe_invoice_id?"NA":
                              <div className="hoverClipCopy">
                                 <div>{row.stripe_invoice_id}</div>
                                 <div className="tooltiptext small">
                                    <i className="fa fa-copy copySelection" id={"stripe_invoice_id"+row.id}
                                    onClick={(e) => this.copyToClipboard(row.stripe_invoice_id)}> Copy</i>
                                 </div>
                              </div>
    purchaseColumns[2].cell=row => <div className="hyperlink"
                                      onClick={event=>this.props.params.breadcrumbTail("licenses",row, "Licenses")}>
                                    {row.available_credits +'/'+ row.total_licences}
                                  </div>
    purchaseColumns[3].cell=row => row.invitees.length>0
    ?<div className="hyperlink"
        onClick={event=>this.props.params.breadcrumbTail("users",row.invitees, "Invitees")}>
      {row.invitees.length}
    </div>
    :<span>{row.invitees.length}</span>;
    purchaseColumns[4].cell=row => row.created_on !== null ? moment(row.created_on).format("DD MMM,YYYY HH:mm"):"NA";
    purchaseColumns[5].cell=row => row.cancelled_on !== null ? moment(row.cancelled_on).format("DD MMM,YYYY HH:mm"):"NA";
    purchaseColumns[6].cell=row => null===row.payment_status?"NA":row.payment_status;
    purchaseColumns[7].cell=row => row.is_cancelled ? 'Inactive':'Active';
    purchaseColumns[8].cell=row => null===row.comment?"NA":<textarea disabled={true} className="comment-text-area" value={row.comment} />;

    return (
      <div className="page">
        
        <div className="container-fluid">
        <div className="row panelDiv">
          <div className="col-sm-6 col-lg-3">
            <strong>A/C Id: </strong>
            <span>{this.props.viewDetails.account_id}</span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Stripe Id: </strong>
            <span>
              {this.props.viewDetails.stripe_customer_id===null?"NA":this.props.viewDetails.stripe_customer_id}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Sub Id: </strong>
            <span>
              {this.props.viewDetails.subscription_id===null?"NA":this.props.viewDetails.subscription_id}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Status: </strong>
            <span>
              {this.props.viewDetails.subscription_status}
            </span>
          </div>
          <div className="customer-action">
            <i id="addEntId" data-toggle="tooltip" title="Modify Enterprise"
              style={{display:((this.props.enterpriseWriteAccess&&this.props.viewDetails.entAccess) || this.props.myWriteAccountAccess /**TP-4952*/) ?'flex':'none'}}
              onClick={()=>this.props.params.breadcrumbTail("modEnt",this.props.viewDetails,"Modify Enterprise")}
              className="fa fa-edit edit-enterprise-icon">
            </i>
            {/*TP-729/Added subscription_status to verify if customer is not vendor trail */}
            {sessionStorage.userDbRole === "admin" && sessionStorage.url.includes("staging") === false && this.state.adminUserInfo  && this.props.viewDetails.subscription_status !=="trial_approved" 
              && this.props.viewDetails.subscription_status !== "trial_pending_approval" ? DeleteAccountIcon(this.showConfirmationPopup):null}
              {/* Jira Ticket:TP-2177 Making below code to be hidden on UI */}
            <div className='analytics-icon-div' style={{display:'none'}}> 
              <DiGoogleAnalytics onClick={()=>{this.props.params.breadcrumbTail('custAnalytics',this.props.viewDetails,'Usage Details')}} title='Usage Details'></DiGoogleAnalytics>
            </div> 

          </div>

          <div className="mt-2 col-lg-12 media-show-sm"></div>

          <div className="col-sm-6 col-lg-3">
            <strong>Created On: </strong>
            <span>
              {this.props.viewDetails.created_on != null
                ? moment(this.props.viewDetails.created_on).format(
                    "DD MMM,YYYY HH:mm"
                  )
                : ""}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Sub Created On: </strong>
            <span>
              {this.props.viewDetails.subscription_start_date != null
                ? moment(
                    this.props.viewDetails.subscription_start_date
                  ).format("DD MMM,YYYY HH:mm")
                : ""}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Sub Expires On: </strong>
            <span>
              {this.props.viewDetails.subscription_end_date != null
                ? moment(
                    this.props.viewDetails.subscription_end_date
                  ).format("DD MMM,YYYY HH:mm")
                : ""}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Audio Mode: </strong>
            <span>{AUDIO_MODE[this.props.viewDetails.audio_mode]}</span>
          </div>

          <div className="mt-2 col-lg-12 media-show-sm"></div>

          <div className="col-sm-6 col-lg-3">
            <strong>Website: </strong>
            <span>
              {null===this.props.viewDetails.company_url?'NA':this.props.viewDetails.company_url}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Phone: </strong>
            <span>
              {/* <Edit fieldValue={this.props.viewDetails.company_phone} params={this} fieldName={'Phone'}/> */}
              {this.props.viewDetails.company_phone}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Vendor Name: </strong>
            <span>
              {this.props.viewDetails.vendor_name===null?"NA":this.props.viewDetails.vendor_name}
            </span>
          </div>
          { "true" !== sessionStorage.isSmartVision  ?
              <div className="col-sm-6 col-lg-3" >
                <strong>Custom Stream Mode: </strong>
                <span>
                  {this.props.viewDetails.is_customizable?'Yes':'No'}
                </span>
              </div>
              :
              ''
          }

          <div className="mt-2 col-lg-12 media-show-sm"></div>

          <div className="col-sm-6 col-lg-3">
            <strong>Payment Mode: </strong>
            <span>
              {this.props.viewDetails.is_paid_offline?'Postpaid':'Prepaid'}
            </span>
          </div>
          { "true" !== sessionStorage.isSmartVision ?
          <div className="col-sm-6 col-lg-3">
            <strong>Job Assignee Level: </strong>
            <span>
              {this.props.viewDetails.assign_jobs_to_all_users?'Account':'Group'}
            </span>
          </div>
          :''
          }
          { "true" !== sessionStorage.isSmartVision  ?
          <div className="col-sm-6 col-lg-3">
            <strong>Workflow: </strong>
            <span>
              {this.props.viewDetails.is_gps_enabled?'Yes':'No'}
            </span>
          </div>
          :''
          }
          { "true" !== sessionStorage.isSmartVision ?
          <div className="col-sm-6 col-lg-3">
            <strong>On-premise support: </strong>
              {this.props.viewDetails.onprem_only?'Yes':'No'}
          </div>
          :''
          }
          <div className="mt-2 col-lg-12 media-show-sm"></div>

          <div className="col-sm-6 col-lg-3">
            <strong>Experts per Group: </strong>
              {this.props.viewDetails.number_of_experts}
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Code: </strong>
            <span id="code" className="small">
              {this.props.viewDetails.code}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Comment: </strong>
            <span>
              {this.props.viewDetails.comment===null?"NA":this.props.viewDetails.comment}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Groups: </strong>
            {this.state.groups.length>0
            ?<div onClick={()=>this.props.params.breadcrumbTail("groups",this.state.groups,"Groups")}
                id="groupsId" className="hyperlink d-inline" data-toggle="tooltip" title="View group list">
              {this.state.groups.length}
            </div>
            :this.state.groups.length
            }
          </div>

          <div className="mt-2 col-lg-12 media-show-sm"></div>
          <div className="col-sm-6 col-lg-3">
            <strong>Address: </strong>
            <span>
              {/* <Edit fieldValue={this.props.viewDetails.company_address===null?"NA":this.props.viewDetails.company_address} params={this} fieldName={'Address'}/> */}
              {this.props.viewDetails.company_address===null?"NA":this.props.viewDetails.company_address}
            </span>
          </div>
          <div className="col-sm-6 col-lg-3">
            <strong>Allow only Telepresenz App on Home Screen: </strong>
              {this.props.viewDetails.is_solo_program?'Yes':'No'}
          </div>
          { "true" !== sessionStorage.isSmartVision ?
          <div className="col-sm-6 col-lg-3" style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>Asset Scan Allowed: </strong>
              {this.props.viewDetails.asset_scan?'Yes':'No'}
          </div>
          :''
          }
          { "true" !== sessionStorage.isSmartVision ?
          <div className="col-sm-6 col-lg-3" style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>Allow Guest User: </strong>
              {this.props.viewDetails.guest_user_enabled?'Yes':'No'}
          </div>
          :''
          }
          <div className="mt-2 col-lg-12 media-show-sm" style={{display:this.state.showEntSettings?'block':'none'}}></div>
          <div className="col-sm-6 col-lg-3" style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>Allow Expert Screen Share: </strong>
            {"screen_share_enabled"===this.props.viewDetails.screen_share_mode
            ||"expert_screen_share_enabled"===this.props.viewDetails.screen_share_mode?'Yes':'No'}
          </div>
          {/* <div className="col-sm-6 col-lg-3" style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>Allow Remote User Screen Share: </strong>
            {"screen_share_enabled"===this.props.viewDetails.screen_share_mode
            ||"tech_screen_share_enabled"===this.props.viewDetails.screen_share_mode?'Yes':'No'}
          </div> */}
          <div className="col-sm-6 col-lg-3">
            <strong>Allow User to turn off video: </strong>
              {this.props.viewDetails.can_turn_off_user_video ?'Yes':'No'}
          </div>
          <div className="col-sm-6 col-lg-3"
          style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>{!this.props.viewDetails.password_expiry_duration>0?"Allow Password Expiry: ":"Password Expiry Duration: "}</strong>
            {!this.props.viewDetails.password_expiry_duration>0?"No":this.props.viewDetails.password_expiry_duration}
          </div>
          <div className="col-sm-6 col-lg-3" style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>Allow Screen Recording: </strong>
            {this.props.viewDetails.screen_recording_enabled?'Yes':'No'}
          </div>

          <div className="mt-2 col-lg-12 media-show-sm" style={{display:this.state.showEntSettings?'block':'none'}}></div>
          <div className="col-sm-6 col-lg-3" style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>Allow Zoom: </strong>
            {this.props.viewDetails.zoom_enabled?'Yes':'No'}
          </div>
          <div className="col-sm-6 col-lg-3" style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>Allow Torch: </strong>
            {this.props.viewDetails.torch_enabled?'Yes':'No'}
          </div>
          <div className="col-sm-6 col-lg-3" style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>Allow Annotation Color Change: </strong>
            {this.props.viewDetails.annotation_color_enabled?'Yes':'No'}
          </div>
          <div className="col-sm-6 col-lg-3" style={{display:this.state.showEntSettings?'block':'none'}}>
            <strong>Allow Annotation Shape Change: </strong>
            {this.props.viewDetails.annotation_shape_enabled?'Yes':'No'}
          </div>

          <div className="mt-2 col-lg-12 media-show-sm" style={{display:this.props.viewDetails.entAccess?'block':'none'}}></div>
          <div className="col-sm-6 col-lg-3" style={{display:this.props.viewDetails.entAccess?'block':'none'}}>
            <strong>Annual Cost: </strong>
              {undefined===this.props.viewDetails.price?"NA":this.props.viewDetails.price}
          </div>
          <div className="col-sm-6 col-lg-3" style={{display:this.props.viewDetails.entAccess?'block':'none'}}>
            <strong>Overage Cost: </strong>
              {undefined===this.props.viewDetails.overage_cost?"NA":this.props.viewDetails.overage_cost}
          </div>
          <div className="col-sm-6 col-lg-3" style={{display:this.props.viewDetails.entAccess?'block':'none'}}>
            <strong>Streaming Quota: </strong>
              {undefined===this.props.viewDetails.monthly_minutes?"NA":this.props.viewDetails.monthly_minutes}
          </div>
          <div className="col-sm-6 col-lg-3" style={{display:this.props.viewDetails.entAccess?'block':'none'}}>
            <strong>Account Status: </strong>
              {this.props.viewDetails.isInactive?'Inactive':'Active'}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div className="customer-table-title">Purchase History</div>
            <DataTable
              pagination={this.state.purchaseDetails.length>3}
              paginationPerPage={3}
              columns={purchaseColumns}
              data={this.state.purchaseDetails}
              noDataComponent="There are no records to display"
              sortFunction={(rows, field, direction)=>this.customSort(rows, field, direction)}
            />
          </div>
        </div>
      </div>
      {this.state.showPopUp && this.props.viewDetails.is_paid_offline &&<ConfirmationPopUp modalTitle={this.state.modalTitle} modalBody={this.state.modalBody} closeAlert={this.closeAlert} onConfirm={this.deleteCustomerAccountId}/>}
      {this.state.showPopUp && !this.props.viewDetails.is_paid_offline &&<ConfirmationPopUp modalTitle={this.state.modalTitle} modalBody={this.state.modalBody} closeAlert={this.closeAlert} onConfirm={this.deletePrepaidAccount}/>}
      {this.state.serverMsg && <AlertPopUp modalTitle={this.state.modalTitle} modalBody={this.state.serverMsg} closeAlert={this.closeAlertPopUpMoveBacK} />}
      {this.state.isLoading && <LoadThreeDots isLoading={this.state.isLoading}/>} 
      </div>
    );
  }
}


const mapDispatchToProps = dispatch =>({
  deleteCustomerSuccess: (accountId) => dispatch(deleteCustomerSuccess(accountId))
});

export default connect(null,mapDispatchToProps)(CustomerDtlsTab);


class ConfirmationPopUp extends React.Component{
	constructor(props){
    super();
      this.state = {
        alertPopUp: false
      }
	    this.close = this.close.bind(this);
	}
  
	close() {
	  this.setState({alertPopUp:false});
	  this.props.closeAlert();
	}
  
	componentDidMount(){
	  this.setState({alertPopUp:true});
	}
  
	render() {
	  return (
		  <Modal show={this.state.alertPopUp} onHide={this.close} backdrop="static" keyboard={false}>
			<Modal.Header>
			  <Modal.Title>{this.props.modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  {this.props.modalBody}
			</Modal.Body>
			<Modal.Footer>
			  <Button onClick={this.props.onConfirm}>OK</Button>
        <Button onClick={this.close}>Cancel</Button>
			</Modal.Footer>
		  </Modal>
	  );
	}
} 

class AlertPopUp extends React.Component{
	constructor(props){
	  super(props);
	  this.state = {
		alertPopUp: false
	  }
	  this.close = this.close.bind(this);
	}
  
	close() {
	  this.setState({alertPopUp:false});
	  this.props.closeAlert();
	}
  
	componentDidMount(){
	  this.setState({alertPopUp:true});
	}
  
	render() {
	  return (
		  <Modal show={this.state.alertPopUp} onHide={this.close} backdrop="static" keyboard={false}>
			<Modal.Header>
			  <Modal.Title>{this.props.modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  {this.props.modalBody}
			</Modal.Body>
			<Modal.Footer>
			  <Button onClick={this.close} className="btn-green">OK</Button>
			</Modal.Footer>
		  </Modal>
	  );
	}
  }
  //TP-729
  const DeleteAccountIcon = (showConfirmationPopup) =>{
    return (
      <div>
          <i className="fa fa-trash-o fa-2x delete-enterprise-icon" title="Delete Enterprise" onClick={showConfirmationPopup}></i>
      </div>
    )
  } 

