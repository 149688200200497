import * as React from 'react';
import { Dropdown } from '../CustomComponents/customDropDown';
import { connect } from "react-redux";
import {updateGroupConfiguration} from '../redux/Group/GroupActions';

const AnnotationPointerPermission = ({viewDetails, updateGroupConfiguration}) => {
    let {annotation_pointer_enabled: permission} = viewDetails;

    const options = [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ];

    const [value, setValue] = React.useState(permission);
  
    
    const setGroupConfiguration = (selectedPermission) =>{
      const params={
        group_id: viewDetails.group_id,
        annotation_pointer_enabled: selectedPermission,
        keyName: "annotation_pointer_enabled"
      }
  
      const onSuccessMsg = `${selectedPermission === 'true' ? 'Pointer annotation active for': 'Pointer annotation inactive for'} ${viewDetails.group_name}`; 
  
      updateGroupConfiguration(viewDetails,params,onSuccessMsg);
    }  
    
    const handleChange = (event) => {
      setValue(event.target.value);
      setGroupConfiguration(event.target.value)
    };

    return (
      <>
        <Dropdown
          options={options}
          value={value}
          onChange={handleChange}
        />
      </>
    );
  };
  
  // export default AnnotationPointerPermission;
  const mapDispatchToProps = dispatch =>({
    updateGroupConfiguration: (groupInfo,groupValue,successMsg) => dispatch(updateGroupConfiguration(groupInfo,groupValue,successMsg)),
  })

  export default connect(null,mapDispatchToProps)(AnnotationPointerPermission)
  