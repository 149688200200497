import React from "react";
import PieChart from "../graph/pieChart";
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';


export default class HomeTab extends React.Component{

constructor(props){
  super(props);
  this.state = {
    show:false,
    whichPie:"tlc",
    userByLicenses : []
  }
}

componentDidMount(){
  const {state} = this.props.params;
  const {userList, inviteList} = state;
  this.setState({
    userByLicenses: [
      ...userList.filter(user=>user.isexpert || !user.isadmin), 
      ...inviteList.filter(inv=>!(inv.isadmin&&!inv.isexpert) && "trial_pending_approval"!==inv.subscription_status)
    ]
  })
}

search = (e, data) => {
  const {breadcrumbTail, state} = this.props.params;
  const {userList, inviteList, groupList} = state;
  let searchPage = null,
  filterBy = null,
  label = data.huntLabel,
  filterValue=null;
  
  if("mru"===data.key || "online"===data.key){
    searchPage="users";
    label = "Users";
    filterBy=data.key;
    data = [...userList, ...inviteList];
  }else if("group" === data.key){
    searchPage="group";
  }else if("Users"===label.substring(label.length-5, label.length)){
    searchPage="users";
    label = "Users";
    filterBy="account_id";
    filterValue=data.account_id;
    data = [...userList, ...inviteList];
  }else if("Groups"===label.substring(label.length-6, label.length)){
    searchPage="groups";
    label = "Groups";
    data = groupList.filter(group=>data.account_id === group.account_id && group.group_id);
  }else if(undefined!==data.users){
    searchPage="customer";
    label = data.huntLabel;
  }else if(undefined===data.users){
    searchPage="user";
  }
  breadcrumbTail(searchPage,data,label,filterBy,filterValue);
}

render(){
  const {show, whichPie, userByLicenses} = this.state;
  const { params, enterpriseWriteAccess,prepaidWriteAccess,myWriteAccountAccess,myReadAccountAccess } = this.props;
  const {breadcrumbTail, state} = params;
  const { sum_of_total_licenses, avg_monthly_cost,
    pieChart1, pieChart2, pieChart5, pieChart6,pieChart7, 
    globalHunt, allCustomers,groupList} = state;
    //TP-809
  let onlineGroupCount = 0,onlineUserCount = 0,groupListCount=0;
      onlineGroupCount = groupList.filter(grp=>grp.isongoingnow).length;
      onlineUserCount = userByLicenses.filter(usr=> usr.is_loggedin).length;
      groupListCount = groupList.length;
  return (
  <div className="container-fluid">
    <div className="row">
      <div className="col align-self-center mt-2">
      <div className="dropdown" style={{display:("false" === sessionStorage.isSmartVision && (enterpriseWriteAccess || prepaidWriteAccess || myWriteAccountAccess || myReadAccountAccess) /**TP-4952*/) ? "block":"none"}}>
          <div className="dropdownlist">
            <div className="dropbtn" onClick={()=>this.setState({show:!show})} style={{display:("false" === sessionStorage.isSmartVision && (enterpriseWriteAccess || prepaidWriteAccess || myWriteAccountAccess || myReadAccountAccess)) /**TP-4952*/ ? "block":"none"}}></div>
            <div className="Socket-connection" /**TP-4952*/ style={{display:("false" === sessionStorage.isSmartVision && (enterpriseWriteAccess || prepaidWriteAccess)) ? "block":"none"}}><a title="Active Session Details"  onClick={()=>breadcrumbTail("socket",userByLicenses,"Active Session Details")} style={{display:'inline-block'}}><i className="fa fa-exclamation-triangle fa-2x yellow"></i></a></div>
            
          </div>
          <div className="dropdown-content" style={{display:show?"block":"none"}}>
            <a id="addEntId"
            onClick={e=>breadcrumbTail("addTrial",e,"Register Vendor Trial")}
            style={{display:myWriteAccountAccess || myReadAccountAccess?'none':'inline-flex'}} /**TP-4952*/
            className="hyperlink">
              New&nbsp;Vendor&nbsp;Trial
            </a>
            <a id="addEntId"
            onClick={e=>breadcrumbTail("addEnt",e,"Register Enterprise")}
            style={{display:enterpriseWriteAccess?'inline-flex':'none'}}
            className="hyperlink">
              New&nbsp;Enterprise
            </a>
            {/**TP-4921  TP-4952*/} 
         
            {/* <>   // TP-6475
            <a id="addEntId"
            onClick={e=>breadcrumbTail("pendApprvl",allCustomers,"Pending Approvals")}
            style={{display:enterpriseWriteAccess || myWriteAccountAccess || myReadAccountAccess?'inline-flex':'none'}} 
            className="hyperlink">
              Pending&nbsp;Approvals
            </a>
            </> */}

          </div>
        </div>
        <Autocomplete
        id="globalHuntId"
        options={globalHunt}
        onChange={(e, data) => this.search(e, data)}
        getOptionLabel={(option) =>option.huntLabel}
        className="globalHuntCls"
        renderInput={params => (
          <TextField {...params} placeholder="Search" variant="filled" fullWidth />
        )} />
      </div>
    </div>
    <div className="row">
      <div className="col-sm-6 col-lg-5">
        <PieChart analyticData={pieChart1} chartId="byType"/>
        <h5 className="chart-title text-center">
          <div>
            <span className="hyperlink" onClick={()=>breadcrumbTail("customers",allCustomers,"All")}>Customers By Type ({allCustomers.length})</span>
          </div>
        </h5>
      </div>
      <div id="lapy" className="col-sm-2 col-lg-2"></div>
      <div className="col-sm-6 col-lg-5">
        <PieChart analyticData={pieChart2} chartId="byStatus"/>
        <h5 className="chart-title text-center">
          <div>
           <span className="hyperlink" onClick={()=>breadcrumbTail("customers",allCustomers,"All")}>Customers By Status ({allCustomers.length})</span>
          </div>
        </h5>
      </div>
    </div>
    <div className="row justify-content-around">
      <div className="col-sm-6 col-lg-5">
         
      {"default"===whichPie&&<div className="homepage-pie-labels">
      <div className="chart-title">
        <span className="hyperlink" onClick={()=>this.setState({whichPie: "tlc"})} >Total licenses </span>
        <span className="hyperlink" onClick={()=>breadcrumbTail("users",userByLicenses, "Users", "usersbylicense")}>({onlineUserCount ? onlineUserCount+"/"+sum_of_total_licenses:sum_of_total_licenses})</span>
      </div>
        <div className="chart-title hyperlink" onClick={()=>this.setState({whichPie: "armc"})} style={{display:(!myWriteAccountAccess && !myReadAccountAccess) ? 'block':'none'}}>
          Current monthly revenue (${avg_monthly_cost})
        </div>
        <div className="chart-title"> 
          <span className="hyperlink" onClick={()=>this.setState({whichPie:"tgc"})}>Total Groups </span>
          <span className="hyperlink" onClick={()=>breadcrumbTail('groups',groupList,'All Groups')}>({onlineGroupCount ? onlineGroupCount+"/"+groupListCount:groupListCount})</span>
        </div>
      </div>}
      {"tgc"===whichPie&&<>
      <PieChart analyticData={pieChart7} width="300" height="100" chartId="byTGC"></PieChart>
        <div className="homepage-pie-labels">
            <div className="chart-title">
              <span className="hyperlink" onClick={()=>this.setState({whichPie: "tlc"})}>Total licenses </span>
              <span className="hyperlink" onClick={()=>breadcrumbTail("users",userByLicenses, "Users", "usersbylicense")}>({onlineUserCount ? onlineUserCount+"/"+sum_of_total_licenses:sum_of_total_licenses})</span>
            </div>
            {/* <div className="chart-title hyperlink" onClick={()=>this.setState({whichPie: "armc"})} style={{display:(!myWriteAccountAccess && !myReadAccountAccess) ? 'block':'none'}}>
              Current monthly revenue (${avg_monthly_cost})
            </div> */}
             <div className="chart-title" style={{display:(!myWriteAccountAccess && !myReadAccountAccess) ? 'block':'none'}} >
               <span className="hyperlink" onClick={()=>this.setState({whichPie: "armc"})} >Current monthly revenue (${avg_monthly_cost})</span>
             </div> 
            <div className="chart-title"> 
              <span className="hyperlink hyperlink-active">Total Groups </span>
              <span className="hyperlink" onClick={()=>breadcrumbTail('groups',groupList,'All Groups')}>({onlineGroupCount ? onlineGroupCount+"/"+groupListCount:groupListCount})</span>
            </div>
        </div>
        </>
      } 
      {"tlc"===whichPie&&<>
        <PieChart analyticData={pieChart5} width="300" height="100" chartId="byTLC"/>
        <div className="homepage-pie-labels">
        <div className="chart-title">
        <div className="chart-title">
            <span className="hyperlink hyperlink-active">Total licenses </span>
            <span className="hyperlink" onClick={()=>breadcrumbTail("users",userByLicenses, "Users", "usersbylicense")}>({onlineUserCount ? onlineUserCount+"/"+sum_of_total_licenses:sum_of_total_licenses})</span>
          </div>
          {/* <div className="chart-title hyperlink" onClick={()=>this.setState({whichPie: "armc"})} style={{display:(!myWriteAccountAccess && !myReadAccountAccess) ? 'block':'none'}}>
            Current monthly revenue (${avg_monthly_cost})
          </div>  */}
          <div className="chart-title" style={{display:(!myWriteAccountAccess && !myReadAccountAccess) ? 'block':'none'}} >
           <span className="hyperlink" onClick={()=>this.setState({whichPie: "armc"})} >Current monthly revenue (${avg_monthly_cost})</span>
          </div> 
          <span className="hyperlink " onClick={()=>this.setState({whichPie:"tgc"})}>Total Groups </span>
          <span className="hyperlink" onClick={()=>breadcrumbTail('groups',groupList,'All Groups')}>({onlineGroupCount ? onlineGroupCount+"/"+groupListCount:groupListCount})</span>
          </div>
          
        </div>
      </>}
      {"armc"===whichPie&&<>
        <PieChart analyticData={pieChart6} chartId="byARMC"/>
        <div className="homepage-pie-labels">
          <div className="chart-title">
            <span className="hyperlink" onClick={()=>this.setState({whichPie: "tlc"})}>Total licenses </span>
            <span className="hyperlink" onClick={()=>breadcrumbTail("users",userByLicenses, "Users", "usersbylicense")}>({onlineUserCount}/{sum_of_total_licenses})</span>
          </div>
          {/* <div className="chart-title" style={{display:(!myWriteAccountAccess && !myReadAccountAccess) ? 'block':'none'}}>
            <span className="hyperlink hyperlink-active" >Current monthly revenue (${avg_monthly_cost})</span>
          </div> */}
          <div className="chart-title" style={{display:(!myWriteAccountAccess && !myReadAccountAccess) ? 'block':'none'}}>
            <span className="hyperlink hyperlink-active" >Current monthly revenue (${avg_monthly_cost})</span>
          </div> 
          <div className="chart-title">
            <span className="hyperlink" onClick={()=>this.setState({whichPie:"tgc"})}>Total Groups </span>
            <span className="hyperlink" onClick={()=>breadcrumbTail('groups',groupList,'All Groups')} >({onlineGroupCount}/{groupListCount})</span>
          </div>
        </div>
      </>}
     

      </div>
      <div id="chartjs-tooltip"></div>
    </div>
  </div>
  );
}
}
