import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Box, Button, CardActionArea, CardActions } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { Grid } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Carousel from 'react-material-ui-carousel';
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const breakArr = [
    {
        breakpoint: 1536,
        cols: 5,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 1200,
        cols: 4,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 900,
        cols: 4,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 600,
        cols: 2,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 0,
        cols: 2,
        rows: 1,
        gap: 10
    }
]

const theme = createTheme({
    spacing: 2,
    palette: {
      background: {
        paper: '#384571', // your color
      },
      divider: {
        primary: '#1c2238',
        secondary: '#384571'
      }
    },
    typography: {
        allVariants: {
            fontFamily: "Titillium Web",
            color: "white",   
            paddingTop: 6,
            paddingBottom: 2,                     
        },
        body2: {
            textAlign: 'left',
            paddingLeft: 10,
            paddingBottom: 2
        }
    },
  });

  theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  const SubCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    background: '#1c2238',
    color: "white",
    height: 170,
    lineHeight: '60px',
    /* margin: '10px', */
    borderRadius: '10px' /**TP-5488*/
  }));

/* const ActionBox = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    flexDirection: 'row',
    justifyContent: 'flex-end'
})) */

const colorPalettes = [
    {name: 'Active', value: '#6fc566'},
    {name: 'Inactive', value: '#FF0000'}
]

const setColor = (value) => {
    let color = '';
    colorPalettes.forEach(cp => {
        //colorPalettes.name === columns[8].cell(row) ? colorPalettes.value : '#0c7aff'
        if (cp.name === value) 
            color = cp.value;
    })
    return color;
}

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    //TP-5781 -- Handled the null value of company name for the Avatar
    return {
      sx: {
        bgcolor: name !== null ? stringToColor(name) : "#CCCCCC",
        width: 140, height: 140, fontWeight: 'bold',
        fontFamily: "Titillium Web",
      },
      children: <span style={{fontSize: "50px"}}>{name !== null ? name.split(' ')[0][0] : "N"}</span>,
    };
  }

const Item = ({index, row, columns}) => {
    return <Grid key={index} item xs={6} sm={4.4} md={3} lg={2.4}>
                <Box sx={{ maxWidth: 355, borderRadius: '10px', boxShadow: 3}}>
                    <Card sx={{ maxWidth: 345, borderRadius: '10px' }}>
                        {/* <CardActionArea> */}
                        { /**TP-5781*/ columns[8] && columns[8].cell !== undefined && columns[8].cell(row) !== undefined ?
                            <CardContent sx={{ padding: 3, background: setColor(columns[8].cell(row)) }}>                      
                            </CardContent>   
                            :
                            ''
                        }
                            { /**TP-5781 */ columns[11] && columns[11].cell !== undefined && columns[11].cell(row) !== undefined ?
                                <Box mt={row.allow_custom_logo === true && row.jobs_logo_url !== '' && row.isFileExist === true ? 0: 2} ml={row.allow_custom_logo === true && row.jobs_logo_url !== '' && row.isFileExist === true ? 0 : "28%"} sx={{ minHeight: 140, width: row.allow_custom_logo === true && row.jobs_logo_url !== '' && row.isFileExist === true ? "100%" : "50%" }}>
                                    { row.allow_custom_logo === true && row.jobs_logo_url !== '' ?
                                        row.isFileExist === true ?
                                            <CardMedia
                                                component="img"
                                                height={/* columns[10].cell(row).includes("profile") === true ? "70" :  */"140"}                                        
                                                image={columns[11].cell(row)}
                                                alt="User"
                                            />
                                            :
                                            <Avatar {...stringAvatar(row.company_name)} ></Avatar>
                                        :
                                        <Avatar {...stringAvatar(columns[11].cell(row))} ></Avatar>
                                    }
                                </Box>
                                :
                                <Box mt={2} ml={"28%"} sx={{ minHeight: 140, width: "50%" }}>
                                    <Avatar {...stringAvatar(row.company_name)} ></Avatar>
                                </Box>                                
                            }
                        <CardContent>
                            <Box pb={0}>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Grid item xs={8} md={10}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {columns[0].cell(row)}
                                        </Typography>
                                    </Grid>
                                    { columns[10] && columns[10].cell !== undefined ?
                                        <Grid item xs={4} md={2} textAlign={"right"}>
                                            <Typography variant="" /* sx={{ textAlign:"right" }} */>
                                                {columns[10].cell(row)}
                                            </Typography>
                                        </Grid>
                                        :
                                        ''
                                    }
                                </Grid>
                            </Box>
                            <Divider ></Divider>
                            <Box pt={2.5} pb={5}>
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="body1" >
                                        {columns[1].name + ": "}
                                    </Typography>                                    
                                    <Typography variant="body1" component="div">
                                        {columns[1].cell(row)}
                                    </Typography>
                                    
                                </Stack>
                                {/* <Typography variant="body1" >
                                    {columns[1].name + ": " + columns[1].cell(row)}
                                </Typography> */}
                                <Typography variant="body1" >
                                    {columns[2].name+": " + columns[2].cell(row)}
                                </Typography>
                                <Typography variant="body1" >
                                    {columns[5].name + ": " + columns[5].cell(row)}
                                </Typography>
                            </Box>
                            <SubCard elevation={1} sx={{ marginTop: row.allow_custom_logo === true && row.jobs_logo_url !== '' && row.isFileExist === true ? 2: 0}}>
                                <Grid container textAlign="center">
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[6].name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[7].name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[6].cell(row)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[7].cell(row)}
                                        </Typography>
                                    </Grid>
                                </Grid>   
                                <Divider color='#384571' sx={{ }} style={{ margin: 10 }}></Divider>  
                                <Grid container textAlign="center">
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[3].name + ": "}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[3].cell(row)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[4].name +": "}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[4].cell(row)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {/* <Stack direction="row" spacing={2}>
                                    <Typography variant="body2" component="div">
                                        {columns[3].name + ": "}
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        {columns[3].cell(row)}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="body2">
                                        {columns[4].name +": "}
                                    </Typography>
                                    <Typography variant="body2">
                                        {columns[4].cell(row)}
                                    </Typography>
                                </Stack> */}
                            </SubCard>
                        </CardContent>
                        {/* </CardActionArea> */}  
                        { columns[9] && columns[9].cell !== undefined && columns[9].cell(row) !== undefined ?
                            <Box pb={5} pt={5}>
                                <Grid pr={8} container textAlign="right">
                                    <Grid item xs={6} md={6} lg={6}>

                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <Typography variant=""> 
                                            {columns[9].cell(row)}
                                        </Typography>
                                    </Grid>
                                </Grid>                                            
                            </Box>
                            :
                            ''
                        }                      
                                            
                        {/* <Button size="small" color="primary">
                            {columns[9]}
                        </Button> */}
                    </Card>
                </Box>
            </Grid>
}

const PageLayout = (props) => {
    return <div>
        <Paper elevation={0} sx={{ background: '#1c2238' }}>
            <ThemeProvider theme={theme}>
                <Grid container spacing={1}>
                    
                    { props.data.map((dat,index) => {
                        return <Item index={index} row={dat} columns={props.columns}></Item>
                        })
                    }
                </Grid>  
            </ThemeProvider>          
        </Paper>
    </div>
}

const NoDataComponent = (props) => {
    return <div>
        <Paper elevation={0} sx={{ background: '#384571', height: "80px" }}>
            <ThemeProvider theme={theme}>
                <Box 
                    display="flex" 
                    width={"100%"} height={"100%"} 
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="" sx={{ color:"white" }}>
                        {props.messageText}
                    </Typography>
                </Box>
            </ThemeProvider>
        </Paper>
    </div>
}

let pagination = [];
//let pagination = getPagination(props.data, 1);

const getPagination = (total_data, pageNo) => {
    const maxWidth = window.innerWidth;
    let cols, rows;
    for( let i=0; i<breakArr.length; i++) {
        if(maxWidth >= breakArr[i].breakpoint) {
            cols= breakArr[i].cols;
            rows = breakArr[i].rows;
            break;
        }
    }
    const data_perpage = cols * rows;
    const total_no_of_pages = Math.ceil(total_data.length/data_perpage) 
    let startingPoint = (data_perpage * pageNo) - data_perpage;
    let endPoint = startingPoint + data_perpage;
    let finalData = total_data.slice(startingPoint, endPoint);
    pagination.push({data: finalData, currentPage: pageNo, perPage: data_perpage, totalPages: total_no_of_pages});
    if (total_no_of_pages > 1 && pagination.length < total_no_of_pages) {
        for(let pp=pageNo+1; pp <= total_no_of_pages; pp++) {
            startingPoint = (data_perpage * pp) - data_perpage;
            endPoint = startingPoint + data_perpage;
            finalData = total_data.slice(startingPoint, endPoint);
            pagination.push({data: finalData, currentPage: pp, perPage: data_perpage, totalPages: total_no_of_pages});
        }
    }
    //console.log(pagination);
    return pagination;
}

class Layout extends React.Component  {  
    constructor(props) {
        super(props);
        pagination = [];
    }

    componentDidMount() {
        pagination = (this.props && this.props.data && this.props.data.length > 0) ? getPagination(this.props.data, 1) : [];        
        //console.log(pagination.length);        
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data && this.props.data !== undefined && this.props.data.length > 0) {
            pagination = [];
            pagination = getPagination(this.props.data, 1);
            //console.log(pagination.length);
            this.setImageExistsFlag();
        }
        if(prevProps.isSorted !== this.props.isSorted && this.props.isSorted === true) {
            pagination = [];
            pagination = getPagination(this.props.data, 1);
            //console.log(pagination.length);
        }
        /* if(prevProps.data !== undefined && prevProps.data.length > 0 && prevProps.data[0].account_id !== this.props.data[0].account_id){
            pagination = [];
            pagination = getPagination(this.props.data, 1);
        } */
    }

    setImageExistsFlag = () => {
        if (pagination.length > 0) {
            pagination.forEach(page => {
                page.data.forEach(dat => {
                    if (dat.allow_custom_logo === true && dat.jobs_logo_url !== '') {
                        if (this.props.columns[11].fileExists(dat) === true )
                            dat.isFileExist = true;
                        else dat.isFileExist = false;
                    } else dat.isFileExist = false;
                })
            })
        }
    }

    render () {
        const {props} = this;
        pagination = [];
        pagination = (props && props.data && props.data.length > 0) ? getPagination(props.data, 1) : [];
        if (pagination.length > 0) {
            return <div>
                <Carousel autoPlay={false} cycleNavigation={false} animation="slide"
                    //IndicatorIcon={<FiberManualRecordIcon size='small' /* className={classes.indicatorIcon} *//>}
                    swipe={false}
                    NextIcon={<ArrowForwardIosIcon size='small' />}
                    PrevIcon={<ArrowBackIosIcon size='small' />}
                    indicatorIconButtonProps={{
                        style: {
                            padding: '3px',    // 1
                            color: '#384571'       // 3
                        }
                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            color: 'grey'
                        }
                    }}
                    indicatorContainerProps={{
                        style: {
                            marginTop: '1px', // 5
                            textAlign: 'center' // 4
                        }
                
                    }}
                >
                    { pagination.length > 0 && pagination[0].totalPages > 0 && pagination[0].totalPages > 1 ?
                        pagination.map((pp, ind, pagination) => {
                            return <PageLayout data={pp.data} currentPage={pp.currentPage} columns={props.columns}></PageLayout>                        
                            
                        })
                        :
                            <PageLayout data={props.data} currentPage={1} columns={props.columns}></PageLayout>
                    }
                </Carousel>
            </div>
        } else {
            return <NoDataComponent messageText={props.nodataMessage} />
        }
    }
}

export default Layout;