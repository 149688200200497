import React from 'react';
import Bus from './Bus';
import './flash.css';

export default class Flash extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            visibility:false,
            message:null,
            type:null
        }
    }

    setVisibility = (visibilityParam)=>{
        this.setState({visibility:visibilityParam})
    }

    setMessage = (messageParam) =>{
        this.setState({message:messageParam});
    }
    
    setType = (typeParam) =>{
        this.setState({type:typeParam});
    }

    componentDidMount(){
        Bus.addListener('flash', ({message, type}) => {
            this.setVisibility(true);
            this.setMessage(message);
            this.setType(type);
            setTimeout(() => {
                this.setVisibility(false);
            }, 4000);
        });

        
    }


    render(){
        const {visibility,type,message} = this.state;
        
        return(
            visibility && <div className={`alert-msg alert-${type}`}>
                <p>{message}</p>
            </div>
        )
    }
    
}