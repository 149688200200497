import moment from "moment";

export const getMinutesDifference = paramObj =>{
    let startTime =  new Date(paramObj.start_time);
    let stopTime =  new Date(paramObj.stop_time);
    let diffMs = (stopTime - startTime);
    return Math.round((diffMs/1000)/60);
}

export const getUserName = (param,userArray) =>{
    const userInfo =  userArray.filter(item=> (item.id === param.user_id)).pop();
    return userInfo?.first_name+' '+userInfo?.last_name;
}

export const formatDonutData = (arrayData,uniqueGroupObject) =>{
    const outputArray = [];
    const groupNameCount = arrayData.reduce((accumlator,current)=>{
        let groupId = current.group_id,diffMinsValue = current.diffMins;
        if(accumlator[groupId]){
            accumlator[groupId] += diffMinsValue;
        }else{
            accumlator[groupId] = diffMinsValue;
        }
        return accumlator
    },{})

    const totalGroupCount =  Object.values(groupNameCount).reduce((accumlator,currentValue)=>{return accumlator + currentValue});

    for(let[objKey,objVal] of Object.entries(groupNameCount)){
        let groupObj = {};
        groupObj.label = uniqueGroupObject.filter(item=> item.group_id === parseInt(objKey))[0].group_name;
        groupObj.count = objVal;
        groupObj.value =  (objVal * 100/totalGroupCount).toFixed(2) ;//Math.round(objVal * 100/ totalGroupCount);
        groupObj.id = parseInt(objKey);

        outputArray.push(groupObj);
    }
    return outputArray;
}


export const getGroupStackData = (pickedGroupid,arrayData,groupNameSelected) =>{

    const resultData = [], uniqueDateObjRec = [] ,userIdKeyValueObject = {};
    let maxMinutes = 0;

    const filterdData = arrayData.filter(ar=>ar.group_id === pickedGroupid)
                        .map((item)=>({...item,fStartDate:moment(item.start_time).format('MMM-YYYY')}));

   const uniqueDateArray = [...new Set(filterdData.map((item)=>item.userName))];
    
   uniqueDateArray.forEach((ele)=>{
          let user = filterdData.filter((item)=>item.userName === ele).pop();
          userIdKeyValueObject[ele] = user.first_name ? user.userName:'Removed';     
   })

   const userDateMinutesObject = filterdData.reduce((accumlator,currentValue)=>{
       let userId = currentValue.userName, startDate = currentValue.fStartDate, minDiff = currentValue.diffMins;
       let obj = {};
       obj['group'] = startDate;
        if(accumlator[startDate]){
            let exObj = accumlator[startDate];
            if(exObj[userId]){
                exObj[userId] += minDiff;
                if(minDiff > maxMinutes) maxMinutes = minDiff;
            }else{
                exObj[userId] = minDiff;
                if(minDiff > maxMinutes) maxMinutes = minDiff;
            }
        }else{
            obj[userId] = minDiff;
            accumlator[startDate] = obj;
            if(minDiff > maxMinutes) maxMinutes = minDiff;        
        }
        return accumlator;
       
    },{}) 

    // creating enteries for each user present in account with 
    Object.entries(userDateMinutesObject).forEach(([key,value])=>{
        
        for(let item of uniqueDateArray){
            if(value[item]){
                continue
            }else{
                value[item] = 0;  
            }  
        }
        resultData.push(value);
        
    })

    //normalizeResultData(resultData); 
    
    uniqueDateObjRec.data = resultData;
    uniqueDateObjRec.columns = uniqueDateArray;
    uniqueDateObjRec.users = userIdKeyValueObject;
    uniqueDateObjRec.maxMinutes = maxMinutes; 
    uniqueDateObjRec.selectedGrpName = groupNameSelected;

   return uniqueDateObjRec;
} 


function normalizeResultData(arrayData){
    let numbers = [];
    for(let obj of arrayData){
       
        Object.keys(obj).forEach(ele=>{
          if(!isNaN(obj[ele])) numbers.push(obj[ele]);  
        })

        let  ratio = Math.max.apply(Math,numbers) / 50, totalLength = numbers.length, i;
        for(i =0 ; i < totalLength; i++){
            numbers[i] = Math.round(numbers[i]/ratio)
        }

        numbers.forEach((ele,idx)=>{
            obj[idx] = ele;
        })

    }
    return numbers;
}