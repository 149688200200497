  
export const S3config = {
    bucketName: "telepresenz-20-p2pfiles",
    dirName:  "companyLogo",
    region: "us-east-2",
    accessKeyId:  "AKIAZ3YXYNS3PUXHHUZO",  // TZ-157
    secretAccessKey: "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",
}
 

 

