import React from 'react';
import moment from "moment";
import Edit from "../editForm";
import DataTable from "react-data-table-component";
import {Button, Modal} from 'react-bootstrap';
import Dropdown from './streamModeDropDown';
import {connect} from 'react-redux';
import { updateGroupsAction } from '../redux/Group/GroupActions';
//import GroupPermissionDropdown from './groupPermissionDropdown';
import AnnotationPointerPermission from './annotationPointerPermissionDropdown'

const usersGroupColumns = [
  {name:"Name",selector:"first_name",sortable:true},
  {name:"Email",selector:"email",sortable:true},
  {name:"Phone",selector:"phonenumber",sortable:true},
  {name:"Role",selector:"role",sortable:true},
];

function typePane(item){
  return item.isadmin
  ? item.isexpert
    ?<div className="user-type-flag" style={{backgroundColor:"#89783f"}}>Admin/Expert</div>
    :<div className="user-type-flag" style={{backgroundColor:"#89783f"}}>Admin</div>
  : item.isexpert
    ?<div className="user-type-flag" style={{backgroundColor:"#485890"}}>Expert</div>
    :!item.isadmin&&!item.isexpert
      ?<div className="user-type-flag" style={{backgroundColor:"#71a95a"}}>Technician</div>
      :"";
}

class GroupDetail extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      usersGroup:[],
      customerDtls:null,
      viewDetails:null,
      lastUpdatedChildValue:null,
      showConfirmationAlert:false
    }
  }

  componentDidMount(){
    //TP-809
    //if(this.state.customerDtls && this.props.params.state.allCustomers){
      const custDetails =  this.props.params.state.allCustomers.filter(cust=>this.props.viewDetails.account_id === cust.account_id)[0];
      this.setState({customerDtls:custDetails,viewDetails:this.props.viewDetails});
    //}
  }

  static getDerivedStateFromProps(nextProps, prevState){

    if(nextProps.viewDetails!==prevState.viewDetails ){
        return { viewDetails: nextProps.viewDetails };
    }else if(nextProps.viewDetails!==prevState.viewDetails ){
      return { viewDetails: nextProps.viewDetails };
    }else{
          return null;
      }

  }

  showGroupParticipant = (users, title) => {
    const {params, viewDetails} = this.props;
    let userList = [];
    let customerDtls = params.state.allCustomers.filter(cust=>viewDetails.account_id===cust.account_id)[0];
    users.forEach(usr=>{
      userList.push(...customerDtls.users.filter(user=>user.email===usr.email));
    });
    this.setState({usersGroup:userList});
  }

  childCommunicator = (lastUpdatedKey) =>{
    const prevUpdatedDtls = this.state.viewDetails;
    
    if(this.props.params.state.breadcrumbItems.length <= 2){
      if(lastUpdatedKey.keyName){
        const keyName = lastUpdatedKey.keyName
        prevUpdatedDtls[keyName] = lastUpdatedKey[keyName]
      }
    }
   
    this.setState({
      ...this.state,
      viewDetails:prevUpdatedDtls,
      lastUpdatedChildValue:lastUpdatedKey
    })
  }

  endOnGoingSession = () =>{

    try{
      const { socket } = this.props.params;
      const {group_id} = this.state.viewDetails;
      const params = {
        groupId : group_id,
        keyName : 'isongoingnow',
        isongoingnow:false
      }

      if(socket && params.groupId){

       const callEmitter =  socket.emit('end-group-call',{group_id: params.groupId});
       console.log(callEmitter); 
       this.childCommunicator(params);
      if(this.props.updateGroupsAction) 
       this.props.updateGroupsAction(this.state.viewDetails,params) ;
       this.handleConfirmationPopup();

      }
     }catch(error){
       console.log(error);
     }
  }

  handleConfirmationPopup = () =>{
    this.setState(prevState=>({...prevState,showConfirmationAlert:!prevState.showConfirmationAlert}));
  }
  

  render(){
    usersGroupColumns[0].cell=row=>
    <div onClick={() => this.props.params.breadcrumbTail("user",row,row.first_name)}
      className="hyperlink text-ellipses" data-toggle="tooltip"
      title="View Details">
      {row.first_name+" "+row.last_name}
    </div>;
    usersGroupColumns[3].cell=row=>typePane(row);

    return(
<div className="page">
  <div className="container-fluid">
    <div className="row">
    <div className="col-sm-12 col-lg-4 panelDiv">
      <div className="col-sm col-lg">
        <h5 className="center">Group Details</h5>
      </div>
      <div className="col-sm col-lg">
        <strong>Description: </strong>
        <span>{this.state.viewDetails.description}</span>
        {/* <textarea disabled={true} className="div-text-area" value={this.props.viewDetails.description} /> */}
      </div>
      <div className="col-sm col-lg">
        <strong>Company Name: </strong>
        <span>{this.state.viewDetails.company_name}</span>
      </div>
      <div className="col-sm col-lg">
        <strong>Account Id: </strong>
        <span className={sessionStorage.getItem('userDbRole') !== "group_admin" ? "hyperlink d-inline":"disable-span-link"} style={{display: 'contents'}} onClick={()=> this.props.params.breadcrumbTail("customer",this.state.customerDtls,this.state.customerDtls.company_name)} >
        {this.state.viewDetails.account_id}
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Vendor Name: </strong>
        <span>
        {null===this.state.viewDetails.vendor_name?'NA':this.state.viewDetails.vendor_name}
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Sub Status: </strong>
        <span>
        {this.state.viewDetails.subscription_status}
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Group Id: </strong>
        <span>
        {this.state.viewDetails.group_id}
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Created By: </strong>
        <span>
        {null===this.state.viewDetails.created_by_fname?'NA'
        :this.state.viewDetails.created_by_fname + " " + this.state.viewDetails.created_by_lname}
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Created On: </strong>
        <span>
        {null!==this.state.viewDetails.created_on
        ? moment(
        this.state.viewDetails.created_on
        ).format("DD MMM,YYYY HH:mm")
        : ""}
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Last Used On: </strong>
        <span>
        {null!==this.state.viewDetails.last_active_on
        ? moment(
        this.state.viewDetails.last_active_on
        ).format("DD MMM,YYYY HH:mm")
        : ""}
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>On Going Now: </strong>
        { sessionStorage.getItem('userDbRole') === 'admin' ?  
           <span>
            { this.state.viewDetails.isongoingnow ? 
          <span className='editLink' id='GroupStatusIndicator' onClick={this.handleConfirmationPopup}>Online</span>
          :<span className='non-editLink' id='GroupStatusIndicator'>Offline</span>
          } 
          &nbsp;
          {this.state.viewDetails.isongoingnow
          ?<div className="ring-container d-inline" style={{top:"12px"}}> 
            <div className="ringring"></div>
            <div className="circle" id="indicator1"></div>
          </div>
          :this.state.viewDetails.isongoingnow ?<span className="spinner-grow text-success"></span>:<span className='dot bg-danger'></span>
          }
           </span>
          :<span>{this.state.viewDetails.isongoingnow?'Yes':'No'}</span>
      } 
        
         
      </div>
      {/**TP-4645*/}
      <div className="col-sm col-lg">
        <strong>Participant Count: </strong>
        <span>{null===this.state.viewDetails.participant_count?'NA':this.state.viewDetails.participant_count}</span>
      </div>
      <div className="col-sm col-lg">
        <strong>Expert Count: </strong>
        <span>{null===this.state.viewDetails.session_expert_count?'NA':this.state.viewDetails.session_expert_count}</span>
      </div>
      <div className="col-sm col-lg">
        <strong>Session Id: </strong>
        <small className="word-break-all">{null===this.state.viewDetails.sessionid?'NA':this.state.viewDetails.sessionid}</small>
      </div>
      <div className="col-sm col-lg">
        <strong>Streaming Mode: </strong>
        <span>{null===this.state.viewDetails.streaming_mode?'NA':this.state.viewDetails.streaming_mode}</span>
      </div>
      <div className="col-sm col-lg">
        <strong>Streaming Type: </strong>
        {/*TP-749*/}
        <span>{(this.state.customerDtls && this.state.customerDtls.streaming_medium === "both" && this.props.enterpriseWriteAccess) ? < Dropdown viewDetails={this.state.viewDetails}/>
            :(this.state.customerDtls && this.state.viewDetails.is_onprem === true) ? (this.state.customerDtls.streaming_medium === "open_tok") ? < Dropdown viewDetails={this.state.viewDetails}/> : "OWRTC" /**TP-3555*/
            :(this.state.customerDtls && this.state.viewDetails.is_onprem === false) ? (this.state.customerDtls.streaming_medium === "onprem") ? < Dropdown viewDetails={this.state.viewDetails}/> : "OT" /**TP-3555*/
            :(this.state.customerDtls && this.state.customerDtls.streaming_medium ==="both") && this.state.viewDetails && this.state.viewDetails.is_onprem ? "OWRTC": "OT"}
          </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Codec Type: </strong>
        <span>
        <Edit fieldValue={null===this.state.viewDetails.codec_type?'NA':this.state.viewDetails.codec_type} params={this} 
        parentCommunicator={this.childCommunicator} fieldName={'Codec Type'}  />
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Location Hint: </strong>
        <span>
        <Edit fieldValue={!this.state.viewDetails.location_hint || ""===this.state.viewDetails.location_hint?'NA':this.state.viewDetails.location_hint}
        params={this} parentCommunicator={this.childCommunicator} fieldName={'Location Hint'}/>
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Experts: </strong>
        <span className="hyperlink"
        onClick={()=>this.showGroupParticipant(this.state.viewDetails.experts,"Experts")}>
        {this.state.viewDetails.experts.length}
        </span>
      </div>
      <div className="col-sm col-lg">
        <strong>Technicians: </strong>
        <span className="hyperlink" data-toggle="tooltip" title="View user list"
        onClick={()=>this.showGroupParticipant(this.state.viewDetails.techs,"Technicians")}>
        {this.state.viewDetails.techs.length}
        </span>
      </div>
      {/*TP-4659 & TP-2872 & TP-2326 */}
      {"smartvision" !== sessionStorage.getItem('userBehaviour') && this.state.customerDtls && "disabled" !== this.state.customerDtls.allow_annotation_pointer  && this.props.enterpriseWriteAccess? 
        <>
          {/* <div className="col-sm col-lg">
            <strong>Auto Connect: </strong>
            <span ><GroupPermissionDropdown viewDetails={this.state.viewDetails}/></span>
          </div>  */}
          <div className="col-sm col-lg">
            <strong>Annotation Pointer: </strong>
            <span >
              { "enabled_for_all" === this.state.customerDtls.allow_annotation_pointer ? 
                "Yes"
                :
                <AnnotationPointerPermission viewDetails={this.state.viewDetails}/>
              }
            </span>
          </div> 
        </>
        : ''
      }
    </div>

      <div className="col-sm-12 col-lg-8 panelDiv">
        <div className="col-sm col-lg">
        <h5 className="center">Group participants</h5>
        <DataTable
          pagination={this.state.usersGroup.length>this.props.pagination}
          paginationPerPage={this.props.pagination}
          columns={usersGroupColumns}
          data={this.state.usersGroup}
          className="group-participants"
          noDataComponent="Click on Experts/Technicians to view users list"
          // sortFunction={(rows, field, direction)=>this.customSort(rows, field, direction)}
        />
        </div>
      </div>
      {this.state.showConfirmationAlert && 
      <ConfirmationPopUp modalTitle={"Alert"} 
        modalBody={"Are you sure you want to end the ongoing session?"} 
        closeConfirm={this.handleConfirmationPopup} 
        onConfirm={this.endOnGoingSession}/>}
    </div>
  </div>
</div>);
  }
}

const mapStateToProps = dispatch => ({
  updateGroupsAction:(groupParam,groupValue)=>dispatch(updateGroupsAction(groupParam,groupValue))
})

export default connect(null,mapStateToProps)(GroupDetail)

class ConfirmationPopUp extends React.Component{
	constructor(props){
    super();
      this.state = {
        alertPopUp: false
      }
	    this.close = this.close.bind(this);
	}
  
	close() {
	  this.setState({alertPopUp:false});
	  this.props.closeConfirm();
	}
  
	componentDidMount(){
	  this.setState({alertPopUp:true});
	}
  
	render() {
	  return (
		  <Modal show={this.state.alertPopUp} onHide={this.close} backdrop="static" keyboard={false}>
			<Modal.Header>
			  <Modal.Title>{this.props.modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  {this.props.modalBody}
			</Modal.Body>
			<Modal.Footer>
			  <Button onClick={this.props.onConfirm}>OK</Button>
        <Button onClick={this.close}>Cancel</Button>
			</Modal.Footer>
		  </Modal>
	  );
	}
}