import React from 'react';
import DataTable from "react-data-table-component";
import superagent from "superagent";
import ViewMap from '../map_tab/viewMap';
import moment from "moment";
import "react-dropdown-tree-select/dist/styles.css";
import "./users.css";
import EditEmail from './editEmail';
import {connect} from 'react-redux';
import {updatePasswordLock} from '.././redux/Customer/CustomerActions'
import LayoutUsers from '../layouts/layoutUsers';
import { trimText } from '../Utils/formatUtils';
import SearchUserAppBar from './topNavBar';


var tree = [];
let uluColumnsAdmin = [
  {name:"A/C Id",selector:"account_id",sortable:true, width:"100px"},
  {name:"Company Name",selector:"company_name",sortable:true},
  {name:"Name",selector:"first_name",sortable:true},
  {name:"Email",selector:"email",sortable:true},
  {name:"Phone",selector:"phonenumber",sortable:true},
  {name:"Last Activity On",selector:"last_accessed_on",sortable:true},
  {name:"Type",selector:"role",sortable:true},
  {name:"Device Make/Model", selector:"device_model", sortable: true, width:"160px"}, /**TP-3039 */
  {name:"User Info",selector:"info_type",sortable:true,width:"160px"},
  {name:"(Un)/ Locked",selector:"pwd_lock",sortable:true,width:"120px"}
  /* {name:"Allow Auto Connect",selector:"allow_auto_connect", sortable:true, width:"120px" } */
];

const sortColumns = [
  {name:"Last Accessed On",value:"last_accessed_on",isDefault:true},
  {name:"User Name",value:"user"},
  {name:"User Email",value:"email"},
  {name:"Phone Number",value:"phonenumber"},
  {name:"User Role",value:"role"},
  {name:"Company Name",value:"company_name"},
  {name:"Account ID",value:"account_id"},
  {name:"Device Make/Model",value:"device_make"},
  {name:"User Info",value:"info_type"},
];

//{/*TP-694*/}
function typePane(item){
  if(item.invitehash && item.isadmin){
    return <div><span className="user-type-flag" style={{backgroundColor:"#a460d7",margin:'5px'}}>Invitee</span>
    <span className="user-type-flag" style={{backgroundColor:"#ed9a37"}}>Admin</span></div>;
  }else if (item.invitehash && item.isexpert && item.isclicked){
    return <div><span className="user-type-flag" style={{backgroundColor:"#a460d7",margin:'5px'}}>Invitee</span>
    <span className="user-type-flag" style={{backgroundColor:"#3478f6"}}>Expert</span></div>;
  }else if (item.invitehash && !item.isadmin && !item.isexpert && item.isclicked){
    return <div><span className="user-type-flag" style={{backgroundColor:"#a460d7",margin:'5px'}}>Invitee</span>
    <span className="user-type-flag" style={{backgroundColor:"#6fc566"}}>Technician</span></div>;
  }else if (item.invitehash && !item.isclicked){
    return <div><div className="user-type-flag" style={{backgroundColor:"#a460d7",margin:'5px'}}>Invitee</div></div>;
  }else{
    return item.isadmin
    ? item.isexpert
      ?<div className="user-type-flag" style={{backgroundColor:"#89783F"}}>Admin/Expert</div>
      :<div className="user-type-flag" style={{backgroundColor:"#ed9a37"}}>Admin</div>
    : item.isexpert
      ?<div className="user-type-flag" style={{backgroundColor:"#3478f6"}}>Expert</div>
      :!item.isadmin&&!item.isexpert
        ?<div className="user-type-flag" style={{backgroundColor:"#6fc566"}}>Technician</div>
        :"";
  }
}
function newType(item) {
  if(item.invitehash && item.isadmin){
    return "Admin";
  }else if (item.invitehash && item.isexpert && item.isclicked){
    return "Expert";
  }else if (item.invitehash && !item.isadmin && !item.isexpert && item.isclicked){
    return "Technician";
  }else if (item.invitehash && !item.isclicked){
    return "Invitee";
  }else{
    return item.isadmin
    ? item.isexpert
      ?"Admin/Expert"
      :"Admin"
    : item.isexpert
      ?"Expert"
      :!item.isadmin&&!item.isexpert
        ?"Technician"
        :"";
  }
}

function removeDuplicates(array) {
  array.splice(0, array.length, ...(new Set(array)));
}
function checkString(item){
  if(undefined===item||null===item||""===item)
    return "NA";
  else return item;
}

//TP-3221
function validateJSON(item) {
  try {
    const obj = JSON.parse(item);
    return item;
  } catch (e) {
    console.log("error in validate JSON ", e);
    return "NA";
  }
}

class UsersTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableList:[],
      unknownList:[],
      needMap:false,
      tableHeader:"All "+this.props.userLabel,
      mapViewContainer:false,
      tableViewContainer:true, //TP-6405
      gridViewContainer:false, //TP-6405
      pickedUser:"",
      completeUserList:[],
      userOptList:[],
      showOnlineToggleObj:{
        value: false,
        label:"Show Online Users",
        id:"groups-online-users"
      },
      blockedLabelArr : [
        'invitees',
        'offline'
      ],
      lastUpdatedKeyName:null,
      selectedDropDownVal:null,
      isAnyUserLocked:false,
      selectedRows:0,
      currentPageNumber:1,
      resetPaginationToggle:false,
      lastUpdatedSortValue:{
        direction:null
      },
      isSorted: false,
      showLegends: false,
      globalUserHunt:[],
      fixedUserData:[],
      globalUniqueData:[],
      sortColumn: "last_accessed_on",
      sortDirection: "asc",   
      filteredData: [],  //TP-6823
    }
  }

  componentDidMount() {
    this.getUsersList();
    this.setGlobalHuntUser();
    this.dynamicDropDown();
    window.addEventListener("resize", this.reDrawUsers);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reDrawUsers);
  }

  copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  reDrawUsers = () => {
    this.setState({ isSorted: true }, () => {
      this.setState({ isSorted: false });
    })
  }

  toggleContainer = () => {
    if (this.state.mapViewContainer)
      this.setState({
        mapViewContainer:!this.state.mapViewContainer,
        tableViewContainer: false,
        gridViewContainer:!this.state.gridViewContainer,
      },document.getElementById('bytype') === null ? () => this.createTagItem(this.state.selectedDropDownVal): null); //TP-1347
    else if (this.state.gridViewContainer)
      this.setState({
        tableViewContainer:!this.state.tableViewContainer,
        mapViewContainer: false,
        gridViewContainer:!this.state.gridViewContainer,
      },document.getElementById('bytype') === null ? () => this.createTagItem(this.state.selectedDropDownVal): null); //TP-1347
    else if (this.state.needMap === true)
      this.setState({
        tableViewContainer:!this.state.tableViewContainer,
        gridViewContainer: false,
        mapViewContainer:!this.state.mapViewContainer,
      },document.getElementById('bytype') === null ? () => this.createTagItem(this.state.selectedDropDownVal): null); //TP-1347
    else 
      this.setState({
        tableViewContainer:!this.state.tableViewContainer,
        gridViewContainer: !this.state.gridViewContainer,
        mapViewContainer: false,
      },document.getElementById('bytype') === null ? () => this.createTagItem(this.state.selectedDropDownVal): null); //TP-1347
  }
  //TP-909
  pickUser = (e) => {
    const {userLabel, users, /*params*/} = this.props;
    // const {userList, inviteList} = params.state;
    let list = [], label = null;
    switch(e.target.value){
      case "online":
            list = users.filter(function(value){
              return value.is_loggedin;
            });
            label="Online "+userLabel;
            break;
      case "offline":
            list = users.filter(function(value){
              return !value.is_loggedin;
            });
            label="Offline "+userLabel;
            break;
      case "unknown":
            list = this.state.unknownList;
            label=userLabel+" with unknown location";
            break;
      case "invitees":
            list = this.state.completeUserList.filter(value =>value.invitehash);
            label="Invitee users";
            break;
      case "mru":
            list = users;
            label= "Most recent users";
            break;
      case "usersbylicense":
            list = users.filter(usr=>!(usr.isadmin&&!usr.isexpert) && "trial_pending_approval"!==usr.subscription_status);
            label= userLabel+" by license";
            break;     
      default :
            if("Users"===userLabel)
              list = this.state.completeUserList;
            else if("Invitees"===userLabel && e.target.value === "")
              list = this.state.completeUserList.filter(value =>value.invitehash);  
            else
              list = users;
            label="All "+userLabel;
    }
    list.sort((s1,s2)=>{
      if(moment(s2.last_accessed_on).isAfter(moment(s1.last_accessed_on))){
        return 1;
      }else if(moment(s2.last_accessed_on).isBefore(moment(s1.last_accessed_on))){
        return -1;
      }else return 0;
    });
    if(null===document.getElementById("bytype")){
      this.createTagItem();
    }
    document.getElementById("bytype").innerText="All Type";
    //{/*TP-694*/}
    if(userLabel === "Invitees" && e.target.value === undefined){
      this.setState({tableList:[], tableHeader:"Invitees by license", pickedUser:'usersbylicense'}, () => {
        this.setState({tableList:list, tableHeader:"Invitees by license", pickedUser:'usersbylicense'},()=>{
          this.validateIfAnyUserLocked();
          if(this.state.tableList.length > 0)
            this.setState({ showLegends: true })
          else
            this.setState({ showLegends: false });
          this.setState({ isSorted: true }, () => {
            this.setState({ isSorted: false })
          })
        });
      })
    }else{
      this.setState({tableList:[], tableHeader:label, pickedUser:e.target.value}, () =>{
        this.setState({tableList:list, tableHeader:label, pickedUser:e.target.value},()=>{
          this.validateIfAnyUserLocked()
          if(this.state.tableList.length > 0)
            this.setState({ showLegends: true })
          else
            this.setState({ showLegends: false });
          this.setState({ isSorted: true }, () => {
            this.setState({ isSorted: false })
          })
        });
      })
    }
    this.setState({showOnlineToggleObj:{ ...this.state.showOnlineToggleObj,value:false}})
    


  }

  treeSelect = (cn,sn) => {
    let list = [];
    let label = null;
    let type="";
    if("By OS"===cn.type){
      type="os_name";
    }else if("By Device Model"===cn.type){
      type="device_model";
    }else if("By APK Version"===cn.type){
      type="apk_version";
    }else if("By Customers"===cn.type){
      type="account_id";
    }
    if(""===cn.value){
      list = this.props.users;
      label="All "+this.props.userLabel;
    }else if("All Type"===cn.label && isNaN(cn.value)){
      list = this.props.users;
      const selectedvalue = document.getElementById("onlyFilteredUsers").value;
      const {userLabel} = this.props;
        switch(selectedvalue){
          case 'offline':
            label = `Offline ${userLabel}`
            break
          case 'unknown':
            label = `${userLabel} with unknown location`
            break
          case 'usersbylicense':
            label = `${userLabel} by license`
            break
          case 'invitees':
            label = `${userLabel} by license`
            break
          default:
            label = `All ${userLabel}`
            break   
        }
    }else {
      list = this.props.users.filter(function(value){
        if(type==="account_id"){
          if(value.account_id===cn.value){
            return true;
          }else return false;
        }else{
          if(undefined!==value.user_info&&null!==value.user_info&&value.user_info.includes(type)){
            let userInfo = JSON.parse(value.user_info);
            if("all"===cn.value.substring(0,3)){
              return userInfo[type];
            }else if("NA"===cn.value.substring(0,2)){
              return !userInfo[type];
            }else if(cn.value===userInfo[type]){
              return true;
            }else return false;
          }else if("NA"===cn.value.substring(0,2)){
            return true;
          }else return false;
        }
      });
      if("all"===cn.value.substring(0,3)){
        label=this.props.userLabel+" "+cn.type;
      }else if("NA"===cn.value.substring(0,2)){
          label=this.props.userLabel+" have no user-info";
      }else
       //label=this.props.userLabel+" on "+cn.value.substring(0,14);
       // Below case will handle header name if user navigate from search users or else by default
       if(type==="account_id" && !isNaN(cn.value))
        label = list[0].company_name+" "+this.props.userLabel;
       else  
        label = this.state.tableHeader;
    }
    if(null===document.getElementById("bytype")){
      this.createTagItem();
    }

    if(!cn.value && cn.label.includes("Customers")){
      label = this.props.userLabel + " " +cn.label;
    }

    document.getElementById("bytype").innerText=cn.label;
    this.setState({tableList:[], tableHeader:label, pickedUser:"",selectedDropDownVal:cn.label}, () => {
      this.setState({tableList:list, tableHeader:label, pickedUser:"",selectedDropDownVal:cn.label}, () => {
        this.setState({ isSorted: true }, () => {
          this.setState({ isSorted: false })
        })
      });
    })
  }

  copyEmails = () => {
    let emails = "";
    this.state.tableList.forEach((user,i)=>{
      emails=emails+user.email+", ";
    })
    this.copyToClipboard(emails);
  }

  getUsersList = () => {

    const {users, params, filterValue} = this.props;
    const {userList, inviteList} = params.state;
    const selectedFilterVal = document.getElementById('onlyFilteredUsers').value;
    const filterBy =  selectedFilterVal ? selectedFilterVal :this.props.filterBy; 

    let needMap = true,
    crd = "",
    osList = [],
    deviceList = [],
    apkList = [],
    custList = [],
    unknownList=[];
    if(users.length>0){
      users.forEach((item, index) => {
        if(item && item.user_info && item.user_info.includes("coordinates")){
          let userInfoMap = JSON.parse(item.user_info);
          let userCoordinate = userInfoMap.coordinates;
          if("NA"!==checkString(userInfoMap.device_model))
            deviceList.push(userInfoMap.device_model);
          if("NA"!==checkString(userInfoMap.os_name))
            osList.push(userInfoMap.os_name);
          if("NA"!==checkString(userInfoMap.apk_version))
            apkList.push(userInfoMap.apk_version);
          //For map coordinates
          userCoordinate = userCoordinate.split(",").reverse();
          if(userCoordinate.length===2
            && (userCoordinate[0]!=="0.0" || userCoordinate[1]!=="0.0")
            && (userCoordinate[0]>=-180 && userCoordinate[0]<=180)
            && (userCoordinate[1]>=-90 && userCoordinate[1]<=90)){
            crd = crd+JSON.parse(item.user_info).coordinates;
          }else{
            unknownList.push(item);
          }
        }else{
          unknownList.push(item);
        }
        custList.push(item.account_id);
      });
    }else{
      needMap = false;
    }
    if(needMap && crd.split(",").length>1){
      this.setState({needMap:needMap});
    }
    const allUserList = [...userList,...inviteList];
    removeDuplicates(allUserList);
    removeDuplicates(osList);
    removeDuplicates(deviceList);
    removeDuplicates(apkList);
    removeDuplicates(custList);
    tree=[];
   
    this.setTreeChildren(allUserList, "All Type", "All Type", "bytype");
    this.setTreeChildren(osList, "By OS", "By OS", "allOS");
    this.setTreeChildren(deviceList, "By Device Model", "By Device Model", "allDevice");
    this.setTreeChildren(apkList, "By APK Version", "By APK Version", "allAPK");
    this.setTreeChildren(custList, "By Customers", "By Customers", "");

    this.setState({
      tableList:this.setInfoType(users),
      fixedUserData:this.setInfoType(users),
      unknownList:this.setInfoType(unknownList),
      completeUserList:this.setInfoType([...userList, ...inviteList])
    },()=>{
      this.validateIfAnyUserLocked();
      if (this.state.gridViewContainer)
        this.customSortCards("last_accessed_on", "asc");
      else 
        this.customSort(this.state.tableList,"last_accessed_on", "asc");
      let userArr = [];
      users.forEach(u => {
        userArr.push(u.id)
      })
      let apiUrl = '/api/user/getProfilePicture/';
      const params = {
        user_ids: userArr
      }
      this.serverPostRequest(params,apiUrl);
    });
    this.state.tableList.sort((s1,s2)=>{
      if(moment(s2.last_accessed_on).isAfter(moment(s1.last_accessed_on))){
        return 1;
      }if(moment(s2.last_accessed_on).isBefore(moment(s1.last_accessed_on))){
        return -1;
      }else return 0;
    });
    this.createTagItem();
    if("account_id"!==filterBy){
      let selectOpt = document.getElementById("onlyFilteredUsers");
      selectOpt.target = {
        value : filterBy
      }
      this.pickUser(selectOpt);
    }else if("account_id"===filterBy){
      this.treeSelect({type:"By Customers", value:filterValue, label:"All Type"})
    }
  }

  setInfoType = (list) =>{
    let user_list = [];
    list.forEach((item, i) => {
      let info_type = null;
      if(undefined!==item.invitehash){
        if("NA"!==checkString(item.invitehash)){
          info_type=item.invitehash;
        }else info_type=null;
      }else{
        if("NA"===checkString(item.user_info)){
          info_type=null;
        }else {
          if(!item.user_info.includes("os_name")){
            info_type=null;
          }else{
            //TP-3221
            if ("NA"===validateJSON(item.user_info)) {
              info_type=null;
            } else {
              let usrInfo = JSON.parse(item.user_info);
              if(item.isadmin||item.isexpert){
                info_type="NA"===checkString(usrInfo.os_name)?null:usrInfo.os_name;
              }else if(!item.isadmin && !item.isexpert){
                if("NA"===checkString(usrInfo.serial_number)){
                  if("NA"===checkString(usrInfo.device_manufacturer)){
                    if("NA"===checkString(usrInfo.browser_name)){
                      if("NA"===checkString(usrInfo.apk_version)){
                        info_type="NA";
                      }else info_type=usrInfo.apk_version
                    }else info_type=usrInfo.browser_name;
                  }else info_type=usrInfo.device_manufacturer;
                }else info_type=usrInfo.serial_number;
              }
            }
          }
        }
      }
      item.info_type=info_type;
      user_list.push(item);
    });
    // console.log(user_list);
    user_list = this.getDeviceMakeandModel(user_list); //TP-3039
    //console.log(user_list);
    return user_list;
  }
  /**TP-3039 -- Support new column for device make & model */
  getDeviceMakeandModel = (list) => {
    let user_list = [];
    list.forEach((item, i) => {
      let device_make = null;
      if("NA"===checkString(item.user_info)){
        device_make=null;
      }else {
        //TP-3221
        if ("NA"===validateJSON(item.user_info)) {
          device_make = null;
        } else {
          let usrInfo = JSON.parse(item.user_info);
          
          if("NA"===checkString(usrInfo.device_manufacturer)){
            if("NA"===checkString(usrInfo.device_model)){
              device_make="";
            }else device_make=usrInfo.device_model;
          }else {
            device_make=usrInfo.device_manufacturer;
            if("NA"===checkString(usrInfo.device_model)) {
              device_make=device_make;
            } else device_make += "/"+usrInfo.device_model;
          }
        }
      }
      item.device_make=device_make;
      user_list.push(item);
    });
    return user_list;
  }
  
  setUserProfilePicture = (list) => {
    let newTableList = [...this.state.tableList];
    list.forEach(element => {      
      let [user] = newTableList.filter(t => t.id === element.user_id);
      if (user) {
        let ind = newTableList.indexOf(user);
        if(element.my_picture !== '' && element.my_picture !== null) {
          user.my_picture = 'data:image/jpg;base64,'+element.my_picture
        } else 
          user.my_picture = (window.location.href.indexOf("localhost") > 0) ? 'http://localhost:3000/'+'profile.png' : window.location.href+'profile.png';
        newTableList[ind] = user;
      }      
    });

    this.setState({tableList: newTableList, showLegends: true}, () => {
      //console.log(this.state.tableList);      
      this.setState({ isSorted: true }, () => {
        this.setState({ isSorted: false });
      })
    });
  }

  serverPostRequest = async(params, apiUrl, msg) =>{
    if (null!==sessionStorage.getItem("userToken")
        && null!==params && null!==apiUrl) {
        this.setState({isLoading:true});
         await superagent.post(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .send(params)
        .then(response => response) 
        .then(data => {
          if(200===data.status){
            //console.log(data.body);
            this.setUserProfilePicture(data.body)
          }
        }).catch(error => {
          if(undefined===error.status){
            msg = "May be internet is not connected, try again sometime";
          }else if(400===error.status){
            msg = "Data not updated";
          }else if(401===error.status){
            msg = "Session token experied, please re-login";
          }else if(403===error.status){
            msg = "You are not authorized";
          }else if(404===error.status){
            msg = "Not able to request server";
          }else if(422===error.status){
            msg = "Invalid input value";
          }else if(500===error.status){
            msg = "Something went wrong, try again sometime";
          }
          this.setState({ serverResponse:msg, isLoading:false, success:false });
        });
    }else{
    }
  }

  getParamsList = (list,type) => {
    let tempList = [];
    if(type==="By Customers" || type === "All Type"){
      list.forEach((item, i) => {
        tempList.push({
          "type":type,
          "label": this.getCompanyNameByAccountId(item),
          "value": item,
        });
      });
    }else{
      list.forEach((item, i) => {
        tempList.push({
          "type":type,
          "label": item.substring(0,14),
          "value": item,
        });
      });
      tempList.push({
        "type": type,
        "label": "NA",
        "value": "NA",
      });
    }
    return tempList
  }

  setTreeChildren = (list, type, label, value) => {
    if(type==="All Type"){
      tree.push({
        "type":type,
        "label":label,
        "value":value
      })
    }
    else if(list.length){
      tree.push({
        "type": type,
        "label": label,
        "value": value,
        "children": this.getParamsList(list,label)
      })
    }
  }

  getCompanyNameByAccountId = (id) =>{
    let obj=this.props.users.find((item, i) => item.account_id===id);
    if(undefined!==obj){
      if(null===obj.company_name){
        return obj.account_id.substring(0,14);
      }else{
        return obj.company_name.substring(0,14);
      }
    }else return "NA";
  }

  setSortColumn = (value) => {
    this.customSortCards(value, this.state.sortDirection);
    this.setState({ sortColumn: value });
  }

  setSortDir = (value) => {
    this.customSortCards(this.state.sortColumn, value);
    this.setState({ sortDirection: value });
  }

  customSortCards = (field, direction) => {
    //console.log(field, direction);
    if(null===field) field="last_accessed_on";
    else if("user"===field) field="first_name";
    let usrPicked = this.state.tableList.filter(row=>null!==row[field]);
    let usrNullPicked = this.state.tableList.filter(row=>null===row[field]);
    let mul=-1;
    if("asc"===direction){
      mul=1;
    }else if("desc"===direction){
      mul=-1;
    }
      if("last_accessed_on"===field){
        usrPicked.sort((c1,c2)=>{
          if(moment(c1[field]).isAfter(moment(c2[field]))){
            return -1*mul;
          }else if(moment(c1[field]).isBefore(moment(c2[field]))){
            return 1*mul;
          }else return 0;
        });
      }else if("account_id"===field||"company_name"===field||"first_name"===field||"email"===field||"info_type"===field||"device_make"===field){
        usrPicked.sort((a, b)=>{
          if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
          else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
          return 0;
         })
      }else if("phonenumber"===field){
        let usrNANpicked=[], usrDigitPicked = [];
        usrPicked.forEach((item, i) => {
          if(""===item[field]||isNaN(item[field]))
            usrNANpicked.push(item);
          else
            usrDigitPicked.push(item);
        });

        usrDigitPicked.sort((a, b)=>{
          if(+a[field] < +b[field]) return -1*mul;
          else if(+a[field] > +b[field]) return 1*mul;
          return 0;
        });
        usrPicked=[...usrDigitPicked,...usrNANpicked];
      }else if("role"===field){
         let adminL = [], expertL = [], techL = [], inviteL = [];
         usrPicked.forEach((item, i) => {
           if(undefined!==item.invitehash){
             inviteL.push(item);
           }else if(item.isadmin){
             adminL.push(item);
           }else if(item.isexpert){
             expertL.push(item);
           }else if(!item.isadmin && !item.isexpert){
             techL.push(item);
           }
         });

         if("asc"===direction){
           usrPicked=[...adminL,...expertL, ...inviteL,...techL];
         }else if("desc"===direction){
           usrPicked=[...techL,...inviteL,...expertL,...adminL];
         }
      }else if(field === "pwd_lock"){
        usrPicked =  usrPicked.sort(function(x, y) {
          if("asc"===direction)
            return (x.pwd_lock === y.pwd_lock)? 0 : x.pwd_lock? -1 : 1;
          else 
           return (x.pwd_lock === y.pwd_lock)? 0 : x.pwd_lock? 1 : -1;
      });
      } /* else if(field === "allow_auto_connect") {
        usrPicked =  usrPicked.sort(function(x, y) {
          if("asc"===direction)
            return (x.allow_auto_connect === y.allow_auto_connect)? 0 : x.allow_auto_connect? -1 : 1;
          else 
           return (x.allow_auto_connect === y.allow_auto_connect)? 0 : x.allow_auto_connect? 1 : -1;
        });
      } */
      // ...this.state,
			// 	custFields : {
			// 		...this.state.custFields,
			// 		[id] : value
     
			// 	}
      if(field === "pwd_lock" && this.state.lastUpdatedSortValue.direction !== direction){
        let paramPageNum =  this.state.currentPageNumber;
        this.setState({lastUpdatedSortValue:{field,direction}},()=>this.setPage(paramPageNum)) ;
      } /* else if (field === "allow_auto_connect" && this.state.lastUpdatedSortValue.direction !== direction){
        let paramPageNum =  this.state.currentPageNumber;
        this.setState({lastUpdatedSortValue:{field,direction}},()=>this.setPage(paramPageNum)) ;
      } */

      //console.log(usrPicked, usrNullPicked);
      this.setState({tableList: [...usrPicked, ...usrNullPicked]}, () => {
        this.setState({ isSorted: true }, () => {
          this.setState({ isSorted: false });
        });
      });
  }

  customSort = (rows, field, direction) =>{
    if(null===field) field="last_accessed_on";
    let usrPicked = rows.filter(row=>null!==row[field]);
    let usrNullPicked = rows.filter(row=>null===row[field]);
    let mul=-1;
    if("asc"===direction){
      mul=1;
    }else if("desc"===direction){
      mul=-1;
    }
      if("last_accessed_on"===field){
        usrPicked.sort((c1,c2)=>{
          if(moment(c1[field]).isAfter(moment(c2[field]))){
            return -1*mul;
          }else if(moment(c1[field]).isBefore(moment(c2[field]))){
            return 1*mul;
          }else return 0;
        });
      }else if("account_id"===field||"company_name"===field||"first_name"===field||"email"===field||"info_type"===field||"device_make"===field){
        usrPicked.sort((a, b)=>{
          if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
          else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
          return 0;
         })
      }else if("phonenumber"===field){
        let usrNANpicked=[], usrDigitPicked = [];
        usrPicked.forEach((item, i) => {
          if(""===item[field]||isNaN(item[field]))
            usrNANpicked.push(item);
          else
            usrDigitPicked.push(item);
        });

        usrDigitPicked.sort((a, b)=>{
          if(+a[field] < +b[field]) return -1*mul;
          else if(+a[field] > +b[field]) return 1*mul;
          return 0;
        });
        usrPicked=[...usrDigitPicked,...usrNANpicked];
      }else if("role"===field){
         let adminL = [], expertL = [], techL = [], inviteL = [];
         usrPicked.forEach((item, i) => {
           if(undefined!==item.invitehash){
             inviteL.push(item);
           }else if(item.isadmin){
             adminL.push(item);
           }else if(item.isexpert){
             expertL.push(item);
           }else if(!item.isadmin && !item.isexpert){
             techL.push(item);
           }
         });

         if("asc"===direction){
           usrPicked=[...adminL,...expertL, ...inviteL,...techL];
         }else if("desc"===direction){
           usrPicked=[...techL,...inviteL,...expertL,...adminL];
         }
      }else if(field === "pwd_lock"){
        usrPicked =  usrPicked.sort(function(x, y) {
          if("asc"===direction)
            return (x.pwd_lock === y.pwd_lock)? 0 : x.pwd_lock? -1 : 1;
          else 
           return (x.pwd_lock === y.pwd_lock)? 0 : x.pwd_lock? 1 : -1;
      });
      } /* else if(field === "allow_auto_connect") {
        usrPicked =  usrPicked.sort(function(x, y) {
          if("asc"===direction)
            return (x.allow_auto_connect === y.allow_auto_connect)? 0 : x.allow_auto_connect? -1 : 1;
          else 
           return (x.allow_auto_connect === y.allow_auto_connect)? 0 : x.allow_auto_connect? 1 : -1;
        });
      } */
      // ...this.state,
			// 	custFields : {
			// 		...this.state.custFields,
			// 		[id] : value
     
			// 	}
      if(field === "pwd_lock" && this.state.lastUpdatedSortValue.direction !== direction){
        let paramPageNum =  this.state.currentPageNumber;
        this.setState({lastUpdatedSortValue:{field,direction}},()=>this.setPage(paramPageNum)) ;
      } /* else if (field === "allow_auto_connect" && this.state.lastUpdatedSortValue.direction !== direction){
        let paramPageNum =  this.state.currentPageNumber;
        this.setState({lastUpdatedSortValue:{field,direction}},()=>this.setPage(paramPageNum)) ;
      } */
        
      
    return [...usrPicked, ...usrNullPicked];
  }

  createTagItem = (selectedDropDownVal) =>{
    let li = document.createElement('li');
    li.setAttribute('class', 'tag-item');
    let span = document.createElement('span');
    span.setAttribute('class', 'tag');
    span.setAttribute('id', 'bytype');
    span.textContent = "All Type";
    li.appendChild(span);
    if(document.getElementsByClassName("tag-list").length>0 && !document.getElementById('bytype')){  //{/*TP-694*/}
      document.getElementsByClassName("tag-list")[0].appendChild(li);
      if(selectedDropDownVal){
        document.getElementById('bytype').innerText = selectedDropDownVal;
      }
    }
  }

  createCopyIcon = () =>{
    let node = document.createElement("span");
    node.setAttribute('class', 'fa fa-copy copy-emails copySelection');
    node.addEventListener("click", this.copyEmails(), false);
    if(document.getElementById("column-email")){
      document.getElementById("column-email").appendChild(node);
    }
  }

  getCustomerData = (row) => {
    if(undefined!==row){
      row = this.props.params.state.allCustomers.filter(c=>c.account_id===row.account_id);
      if(row.length>0){
        let label = null!==row[0].company_name?row[0].company_name:row[0].account_id;
        this.props.params.breadcrumbTail("customer",row[0],label);
      }
    }
  }

  controls = (evt) => {
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement){
      this.toggleContainer();
    }
  }

  dynamicDropDown = () => {
    const {userLabel} = this.props;
    let userOptList=[];

    userOptList.push({value: "all", label: `All ${userLabel}`});    
    // userOptList.push({value: "online", label: `Online ${userLabel}`}); //{/*TP-694*/}
    userOptList.push({value: "offline", label: `Offline ${userLabel}`});
    userOptList.push({value: "unknown", label: `${userLabel} with unknown location`});
    if("Users"===userLabel)
      userOptList.push({value: "invitees", label: `Invitees`});
    userOptList.push({value: "usersbylicense", label: `${userLabel} by license`});

    this.setState({userOptList:userOptList});
  }
  //{/*TP-809*/}
  toggleOnlineUser = () => {
    this.setState(({showOnlineToggleObj:{
      ...this.state.showOnlineToggleObj,
      value:!this.state.showOnlineToggleObj.value
    }}),()=>this.showUsersTable())
  }
//{/*TP-694*/}
  showUsersTable = () => {
    if(this.state.showOnlineToggleObj.value === true && this.state.filteredData.length === 0){
      const {users,userLabel} = this.props;
      const {completeUserList} = this.state;
      let  userList = [];
      if((this.state.pickedUser) || ((userLabel === "Experts") || (userLabel === "Technicians"))){
        userList = users.filter(usr=> usr.is_loggedin);
      }else{
        userList = completeUserList.filter(usr=> usr.is_loggedin)
      }
      this.setState({tableList:userList},()=>
        {
          this.validateIfAnyUserLocked();
          this.setState({ isSorted: true }, () => {
            this.setState({ isSorted: false })
          })
        }
      );
      //TP-6823
    }else if ((this.state.filteredData.length === 1) && (this.state.showOnlineToggleObj.value === true || this.state.showOnlineToggleObj.value === false)){
    
      const {users,userLabel} = this.props;
      const {completeUserList} = this.state;
      let  userList = [];
      if((this.state.pickedUser) || ((userLabel === "Experts") || (userLabel === "Technicians"))){
        userList = users.filter(usr=> usr.is_loggedin);
      }else{
        userList = completeUserList.filter(usr=> usr.is_loggedin)
      }
      this.setState({tableList:this.state.filteredData},()=>
        {
          this.validateIfAnyUserLocked();
          this.setState({ isSorted: true }, () => {
            this.setState({ isSorted: false })
          })
        }
      );
    }else if (this.state.showOnlineToggleObj.value === false && this.state.filteredData.length === 0){
      this.getUsersList();
    }

  }

  setGlobalHuntUser = ()=>{
    this.setState({globalUserHunt:[]},()=>{
       const searchQuerys = this.state.globalUserHunt;
       const map = new Map(); 
       const {users,userLabel} = this.props;
       const {completeUserList} = this.state;
       let  userList = [];
       let uniqueArrayData = [],userNameArr =[],userEmailArr = [],userAccountArr= [],companyNameArr=[],phoneNumberArr=[];
        if((this.state.pickedUser) || ((userLabel === "Experts") || (userLabel === "Technicians"))){
          userList = users;
        }else{
          userList = completeUserList
        }
        userList.forEach((user)=>{
         if(user.first_name || user.last_name){
            searchQuerys.push({...user,huntLabel:user.first_name+" "+user.last_name});
          if(!map.has(user.first_name+" "+user.last_name)){
             map.set(user.first_name+" "+user.last_name, true);
             userNameArr.push({...user,huntLabel:user.first_name+" "+user.last_name}); 
          }
         }
         if(user.email){            
          searchQuerys.push({...user,huntLabel:user.email});
          if(!map.has(user.email)){
            map.set(user.email, true);
            userEmailArr.push({...user,huntLabel:user.email}); 
          }
            
         }
         if(user.account_id){
          searchQuerys.push({...user,huntLabel:user.account_id});
          if(!map.has(user.account_id)){
            map.set(user.account_id, true);
            userAccountArr.push({...user,huntLabel:user.account_id}); 
          }
         }
         if(user.company_name){
          searchQuerys.push({...user,huntLabel:user.company_name});
          if(!map.has(user.company_name)){
            map.set(user.company_name, true);
            companyNameArr.push({...user,huntLabel:user.company_name})
          }
         }
         if(user.phonenumber){
          searchQuerys.push({...user,huntLabel:user.phonenumber});
          if(!map.has(user.phonenumber)){
            map.set(user.phonenumber, true);
            phoneNumberArr.push({...user,huntLabel:user.phonenumber})
          }
         }
       })
      
       userNameArr = userNameArr.sort((x,y)=> (x.huntLabel > y.huntLabel) ? 1:-1);
       userEmailArr = userEmailArr.sort((x,y)=> (x.huntLabel > y.huntLabel) ? 1:-1);
       userAccountArr = userAccountArr.sort((x,y)=> (x.huntLabel > y.huntLabel) ? 1:-1);
       companyNameArr = companyNameArr.sort((x,y)=> (x.huntLabel > y.huntLabel) ? 1:-1);
       phoneNumberArr = phoneNumberArr.sort((x,y)=> (x.huntLabel > y.huntLabel) ? 1:-1);
       uniqueArrayData.push(...userNameArr,...userEmailArr,...userAccountArr,...companyNameArr,...phoneNumberArr);
       this.setState({globalUserHunt:searchQuerys,globalUniqueData:uniqueArrayData}); 
    })
  }

  searchUser = (event,data) =>{
    event.preventDefault();
    let  filter; 
    const { globalUserHunt,fixedUserData } = this.state;
    let userSearch = [];
    const result = [];
    const map = new Map();
    filter =  data && data.huntLabel ?  data.huntLabel.toUpperCase():
              event.target.outerText ? event.target.outerText.toUpperCase(): '';

    if(filter)
      userSearch = globalUserHunt.filter((user)=> user.huntLabel ? user.huntLabel.toUpperCase().includes(filter):false);
 
      if(userSearch && userSearch.length>0){
        for (const item of userSearch) {
            if(!map.has(item.id)){
                map.set(item.id, true);    // set any value to Map
                result.push(item);
            }
        }
      }
      this.setState({filteredData:result});    //TP-6823  
      let infoPicked = result.length > 0 ? result: fixedUserData;      
      this.setState({tableList:infoPicked}, () => {
        this.validateIfAnyUserLocked()
        if (this.state.gridViewContainer) {
          if (this.state.sortColumn === "last_accessed_on" && this.state.sortDirection === "asc")
            this.customSortCards("last_accessed_on", "asc");
          else
            this.customSortCards(this.state.sortColumn, this.state.sortDirection);            
        }
        else 
          this.customSort(this.state.tableList,"last_accessed_on", "asc");
        if(this.state.tableList.length > 0)
          this.setState({ showLegends: true })
        else
          this.setState({ showLegends: false });
        this.setState({ isSorted: true }, () => {
          this.setState({ isSorted: false })
        })
      });
  }

  getOptionLabel =(option)=>{
    return  option.huntLabel ? option.huntLabel:'';
  }

  onTreeSelectFocus = () => {
      // below condition is added to  check if the on focus the default value is not reseting upto All Type 
    // so remove the existing li elements and adding li of all type
    const tagListEle = document.getElementsByClassName("tag-list");
    if(tagListEle && tagListEle.length>0 && !tagListEle[0].innerText.includes("All Type") && 
      document.getElementsByClassName('tag-list')[0].lastElementChild){  
      
      tagListEle[0].removeChild(document.getElementsByClassName('tag-list')[0].lastElementChild);  
      
      let li = document.createElement('li');
        li.setAttribute('class', 'tag-item');
        let span = document.createElement('span');
        span.setAttribute('class', 'tag');
        span.setAttribute('id', 'bytype');
        span.textContent = "All Type";
        li.appendChild(span);
        document.getElementsByClassName("tag-list")[0].appendChild(li);
        
    }
  }

  childCommunicator = (lastKeyname) =>{
    this.setState({
      lastUpdatedKeyName:lastKeyname
    })
  }
changeTableList(newList,paramUpUser){
  newList.push(paramUpUser);
  this.setState({tableList:newList},()=>this.validateIfAnyUserLocked());
}
  
  changeUserStatus(userInfo){
    if(userInfo && userInfo.email !== sessionStorage.userEmail){
      this.props.updatePasswordLock(userInfo);
      const {tableList} = this.state;
      const newUserList = tableList;
      const indexNum = tableList.findIndex(user => user.email === userInfo.email);
      const upUserInfo = tableList[indexNum];
      upUserInfo.pwd_lock = !upUserInfo.pwd_lock;
      tableList.splice(indexNum,1)
      this.setState({tableList:tableList},()=> this.changeTableList(newUserList,upUserInfo));
     
    }
}

setFilterData(uluColumns){

  if(sessionStorage.getItem('userDbRole') !== "admin" || this.props.userLabel === "Invitees"){
    const restictedColumnName = "pwd_lock";
    for(let i = 0; i < uluColumns.length; i++) {
      if(uluColumns[i].selector === restictedColumnName) {
        uluColumns.splice(i, 1);
            break;
        }
    }
    
    const restictedColumnNameAction = "allow_auto_connect";
    for(let i = 0; i < uluColumns.length; i++) {
      if(uluColumns[i].selector === restictedColumnNameAction) {
        uluColumns.splice(i, 1);
            break;
        }
    }
  }
  return uluColumns;
}

handleUnloackUser = async(e)=>{
  e.preventDefault();
 const {tableList} = this.state;
  try{
    if(tableList && tableList.length>1){
      for (let index = 0; index < tableList.length; index++) {
        if(tableList[index].pwd_lock){
         await this.updateMultiUserStatus(tableList[index]);
         tableList[index].pwd_lock = !tableList[index].pwd_lock;
        }
      }
      this.setState({tableList:[]});
    }
    this.setState({tableList:tableList},()=> this.validateIfAnyUserLocked());
  }catch(error){
    console.error('Exception eccor while updating unlock user for all'+error);
  }

}

updateMultiUserStatus(userInfo){
  if(userInfo && userInfo.email !== sessionStorage.userEmail){
    this.props.updatePasswordLock(userInfo);
  }
}

validateIfAnyUserLocked(){
  const {tableList} =  this.state;
  const isLocked =  tableList.some((ele)=> ele.pwd_lock);
  this.setState({isAnyUserLocked:isLocked}); 
}

updateDataTableState(selectedRows){
  this.setState({selectedRows:selectedRows })
}

setPage(page){
 this.setState({currentPageNumber:page})
}

  render() {    
    uluColumnsAdmin[0].cell=row=>undefined!==row.invitehash ?<div className="text-ellipses">{row.account_id}</div>:
                        <div onClick={()=>this.getCustomerData(row)} className="hyperlink text-ellipses" data-toggle="tooltip" 
                        title="View Details">{row.account_id}</div>
    uluColumnsAdmin[1].cell=row=>undefined!==row.invitehash?<div className="text-ellipses">{row.company_name}</div>:
                      <div onClick={()=>this.getCustomerData(row)} className="hyperlink text-ellipses" data-toggle="tooltip" title="View Details"> {row.company_name} </div>
    //TP-4084 -- Edit user name field
    if (this.state.gridViewContainer) {
      uluColumnsAdmin[2].cell=row=> undefined!==row.invitehash?<div className="">{row.first_name+" "+row.last_name}</div>:
                      <div onClick={()=>this.props.params.breadcrumbTail("user",row,row.first_name,this.state.tableList)}
                      data-toggle="tooltips" title="View details" className="hyperlink text-ellipses">{row.first_name+" "+row.last_name}</div>
      uluColumnsAdmin[2].cellEdit=row=>undefined!==row.invitehash?<EditEmail fieldValue={row.email} params={row} fieldName="EditRow" parentCommunicator={this.childCommunicator}/>
                      :<EditEmail fieldValue={row.email} params={row} fieldName="EditRow" parentCommunicator={this.childCommunicator}/>
    } else {
      uluColumnsAdmin[2].cell=row=> undefined!==row.invitehash?<div className="hoverClipCopy"><div className="text-ellipses">{row.first_name+" "+row.last_name}</div><div className="tooltiptext small">{row.first_name+" "+row.last_name}&nbsp;<EditEmail fieldValue={row.email} params={row} fieldName="EditRow" parentCommunicator={this.childCommunicator}/></div></div>:
                        <div className="hoverClipCopy"><div onClick={()=>this.props.params.breadcrumbTail("user",row,row.first_name,this.state.tableList)}
                        data-toggle="tooltips" title="View details" className="hyperlink text-ellipses">{row.first_name+" "+row.last_name}</div><div className="tooltiptext small">{row.first_name+" "+row.last_name}&nbsp;<EditEmail fieldValue={row.email} params={row} fieldName="EditRow" parentCommunicator={this.childCommunicator}/></div></div>
    }
    if (this.state.gridViewContainer) {
      uluColumnsAdmin[3].cell=row=>row.email;
      uluColumnsAdmin[3].cellEdit=row=>undefined !== row.email && null !== row.email ?<EditEmail fieldValue={row.email} params={row} fieldName="EditRow" parentCommunicator={this.childCommunicator}/>:null
      uluColumnsAdmin[3].cellCopy=row=>undefined !== row.email && null !== row.email ? <i className="fa fa-copy copySelection" id={"email"+row.id}
                                  onClick={(e) => this.copyToClipboard(row.email)} style={{ marginTop: "-8px"}}></i> : null
    } else {
      uluColumnsAdmin[3].cell=row=><div className="hoverClipCopy"><div>{row.email}</div> <div className="tooltiptext small">{row.email}&nbsp;
                      <EditEmail fieldValue={row.email} params={row} fieldName="EditRow" parentCommunicator={this.childCommunicator}/><i className="fa fa-copy copySelection" id={"email"+row.id}
                      onClick={(e) => this.copyToClipboard(row.email)}></i></div></div>
    }
    if (this.state.gridViewContainer) {
      uluColumnsAdmin[4].cell=row=>row.phonenumber;
      uluColumnsAdmin[4].cellCopy=row=>undefined !== row.phonenumber && null !== row.phonenumber ? <i className="fa fa-copy copySelection" id={"phone"+row.id} 
                          onClick={(e) => this.copyToClipboard(row.phonenumber)} style={{ marginTop: "-8px"}}></i> : null
    } else {
      uluColumnsAdmin[4].cell=row=><div className="hoverClipCopy"><div>{row.phonenumber}</div><div className="tooltiptext small">{row.phonenumber}&nbsp;
                    <i className="fa fa-copy copySelection" id={"phone"+row.id} onClick={(e) => this.copyToClipboard(row.phonenumber)}></i></div></div>
    }

    if (this.state.gridViewContainer) {
      if("Invitees"!==this.props.userLabel){
        uluColumnsAdmin[5].name="Last Activity On";
        uluColumnsAdmin[5].cell=row=>row.last_accessed_on !== null ? moment(row.last_accessed_on).format("D.MM.YYYY HH:mm"):"NA";
      }else{
        uluColumnsAdmin[5].name="Invited On";
        uluColumnsAdmin[5].cell=row=>row.invited_on !== null ? moment(row.invited_on).format("D.MM.YYYY HH:mm"):"NA";
      }
      if("Users"===this.props.userLabel){
        uluColumnsAdmin[5].value=row=>newType(row);
        /* uluColumnsAdmin[5].name=row=> newType(row) === "Invitee" ? "Invited On" : "Last Activity"; */
        uluColumnsAdmin[5].cell=row=>undefined!==row.invitehash ? moment(row.invited_on).format("D.MM.YYYY HH:mm"):moment(row.last_accessed_on).format("D.MM.YYYY HH:mm");
      }
    } else {
      if("Invitees"!==this.props.userLabel){
        uluColumnsAdmin[5].name="Last Activity On";
        uluColumnsAdmin[5].cell=row=>row.last_accessed_on !== null ? moment(row.last_accessed_on).format("DD MMM,YYYY HH:mm"):"NA";
      }else{
        uluColumnsAdmin[5].name="Invited On";
        uluColumnsAdmin[5].cell=row=>row.invited_on !== null ? moment(row.invited_on).format("DD MMM,YYYY HH:mm"):"NA";
      }
      if("Users"===this.props.userLabel){
        uluColumnsAdmin[5].name="Last Activity / Invited On";
        uluColumnsAdmin[5].cell=row=>undefined!==row.invitehash ? moment(row.invited_on).format("DD MMM,YYYY HH:mm"):moment(row.last_accessed_on).format("DD MMM,YYYY HH:mm");
      }
    }
    if (this.state.gridViewContainer) {
      uluColumnsAdmin[6].cell=row=>newType(row);
    } else {
      uluColumnsAdmin[6].cell=row=>typePane(row)
    }
    if (this.state.gridViewContainer) {
      uluColumnsAdmin[7].cell=row=>null!==row.device_make&&row.device_make.length>12?<div><div>{trimText(row.device_make, 12)}</div>
                              </div>:null===row.device_make?"NA":trimText(row.device_make,12);
      uluColumnsAdmin[7].cellTooltip=row=>null!==row.device_make&&row.device_make.length>12?<div className="small" style={{ left: 0 }}>{row.device_make}&nbsp;<i className="fa fa-copy copySelection" id={"device_make"+row.id} 
      onClick={(e) => this.copyToClipboard(row.device_make)}></i></div>:null;
      
      //uluColumnsAdmin[7].cellCopy=row=>null!==row.device_make&&row.device_make.length>15?<i className="fa fa-copy copySelection" id={"device_make"+row.id} 
                                  //onClick={(e) => this.copyToClipboard(row.device_make)}></i>:null;
      uluColumnsAdmin[8].cell=row=>null!==row.info_type&&row.info_type.length>10?<div><div>{trimText(row.info_type, 12)}</div>
                                </div>:null===row.info_type?"NA":trimText(row.info_type, 12);
      uluColumnsAdmin[8].cellTooltip=row=>null!==row.info_type&&row.info_type.length>10?<div className="small" >{row.info_type}&nbsp;<i className="fa fa-copy copySelection" id={"info_type"+row.id} 
      onClick={(e) => this.copyToClipboard(row.info_type)}></i></div>:null;
      
      //uluColumnsAdmin[8].cellCopy=row=>null!==row.info_type&&row.info_type.length>10?<i className="fa fa-copy copySelection" id={"info_type"+row.id} 
                                  //onClick={(e) => this.copyToClipboard(row.info_type)}></i>:null;
    } else {
      /**TP-3039 */
      uluColumnsAdmin[7].cell=row=>null!==row.device_make&&row.device_make.length>12?<div className="hoverClipCopy"><div>{trimText(row.device_make, 12)}</div>
                              <div className="tooltiptext small"><i className="fa fa-copy copySelection" id={"device_make"+row.id} 
                              onClick={(e) => this.copyToClipboard(row.device_make)}>&nbsp;Copy</i></div></div>:null===row.device_make?"NA":trimText(row.device_make,12);
      uluColumnsAdmin[8].cell=row=>null!==row.info_type&&row.info_type.length>10?<div className="hoverClipCopy"><div>{trimText(row.info_type, 12)}</div>
                              <div className="tooltiptext small"><i className="fa fa-copy copySelection" id={"info_type"+row.id} 
                              onClick={(e) => this.copyToClipboard(row.info_type)}>&nbsp;Copy</i></div></div>:null===row.info_type?"NA":trimText(row.info_type, 12);
    }
    if(sessionStorage.getItem("userDbRole") === "admin" &&  this.props.userLabel !== "Invitees"){
      if (this.state.gridViewContainer) {
        if(uluColumnsAdmin[9]){
          uluColumnsAdmin[9].cell=row=>row.pwd_lock !=null && <a title={row.isInactive ? "This account is inactive" :""} className={row.email === sessionStorage.getItem("userEmail") ? "disabled-lock-icon":"align-Lock-Icon"} onClick={ !row.isInactive ? () => this.changeUserStatus(row): ""} >
          <i className={row.pwd_lock ? "fa fa-lock fa-lg lock-icon": "fa fa-unlock fa-lg lock-icon"} style={{color: row.email === sessionStorage.getItem("userEmail") ? "#4B4B4B" : row.pwd_lock ? "#ff0a09" :"#2ac878"}} /></a>;
        }else{
          uluColumnsAdmin.push({name:"(Un)Locked",selector:"pwd_lock",sortable:true,width:"120px"})
          uluColumnsAdmin[9].cell=row=>row.pwd_lock !=null && <a title={row.isInactive ? "This account is inactive" :""} className={row.email === sessionStorage.getItem("userEmail") ? "disabled-lock-icon":"align-Lock-Icon"} onClick={ !row.isInactive ? () => this.changeUserStatus(row): ""} >
          <i className={row.pwd_lock ? "fa fa-lock fa-lg lock-icon": "fa fa-unlock fa-lg lock-icon"} style={{color: row.email === sessionStorage.getItem("userEmail") ? "#4B4B4B" : row.pwd_lock ? "#ff0a09" :"#2ac878"}} /></a>;
        

        } 
      } else {
        if(uluColumnsAdmin[9]){
          uluColumnsAdmin[9].cell=row=>row.pwd_lock !=null && <a title={row.isInactive ? "This account is inactive" :""} className={row.email === sessionStorage.getItem("userEmail") ? "disabled-lock-icon":"align-Lock-Icon"} onClick={ !row.isInactive ? () => this.changeUserStatus(row): ""} >
          <i className={row.pwd_lock ? "fa fa-lock fa-lg lock-icon-table": "fa fa-unlock fa-lg lock-icon-table"} style={{color: row.email === sessionStorage.getItem("userEmail") ? "#4B4B4B" : row.pwd_lock ? "#ff0a09" :"#2ac878"}} /></a>;
        }else{
          uluColumnsAdmin.push({name:"(Un)Locked",selector:"pwd_lock",sortable:true,width:"120px"})
          uluColumnsAdmin[9].cell=row=>row.pwd_lock !=null && <a title={row.isInactive ? "This account is inactive" :""} className={row.email === sessionStorage.getItem("userEmail") ? "disabled-lock-icon":"align-Lock-Icon"} onClick={ !row.isInactive ? () => this.changeUserStatus(row): ""} >
          <i className={row.pwd_lock ? "fa fa-lock fa-lg lock-icon-table": "fa fa-unlock fa-lg lock-icon-table"} style={{color: row.email === sessionStorage.getItem("userEmail") ? "#4B4B4B" : row.pwd_lock ? "#ff0a09" :"#2ac878"}} /></a>;
        

        }
      }  

      /* if(uluColumnsAdmin[11]){
        uluColumnsAdmin[11].cell=row=>row.allow_auto_connect !=null && <a title={row.isInactive ? "This account is inactive" :""} className={row.email === sessionStorage.getItem("userEmail") ? "disabled-lock-icon":"align-Lock-Icon"} onClick={ !row.isInactive ? () => this.changeUserStatus(row): ""} >
        <i className={row.allow_auto_connect ? "fa fa-check fa-lg": "fa fa-times fa-lg"} style={{color: row.email === sessionStorage.getItem("userEmail") ? "#4B4B4B" : row.allow_auto_connect ? "#2ac878": "#ff0a09"}} /></a>;
      }else{
        uluColumnsAdmin.push({name:"Allow Auto Connect",selector:"allow_auto_connect",sortable:true,width:"120px"})
        uluColumnsAdmin[11].cell=row=>row.allow_auto_connect !=null && <a title={row.isInactive ? "This account is inactive" :""} className={row.email === sessionStorage.getItem("userEmail") ? "disabled-lock-icon":"align-Lock-Icon"} onClick={ !row.isInactive ? () => this.changeUserStatus(row): ""} >
        <i className={row.allow_auto_connect ? "fa fa-check fa-lg": "fa fa-times fa-lg"} style={{color: row.email === sessionStorage.getItem("userEmail") ? "#4B4B4B" : row.allow_auto_connect ? "#2ac878": "#ff0a09"}} /></a>;
      

      } */      
    }

    if (this.state.gridViewContainer) {
      uluColumnsAdmin = uluColumnsAdmin.filter(gr => gr.selector !== "my_picture");
      if (uluColumnsAdmin.length === 10) {
        uluColumnsAdmin.push({name:"Profile Picture",selector:"my_picture",sortable:false,width:"120px"})
        uluColumnsAdmin[10].cell=row=>undefined !== row.my_picture ? row.my_picture : (window.location.href.indexOf("localhost") > 0) ? 'http://localhost:3000/'+'profile.png' : window.location.href+'profile.png';
      }
    } else {
      //if (uluColumnsAdmin.length > 10) 
      //if(uluColumnsAdmin[uluColumnsAdmin.length-1].selector==="my_picture")
        uluColumnsAdmin = uluColumnsAdmin.filter(gr => gr.selector !== "my_picture")
    }
                           
    
    //console.log(this.state.gridViewContainer, this.state.mapViewContainer, this.state.tableViewContainer);
    //console.log(uluColumnsAdmin);
    return (
      <div className="page">
        <div className="media">
          <h3>Please rotate your device to landscape mode</h3>
        </div>
        <div className="container-fluid media-show">
          {this.state.mapViewContainer
          ?<div className="row">
            <div className="col-sm-12 col-lg-1">
              <div title="Switch to Card View" onClick={()=>this.toggleContainer()}>
                <i className="fa fa-id-card switch-icons" style={{color: 'white'}}></i>
              </div>
            </div>
            <div className="col-sm-12 col-lg-11">
              <ViewMap users={this.props.users} controls={this.controls}/>
            </div>
          </div>
          :<div className="row">           
            <SearchUserAppBar 
              tree={tree} treeSelect={this.treeSelect} userLabel={this.props.userLabel}
              filterBy={this.state.filterBy} handleUnloackUser ={this.handleUnloackUser}
              needMap={this.state.needMap} pickedUser={this.state.pickedUser}
              pickUser={this.pickUser} userOptList={this.state.userOptList} sortcolumns={sortColumns}
              gridViewContainer={this.state.gridViewContainer} toggleHandler={this.toggleOnlineUser} 
              toggleContainer={this.toggleContainer} isAnyUserLocked={this.state.isAnyUserLocked}
              {...this.state.showOnlineToggleObj} blockedLabelArr={this.state.blockedLabelArr}
              setSortColumn={(field)=>this.setSortColumn(field)} globalUniqueData={this.state.globalUniqueData}
              setSortDir={(direction)=>this.setSortDir(direction)} getOptionLabel={this.getOptionLabel}
              searchUser={this.searchUser} copyEmails={this.copyEmails}
            />
            <div className="col-12 align-self-start">
              { this.state.tableViewContainer ?                
                <DataTable
                pagination={this.state.tableList.length>this.props.pagination}
                paginationPerPage={this.props.pagination}
                paginationTotalRows={this.state.tableList.length}
                onChangePage={page => this.setPage(page)}
                paginationDefaultPage={this.state.currentPageNumber}
                columns={uluColumnsAdmin && this.setFilterData(uluColumnsAdmin)}
                keyField={"email"}
                data={this.state.tableList}
                onRowSelected={this.updateDataTableState}
                className="table-responsive table-container usersTable" //TP-6485
                sortFunction={(rows, field, direction)=>this.customSort(rows, field, direction)}
                subHeader={this.state.tableList.length>1}
                subHeaderComponent={(
                  <div className="fa fa-copy copy-emails copySelection"
                    onClick={()=>this.copyEmails()} data-toggle="tooltip" title="Copy email ids">
                  </div>
                )}
                />
                :
                <LayoutUsers isSorted={this.state.isSorted} 
                  data={this.state.tableList} 
                  columns={uluColumnsAdmin && this.setFilterData(uluColumnsAdmin)}
                  nodataMessage="There is no records to display"
                ></LayoutUsers>
              }
              { this.state.gridViewContainer && this.state.showLegends === true ?
                <div className='legend-cls'><span className="user-type-flag text-shadox" style={{backgroundColor:"#777",margin:'5px', fontSize: '16px', padding:'6px'}}>Invitee</span>
                  <span className="user-type-flag text-shadox" style={{backgroundColor:"#3478f6",margin:'5px', fontSize: '16px', padding:'6px'}}>Expert</span>
                  <span className="user-type-flag text-shadox" style={{backgroundColor:"#89783F",margin:'5px', fontSize: '16px', padding:'6px'}}>Admin</span>
                  <span className="user-type-flag text-shadox" style={{backgroundColor:"#89783F",margin:'5px', fontSize: '16px', padding:'6px'}}>Admin/Expert</span>
                  <span className="user-type-flag text-shadox" style={{backgroundColor:"#6fc566",margin:'5px', fontSize: '16px', padding:'6px'}}>Technician</span>
                </div>
                :
                ''
              }
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

//{/*TP-809*/}
const ShowOnlineCheckbox = (props) => {
  return( <div id="show-online-user-container" >
    <label className="col-xs-3 disable-pointer-event"  htmlFor={props.id}>{props.label}&nbsp;</label>
    <label className="col-xs-4 col-xs-offset-2 switch">
      <input type="checkbox" id={props.id} disabled={props.isDisabled}
      name={props.id}
      key={props.id}
      onChange={props.toggleHandler}
      checked={props.value}
      />
      <span className="slider round"  title={props.isDisabled ? "Disabled for this View":""} ></span>
    </label>
  </div>
  )
}


const mapDispatchToProps = dispatch => ({
  updatePasswordLock: (userInfo) => dispatch(updatePasswordLock(userInfo))
});

export default connect(null,mapDispatchToProps)(UsersTab);