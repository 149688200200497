import React, { useState, useEffect } from "react";
import {S3config} from '../Utils/config';
import S3FileUpload from 'react-s3';
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

export default function CustomerModel(viewDetails) {
    const {viewDetails: {account_id}} = viewDetails
    const [models, setModels] = useState([]);
    const [isFileUploading, setFileLoadingFlag] = useState(false);
    const [isFileDeleting, setFileDeletingFlag] = useState(false);

    useEffect(() => {
      getCustomerModels();
    }, [account_id]);
    
    // getting assets
    const getCustomerModels = () => {
        fetch(sessionStorage.getItem("url") + `/api/dash/getCustomerModel/${account_id}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + sessionStorage.getItem("userToken")
            }
        })
        .then(results => results.json())
        .then(data => {
            setModels(data);
        }).catch(error => {
            console.log(error);
        })
    }

    const showOriginalFileName = ({fileName}) => {
        try{
            let originalFileName = '';
            if (fileName){
                let splitedFileName = fileName.split(/_(.+)/);//TP-1058
                // splitedFileName.shift();originalFileName = splitedFileName.join('-');
                originalFileName = splitedFileName[1];
            }
            return originalFileName;
        }catch(error){
            console.log('showOriginalFileName error', error)
            return ''
        }
    }

    // upload model to s3
    const uploadModel = (e) => {
        if(!e.target.files[0]){
            return false;
        }
        let fileToUpload, newFileName, file = e.target.files[0];
        if (file.name) {
            newFileName = file.name.replace(/\+/g, "__");
            fileToUpload = new File([file], (Date.now() + '_' + newFileName), { type: file.type });
        }
        const s3Details = {...S3config, dirName:  "jobs"};
        setFileLoadingFlag(true);
        S3FileUpload.uploadFile(fileToUpload, s3Details)
        .then(data => {
            //console.log(data.location)
            if(data.result.status === 204){
                let uploadedFile = {"location": data.location, "file_name": fileToUpload.name};
                saveModel([uploadedFile]);
            }
        })
        .catch(err => {
            setFileLoadingFlag(false);
            console.error(err)
        });
    }

    // api for upload model
    const saveModel = (models) => {
        fetch(sessionStorage.getItem("url") + `/api/dash/editCustomerModel`, {
            method: "put",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + sessionStorage.getItem("userToken")
            },
            body: JSON.stringify({ account_id, model_references: models})
        })
        .then(()=> {
            getCustomerModels();
        }).catch(error => {
            console.log(error);
        })
        .finally(() =>{
            setFileLoadingFlag(false);
        })
    }

    //api call for detaching model
    const detachFiles = ({e, fieldValue}) => {
        e.preventDefault();
        setFileDeletingFlag(true);
        fetch(sessionStorage.getItem("url") + `/api/dash/editCustomerModel`, {
            method: "put",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + sessionStorage.getItem("userToken")
            },
            body: JSON.stringify({ account_id, remove_model_references: [fieldValue]})
        })
        .then(()=> {
            getCustomerModels();
        }).catch(error => {
            console.log(error);
        })        
        .finally(() =>{
            setFileDeletingFlag(false);
        })
    }

    const showLoader = (isLoading) => {
        const loader = isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""
        return loader;
    }

    return(
        <>
            <h5 className="text-center">Trained Model</h5>
            <br/>
            <div className="row">
                <div className="col-4">
                    <input type="file" id="model" style={{display:'none'}} onChange={e=>uploadModel(e)} ></input>
                    {models.length ?
                        <label className="btn btn-secondary upload-model">Attach model</label>
                    :
                        <label htmlFor="model" className="btn btn-info upload-model">{showLoader(isFileUploading)} Attach model</label>
                    }
                </div>
            </div>
            {
                models.map((reference, index) => 
                    <div className="row" key={index}>
                        <div className="col-7 mt-1">{reference.file_name ? showOriginalFileName({fileName: reference.file_name}) : ''}</div>
                        <div className="col-2 mt-1">
                            {reference && reference.file_name ? 
                                <span className="pointer" onClick={(e) => detachFiles({e, fieldValue: reference})}>
                                    {showLoader(isFileDeleting)}&nbsp;&nbsp;
                                    <i className="fa fa-trash-o fa-lg" title="Delete Model" style={{color: 'red', cursor: 'pointer'}}></i>
                                </span>
                                : null
                            }
                        </div>
                    </div>
                )
            }
        </>
    );
}
