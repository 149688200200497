//created by Roopesh Singh
import JSZip from  'jszip';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import GenerarteJobPDF from "./generateJobPDF";
import GenerateCheckListJobPDF from "./generateCheckListJobPDF"; //TP-2772

export default async ({data, selectedJob, customer,callBackFunction, language}) => { 
    let filename = selectedJob.name;
    const zip = new JSZip();
    const folder = zip.folder('assets');
    /**TP-2772 */
    let documentDefinition;
    if(selectedJob.job_type  === 'checklist'){
        documentDefinition = await GenerateCheckListJobPDF({...data,selectedJob,customer, language});//TP-4211 && TP-2653
    }else{
        documentDefinition = await GenerarteJobPDF({...data,selectedJob,customer, language});//	TP-4211 & TP-2765
    }
    
    //let documentDefinition = await GenerarteJobPDF({data,selectedJob,customer});
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

    //TP-4211
    let jobsBlobPromise = new Promise(function(myResolve) {
        pdfDocGenerator.getBase64((data) => {myResolve(data);});});
        jobsBlobPromise.then(response => {
        zip.file(filename+'.pdf', response, { base64: true });
        /**TP-2772 */
        let s3Urls;
        if(selectedJob.job_type  === 'checklist'){
            const checkListTasks = data.checkListTasks, modelAssets = data.modelAssets;
            s3Urls = fetchUrlData(checkListTasks, modelAssets); //TP-4211
        }else{
            const subTasks = data.subTasks, modelAssets = data.modelAssets;
            s3Urls = fetchUrlData(subTasks, modelAssets);
        }
        //const s3Urls = fetchUrlData(data);
        let timestamp = new Date().getTime();
        
        processS3UrlBeforeDownload(s3Urls).then(res => {
            downloadTheZip(res,callBackFunction,filename,folder,zip,timestamp);
          });
        
        // s3Urls.forEach((url)=> {
        //     const blobPromise =  fetch(url)    
        //     .then(function (response) {              
        //         if (response.status === 200 || response.status === 0) {
        //             return Promise.resolve(response.blob());
        //         } else {
        //             return Promise.reject(new Error(response.statusText));
        //         }
        //     })                          
        //     const name = url.substring(url.lastIndexOf('/'))
        //             folder.file(name.split('/').pop(), blobPromise)
                  
        // })
    
        // zip.generateAsync({type:"blob"},function updateCallback(metadata){
        //     if(metadata.percent === 100 && metadata.currentFile){
        //          callBackFunction(false);
        //     } 
        // }).then(blob => saveAs(blob, filename+'_'+timestamp+".zip"))
        //     .catch(e => {console.log(e); callBackFunction(false)});
    
    })
   
}

async function processS3UrlBeforeDownload(items) {
  const validUrl = [];
  for (let item of items) {
        const  response = await fetch(item);
       if(response.status === 200)
            validUrl.push(item); 
  }
  return validUrl;
}



const downloadTheZip = (s3Urls,callBackFunction,filename,folder,zip,timestamp)=>{
    s3Urls.forEach((url)=> {
        //TP-2829
        if (url) {
            const blobPromise =  fetch(url)    
            .then(function (response) {              
                if (response.status === 200 || response.status === 0) {
                    return Promise.resolve(response.blob());
                } else {
                    return Promise.reject(new Error(response.statusText));
                }
            })                          
            const name = url.substring(url.lastIndexOf('/'))
                    folder.file(name.split('/').pop(), blobPromise)
        }
              
    })

    zip.generateAsync({type:"blob"},function updateCallback(metadata){
        console.log('Completed Downloading Percent:'+metadata.percent);
        if(metadata.percent === 100 && metadata.currentFile){
             callBackFunction(false);
        } 
    }).then(blob => saveAs(blob, filename+'_'+timestamp+".zip"))
        .catch(e => {console.log(e); callBackFunction(false)});
}

const fetchUrlData = (dataArr, assets) =>{
    let outputResult = []; //TP-2829
    dataArr.forEach((item)=>{
            let taskData = item.task_data ? item.task_data: null; //TP-2829
            let referenceMaterial = item.reference_materials; 
            let externaleEvidence = item.external_evidence && item.external_evidence !== null ? JSON.parse(item.external_evidence):null; //TP-2829
            /**TP-2772 */
            let model_references = item.model_references ? item.model_references: null;

            if(taskData && taskData.length > 0){
                taskData.forEach((task) => {
                    const parsedTaskData = JSON.parse(task);
                    outputResult.push(parsedTaskData.url)
                })
            }

            if(externaleEvidence && externaleEvidence){
                let externalEvidenceUrls = getReferenceUrls(externaleEvidence,'external');
                outputResult.push(...externalEvidenceUrls);
            }
            if(referenceMaterial && referenceMaterial.length){
               let refMaterialUrls =  getReferenceUrls(referenceMaterial,'material');
               outputResult.push(...refMaterialUrls);
            }

            //TP-2772
            if(model_references && model_references.length> 0){
                let modelReferenceUrls =  getReferenceUrls(model_references,'model_reference');
                outputResult.push(...modelReferenceUrls)
            }
            //TP-2772
            if(assets && assets.length> 0){
                let assetReferenceUrls =  getReferenceUrls(assets,'asset_reference');
                outputResult.push(...assetReferenceUrls)
            }
            if(item.audio_link){
                try{
                    let audioUrl = item.audio_link.map(audio=>JSON.parse(audio).url).pop();
                    if(audioUrl){
                        outputResult.push(audioUrl);
                    }
                }catch(error){
                    console.log('Error found while parsing audio link'+error);
                } 
            }
            
        })
    return outputResult
}

 

const getReferenceUrls = (dataArr,type) =>{
    let outputResult = [];
    dataArr.forEach((item)=>{
        if(type === 'material'){
            let fileInfo = item.file_info ? JSON.parse(item.file_info): null;
            if(fileInfo){
               outputResult.push(fileInfo.location)
           }
        }else{
            let location = item.location ? item.location:null;
            if(location){
               outputResult.push(location)
           } 
        }
        
    })
    return outputResult;
}

