import {CustomerActionTypes} from './CustomerTypes';
import superagent from "superagent";
import moment from 'moment';
import {encryptData} from '../../cryptography';

const promisesArray = [];
export const fetchCustomerStart = () => ({
    type: CustomerActionTypes.FETCH_CUSTOMER_START
  });
  
  export const fetchAllCustomerSuccess = allCustomer => ({
    type: CustomerActionTypes.FETCH_CUSTOMER_SUCCESS,
    payload: allCustomer
  });
  
  export const fetchCustomerFailure = (errorMsg,error) => ({
    type: CustomerActionTypes.FETCH_CUSTOMER_FAILURE,
    payload: errorMsg,
    payloadError:error
  });

  export const fetchCustomerSuccess = (customer,responsePayload) =>({
      type:CustomerActionTypes.FETCH_ENTERPRISE_SUCCESS,
      custPayload:customer,
      payload:responsePayload 
  });

  export const fetchUserProfileSuccess = (customer, responsePayload) =>({
    type:CustomerActionTypes.FETCH_ENTERPRISE_USER_PROFILE_SUCCESS,
    custPayload: customer,
    userPayload:responsePayload
  })

  export const fetchUserProfileFailure = (errorMsg,error) => ({
    type: CustomerActionTypes.FETCH_ENTERPRISE_USER_PROFILE_FAILURE,
    payload: errorMsg,
    payloadError:error
  });

  export const fetchCustomerEnd = () => ({
    type:CustomerActionTypes.FETCH_CUSTOMER_END
  });

  export const deleteCustomerSuccess = (customer)=>({
    type:CustomerActionTypes.DELETE_CUSTOMER_SUCCESS,
    payload:customer
  });

  export const deleteCustomerFailure = error => ({
    type:CustomerActionTypes.DELETE_CUSTOMER_FAILURE,
    payload:error
  })
  
  export const updatePageLoadFlag = () => ({
    type:CustomerActionTypes.UPDATE_PAGE_LOAD_SUCCESS,
  });

  export const updateUserProfileAction = (accountParam,userParam) =>({
    type:CustomerActionTypes.UPDATE_USER_PROFILE_ACTION,
    payloadAccount:accountParam,
    payloadValue:userParam
  });

  export const updateProcessingStart = () =>({
    type:CustomerActionTypes.UPDATE_PROCESSING_START,
  })

  export const updateProcessingEnd = () =>({
    type:CustomerActionTypes.UPDATE_PROCESSING_END,
  })

  export const  fetchAllCustomers = () => {
    return dispatch =>{
        dispatch(fetchCustomerStart())
        if (sessionStorage.getItem("userToken")) {
             superagent
              .get(sessionStorage.getItem("url") + "/api/dash/getAllCustomers")
              .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
              .accept("application/json")
              .then((response) => response)
              .then(async (data) => {
                if (data.body) {
                  const customerList = data.body;
                  const allCustomerList = await fetchAllCustomerSuccess(customerList)
                  dispatch(allCustomerList);
                  for(let custIndx = 0; custIndx < customerList.length;custIndx++){
                     handleAsyncCustomersCall(dispatch,customerList[custIndx],custIndx,customerList.length-1);
                  }
                  
                }
              })
              .catch((error) => {
                dispatch(fetchCustomerFailure("Error in function fetchAllCustomers :"+error.message+" While fetching  all customer data",error));
              });
        }
    }
  }

  export const handleAsyncCustomersCall = async (dispatch,cust,indx,lastIndex) => {
    try{

     
      promisesArray.push(fetchEnterpriseDetails(dispatch,cust));
      promisesArray.push(fetchPurchaseDetails(dispatch,cust));
      promisesArray.push(fetchUserDetails(dispatch,cust));
      promisesArray.push(fetchCustomerDetails(dispatch,cust));

     await Promise.all(promisesArray).then((a)=>{
        if(indx === lastIndex){
          dispatch(fetchCustomerEnd());
          dispatch(updatePageLoadFlag());
        }
      })
      
    }catch(error){
      dispatch(fetchCustomerFailure("Error in function handleAsyncCustomersCall :"+error.message+" While fetching all promise request",error));
    }
         
}

  const fetchEnterpriseDetails = async (dispatch,item) => {
    return new Promise(resolve => {
        if (enterpriseAccess() && "enterprise" === item.subscription_status) {
          superagent
          .get(sessionStorage.getItem("url") + "/api/getEnterpriseCustomerDetailsByAccountId/" + item.account_id)
          .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
          .accept("application/json")
          .then((response) => response)
          .then((data) => {
            if (data.body) {
              data.body.entAccess = true;
              dispatch(fetchCustomerSuccess(item,data.body));
              resolve(item); 
            }
          })
          .catch((error) => {
            if (error.status) {
              dispatch(fetchCustomerFailure("Error in function fetchEnterpriseDetails :"+error+" While fetching results for enterprise customer",error));
            }
          });
      }else{
        resolve(item); 
      }
    }
    )
  }

  const fetchCustomerDetails = async (dispatch,item) =>{
    return new Promise(resolve => {
        if(sessionStorage.getItem("userToken")){
          superagent.get(sessionStorage.getItem("url") +  "/api/dash/getCustomerByAccountId/" + item.account_id)
          .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
          .accept("application/json")
          .then((response) => response)
          .then((data) => {
            if (null != data.body) {
              dispatch(fetchCustomerSuccess(item,data.body));
              resolve(item)
            }
          }).catch((error)=>{
            dispatch(fetchCustomerFailure("Error in function fetchCustomerDetails :"+error+" While fetching results for customer details",error));
          })
        }
      }
    )
    }

    const fetchPurchaseDetails = async (dispatch,item) =>{
      return new Promise(resolve => {
        if(sessionStorage.getItem("userToken")){
          superagent
          .get(sessionStorage.getItem("url") + "/api/dash/getPurchasesByAccountId/" + item.account_id)
          .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
          .accept("application/json")
          .then((response) => response)
          .then(async (data) => {
            //console.log(data.body);
            const purchaseList = data.body;
            const purchaseItems = item;
            let total_used = 0, total_max = 0, credits = [],total_licences = 0, plan_id = null;
            
            for(let idx = 0; idx < purchaseList.length;idx++){
              if(idx === 0) plan_id = purchaseList[idx].plan_id;
              purchaseList[idx].credits.forEach((credit)=>{
                total_max = total_max + credit.max_monthly_minutes;
                total_used = total_used + (credit.max_monthly_minutes - credit.available_monthly_minutes);
                credits.push(credit)
              });
              total_licences = total_licences + purchaseList[idx].total_licences; 
            }
            purchaseItems.plan_id = plan_id;
            purchaseItems.total_licences =  total_licences;
            purchaseItems.credits = credits;
            purchaseItems.total_max = total_max;
            purchaseItems.total_used = total_used;
            purchaseItems.purchases = purchaseList;
            dispatch(fetchCustomerSuccess(purchaseItems,item));
            resolve(item);
          }).catch((error)=>{
            dispatch(fetchCustomerFailure("Error found in function fetchPurchaseDetails :"+error+" While fetching details for purchase details.",error));
          })
        }
      })
    }

  const fetchUserDetails = async (dispatch,item) =>{
      return new Promise(resolve =>{
        if(sessionStorage.getItem("userToken") && item.account_id){
          superagent.get(sessionStorage.getItem("url")+"/api/getusers/"+item.account_id)
          .set("Authorization","Bearer "+sessionStorage.getItem("userToken"))
          .accept("application/json")
          .then((response)=>response)
          .then((data) =>{
            if(data.body){
              let last_used_by = null, last_used_on = null, last_used_by_Obj = null;
             
              let dt =  data.body.map((user)=>{
                if(user.last_accessed_on){
                  user.company_name = item.company_name;
                  user.account_id = item.account_id;
                  return user;
                }else return []
              })
  
              if(dt.length){
                
                dt.sort((d1, d2) => {
                  if (moment(d2.last_accessed_on).isBefore(moment(d1.last_accessed_on))) {
                    return 1;
                  } else if (moment(d2.last_accessed_on).isAfter(moment(d1.last_accessed_on))) {
                    return -1;
                  } else return 0;
                });

                  last_used_on = dt[dt.length -1]?.last_accessed_on;
                  last_used_by = dt[dt.length -1]?.first_name +" "+dt[dt.length -1]?.last_name;
                  last_used_by_Obj = dt[dt.length-1];
                
              }
              
              
              
             const params = {
                ...item,
                users:data.body,
                last_used_by: last_used_by,
                last_used_on:last_used_on,
                last_used_by_Obj:last_used_by_Obj
              }
              dispatch(fetchCustomerSuccess(params,item));
              resolve(item);
              
              /* TP-3554 -- removed from State management
              const userList = data.body;
              for(let userIndx = 0; userIndx < userList.length;userIndx++){
                handleAsyncUserProfileCall(dispatch,params,userList[userIndx],userIndx,userList.length-1);
              } */
            }
          }).catch((error)=>{
            dispatch(fetchCustomerFailure("Error in function fetchUserDetails :"+error+" While fetching results for User List",error));
          })
        }
      }
      )
    }
    
    export const handleAsyncUserProfileCall = async (dispatch,cust,user,indx,lastIndex) => {
      try{
  
      
        promisesArray.push(fetchUserProfileSettings(dispatch, cust, user));
        
  
      /* await Promise.all(promisesArray).then((a)=>{
          if(indx === lastIndex){
            dispatch(fetchCustomerEnd());
            dispatch(updatePageLoadFlag());
          }
        }) */
        
      }catch(error){
        dispatch(fetchCustomerFailure("Error in function handleAsyncUserProfileCall :"+error.message+" While fetching all promise request",error));
      }
          
    }
  
  const fetchUserProfileSettings = async (dispatch,cust,item) => {
    return new Promise(resolve => {
      if(sessionStorage.getItem("userToken") && item.id){
        superagent.get(sessionStorage.getItem("url") +  "/api/user/getProfileData/" + item.id)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .then((response) => response)
        .then((data) => {
          if (null != data.body) {
            const params = {
              id: data.body.user_id,
              my_camera_on: data.body.my_camera_on,
              streaming_quality: data.body.streaming_quality,
              image_quality: data.body.image_quality,
              allow_auto_connect: data.body.allow_auto_connect,
              disable_notifications: data.body.disable_notifications
            }
            dispatch(fetchUserProfileSuccess(item,params));
            resolve(item)
          }
        }).catch((error)=>{
          let msg = null;
          if(!error.status){
            msg = "Internet is not connected, Please try again after connecting network";
          }else if(400 === error.status){
            msg = "Not found";
          }else if(401 === error.status){
            msg = "session Token is expired, Please re-loggin again";
          }else if (403 === error.status){
            msg = "You are not authorized."
          }else if(422===error.status){
           msg = "Invalid Paramater send";
          }else if(500 ===error.status){
            msg = "User Not Found";
          }else{
            msg = error.message ? error.message : error.status;
          }
          dispatch(fetchUserProfileFailure("Error in function fetchUserProfileSettings :"+msg+" While fetching results for User Profile details",error));
        })
      }
    }
  )
  }
 
  export const updatePasswordLock = (userInfo)=>{
    return dispatch => {
      const params = {
          email:encryptData(userInfo.email.toLowerCase()),flag:!userInfo.pwd_lock
      }
      
      if(sessionStorage.userToken){
        superagent.put(sessionStorage.url+"/api/changePasswordLock")
        .set("Authorization","Bearer"+sessionStorage.userToken)
        .type("application/json")
        .send(params)
        .accept("application/json")
        .then(response=> response)
        .then(data=>{
          if(data.body){
            params.keyName = "pwd_lock";
            params.pwd_lock =  params.flag;
            dispatch(updateUserProfileAction(userInfo,params))
            return true;
          }
        }).catch(error=>{
          let msg = null;
          if(!error.status){
            msg = "Internet is not connected, Please try again after connecting network";
          }else if(400 === error.status){
            msg = "Not updated";
          }else if(401 === error.status){
            msg = "session Token is expired, Please re-loggin again";
          }else if (403 === error.status){
            msg = "You are not authorized."
          }else if(422===error.status){
           msg = "Invalid Paramater send";
          }else if(500 ===error.status){
            msg = "Something went wrong";
          }else{
            msg = error.message ? error.message : error.status;
          }
          dispatch(fetchCustomerFailure("Error in function updatePasswordLock :"+msg+" While updating results for User",error));
          return false;
        })
      }
    }
  }

  export const updateUserProfile = (accountParam,userParam) =>{
    return dispatch =>{
      dispatch(updateUserProfileAction(accountParam,userParam))
    }
  }

  const enterpriseAccess = () => {
    let flag = sessionStorage.getItem("userDbRole");
    if (
      flag === "read_ent" ||
      flag === "admin" ||
      flag === "write_ent" ||
      flag === "write_all" ||
      flag === "read_all" ||
      flag === "write_my_account" ||
      flag === "read_my_account"
    ) {
      return true;
    } else {
      return false;
    }
  }