import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { Grid } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Carousel from 'react-material-ui-carousel';

const breakArr = [
    {
        breakpoint: 1536,
        cols: 5,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 1200,
        cols: 4,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 900,
        cols: 4,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 600,
        cols: 2,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 0,
        cols: 2,
        rows: 1,
        gap: 10
    }
]

const theme = createTheme({
    spacing: 2,
    palette: {
      background: {
        paper: '#384571', // your color
      },
      divider: {
        primary: '#1c2238',
        secondary: '#384571'
      }
    },
    typography: {
        allVariants: {
            fontFamily: "Titillium Web",
            color: "white",   
            paddingTop: 6,
            paddingBottom: 10,                     
        },
        body2: {
            textAlign: 'left',
            paddingLeft: 10,
            paddingBottom: 2
        }
    },
  });

  theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  const SubCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    background: '#1c2238',
    color: "white",
    height: "fit-content",
    minHeight: "135px",  // TP-6406
    lineHeight: '60px',
    /* margin: '10px', */
    borderRadius: '10px' /**TP-5488*/
  }));

/* const ActionBox = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    flexDirection: 'row',
    justifyContent: 'flex-end'
})) */

const colorPalettes = [
    {name: 'Technician', value: '#6fc566'},
    {name: 'Expert', value: '#3478f6'},
    {name: 'Admin', value: '#89783F'},
    {name: 'Admin/Expert', value: '#89783F'},
    {name: 'Invitee', value: '#777'}
]

const setColor = (value) => {
    let color = '';
    colorPalettes.forEach(cp => {
        //colorPalettes.name === columns[8].cell(row) ? colorPalettes.value : '#0c7aff'
        if (cp.name === value) 
            color = cp.value;
    })
    return color;
}

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontFamily: "Titillium Web",
      fontSize: "20px",
    },
  }));

const Item = ({index, row, columns}) => {
    //console.log(index, row, columns);
    const [imageCol] = columns.filter(col => col.selector === "my_picture");
    const [pwdCol] = columns.filter(col => col.selector === "pwd_lock");
    const [typeCol] = columns.filter(col => col.selector === "role");
    return <Grid key={index} item xs={6} sm={4.4} md={3} lg={2.4}>
                <Box sx={{ maxWidth: 355, borderRadius: '10px', boxShadow: 3}}>
                    <Card sx={{ maxWidth: 345, borderRadius: '10px' }}>
                        {/* <CardActionArea> */}
                            { typeCol && typeCol.cell(row) !== undefined && typeCol.cell(row) !== null ?
                                <Box sx={{ padding: 3, background: setColor(typeCol.cell(row)) }}>                      
                                </Box>   
                                :
                                <Box sx={{ padding: 3, background: setColor('Invitee') }}>                      
                                </Box> 
                            }
                            { imageCol && imageCol.cell(row) !== undefined ?
                                <Box mt={typeof(imageCol.cell(row)) !== "string" || imageCol.cell(row).includes("profile") === true ? 2: 0} ml={typeof(imageCol.cell(row)) !== "string" || imageCol.cell(row).includes("profile") === true ? "25%": 0} sx={{ minHeight: 140, width: typeof(imageCol.cell(row)) !== "string" || imageCol.cell(row).includes("profile") === true ? "50%": "100%" }}>
                                    <CardMedia
                                        component="img"
                                        height={/* columns[10].cell(row).includes("profile") === true ? "140" : */ "140"}                                        
                                        image={typeof(imageCol.cell(row)) === "string" ? imageCol.cell(row) : window.location.href+'profile.png'}
                                        alt="User"
                                        sx={{ objectFit: typeof(imageCol.cell(row)) !== "string" || imageCol.cell(row).includes("profile") === true ?  "contain" : "cover"}}
                                    />
                                </Box>
                                :
                                ''
                            }             
                        <CardContent> 
                            { columns[2] && columns[2].cellEdit(row) !== null ?
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Grid item xs={10} md={10}>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {columns[2].cell(row)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} md={2} textAlign="right">
                                        <Typography gutterBottom variant="h5" component="div">
                                            {columns[2].cellEdit(row)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Typography gutterBottom variant="h5" component="div">
                                    {columns[2].cell(row)}
                                </Typography>
                            }
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                { columns[0] && columns[0].cell(row) !== undefined ?
                                    <Grid item xs={2} md={4}>
                                        <Typography gutterBottom variant="body1" component="div">
                                            {columns[0].cell(row)}
                                        </Typography> 
                                    </Grid>
                                    :
                                    ''
                                }
                                {  columns[1] && columns[1].cell(row) !== undefined ?
                                    <Grid item xs={10} md={8}>
                                        <Typography gutterBottom variant="body1" component="div">
                                            {columns[1].cell(row)}
                                        </Typography>
                                    </Grid>
                                    :
                                    ''
                                }
                            </Grid>                                                            
                            <Divider ></Divider>
                            <Box pt={6} pb={5} sx={{ height: 80 + "px"  /**TP-6406 */}}>
                                { columns[3] && row[columns[3].selector] !== undefined && row[columns[3].selector] !== '' ?
                                    columns[3].cellEdit(row) !== null && columns[3].cellCopy(row) !== null ?
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item xs={8} md={8}>
                                                <Typography variant="body1">
                                                    {columns[3].cell(row)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} md={2} textAlign="right">
                                                <Typography variant="body1">
                                                    {columns[3].cellEdit(row)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} md={2} textAlign="center">
                                                <Typography variant="body1">
                                                    {columns[3].cellCopy(row)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Typography variant="body1">
                                            {columns[3].name + ": "}{columns[3].cell(row)}
                                        </Typography>
                                    :
                                    columns[4] && columns[4].cellCopy(row) !== null ?
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item xs={10} md={8}>
                                                <Typography variant="body1">
                                                    { row[columns[4].selector] !== undefined && row[columns[4].selector] !== '' ?
                                                        columns[4].name+": "
                                                        :
                                                        ''
                                                    } 
                                                    { row[columns[4].selector] !== undefined && row[columns[4].selector] !== '' ?
                                                        columns[4].cell(row)
                                                        :
                                                        ''
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2} md={4} textAlign="right">
                                                <Typography variant="body1">
                                                    { row[columns[4].selector] !== undefined && row[columns[4].selector] !== '' ?
                                                        columns[4].cellCopy(row)
                                                        :
                                                        ''
                                                    } 
                                                    
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Typography variant="body1">
                                            { row[columns[4].selector] !== undefined && row[columns[4].selector] !== '' ?
                                                columns[4].name+": "
                                                :
                                                ''
                                            } 
                                            { row[columns[4].selector] !== undefined && row[columns[4].selector] !== '' ?
                                                columns[4].cell(row)
                                                :
                                                ''
                                            }
                                        </Typography>                                   
                                }        
                            </Box>
                            <SubCard elevation={1} sx={{ marginTop: columns[10] === undefined || columns[10].cell(row).includes("profile") === true ? 0: 2}}>
                                <Grid container textAlign="center">
                                    {/* <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[7].name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[8].name}
                                        </Typography>
                                    </Grid> */}
                                    { columns[7] && columns[7].cell(row) !== undefined && columns[7].cell(row) !== '' ?
                                        <>
                                            <Grid item xs={6} md={6}>
                                                { columns[7].cellTooltip(row) !== undefined && columns[7].cellTooltip(row) !== null ?
                                                    <LightTooltip title={columns[7].cellTooltip(row)}>
                                                        <Typography variant="body1" >
                                                            {columns[7].cell(row)}
                                                        </Typography>
                                                    </LightTooltip>
                                                    :
                                                    <Typography variant="body1" >
                                                        {columns[7].cell(row)}
                                                    </Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                { columns[8].cellTooltip(row) !== undefined && columns[8].cellTooltip(row) !== null ?
                                                    <LightTooltip title={columns[8].cellTooltip(row)}>
                                                        <Typography variant="body1" >
                                                            {columns[8].cell(row)}
                                                        </Typography>
                                                    </LightTooltip>
                                                    :
                                                    <Typography variant="body1" >
                                                        {columns[8].cell(row)}
                                                    </Typography>
                                                }
                                            </Grid>
                                            {/* <Grid item xs={6} md={6} >
                                                <Typography variant="body1" textAlign="center">
                                                    {columns[7].cellCopy(row)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} md={6} >
                                                <Typography variant="body1" textAlign="center">
                                                    {columns[8].cellCopy(row)}
                                                </Typography>
                                            </Grid> */}
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Typography variant="body1" >
                                                    {columns[8].cell(row)}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item xs={12} md={12} >
                                                <Typography variant="body1" textAlign="center">
                                                    {columns[8].cellCopy(row)}
                                                </Typography>
                                            </Grid>  */}                                       
                                        </>
                                    }            
                                </Grid>   
                                <Divider color='#384571' sx={{ }} style={{ margin: 10 }}></Divider>                     
                                <Typography variant="body2" component="div">
                                    { columns[5] && columns[5].cell(row) !== undefined ?
                                        columns[5].cell(row) === "Invitee" ? "Invited On: " : "Last Activity: "
                                        :
                                        columns[5].name + ": "
                                    }
                                    { columns[5].cell(row)}
                                        
                                </Typography> 
                                {/* <Typography variant="body2">
                                    {columns[4].name +": " + columns[4].cell(row)}
                                </Typography> */}
                            </SubCard>
                        </CardContent>
                        {/* </CardActionArea> */}
                        { pwdCol !== undefined && pwdCol.cell(row) !== undefined && pwdCol.cell(row) !== false?
                            <Box pb={5} pt={5}>
                                <Grid pr={8} container textAlign="right">
                                    <Grid item xs={6} md={6}>

                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant=""> 
                                            {pwdCol.cell(row)}
                                        </Typography>
                                    </Grid>
                                </Grid>     
                            </Box>
                            :
                            <Box pb={5} pt={5}>
                                <Grid pr={8} container textAlign="right">
                                    <Grid item xs={6} md={6}>

                                    </Grid>
                                    <Grid item xs={6} md={6} sx={{visibility: 'hidden'}}>
                                        32323232323
                                    </Grid>
                                </Grid>     
                            </Box>
                        }
                    </Card>
                </Box>
            </Grid>
}

const PageLayout = (props) => {
    return <div>
        <Paper elevation={0} sx={{ background: '#1c2238' }}>
            <ThemeProvider theme={theme}>
                <Grid container spacing={1}>                    
                    { props.data.map((dat,index) => {
                        return <Item index={index} row={dat} columns={props.columns}></Item>
                        })
                    }
                </Grid>  
            </ThemeProvider>          
        </Paper>
    </div>
}

const NoDataComponent = (props) => {
    return <div>
        <Paper elevation={0} sx={{ background: '#384571', height: "80px" }}>
            <ThemeProvider theme={theme}>
                <Box 
                    display="flex" 
                    width={"100%"} height={"100%"} 
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="" sx={{ color:"white" }}>
                        {props.messageText}
                    </Typography>
                </Box>
            </ThemeProvider>
        </Paper>
    </div>
}

let pagination = [];
//let pagination = getPagination(props.data, 1);

const getPagination = (total_data, pageNo) => {
    const maxWidth = window.innerWidth;
    let cols, rows;
    for( let i=0; i<breakArr.length; i++) {
        if(maxWidth >= breakArr[i].breakpoint) {
            cols= breakArr[i].cols;
            rows = breakArr[i].rows;
            break;
        }
    }
    const data_perpage = cols * rows;
    const total_no_of_pages = Math.ceil(total_data.length/data_perpage) 
    //console.log(total_no_of_pages);
    /* let startingPoint = (data_perpage * pageNo) - data_perpage;
    let endPoint = startingPoint + data_perpage;
    let finalData = total_data.slice(startingPoint, endPoint);
    pagination.push({data: finalData, currentPage: pageNo, perPage: data_perpage, totalPages: total_no_of_pages}); */
    if (/* total_no_of_pages > 1 && */ pagination.length<=(total_no_of_pages - 1)) {
        for(let pp=pageNo; pp <= total_no_of_pages; pp++) {
            let startingPoint = (data_perpage * pp) - data_perpage;
            let endPoint = startingPoint + data_perpage;
            let finalData = total_data.slice(startingPoint, endPoint);
            pagination.push({data: finalData, currentPage: pp, perPage: data_perpage, totalPages: total_no_of_pages});
        }
    }
    return pagination;
}

class LayoutUsers extends React.Component  {  
    constructor(props) {
        super(props);
        pagination = [];
    }

    componentDidMount() {
        pagination = (this.props && this.props.data && this.props.data.length > 0) ? getPagination(this.props.data, 1) : [];        
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data && this.props.data !== undefined && this.props.data.length > 0) {
            pagination = getPagination(this.props.data, 1);
        }
        if(prevProps.isSorted !== this.props.isSorted && this.props.isSorted === true) {
            pagination = [];
            pagination = getPagination(this.props.data, 1);
        }
        /* if(prevProps.data !== undefined && prevProps.data.length > 0 && prevProps.data[0].email !== this.props.data[0].email){
            pagination = [];
            pagination = getPagination(this.props.data, 1);
        } */
    }

    render () {
        const {props} = this;
        pagination = (props && props.data && props.data.length > 0) ? getPagination(props.data, 1) : [];
        //console.log(pagination.length, pagination);
        if (pagination.length > 0) {
            return <div>
                <Carousel autoPlay={false} cycleNavigation={false} animation="slide"
                    //IndicatorIcon={<FiberManualRecordIcon size='small' /* className={classes.indicatorIcon} *//>}
                    swipe={false}
                    NextIcon={<ArrowForwardIosIcon size='small' />}
                    PrevIcon={<ArrowBackIosIcon size='small' />}
                    indicatorIconButtonProps={{
                        style: {
                            padding: '3px',    // 1
                            color: '#384571'       // 3
                        }
                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            color: 'grey'
                        }
                    }}
                    indicatorContainerProps={{
                        style: {
                            marginTop: '40px', // 5
                            textAlign: 'center' // 4
                        }
                
                    }}
                >
                    { pagination.length > 0 && pagination[0].totalPages > 0 && pagination[0].totalPages > 1 ?
                        pagination.map((pp, ind, pagination) => {
                            return <PageLayout data={pp.data} currentPage={pp.currentPage} columns={props.columns}></PageLayout>                        
                            
                        })
                        :
                            <PageLayout data={props.data} currentPage={1} columns={props.columns}></PageLayout>
                    }
                </Carousel>
            </div>
        } else {
            return <NoDataComponent messageText={props.nodataMessage} />
        }
    }
}

export default LayoutUsers;