import { GroupActionTypes } from "./GroupTypes";

const INITIAL_STATE = {
  groups: [],
  isFetching: false,
  errorMessage: undefined,
  isLoading:false,
};

const groupReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GroupActionTypes.FETCH_GROUPS_START:
      return {
        ...state,
        isFetching: true
      };
    case GroupActionTypes.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        isFetching: false
      };
    case GroupActionTypes.FETCH_GROUPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
     case GroupActionTypes.UPDATE_GROUPS_START:
       return {
         ...state,
         isLoading:true,
       } 
     case GroupActionTypes.UPDATE_GROUPS_ACTION:
      const index = state.groups.findIndex(group=> group.group_id === action.payloadGroup.group_id);
      const newGroupArr = [...state.groups];
            newGroupArr[index][action.payloadValue["keyName"]] = action.payloadValue[action.payloadValue['keyName']];
       return{
        ...state,
        groups: newGroupArr,
        isLoading:false
       } 
    default:
      return state;
  }
};

export default groupReducer;