import React from "react";
import DatePicker from "react-datepicker";
import { Button, Modal } from 'react-bootstrap';
import './CustomerForm.css';
import "react-datepicker/dist/react-datepicker.css";
import superagent from "superagent";
import moment from "moment";
import ValdationBulletPoints from './fileUploadValidation';
import { S3config } from '../Utils/config';
import S3FileUpload from 'react-s3';
import CollapsiblePanel from "../Utils/CollapsiblePanel";
import CustomerModel from "./customerModel";

const currentDate = new Date();
let formObj = null;
const tomorrowDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
var HTTP_STATUS = null;
const allowedErrorCode = [500, 410, 422, 409, 405]
const allowedErrorCodeCreateEnterprise = [500, 401, 410, 422, 409, 405]; //TZ-980

const custDetailFields = [
	{ id: "fname", pholder: "Contact First Name*", type: "text", errtype: "name", required: true, readonly: false },
	{ id: "lname", pholder: "Contact Last Name*", type: "text", errtype: "name", required: true, readonly: false },
	{ id: "email", pholder: "Contact Email*", type: "email", errtype: "email", required: true, readonly: false },
	{ id: "phone", pholder: "Contact Phone*", type: "text", errtype: "phone", required: true, readonly: false },
	{ id: "compname", pholder: "Company Name*", type: "text", errtype: "company", required: true, readonly: false },
	{ id: "web", pholder: "Website*", type: "text", errtype: "website", required: true, readonly: false },
	{ id: "passcode", pholder: "Pass Code*", type: "text", errtype: "name", required: true, readonly: false },//TZ-947
];

const licensePricingFields = [
	{ id: "tprice", label: "Total Price*", type: "number", min: 0, max: 99999999999999999999, required: true, step: "1" },
	{ id: "numlcsn", label: "No of Licenses*", type: "number", min: 1, max: 99999999999999999999, required: true, step: "1" },
	{ id: "overcost", label: "Overage Cost", type: "number", min: 0, max: 99999999999999999999, required: false, step: "any" },
	{ id: "mnthmins", label: "Monthly Minutes*", type: "number", min: 0, max: 99999999999999999999, required: true, step: "1" },
	{ id: "noexpert", label: "Experts per Group*", type: "number", min: 1, max: 99999999999999999999, required: true, step: "1" },
	{ id: "installbydate", label: "Install By Date", type: "date", disabled: false, required: true, step: "1" },
];

const dateFields = [
	{ id: "sdate", label: "Start Date*", disabled: true },
	{ id: "tdate", label: "End Date*", disabled: false }
];

const customizeFields = [
	{ id: "isactive", label: "Active Status*", type: "checkbox", category: "status" }, //0
	{ id: "enablejobs", label: "Enable Jobs", type: "checkbox", category: "jobs" }, //1
	{ id: "enableofflinejobs", label: "Enable Offline Jobs", type: "checkbox", show: false, category: "jobs" }, //2
	{ id: "enableCustomerNameJobsReport", label: "Enable Customer Brand in Jobs Reports", type: "checkbox", category: "jobs" }, //3 	TP-3537
	{ id: "allusr", label: "Assign Jobs To All Users", type: "checkbox", category: "jobs" }, //4
	{ id: "ispasswordexpired", label: "Allow Password Expiry*", type: "checkbox", category: "password" }, //5
	{ id: "pwdexpiryduration", label: "Password Expiry Duration*", type: "number", min: 0, max: 60000000, step: "1", category: "password" }, //6
	{ id: "resetUserPassword", label: "Allow Reset Password", type: "dropdown", category: "password" },	//7
	{ id: "enableStreaming", label: "Enable Streaming", type: "checkbox", category: "session" }, /** TP-3037 & TP-2789 */ //8
	{ id: "stype", label: "Streaming Type*", type: "dropdown", category: "session" }, //9
	{ id: "userVideo", label: "Enable Expert Video*", type: "dropdown", category: "session" }, //	TP-3537
	{ id: "autoConnect", label: "Enable Auto Connect", type: "checkbox", category: "session" },
	{ id: "smode", label: "Support Relay Mode", type: "checkbox", category: "session" },
	{ id: "scanasset", label: "Scan Asset", type: "checkbox", category: "session" },
	{ id: "smedium", label: "Streaming Medium*", type: "dropdown", category: "session" },
	{ id: "amode", label: "Enable Tech to Tech*", type: "checkbox", category: "session" },
	// {id:"installbydate", label:"Install By Date", type:"date", disabled: false, minDate: null},
	{ id: "issoloprogram", label: "Solo Program*", type: "checkbox", category: "status" },
	{ id: "guestuser", label: "Allow Guest User*", type: "checkbox", category: "session" },
	{ id: "inviteOnlineUser", label: "Allow Invite Users", type: "checkbox", category: "session" },
	{ id: "expertscreen", label: "Allow Expert Screen Share*", type: "checkbox", category: "session" },
	//{id:"screenshare", label:"Allow Remote User Screen Share*", type:"checkbox",show:false}, // ` Rs: Commented out ot remove screen share from screen

	{ id: "recordingType", label: "Recording Type", type: "dropdown", category: "session" },
	// {id:"isscreenrecording", label:"Allow Screen Recording*", type:"checkbox",category:"session"},
	{ id: "iszoom", label: "Allow Zoom*", type: "checkbox", category: "session" },
	{ id: "istorch", label: "Allow Torch*", type: "checkbox", category: "session" },
	{ id: "isannotationcolor", label: "Annotation Color Change*", type: "checkbox", category: "session" },
	{ id: "isannotationshape", label: "Annotation Shape Change*", type: "checkbox", category: "session" },
	{ id: "isannotationpointer", label: "Annotation Pointer", type: "dropdown", category: "session" }, //TP-4659
	{ id: "swapcamera", label: "Swap Camera Enabled", type: "checkbox", category: "session" },
	{ id: "canturnoffuservideo", label: "Allow Expert to turn off video", type: "dropdown", category: "session" }, //TP-4644
	{ id: "expertcandisconnectuser", label: "Allow Expert to Disconnect User", type: "dropdown", category: "session" }, //TP-4644
	{ id: "downloadUsage", label: "Allow Download Usage as CSV", type: "checkbox", category: "session" },
	// {id:"enableSilentRecording",label:"Allow Silent Recording",type:"checkbox",category:"session"},
	{ id: "enableRemoteCapture", label: "Allow Remote Capture", type: "checkbox", category: "session" },
	{ id: "enableWakeUpDevice", label: "Allow Wake Up Device", type: "checkbox", category: "session" },
	{ id: "enableIosLogin", label: "Allow iOS Login", type: "dropdown", category: "status" }, //TP-4051
	/**TP-2659 */
	{ id: "enableAssetsClass", label: "Enable Assets", type: "dropdown", category: "jobs" },//TP-4644
	{ id: "enableAssetOffline", label: "Enable Asset Offline", type: "checkbox", category: "jobs" }, //TZ-999
	{ id: "enableKnowledgeCapture", label: "Enable Knowledge Capture", type: "checkbox", category: "jobs" },
	{ id: "enableChecklist", label: "Enable Checklist", type: "checkbox", category: "jobs" },
	{ id: "mandatoryAssigneeJobs", label: "Mandatory Assignee", type: "checkbox", category: "jobs" },
	{ id: "idleTimeout", label: "Idle Time* (in minutes)", type: "number", min: 0, max: 60000000, category: "session" },
	{ id: "enableTemplates", label: "Enable Templates", type: "dropdown", category: "jobs" }, //TP-4644
	{ id: "enableNextTask", label: "Enable Next Task", type: "checkbox", category: "jobs" },
	{ id: "enableSkipTask", label: "Enable Skip Task", type: "checkbox", category: "jobs" },
	{ id: "allowNewEvidence", label: "Allow New Evidence", type: "dropdown", category: "jobs" },//TP-4644 & TP-4288
	// { id: "jobByTechnician", label: "Create Job by Technician", type: "dropdown", category: "jobs" }, //TP-4454
	// { id: "enableJobScheduler", label: "Job Recurrence", type: "dropdown", category: "jobs" },//TP-5006
	{ id: "enableIncident", label: "Enable Incident", type: "checkbox", category: "jobs" },
	{ id: "enableInspection", label: "Enable Inspection", type: "checkbox", category: "jobs" },
	{ id: "allowSmsSupport", label: "SMS Support", type: "checkbox", category: "status" }, //TP-5264
	{ id: "emailnotification", label: "Email Notification", type: "checkbox", category: "status" },//TZ-972
	{ id: "safetyScanFrequency", label: "Safety Scan Frequency* (in seconds)", type: "number", min: 1, max: 9999, category: "jobs" }, //TP-6260
	{ id: "genericNameAdmin", label: "Admin", type: "text", category: "genericname" }, //TP-5932
	{ id: "genericNameExpert", label: "Expert", type: "text", category: "genericname" }, //TP-5932
	{ id: "genericNameTechnician", label: "Technician", type: "text", category: "genericname" }, //TP-5932
];

const licenseKey = [
	{ id: "dbLKey", label: "License Key", tooltip: "Current License Key copied", class: "license-text", readonly: true, disabled: true },
	{ id: "lkey", label: "Modified License Key", tooltip: "Changed License Key copied", class: "license-text", readonly: true, hidden: true }
];

const confirmationKey = [
	{ id: "confirmKey", type: "text", label: "Confirmation Code", errtype: "passcode", required: false, maxlength: 6 }
]

const behaviourKey = [
	{ id: "behaviour", type: "dropdown", label: "Behaviour*" }
]

const rebranding = {
	item: { id: "allowRebranding", label: "Allow Company Logo (Jobs Report)", type: "checkbox", class: "customer-checkbox" }
}

const pattern = {
	name: { pattern: "^[\\w\\d~!@#$%^&*()_+-={}:\"<>?`[];',./`|\\]*$", errMsg: "Please enter a valid text" },
	phone: { pattern: "^[\\w \\d~!@#$%-^=&\\\\*_+:?`',./|<>{}()\"\[\\\]]{9,}$", errMsg: "Please enter minimum 9 characters" },
	email: { pattern: "^[\\w\\d._%+-]+@[\\w\\d.-]+\.[\\w]{2,4}$", errMsg: "Please enter valid email address" },
	company: { pattern: "^[\\w\\d~!@#$-%=^&*()_+{}:\"<>?`[];',./`|\\]*$", errMsg: "Please enter valid company name" },
	website: { pattern: "^[\\w\\d~!@#=$%^&*()_+{}:\"<>?`[];',./`|\\]*$", errMsg: "Please enter valid website URL" },
	tprice: { pattern: "^[\\d]*$", errMsg: "Please enter valid number" },
	numlcsn: { pattern: "^[\\d]*$", errMsg: "Please enter valid number" },
	overcost: { pattern: "^[\\d]+(\.[\\d]+)?$", errMsg: "Please enter valid number" },
	mnthmins: { pattern: "^[\\d]*$", errMsg: "Please enter valid number" },
	noexpert: { pattern: "^[\\d]*$", errMsg: "Please enter valid number" },
	pwdexpiryduration: { pattern: "^[\\d]*$", errMsg: "Please enter valid number" },
	alphanumeric: { pattern: "^[a-zA-Z0-9]+$", errMsg: "Please enter only alphanumeric characters" },
	passcode: { pattern: "^[\\d]*$", errMsg: "Please enter valid numbers" },
	idleTimeout: { pattern: "^[\\d]*$", errMsg: "Please enter valid time in minutes" },
	safetyScanFrequency: { pattern: "^[\\d]*$", errMsg: "Please enter valid time in seconds" }, //TP-6260
	// genericname: { pattern: "^[a-zA-Z0-9]+$", errMsg: "Please enter only alphanumeric characters" }, //TP-5932
}

//TP-2789
const sessionNodes = ["userVideo", "stype", "autoConnect", "smode", "scanasset", "smedium", "amode", "issoloprogram", "guestuser",
	"inviteOnlineUser", "expertscreen", "recordingType", "iszoom", "istorch", "isannotationcolor",
	"isannotationshape", "isannotationpointer", "swapcamera", "canturnoffuservideo", "expertcandisconnectuser",
	"downloadUsage", "enableRemoteCapture", "enableWakeUpDevice", "idleTimeout"];//TP-4659

const smartVisionScreenKey = {
	custFields: {
		scanasset: true,
		isactive: true,
		issoloprogram: true,
		expertscreen: true,
		screenscreen: true,
		ispasswordexpired: true,
		isscreenrecording: true,
		iszoom: true,
		istorch: true,
		isannotationcolor: true,
		isannotationshape: true,
		canturnoffuservideoSelected: "disabled", /**TP-4644*/
		pwdexpiryduration: 365,
		enablejobs: false,
		enableofflinejobs: false,
		expertcandisconnectuserSelected: "disabled", /**TP-4644*/
		downloadUsage: true,
		enableSilentRecording: false,
		enableRemoteCapture: false,
		enableWakeUpDevice: false,
		enableIosLoginSelected: "ios_login_disabled", /**TP-4051*/
		isannotationpointerSelected: "disabled", /**TP-4659*/
		inviteOnlineUser: true,
		resetUserPasswordSelected: "reset_password_with_qr",
		allowSmsSupport: false, //TP-5264
		},
	custModification: [
		{ id: "smodediv", visibility: false },
		{ id: "enablejobsdiv", visibility: false },
		{ id: "allusrdiv", visibility: false },
		{ id: "smediumdiv", visibility: false },
		{ id: "guestuserdiv", visibility: false },
		{ id: "swapcameradiv", visibility: false },
		{ id: "noexpert", value: 1 },
		{ id: "pwdexpiryduration", disabled: true, value: 365, reverseValue: 45 },
		{ id: "tpricediv", visibility: false },
		{ id: "safetyScanFrequencydiv", visibility: false }, //TP-6260

	],
	dependentObject: [
		{ dependentId: "ispasswordexpired", checked: true, id: "pwdexpirydurationdiv", visibility: false },
	]
}

const mandatoryFields = [
	{ id: "amode", label: "Audio Mode*", type: "dropdown" },
	{ id: "smedium", label: "Streaming Medium*", type: "dropdown" },
	{ id: "stype", label: "Streaming Mode*", type: "dropdown" }
]


const pendingApprovalDivMSG = <div className="pending-app-class"><i className="fa fa-info-circle" style={{ color: '#dc3545' }}>
	<span className="fa-icon-innter-text">&nbsp;Your changes are pending approval</span></i></div>;

const ErrorValidationLabel = ({ txtLbl }) => (
	<label htmlFor="" className="col-xs-4 col-xs-offset-2 inptError">
		{txtLbl}
	</label>
);

const SelOptionsTag = ({ selectValue }) => (
	selectValue.map((r, i) => {
		// console.log(r,i)
		return <option key={r.id} value={r.id}>{r.value}</option>
	}
	)
);

function showLoader(isLoading) {
	if (!isLoading) return "";
	else return (<i className="fa fa-spinner fa-spin"></i>)
}



const AccountInfoStatusDIV = (dataProps) => {
	let { props } = dataProps;
	return (<div className="cust-account-header">
		{
			props.items.map((item) => {
				if (item.category === 'status') {
					return (<MakeCustomUI props={props} item={item} key={item.id} />)
				}
			})
		}
	</div>)
}

const AccountInforJobs = (dataProps) => {
	let { props } = dataProps;
	return (<div className="cust-account-header">{
		props.items.map((item) => {
			if (item.category === 'jobs') {
				return (<MakeCustomUI props={props} item={item} key={item.id} />)
			}
		})
	}

	</div>)
}


const AccountInfoPassword = (dataProps) => {
	let { props } = dataProps;
	return (<div className="cust-account-header">{
		props.items.map((item) => {
			if (item.category === 'password') {
				return (<MakeCustomUI props={props} item={item} key={item.id} />)
			}
		})
	}

	</div>)
}


const AccountInfoSession = (dataProps) => {
	let { props } = dataProps;
	return (<div className="cust-account-header">{
		props.items.map((item) => {
			if (item.category === 'session') {
				return (<MakeCustomUI props={props} item={item} key={item.id} />)
			}
		})
	}

	</div>)
}

const GenericNameSection = (dataProps) => {
	let { props } = dataProps;
	return (<div className="cust-account-header">{
		props.items.map((item) => {
			if (item.category === 'genericname') {
				return (<MakeCustomUI props={props} item={item} key={item.id} />)
			}
		})
	}
		</div>)
}



const MakeCustomUI = (passedDataProps) => {

	let { item, props } = passedDataProps;

	//TP-2789
	let showClassSession = "display-show";
	if (props.values["enableStreaming"] === false) {
		showClassSession = "display-hide";
	}

	if (item.type === "dropdown") {
		//TP-4454
		let showJobClass = "display-show";

		if (props.values["enablejobs"] === false) {
			showJobClass = "display-hide";
		}
		let showClass = "display-show";

		if (props.values["stypeSelected"] !== "RM") {
			showClass = "display-hide";
		}
		//TP-4659
		let showAnnPointer = "display-show";
		if (props.values["isannotationshape"] === false) {
			showAnnPointer = "display-hide";
		}
		//TP-2789
		if (sessionNodes.indexOf(item.id) > -1) {
			return (
				<div className={props.className ? (item.id === "userVideo") ? props.className + " " + showClassSession + " " + showClass : (item.id === "isannotationpointer") ? props.className + " " + showClassSession + " " + showAnnPointer : props.className + " " + showClassSession : (item.id === "userVideo") ? "four " + showClassSession + " " + showClass : (item.id === "isannotationpointer") ? "four " + showClassSession + " " + showAnnPointer : "four " + showClassSession} id={item.id + "div"} key={item.id} category={item.category} >
					<label className="col-xs-3" htmlFor={item.id}>
						{item.label}
					</label>
					<select className="customDrop" id={item.id} value={props.values[item.id + "Selected"]} onChange={props.onClickField}>
						<SelOptionsTag selectValue={props.values[item.id + "Items"]} />
					</select>
				</div>
			);
		} else {
			return (
				<div className={props.className ? (item.id === "enableJobScheduler" /**TP-5006*/ || item.id === "jobByTechnician" /**TP-4454*/ || item.id === "allowNewEvidence" /**TP-4644*/ || item.id === "enableAssetsClass") ? "four " + showJobClass : props.className : (item.id === "enableJobScheduler" || item.id === "jobByTechnician" || item.id === "allowNewEvidence" || item.id === "enableAssetsClass" || item.id === "enableTemplates" /**TP-4644*/) ? "four " + showJobClass : "four"} id={item.id + "div"} key={item.id} category={item.category} >
					<label className="col-xs-3" htmlFor={item.id}>
						{item.label}
					</label>
					<select className="customDrop" id={item.id} value={props.values[item.id + "Selected"]} onChange={props.onClickField}>
						<SelOptionsTag selectValue={props.values[item.id + "Items"]} />
					</select>
				</div>
			);
		}
	}
	else if (item.type === "checkbox") {
		let showClass = "display-show", showOfflineJobs = "display-show"; /**TZ-999 */

		if (props.values["enablejobs"] === false) {
			showClass = "display-hide";
		}
		//TZ-999
		if (props.values["enableofflinejobs"] === false) {
			showOfflineJobs = "display-hide";
		}
		//TP-2939
		let showMandatoryAssignee = "display-show";
		if (props.values["enableAssetsClassSelected"] === "disabled" /**TP-4644*/) {
			showMandatoryAssignee = "display-hide";
		}
		/**TP-2659 -- New condition added to show the Enable KC toggle 
		 * only when "enableIosLogin" is enabled on demand/for all */
		let showClassKC = "display-show";
		//TP-4051
		if (props.values["enableIosLoginSelected"] === "ios_login_disabled") {
			showClassKC = "display-hide";
		}

		//TP-2789
		if (sessionNodes.indexOf(item.id) > -1) {
			return (
				<div
					className={"three " + showClassSession}
					id={item.id + "div"} key={item.id} category={item.category}
				>
					<label className="col-xs-3" htmlFor={item.id}>
						{item.label}&nbsp; </label>
					<label className="col-xs-4 col-xs-offset-2 switch">
						<input type="checkbox" id={item.id} name={item.id} disabled={item.disabled} onChange={props.onClickField} checked={props.values[item.id]} />
						<span className="slider round"></span>
					</label>
					{"isactive" === item.id ? (<label htmlFor={item.id}>&nbsp;{props.values[item.id] ? "Active" : "Inactive"}</label>) :
						(<label htmlFor={item.id}>&nbsp;{props.values[item.id] ? "Yes" : "No"}</label>)}
				</div>
			)
		} else {
			return (
				<div
					className={item.id === "enableofflinejobs" ||
						item.id === "enableCustomerNameJobsReport" || item.id === "allusr" ||
							/**TP-2659 */ item.id === "enableChecklist" || /**TP-3150 */ item.id === "enableNextTask" ||
						item.id === "enableSkipTask" ? "three " + showClass : (item.id === "enableKnowledgeCapture") ?
						"three " + showClassKC + " " + showClass /**TP-2659 */ :
						item.id === "mandatoryAssigneeJobs" ? "three " + showMandatoryAssignee : 
						item.id === "enableAssetOffline" /**TZ-999*/ ? "three " + showClass + " " + showMandatoryAssignee + " " + showOfflineJobs : "three"}
					id={item.id + "div"} key={item.id} category={item.category}
				>
					<label className="col-xs-3" htmlFor={item.id}>
						{item.label}&nbsp; </label>
					<label className="col-xs-4 col-xs-offset-2 switch">
						<input type="checkbox" id={item.id} name={item.id} disabled={item.disabled} onChange={props.onClickField} checked={props.values[item.id]} />
						<span className="slider round"></span>
					</label>
					{"isactive" === item.id ? (<label htmlFor={item.id}>&nbsp;{props.values[item.id] ? "Active" : "Inactive"}</label>) :
						(<label htmlFor={item.id}>&nbsp;{props.values[item.id] ? "Yes" : "No"}</label>)}
				</div>
			);

		}

	}
	else if (item.type === "number") {
		//TP-2789
		if (sessionNodes.indexOf(item.id) > -1) {
			return (
				<div className={"three " + showClassSession} id={item.id + "div"} key={item.id} category={item.category} >
					<label className="col-xs-3" htmlFor={item.id}> {item.label}	</label>
					<input className="col-xs-4 col-xs-offset-2 cust-number" id={item.id} name={item.id} disabled={item.disabled} type={item.type} min={item.min} value={props.values[item.id]} step={item.step}
						max={item.max} errtype={item.errtype} onChange={props.onClickField} />
					<ErrorValidationLabel txtLbl={props.error[item.id] ? props.error[item.id] : ""} />
				</div>
			);
		} else {
			return (
				<div className="three" id={item.id + "div"} key={item.id} category={item.category} >
					<label className="col-xs-3" htmlFor={item.id}> {item.label}	</label>
					<input className="col-xs-4 col-xs-offset-2 cust-number" id={item.id} name={item.id} disabled={item.disabled} type={item.type} min={item.min} value={props.values[item.id]} step={item.step}
						max={item.max} errtype={item.errtype} onChange={props.onClickField} />
					<ErrorValidationLabel txtLbl={props.error[item.id] ? props.error[item.id] : ""} />
				</div>
			);
		}
	}
	else if (item.type === "text") {
		return (
			<div className="four" id={item.id + "div"} key={item.id} category={item.category}>
				<label className="col-xs-3" htmlFor={item.id}>{item.label}</label>
				<input className="col-xs-4 col-xs-offset-2 cust-number" id={item.id} name={item.id} disabled={item.disabled} type={item.type} value={props.values[item.id]}
					errtype={item.errtype} onChange={(event) => props.onClickField(event, item.errtype)} />
				<ErrorValidationLabel txtLbl={props.error[item.id] ? props.error[item.id] : ""} />
			</div>
		);
	}
	if (item.type === "date") {
		return (
			<div className="four" id={item.id + "div"} key={item.id} category={item.category}>
				<label className="col-xs-4" htmlFor={item.id}> {item.label} </label>
				<DatePicker id={item.id} popperPlacement="right" disabled={item.disabled} minDate={item.minDate} calendarClassName="rasta-stripes" selected={props.values[item.id]} onChange={(date) => props.onClickField(date, item.id)} />
			</div>
		);
	}
};


function FormFields(props) {
	var listItem;


	switch (props.type) {
		case "details":
			listItem = (
				<div className="detfld">
					{props.items.map((item) =>
						<div className="one" id={item.id + 'div'} key={item.id}>
							<input type={item.type}
								className={props.error[item.id] ? "col-xs-3 error" : "col-xs-3"}
								name={item.id} id={item.id}
								onChange={event => props.onClickField(event, item.errtype)}
								placeholder={item.pholder}
								value={props.values[item.id]}
								errtype={item.errtype}
								disabled={item.disabled}
								required={item.required}
								readOnly={item.readonly} />
							<ErrorValidationLabel txtLbl={props.error[item.id] ? props.error[item.id] : ""} />
						</div>
					)
					}
				</div>
			)
			break;
		case "pricing":
			listItem = (
				<div className="row pricefld">
					{props.items.map((item) => {
						if (item.type === 'date') {
							return (
								<div className="col-6" id={item.id + 'div'} key={item.id}>
									<label className="col-xs-3" htmlFor={item.id}>{item.label}</label>
									<DatePicker id={item.id}
										popperPlacement="left"
										className='enterpriseRowDate'
										disabled={item.disabled} minDate={item.minDate}
										calendarClassName="rasta-stripes"
										selected={props.values[item.id]}
										onChange={date => props.onClickField(date, item.id)} />
									<ErrorValidationLabel txtLbl={props.error[item.id] ? props.error[item.id] : ""} />
								</div>
							)
						} else {
							return (
								<div className="col-6" id={item.id + 'div'} key={item.id}>
									<label className="col-xs-3" htmlFor={item.id}>{item.label}</label>
									<input name={item.id} className="col-xs-4 col-xs-offset-2" id={item.id}
										onChange={event => props.onClickField(event, item.errtype)} type={item.type} min={item.min}
										value={props.values[item.id]} readOnly={item.readonly} max={item.max}
										disabled={item.disabled} step={item.step}
										errtype={item.errtype} required={item.required} />
									<ErrorValidationLabel txtLbl={props.error[item.id] ? props.error[item.id] : ""} />
								</div>
							)
						}
					})
					}
				</div>
			)
			break;
		case "date":
			listItem = (
				<div className="row pricefld-date">
					{props.items.map((item) =>
						<div className="col-6" id={item.id + 'div'} key={item.id}>
							<label className="col-xs-3" htmlFor={item.id}>{item.label}</label><br></br>
							<DatePicker id={item.id}
								popperPlacement="left"
								disabled={item.disabled} minDate={item.minDate}
								calendarClassName="rasta-stripes"
								selected={props.values[item.id]}
								onChange={date => props.onClickField(date, item.id)} />
						</div>
					)}
				</div>
			)
			break;
		case "licensekey":
			let lkeyClass = 'd-blk';
			if (props.values['dbLKey'] === props.values['lkey'])
				lkeyClass = 'd-none';
			listItem = (
				<div className="row pricefld">
					{props.items.map((item) =>
						<>	{props.values['dbLKey'] &&
							<div className={item.id === "lkey" ? "col-sm-12 " + lkeyClass : "col-sm-12"} id={item.id + 'div'} key={item.id} hidden={item.hidden ? item.hidden : false}>
								<label className="col-xs-3" htmlFor={item.id}>{item.label}</label><br></br>
								<textarea name={item.id} className={item.class + " col-xs-4 col-xs-offset-2"} id={item.id} readOnly={item.readonly}
									disabled={item.disabled} style={item.id === "lkey" ? { color: 'red' } : { color: 'blue' }}
									value={props.values[item.id] !== null ? props.values[item.id] : 0} >
								</textarea>&nbsp;
								<i className="fa fa-copy" id={item.id + 'btn'}
									style={item.id === "lkey" ? { color: 'red', position: 'absolute', marginLeft: '23em' } : { color: 'blue', position: 'absolute', marginLeft: '23em' }}
									onClick={event => props.onClickField(event, props.values[item.id])}></i>
								<div id={item.id + 'tooltip'} className="tooltiptext">
									<span >{item.tooltip}</span>
								</div>
							</div>
						}
							<div className="col-md-6">
								<br></br><br></br>
							</div>
						</>
					)}
				</div>
			)
			break;
		case "confirmationKey":
			listItem = (
				<div className="row pricefld divOuter">
					<div className="col-6 divInner" id={props.items[0].id + 'div'}>
						<label className="col-xs-3" htmlFor={props.items[0].id}>{props.items[0].label}</label>
						<input className="col-xs-4 col-xs-offset-2 partitioned" name={props.items[0].id}
							id={props.items[0].id} key={props.items[0].id}
							type={props.items[0].type} maxLength={props.items[0].maxlength}
							onChange={event => props.onClickField(event, props.items[0].errtype)}
							required={props.items[0].required} />
						<ErrorValidationLabel txtLbl={props.error[props.items[0].id] ? props.error[props.items[0].id] : ""} />
					</div>
				</div>
			)
			break;
		case "behaviourKey":
			listItem = (
				<div className="bevfld">
					{props.items.map((item) =>
						<div className="col-6" id={item.id + 'div'} key={item.id}>
							<label className="col-xs-3" htmlFor={item.id}>{item.label}</label>
							<select className="customDrop" id={item.id}
								value={props.values[item.id + 'Selected']}
								onChange={props.onClickField}>
								<SelOptionsTag selectValue={props.values[item.id + 'Items']} />
							</select>
							<ErrorValidationLabel txtLbl={props.error[item.id] ? props.error[item.id] : ""} />
						</div>
					)
					}
				</div>
			)
			break;
		default:
			listItem = (
				<div id="customfld" className={props.className ? props.className + "-custfld" : "custfld"}>
					<CollapsiblePanel title="Account Information" collapse={props.state ? props.state.collapse : true}>
						{<AccountInfoStatusDIV props={props} key="status-custfld" />
						}</CollapsiblePanel>
					<CollapsiblePanel title="Jobs Information" collapse={props.state ? props.state.collapse : true}>
						{<AccountInforJobs props={props} key="jobs-custfld" />
						}</CollapsiblePanel>
					<CollapsiblePanel title="Password" collapse={props.state ? props.state.collapse : true}>
						{<AccountInfoPassword props={props} key="password-custfld" />
						}</CollapsiblePanel>
					<CollapsiblePanel title="Session Information" collapse={props.state ? props.state.collapse : true}>
						{<AccountInfoSession props={props} key="session-custfld" />
						}</CollapsiblePanel>
					<CollapsiblePanel title="Generic Name Change" collapse={props.state ? props.state.collapse : true}>
						{<GenericNameSection props={props} key="generic-custfld" />
						}</CollapsiblePanel>
				</div>
			)
			break;
	}

	return (
		<div>
			{listItem}
		</div>
	);
}


function padLeadingZeros(num, size) {
	var s = num + "";
	while (s.length < size) s = "0" + s;
	return s;
}

export default class EnterpriseForm extends React.Component {
	constructor(props) {
		super(props);
		this.error = {
			fname: false,
			lname: false,
			email: false,
			phone: false,
			compname: false,
			web: false,
			numlcsn: false,
			tprice: false,
			overcost: false,
			mnthmins: false,
			noexpert: false,
			pwdexpiryduration: false,
			passcode: false,
			installbydate: false,
		}

		this.state = {
			licenseKey: {
				value: null
			},
			rawCustomerData: {},
			showDataModal: false,
			serverErrorCode: 200,
			detFields: {
				fname: "",
				lname: "",
				email: "",
				phone: "",
				compname: "",
				web: "",
				passcode: "",//TZ-947
				allowRebranding: false,
				file: null,
				jobsLogoUrl: null,
			},
			priceFields: {
				numlcsn: 10,
				tprice: 1250,
				overcost: 0,
				mnthmins: 1000,
				noexpert: 1,
				installbydate: currentDate,
			},
			dateFields: {
				sdate: currentDate,
				tdate: tomorrowDate,
			},
			custFields: {
				smode: false,
				autoConnect: true,
				enablejobs: false,
				enableofflinejobs: false,
				allusr: false,
				scanasset: false,
				userVideoItems: [
					{ value: "My camera enabled", id: "my_camera_enabled" },
					{ value: "My camera disabled", id: "my_camera_disabled" },
					{ value: "My camera enabled with preferences", id: "my_camera_enabled_with_prefs" }
				],
				userVideoSelected: "my_camera_disabled",
				enableAssetsClassItems: [
					{ value: "Disabled", id: "disabled" },
					{ value: "Enabled for All", id: "enabled_for_all" },
					{ value: "Enabled On Demand", id: "enabled_on_demand" }
				],
				enableAssetsClassSelected: "disabled",//TP-4644
				enableAssetOffline: false, //TZ-999
				enableTemplatesItems: [
					{ value: "Disabled", id: "disabled" },
					{ value: "Enabled for All", id: "enabled_for_all" },
					{ value: "Enabled On Demand", id: "enabled_on_demand" }
				],
				enableTemplatesSelected: "disabled",//TP-4644
				jobByTechnicianItems: [
					{ value: "Disabled", id: "disabled" },
					{ value: "Enabled for All", id: "enabled_for_all" },
					{ value: "Enabled for Some", id: "enabled_for_some" }
				],
				jobByTechnicianSelected: "disabled", /**TP-4454*/
				enableJobSchedulerItems: [
					{ value: "Disabled", id: "disabled" },
					{ value: "Enabled for All", id: "enabled_for_all" },
					{ value: "Enabled On Demand", id: "enabled_on_demand" }
				],
				enableJobSchedulerSelected: "disabled", /**TP-5006*/
				allowNewEvidenceItems: [
					{ value: "Disabled", id: "disabled" },
					{ value: "Enabled for All", id: "enabled_for_all" },
					{ value: "Enabled On Demand", id: "enabled_on_demand" }
				],
				allowNewEvidenceSelected: "disabled",//TP-4644
				smediumItems: [
					{ value: "Open Tok", id: "open_tok" },
					{ value: "OWRTC", id: "onprem" }, /**TP-3537*/
					{ value: "Both", id: "both" }
				],
				smediumSelected: "onprem",
				amodeItems: [
					{ value: "Expert to Tech", id: 'expert2Tech' },
					{ value: "Tech to Tech", id: 'tech2Tech' }
				],
				amodeSelected: "expert2Tech",
				stypeItems: [
					{ value: "Legacy Streaming Mode (RM)", id: "RM" },
					/*TP-3537 {value:"Collaboration Mode (CM)", id:"CM"},
					{value:"Presentation Mode (PM)", id:"PM"}, */
				],	//TP-3043
				stypeSelected: "RM", //TP-3043
				//installbydate: currentDate,
				recordingTypeItems: [
					{ value: 'None', id: 'recording_type_none' },
					{ value: 'On Demand', id: 'screen_recording_enabled' },
					{ value: 'Silent', id: 'enable_silent_recording' },
				],
				recordingTypeSelected: 'recording_type_none',
				isactive: false,
				allowSmsSupport: false, //TP-5264
				issoloprogram: false,
				guestuser: false,
				expertscreen: false,
				screenscreen: false,
				ispasswordexpired: false,
				pwdexpiryduration: 45,
				isscreenrecording: false,
				iszoom: false,
				istorch: false,
				isannotationcolor: false,
				isannotationshape: false,
				isannotationpointerItems: [
					{ value: "Disabled", id: "disabled" },
					{ value: "Enabled for All", id: "enabled_for_all" },
					{ value: "Enabled On Demand", id: "enabled_on_demand" }
				],
				isannotationpointerSelected: "disabled", //TP-4659
				swapcamera: false,
				canturnoffuservideoItems: [
					{ value: "Disabled", id: "disabled" },
					{ value: "Enabled for All", id: "enabled_for_all" },
					{ value: "Enabled On Demand", id: "enabled_on_demand" }
				],
				canturnoffuservideoSelected: "disabled", //TP-4644
				expertcandisconnectuserItems: [
					{ value: "Disabled", id: "disabled" },
					{ value: "Enabled for All", id: "enabled_for_all" },
					{ value: "Enabled On Demand", id: "enabled_on_demand" }
				],
				expertcandisconnectuserSelected: "disabled", //TP-4644
				resetUserPasswordItems: [
					{ value: "None", id: 'none' },
					{ value: "With QR", id: 'reset_password_with_qr' },
					{ value: "Without QR", id: 'reset_password_without_qr' }
				],
				resetUserPasswordSelected: "none",
				downloadUsage: false,
				enableSilentRecording: false,
				enableRemoteCapture: false,
				enableWakeUpDevice: false,
				enableIosLoginItems: [
					{ value: "Disabled (default)", id: 'ios_login_disabled' },
					{ value: "Enabled on demand", id: 'ios_login_enabled_ondemand' },
					{ value: "Enabled for all experts", id: 'ios_login_enabled_all' }
				], //TP-4051
				enableIosLoginSelected: "ios_login_disabled", //TP-4051
				enableKnowledgeCapture: false, //TP-2659
				enableChecklist: false, //TP-2659
				enableNextTask: false, //TP-3150
				enableSkipTask: false, //TP-3150				
				inviteOnlineUser: false,
				enableCustomerNameJobsReport: false,
				mandatoryAssigneeJobs: false, //TP-2939
				emailnotification: true,//TZ-972
				enableStreaming: false, // TP-3037 & TP-2789
				idleTimeout: 15, //TP-2143
				safetyScanFrequency: 15, // TP-6260
				genericNameAdmin: null, // TP-5932
				genericNameExpert: null,
				genericNameTechnician: null,
				enableIncident:false,
				enableInspection:false,
			}, 
			behaviourFields: {
				behaviourItems: [
					{ value: "Default", id: "default" },
					{ value: "Smart Vision", id: "smartvision" },
					{ value: "VuYu", id: "vuyu" },
					{ value: "Pilot", id: "pilot" },
					{ value: "Hindalco", id: "hindalco" },      /* TP-4647*/
					{ value: "PnDSafety", id: "pndsafety" },    /* TP-5322*/
					{ value: "Honeywell", id: "honeywell" },     /* TP-6601 */
					{ value: "FDA", id: "fda" },        /**TZ-1519 */
					{ value: "PGCS", id: "pgcs" },      /**TZ-1519 */
					{ value: "Fort Liberty", id: "fortliberty" }    /**TZ-1519 */
				],
				behaviourSelected: "default",
			},
			showModal: false,
			showPassModal: false,
			modalTitle: null,
			modalBody: null,
			isValidForm: false,
			isLoading: false,
			showAlert: false,
			showConfirmModal: false, //TP-2659
			dbCustFieldsValue: {},
			imageErrorMSG: null,
			uploadedFileInfo: null,
			collapse: true,
		};

		formObj = this;
	}

	copyToClipboard = (e, str) => {
		//console.log(e.target.id);
		var key = e.target.id.replace('btn', '');
		let tooltip = key + 'tooltip';
		const toolElem = document.getElementById(tooltip);
		toolElem.style.visibility = 'visible';
		const el = document.createElement('textarea');
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		this.wait = setTimeout(() => {
			toolElem.style.visibility = 'hidden';
			clearTimeout(this.wait);
		}, 5000);
	}

	generatePassCode = (code) => {
		let passcode = '0';
		const { licenseKey } = this.state;
		if (licenseKey.dbLKey === 0 || licenseKey.dbLKey === null) return passcode;
		const licenseArr = Array.from(licenseKey.dbLKey, Number);
		const arr = Array.from(code, Number);
		passcode = String(licenseArr[arr[0] * 10 + arr[1] - 1]) + String(licenseArr[arr[2] * 10 + arr[3] - 1]) + String(licenseArr[arr[4] * 10 + arr[5] - 1]);
		//console.log("passcode is  ",passcode);
		return passcode;
	}

	getParamsState = () => {
		return ({
			//Common parsing data
			"quantity": +this.state.priceFields.numlcsn,
			"monthly_minutes": +this.state.priceFields.mnthmins,
			"overage_cost": this.state.priceFields.overcost,
			"audio_mode": this.state.custFields.amodeSelected,
			"enable_user_video": this.state.custFields.userVideoSelected,
			"create_scan_job_by_technician": this.state.custFields.jobByTechnicianSelected, //TP-4454
			"enable_job_scheduler": this.state.custFields.enableJobSchedulerSelected, //TP-5006
			"streaming_medium": this.state.custFields.smediumSelected,
			"license_key": this.state.licenseKey.lkey,
			//"install_by_date": new Date(this.state.custFields.installbydate.setHours(0,0,0,0)).toISOString(),
			"install_by_date": new Date(this.state.priceFields.installbydate.setHours(0, 0, 0, 0)).toISOString(),
			"number_of_experts": +this.state.priceFields.noexpert,
			"start_date": moment(this.state.dateFields.sdate).format("YYYY-MM-DD"),
			"end_date": new Date(this.state.dateFields.tdate.setHours(0, 0, 0, 0)).toISOString(),
			"is_customizable": this.state.custFields.smode,
			"enable_auto_connect": this.state.custFields.autoConnect,
			"session_type": this.state.custFields.stypeSelected, //TP-3043
			"is_gps_enabled": this.state.custFields.enablejobs,
			"is_offline_jobs_enabled": this.state.custFields.enableofflinejobs,
			"assign_jobs_to_all_users": this.state.custFields.allusr,
			"asset_scan": this.state.custFields.scanasset,
			"is_solo_program": this.state.custFields.issoloprogram,
			"guest_user_enabled": this.state.custFields.guestuser,
			"screen_share_mode": this.getScreenShareMode(this.state.custFields),
			"check_password_expiry": this.state.custFields.ispasswordexpired,
			"password_expiry_duration": !this.state.custFields.ispasswordexpired ? 0 : +this.state.custFields.pwdexpiryduration,
			//"screen_recording_enabled": this.state.custFields.isscreenrecording,
			"screen_recording_enabled": this.state.custFields.recordingTypeSelected && this.state.custFields.recordingTypeSelected === 'screen_recording_enabled' ? true : false,
			"zoom_enabled": this.state.custFields.iszoom,
			"torch_enabled": this.state.custFields.istorch,
			"annotation_color_enabled": this.state.custFields.isannotationcolor,
			"annotation_shape_enabled": this.state.custFields.isannotationshape,
			"allow_annotation_pointer": this.state.custFields.isannotationpointerSelected, /**TP-4659*/
			"swap_camera_enabled": this.state.custFields.swapcamera,
			"can_turn_off_user_video": this.state.custFields.canturnoffuservideoSelected, /**TP-4644*/
			"expert_can_disconnect_user": this.state.custFields.expertcandisconnectuserSelected, /**TP-4644*/
			"behaviour": this.state.behaviourFields.behaviourSelected,
			"reset_user_password": this.state.custFields.resetUserPasswordSelected,
			"download_usage": (this.state.custFields.downloadUsage && this.state.custFields.downloadUsage === true) ? "as_csv" : "",
			//"enable_silent_recording":this.state.custFields.enableSilentRecording,
			"enable_silent_recording": this.state.custFields.recordingTypeSelected && this.state.custFields.recordingTypeSelected === 'enable_silent_recording' ? true : false,
			"enable_remote_capture": this.state.custFields.enableRemoteCapture,
			"wake_up_device_for_calls": this.state.custFields.enableWakeUpDevice,
			"is_ios_login_allowed": this.state.custFields.enableIosLoginSelected, /**TP-4051*/
			/**TP-2659 */
			"enable_asset_class": this.state.custFields.enableAssetsClassSelected, //TP-4644
			"enable_asset_offline": this.state.custFields.enableAssetOffline, //TZ-999
			"enable_knowledge_capture": this.state.custFields.enableKnowledgeCapture,
			"enable_checklist": this.state.custFields.enableChecklist, //TP-2659
			"enable_job_template": this.state.custFields.enableTemplatesSelected, //TP-4644 & TP-3162
			"enable_next_task": this.state.custFields.enableNextTask, //TP-3150
			"enable_skip_task": this.state.custFields.enableSkipTask, //TP-3150
			"allow_new_evidence": this.state.custFields.allowNewEvidenceSelected, //TP-4644 & TP-4288
			"invite_online_user": this.state.custFields.inviteOnlineUser,
			"allow_company_name_as_report_author": this.state.custFields.enableCustomerNameJobsReport,
			"enable_streaming": this.state.custFields.enableStreaming, //TP-3037 & TP-2789
			"idle_timeout": this.state.custFields.idleTimeout, //TP-2143
			"safety_scan_frequency": this.state.custFields.safetyScanFrequency, //TP-6260
			"generic_name": ((this.state.custFields.genericNameAdmin === "") && (this.state.custFields.genericNameExpert === "") && (this.state.custFields.genericNameTechnician === "") || (this.state.custFields.genericNameAdmin === null) && (this.state.custFields.genericNameExpert === null) && (this.state.custFields.genericNameTechnician === null)) ? null :			
			    JSON.stringify({
				admin:this.state.custFields.genericNameAdmin === "" ? null : this.state.custFields.genericNameAdmin,
				expert:this.state.custFields.genericNameExpert === "" ? null : this.state.custFields.genericNameExpert,
				technician:this.state.custFields.genericNameTechnician === "" ? null : this.state.custFields.genericNameTechnician,
			}),  // TP-5932
			"enable_incident":this.state.custFields.enableIncident,
			"enable_inspection":this.state.custFields.enableInspection,
			"mandatory_assignee_jobs": this.state.custFields.mandatoryAssigneeJobs, //TP-2939
			"email_notification": this.state.custFields.emailnotification,//TZ-972
			//for new enterpise
			"first_name": this.state.detFields.fname,
			"last_name": this.state.detFields.lname,
			"email": this.state.detFields.email,
			"phone": this.state.detFields.phone,
			"company_name": this.state.detFields.compname,
			"website": this.state.detFields.web,
			"company_url": this.state.detFields.web,
			"pass_code": this.state.detFields.passcode, //TZ-947
			// "amount": +this.state.priceFields.price,
			"amount": +this.state.priceFields.tprice,
			//for modify enterprise
			"account_id": this.props.viewDetails.account_id,
			"is_active": this.state.custFields.isactive,
			"total_price": +this.state.priceFields.tprice,
			//for Rebranding and logo upload
			"allow_custom_logo": this.state.detFields.allowRebranding,
			"jobs_logo_url": (this.state.detFields.jobsLogoUrl && document.getElementById('image-src-preview-path').style.display !== 'none') ? JSON.stringify(this.state.detFields.jobsLogoUrl) : "",
			"sms_support": this.state.custFields.allowSmsSupport //TP-5264
		})
	}

	getScreenShareMode = (fields) => {
		if (fields.expertscreen && fields.screenshare)
			return "screen_share_enabled";
		else if (fields.expertscreen && !fields.screenshare)
			return "expert_screen_share_enabled";
		else if (!fields.expertscreen && fields.screenshare)
			return "tech_screen_share_enabled";
		else if (!fields.expertscreen && !fields.screenshare)
			return "screen_share_disabled";
	}

	handleSubmit = async evnt => {
		evnt.preventDefault();
		let enterprise_url = null;
		if ("enterprise" === this.props.modify) {
			enterprise_url = "/api/submitEnterpriseModificationRequest";
		} else if ("create_enterprise" === this.props.modify) {
			enterprise_url = "/api/registerEnterpriseCustomer";
		} else if ("prepaid" === this.props.modify) {
			enterprise_url = "";
		}
		// enterprise_url="";

		/* let dataBody = JSON.stringify(this.getParamsState());
		if (type === true){
			dataBody = JSON.stringify(this.state.rawCustomerData);
		} */
		//console.log(dataBody);
		// console.log(JSON.stringify(this.getParamsState()));

		if (null != sessionStorage.getItem("userToken") && enterprise_url) {
			this.setState({ isLoading: true, showModal: false });
			fetch(sessionStorage.getItem("url") + enterprise_url, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Authorization": "Bearer " + sessionStorage.getItem("userToken")//TP-4416
				},
				body: JSON.stringify(this.getParamsState())
			}).then(response => {
				HTTP_STATUS = response.status;
				return response.json();
			}).then((data) => {
				let mTitle = null, mBody = null;
				if (HTTP_STATUS === 200) {
					if ("enterprise" === this.props.modify) {
						mTitle = "Modification Requested!!!";
						mBody = "The request has been sent for an approval";
					} else if ("create_enterprise" === this.props.modify) {
						mTitle = "Successfully Registered!!!";
						mBody = "Your enterprise registration has been received. You will receive an email shortly with a confirmation and login credentials";
					}
				} else if (HTTP_STATUS === 500) {
					mTitle = "Server Error!!!";
					mBody = "Something went wrong, try again later";
				} else if (HTTP_STATUS === 406) {
					mBody = "Number of licenses always greater than or equal to number of assigned credits";
				} else if (HTTP_STATUS === 409) {
					mBody = data + "";
				} else if (HTTP_STATUS === 422) {
					mTitle = "Validation Error!!!";
					mBody = "Check input parameters";
					if (data.errors) {
						mBody = "";
						data.errors.forEach(error => {
							mBody = mBody + error.param + " : " + error.msg + "\n";
						})
					}
				} else if (HTTP_STATUS === 400) {
					mTitle = "Database Error!!!";
					mBody = data + "";
				} else if (HTTP_STATUS === 524) {
					mTitle = "Server Timeout!!!";
					mBody = "Please retry after some time";
				} else if (HTTP_STATUS === 401 && data.message !== undefined) {
					/**TZ-947 */
					mTitle = "Error!!!";
					mBody = data.message.charAt(0).toUpperCase() + data.message.slice(1);
				} else {
					mBody = data + "";
				}
				this.setState({
					showModal: true,
					modalTitle: mTitle,
					modalBody: mBody,
					serverErrorCode: HTTP_STATUS
				});
			}).catch(error => {
				console.log(error);
			}).finally(() => {
				this.setState({ isLoading: false });
			});
		}
	}

	checkValidation = () => {
		let inpObj = document.getElementById("enterpriseForm");
		let validFlag = false;

		if (inpObj.checkValidity()) {
			validFlag = Object.keys(this.error).every((k) => !this.error[k]);
		}
		// TP-357
		// if(validFlag && this.state.detFields.allowRebranding){
		// 	validFlag = (this.state.detFields.jobsLogoUrl) ? true : false;
		// }
		// console.log(inpObj.checkValidity(), validFlag);
		this.setState({ isValidForm: validFlag });
	}

	handleChange = (e, id = "") => {
		let value;
		if (!e) return;
		if (Object.prototype.toString.call(e) === "[object Date]" && id === "installbydate") {
			value = e;
			this.setState({
				...this.state,
				priceFields: {
					...this.state.priceFields,
					[id]: value
				}
			}, () => {
				const data = this.getParamsState();
				let licenseKey = { ...this.state.licenseKey };
				licenseKey.lkey = this.encodeLicenseKey(data);
				this.setState({ licenseKey });
			});
		} else if (Object.prototype.toString.call(e) === "[object Date]" && id !== "installbydate") {
			value = e;
			this.setState({
				...this.state,
				dateFields: {
					...this.state.dateFields,
					[id]: value
				}
			}, () => {
				const data = this.getParamsState();
				let licenseKey = { ...this.state.licenseKey };
				licenseKey.lkey = this.encodeLicenseKey(data);
				this.setState({ licenseKey });
			});
		} else if (e.target.type === "text" && id === "passcode") {
			let errtype = id;
			id = e.target.id;
			value = e.target.value;
			this.validate({ value: value, name: id, type: errtype });
			//console.log(this.error[id]);
			if (value.length < 6) return;
			else if (this.error[id] === false) {
				const passcode = this.generatePassCode(value);
				document.getElementById(id).setSelectionRange(0, 0);
				const mTitle = "Passcode";
				let mBody = "The generated passcode is " + passcode;
				this.setState({
					showPassModal: true,
					modalTitle: mTitle,
					modalBody: mBody
				});
			}
		} else if (e.target.type === "text" || e.target.type === "email") {
			let errtype = id;
			id = e.target.id;
			value = e.target.value;
			if ("email" === e.target.type) {
				value = null !== value ? value.toLowerCase() : "";
			}
			if (id === "genericNameAdmin" || id === "genericNameExpert" || id === "genericNameTechnician" ){
				errtype = "alphanumeric"
			}
			this.validate({ value: value, name: id, type: errtype });
			if (errtype === "alphanumeric") {
				this.setState({
					...this.state,
					custFields: {
						...this.state.custFields,
						[id]: value
					}
				}, () => {
					const data = this.getParamsState();
					let licenseKey = { ...this.state.licenseKey };
					licenseKey.lkey = this.encodeLicenseKey(data);
					this.setState({ licenseKey });
				})
			} else {
				this.setState(({
					...this.state,
					detFields: {
						...this.state.detFields,
						[id]: value
					}
				}), () => {
					if ("email" === id) this.checkEmailExists();
				})
			}
		} else if (e.target.type === "checkbox") {
			id = e.target.id;
			value = e.target.checked;

			if ("amode" === id) {
				/**TP-4245 -- reverted the behaviour as before to enable turn on
				 * of tech2tech feature TP-3537*/
				//if (value === false) {
				/**TP-3043 */
				this.setState(({
					...this.state,
					custFields: {
						...this.state.custFields,
						[id]: value,
						'amodeSelected': (value === true) ? "tech2Tech" : "expert2Tech"
					}
				}), () => {
					const data = this.getParamsState();
					let licenseKey = { ...this.state.licenseKey };
					licenseKey.lkey = this.encodeLicenseKey(data);
					this.setState({ licenseKey });
				})
				//}
			} else {
				this.setState(({
					...this.state,
					custFields: {
						...this.state.custFields,
						[id]: value
					}
				}), () => {
					if ("ispasswordexpired" === id) {
						this.setPwdExpiry(value);
						let duration = this.state.custFields.pwdexpiryduration;
						if (value && duration === 0 && this.state.behaviourFields.behaviourSelected !== "smartvision") {
							duration = 45;
						}

						this.setState({
							...this.state,
							custFields: {
								...this.state.custFields,
								pwdexpiryduration: duration
							}
						}, () => {
							const data = this.getParamsState();
							let licenseKey = { ...this.state.licenseKey };
							licenseKey.lkey = this.encodeLicenseKey(data);
							this.setState({ licenseKey });
						})
					} else if ("enablejobs" === id) {
						//TP-2659
						this.setState({
							custFields: {
								...this.state.custFields,
								allusr: false,
								enableAssetsClassSelected: "disabled", //TP-4644
								enableAssetOffline: false, //TZ-999
								enableKnowledgeCapture: false,
								enableChecklist: false,
								enableTemplatesSelected: "disabled", //TP-4644 TP-3162
								enableNextTask: false, //TP-3150
								enableSkipTask: false, //TP-3150
								allowNewEvidenceSelected: "disabled", //TP-4644 TP-4288
								jobByTechnicianSelected: "disabled", //TP-4454
								enableJobSchedulerSelected: "disabled", //TP-5006
								enableIncident: false,
								enableInspection:false,
							}
						}, () => {
							const data = this.getParamsState();
							let licenseKey = { ...this.state.licenseKey };
							licenseKey.lkey = this.encodeLicenseKey(data);
							this.setState({ licenseKey });
						})

					} else if ("enableofflinejobs" === id) {
						/**TZ-999 */
						this.setState({
							custFields: {
								...this.state.custFields,								
								enableAssetOffline: false,								
							}
						}, () => {
							const data = this.getParamsState();
							let licenseKey = { ...this.state.licenseKey };
							licenseKey.lkey = this.encodeLicenseKey(data);
							this.setState({ licenseKey });
						})

					} else if ("isannotationshape" === id) {
						this.setState({
							custFields: {
								...this.state.custFields,
								isannotationpointerSelected: "disabled" //TP-4659
							}
						}, () => {
							const data = this.getParamsState();
							let licenseKey = { ...this.state.licenseKey };
							licenseKey.lkey = this.encodeLicenseKey(data);
							this.setState({ licenseKey });
						})
					} else if (id) {
						const data = this.getParamsState();
						let licenseKey = { ...this.state.licenseKey };
						licenseKey.lkey = this.encodeLicenseKey(data);
						this.setState({ licenseKey });
					}
				})
			}
		} else if (e.target.type === "select-one" && e.target.id !== "behaviour") {
			id = e.target.id;
			value = e.target.value;
			/**TP-4051 & TP-2659 */
			if ("enableIosLogin" === id && value === "ios_login_disabled" && this.state.custFields.enableKnowledgeCapture === true) {
				const mTitle = "Please Confirm!";
				const mBody = "Disabling iOS Expert login will also disable Knowledge Capture feature. Are you sure you want to continue?";
				this.setState({
					showConfirmModal: true,
					modalTitle: mTitle,
					modalBody: mBody,
				})

			} else {
				this.setState({
					...this.state,
					custFields: {
						...this.state.custFields,
						[id + 'Selected']: value,
					}
				}, () => {
					const data = this.getParamsState();
					let licenseKey = { ...this.state.licenseKey };
					licenseKey.lkey = this.encodeLicenseKey(data);
					this.setState({ licenseKey });
					/**TZ-999 */
					if ("enableAssetsClass" === id && "disabled" === value) {
						//TP-4644 TP-2659
						this.setState({
							custFields: {
								...this.state.custFields,
								mandatoryAssigneeJobs: false,
								enableAssetOffline: false, /**TZ-999 */
							}
						}, () => {
							const data = this.getParamsState();
							let licenseKey = { ...this.state.licenseKey };
							licenseKey.lkey = this.encodeLicenseKey(data);
							this.setState({ licenseKey });
						})
					}
				})
			}
		} else if (e.target.type === "select-one" && e.target.id === "behaviour") {
			id = e.target.id;
			value = e.target.value;

			this.setState({
				...this.state,
				behaviourFields: {
					...this.state.behaviourFields,
					[id + 'Selected']: value,
				}
			})
			this.changeVisualCustomization(smartVisionScreenKey, value, "onChange");


		} else if (e.target.type === "number") {
			id = e.target.id;
			value = e.target.value;
			this.validateDigit(e.target);
			if ("pwdexpiryduration" === id) {
				if (value < 1 || value > 60000000) return;
				this.setState({
					...this.state,
					custFields: {
						...this.state.custFields,
						[id]: value,
					}
				}, () => {
					const data = this.getParamsState();
					let licenseKey = { ...this.state.licenseKey };
					licenseKey.lkey = this.encodeLicenseKey(data);
					this.setState({ licenseKey });
				})
			} else if (("idleTimeout" === id) || ("safetyScanFrequency" === id)) {  // TP-6260
				/**TP-2143 */
				if (value < 1) return;
				this.setState({
					...this.state,
					custFields: {
						...this.state.custFields,
						[id]: value,
					}
				}, () => {
					const data = this.getParamsState();
					let licenseKey = { ...this.state.licenseKey };
					licenseKey.lkey = this.encodeLicenseKey(data);
					this.setState({ licenseKey });
				})
			}  else {
				this.setState({
					...this.state,
					priceFields: {
						...this.state.priceFields,
						[id]: value
					}
				}, () => {
					const data = this.getParamsState();
					let licenseKey = { ...this.state.licenseKey };
					licenseKey.lkey = this.encodeLicenseKey(data);
					this.setState({ licenseKey });
				})
			}
		} else {
			id = e.target.id;
			value = e.target.value;
			this.setState({
				...this.state,
				priceFields: {
					...this.state.priceFields,
					[e.target.id]: value
				}
			}, () => {
				const data = this.getParamsState();
				let licenseKey = { ...this.state.licenseKey };
				licenseKey.lkey = this.encodeLicenseKey(data);
				this.setState({ licenseKey });
			})
		}
		this.checkValidation();
	}

	setPrice = () => {
		var totalPrice = this.state.priceFields.price * this.state.priceFields.numlcsn;
		this.setState({
			...this.state,
			priceFields: {
				...this.state.priceFields,
				tprice: totalPrice
			}
		})
		this.error = {
			...this.error,
			tprice: 100 > totalPrice ? "Value must be greater than equal to 100" : false
		}

	}

	// Validating Function for validating each of the controlled input Text Fields :::
	validate = (obj) => {
		let error_msg = null;
		if (obj.value.length === 0 && !(obj.name === "genericNameAdmin" || obj.name === "genericNameExpert" || obj.name === "genericNameTechnician")) {
			error_msg = "Required fields cannot be empty";
		} else if ( obj.value.length !== 0 && !obj.value.match(new RegExp(pattern[obj.type].pattern))) {
			error_msg = pattern[obj.type].errMsg;
		}
		this.error = {
			...this.error,
			[obj.name]: null === error_msg ? false : error_msg
		}
	}

	validateDigit = (obj) => {
		let error_msg = null;
		if (obj.value.length === 0) {
			error_msg = "Required fields cannot be empty";
		} else if (+obj.min > +obj.value) {
			error_msg = "Value must be greater than equal to " + obj.min;
		} else if (+obj.max < +obj.value) {
			error_msg = "Value is too long";
		} else if (!obj.value.match(new RegExp(pattern[obj.id].pattern))) {
			error_msg = pattern[obj.id].errMsg;
		} else if (!obj.validity.valid) {
			error_msg = obj.validationMessage;
		}
		this.error = {
			...this.error,
			[obj.name]: null === error_msg ? false : error_msg
		}
	}

	setPwdExpiry = (ispasswordexpired) => {
		let pwddiv = document.getElementById("pwdexpirydurationdiv");
		if (pwddiv) {
			pwddiv.style.display = ispasswordexpired ? "block" : "none";
		}
	}

	getEnterpriseAccountDetails = () => {

		this.setState({ isLoading: true });
		let id = this.props.viewDetails.account_id
		superagent.get(sessionStorage.getItem("url")
			+ "/api/getEnterpriseCustomerDetailsByAccountId/" + id)
			.set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
			.accept("application/json")
			.then(response => response)
			.then(data => {
				if (null != data.body) {
					let customizeFields = { ...this.state.custFields };
					customizeFields.scanasset = data.body.asset_scan;
					customizeFields.allusr = data.body.assign_jobs_to_all_users;
					customizeFields.enablejobs = data.body.is_gps_enabled;
					customizeFields.enableofflinejobs = data.body.is_offline_jobs_enabled;
					customizeFields.amodeSelected = data.body.existing_audio_mode;
					customizeFields.amode = data.body.existing_audio_mode === "tech2Tech";
					customizeFields.userVideoSelected = data.body.enable_user_video;
					customizeFields.jobByTechnicianSelected = data.body.create_scan_job_by_technician; //TP-4454
					customizeFields.enableJobSchedulerSelected = data.body.enable_job_scheduler; //TP-5006
					customizeFields.smediumSelected = data.body.streaming_medium;
					customizeFields.stypeSelected = data.body.session_type;
					//customizeFields.installbydate = new Date(data.body.install_by_date);
					customizeFields.recordingTypeSelected = data.body.screen_recording_enabled ? 'screen_recording_enabled' : data.body.enable_silent_recording ? 'enable_silent_recording' : 'recording_type_none';

					customizeFields.enableStreaming = data.body.enable_streaming; //TP-3037 & TP-2789
					customizeFields.smode = data.body.is_customizable;
					customizeFields.autoConnect = data.body.enable_auto_connect;
					customizeFields.isactive = !data.body.isInactive;
					customizeFields.issoloprogram = data.body.is_solo_program;
					customizeFields.guestuser = data.body.guest_user_enabled;
					customizeFields.expertscreen =
						"screen_share_enabled" === data.body.screen_share_mode
						|| "expert_screen_share_enabled" === data.body.screen_share_mode;
					customizeFields.screenshare =
						"screen_share_enabled" === data.body.screen_share_mode
						|| "tech_screen_share_enabled" === data.body.screen_share_mode;
					customizeFields.ispasswordexpired = data.body.password_expiry_duration > 0;
					customizeFields.pwdexpiryduration = ("true" === sessionStorage.isSmartVision) ? 365 : data.body.password_expiry_duration;
					this.setPwdExpiry(customizeFields.pwdexpiryduration);
					customizeFields.isscreenrecording = data.body.screen_recording_enabled;
					customizeFields.iszoom = data.body.zoom_enabled;
					customizeFields.istorch = data.body.torch_enabled;
					customizeFields.isannotationcolor = data.body.annotation_color_enabled;
					customizeFields.isannotationshape = data.body.annotation_shape_enabled;
					customizeFields.isannotationpointerSelected = data.body.allow_annotation_pointer; //TP-4659
					customizeFields.swapcamera = data.body.swap_camera_enabled;
					customizeFields.canturnoffuservideoSelected = data.body.can_turn_off_user_video; //TP-4644
					customizeFields.expertcandisconnectuserSelected = data.body.expert_can_disconnect_user; //TP-4644
					customizeFields.resetUserPasswordSelected = data.body.reset_user_password;
					customizeFields.downloadUsage = (data.body.download_usage && data.body.download_usage === "as_csv") ? true : false;
					customizeFields.inviteOnlineUser = data.body.invite_online_user;
					customizeFields.enableCustomerNameJobsReport = data.body.allow_company_name_as_report_author;
					customizeFields.enableSilentRecording = data.body.enable_silent_recording;
					customizeFields.enableRemoteCapture = data.body.enable_remote_capture;
					customizeFields.enableWakeUpDevice = data.body.wake_up_device_for_calls;
					customizeFields.enableIosLoginSelected = data.body.is_ios_login_allowed; //TP-4051
					customizeFields.enableAssetsClassSelected = data.body.enable_asset_class; //TP-4644 TP-2659
					customizeFields.enableAssetOffline = data.body.enable_asset_offline; //TZ-999
					customizeFields.enableKnowledgeCapture = data.body.enable_knowledge_capture; //TP-2659
					customizeFields.enableChecklist = data.body.enable_checklist; //TP-2659
					customizeFields.mandatoryAssigneeJobs = data.body.mandatory_assignee_jobs //TP-2939
					customizeFields.enableTemplatesSelected = data.body.enable_job_template //TP-4644 TP-3162
					customizeFields.enableNextTask = data.body.enable_next_task //TP-3150
					customizeFields.enableSkipTask = data.body.enable_skip_task //TP-3150
					customizeFields.allowNewEvidenceSelected = data.body.allow_new_evidence //TP-4644 TP-4288
					customizeFields.idleTimeout = data.body.idle_timeout //TP-2143
					customizeFields.safetyScanFrequency = data.body.safety_scan_frequency //TP-6260
					customizeFields.genericNameAdmin = (data.body.generic_name === undefined || data.body.generic_name === null) ? null : (JSON.parse(data.body.generic_name)).admin;  // TP-5932
					customizeFields.genericNameExpert = (data.body.generic_name === undefined || data.body.generic_name === null) ? null : (JSON.parse(data.body.generic_name)).expert;   // TP-5932
					customizeFields.genericNameTechnician = (data.body.generic_name === undefined || data.body.generic_name === null) ? null : (JSON.parse(data.body.generic_name)).technician;   // TP-5932
					customizeFields.allowSmsSupport = data.body.sms_support //TP-5264
					customizeFields.enableIncident = data.body.enable_incident
					customizeFields.enableInspection = data.body.enable_inspection
					customizeFields.emailnotification = data.body.email_notification//TZ-972
					let customerFields = { ...this.state.detFields };
					customerFields.compname = data.body.company_name;
					customerFields.email = data.body.email;
					customerFields.allowRebranding = data.body.allow_custom_logo;
					customerFields.jobsLogoUrl = data.body.jobs_logo_url ? JSON.parse(data.body.jobs_logo_url) : null;
					customerFields.web = data.body.company_url;

					let priceFields = { ...this.state.priceFields };
					priceFields.mnthmins = data.body.monthly_minutes;
					priceFields.noexpert = data.body.number_of_experts;
					priceFields.overcost = data.body.overage_cost;
					priceFields.numlcsn = data.body.total_licences;
					priceFields.tprice = data.body.total_price ? data.body.total_price : data.body.price * data.body.total_licences;
					priceFields.installbydate = new Date(data.body.install_by_date);

					let dateFields = { ...this.state.dateFields };
					dateFields.sdate = new Date(data.body.start_date);
					// dateFields.sdate.minDate = new Date(data.body.start_date);
					dateFields.tdate = new Date(data.body.end_date);
					// dateFields.tdate.minDate = new Date(dateFields.tdate.value.getTime() + 24 * 60 * 60 * 1000);
					let licenseKey = { ...this.state.licenseKey };
					licenseKey.dbLKey = data.body.license_key;
					licenseKey.lkey = this.encodeLicenseKey(data.body);

					let behavFields = { ...this.state.behaviourFields }
					behavFields.behaviourSelected = data.body.behaviour === "" ? "default" : data.body.behaviour;

					this.setState({
						rawCustomerData: data.body,
						custFields: customizeFields,
						detFields: customerFields,
						priceFields: priceFields,
						dateFields: dateFields,
						licenseKey: licenseKey,
						behaviourFields: behavFields,
						isLoading: false,
						dbCustFieldsValue: customizeFields
					});
				}
			}
			).catch(error => {
				this.setState({ isLoading: false });
				console.log(error);
			});
	};

	setFormDetails = () => {
		const { viewDetails } = this.props;
		let detFields = {
			...this.state.detFields,
			phone: viewDetails.company_phone,
			compname: viewDetails.company_name,
			web: viewDetails.company_url,
		};
		let priceFields = {
			...this.state.priceFields,
			numlcsn: viewDetails.total_licences,
			tprice: "",
			overcost: "",
			mnthmins: "",
			noexpert: viewDetails.number_of_experts,
			installbydate: new Date(viewDetails.install_by_date),
		};
		let dateFields = {
			...this.state.dateFields,
			sdate: new Date(viewDetails.subscription_start_date),
			tdate: new Date(viewDetails.subscription_end_date),
		};
		let custFields = {
			...this.state.custFields,
			smode: viewDetails.is_customizable,
			autoConnect: viewDetails.enable_auto_connect,
			enablejobs: viewDetails.is_gps_enabled,
			enableofflinejobs: viewDetails.is_offline_jobs_enabled,
			allusr: viewDetails.assign_jobs_to_all_users,
			scanasset: viewDetails.asset_scan,
			userVideoSelected: viewDetails.userVideoSelected,
			jobByTechnicianSelected: viewDetails.jobByTechnicianSelected, /**TP-4454*/
			enableJobSchedulerSelected: viewDetails.enableJobSchedulerSelected, /**TP-5006*/
			allowNewEvidenceSelected: viewDetails.allowNewEvidenceSelected, /**TP-4644*/
			smediumSelected: viewDetails.smediumSelected,
			amodeSelected: viewDetails.audio_mode,
			stypeSelected: viewDetails.session_type,

			//installbydate:new Date(viewDetails.install_by_date),			
			isactive: false,
			issoloprogram: viewDetails.is_solo_program,
			guestuser: viewDetails.guest_user_enabled,
			expertscreen: "screen_share_enabled" === viewDetails.screen_share_mode
				|| "expert_screen_share_enabled" === viewDetails.screen_share_mode,
			screenscreen: "screen_share_enabled" === viewDetails.screen_share_mode
				|| "tech_screen_share_enabled" === viewDetails.screen_share_mode,
			ispasswordexpired: viewDetails.password_expiry_duration > 0,
			pwdexpiryduration: viewDetails.password_expiry_duration,
			isscreenrecording: viewDetails.screen_recording_enabled,
			iszoom: viewDetails.zoom_enabled,
			istorch: viewDetails.torch_enabled,
			isannotationcolor: viewDetails.annotation_color_enabled,
			isannotationshape: viewDetails.annotation_shape_enabled,
			isannotationpointerSelected: viewDetails.allow_annotation_pointer, /**TP-4659*/
			swapcamera: viewDetails.swapcamera,
			canturnoffuservideoSelected: viewDetails.canturnoffuservideoSelected, /**TP-4644*/
			expertcandisconnectuserSelected: viewDetails.expertcandisconnectuserSelected, /**TP-4644*/
			resetUserPasswordSelected: viewDetails.resetUserPasswordSelected,
			downloadUsage: viewDetails.downloadUsage,
			enableSilentRecording: viewDetails.enableSilentRecording,
			enableRemoteCapture: viewDetails.enableRemoteCapture,
			enableWakeUpDevice: viewDetails.enableWakeUpDevice,
			enableIosLoginSelected: viewDetails.enableIosLoginSelected, //TP-4051
			inviteOnlineUser: viewDetails.inviteOnlineUser,
			enableCustomerNameJobsReport: viewDetails.enableCustomerNameJobsReport,
			allowSmsSupport: viewDetails.allowSmsSupport //TP-5264
		};

		let licenseKey = {
			...this.state.licenseKey,
			dbLKey: viewDetails.license_key
		}

		let behaviourKey = {
			...this.state.behaviourFields,
			behaviourSelected: viewDetails.behaviour
		}

		this.setState({
			detFields: detFields,
			priceFields: priceFields,
			dateFields: dateFields,
			custFields: custFields,
			licenseKey: licenseKey,
			behaviourFields: behaviourKey
		});
	}

	componentDidMount() {
		this.setPwdExpiry(this.state.custFields.ispasswordexpired);

		if ("enterprise" === this.props.modify || "prepaid" === this.props.modify) {
			if ("enterprise" === this.props.modify) {
				this.getEnterpriseAccountDetails();
			} else if ("prepaid" === this.props.modify) {
				this.setFormDetails();
			}
			if (null !== document.getElementById("fnamediv"))
				document.getElementById("fnamediv").style.display = "none";

			if (null !== document.getElementById("lnamediv"))
				document.getElementById("lnamediv").style.display = "none";

			//TZ-947
			if (null !== document.getElementById("passcodediv"))
				document.getElementById("passcodediv").style.display = "none";
			//TZ-972
			if (null !== document.getElementById("emailnotificationdiv"))
				document.getElementById("emailnotificationdiv").style.display = "none";

			// if(null!==document.getElementById("webdiv"))
			// document.getElementById("webdiv").style.display = "none";

			if (null !== document.getElementById("phonediv"))
				document.getElementById("phonediv").style.display = "none";

			if ("true" === sessionStorage.isSmartVision && sessionStorage.userDbRole !== "admin") {
				document.getElementById("behaviour").disabled = true;
				document.getElementById("behaviour").style.color = "black";
				document.getElementById("pwdexpiryduration").style.marginBottom = "10px";
			}

		} else if ("create_enterprise" === this.props.modify) {
			if (null !== document.getElementById("isactivediv"))
				document.getElementById("isactivediv").style.display = "none";
			if (null !== document.getElementById("customfld"))
				document.getElementById("customfld").style.display = "none";

		}

	}

	componentDidUpdate() {
		if (document.getElementById("behaviour").value === "smartvision") {
			this.changeVisualCustomization(smartVisionScreenKey, "smartvision");
		}
		if (document.getElementById("behaviour").value === "pndsafety") {
			this.changeVisualCustomization(smartVisionScreenKey, "pndsafety");
		}
	}

	checkEmailExists = () => {
		if (this.state.detFields.email && this.state.detFields.email.match(new RegExp(pattern["email"].pattern))) {
			superagent
				.get(sessionStorage.getItem("url") + `/api/checkEmailExists/${this.state.detFields.email}?type=standard`)
				.set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))//TP-4416
				.then(response => response)
				.then(data => {
					this.error = {
						...this.error,
						email: data.body ? "This Email is already in use" : false
					}
					this.checkValidation();
				})
		}
	}

	toggleDataModal = () => {
		const { showDataModal } = this.state;
		this.setState({ showDataModal: !showDataModal });
	}
	handleRawDataChange = (event) => {
		event.preventDefault();
		if (JSON.stringify(this.state.rawCustomerData) !== event.target.value) {
			//console.log(JSON.parse(event.target.value));
			this.setState({ rawCustomerData: JSON.parse(event.target.value) });
		}
	}

	submitRawData = (event) => {
		this.handleSubmit(event, true);
		this.toggleDataModal();
	}
	closeAlert = () => {
		this.setState({ showAlert: false });
	}

	//TP-2659
	updateShowConfirmModal = (val) => {
		this.setState({ showConfirmModal: val });
	}
	//TP-2659
	setEnableKC = () => {
		this.setState({ custFields: { ...this.state.custFields, enableIosLoginSelected: "ios_login_disabled", enableKnowledgeCapture: false } });/**TP-4051*/
	}

	rawDataFormModal = () => {
		const { rawCustomerData, showDataModal } = this.state;
		//console.log(rawCustomerData);
		return (
			<>
				<Modal show={showDataModal} onHide={this.toggleDataModal} aria-labelledby="ModalHeader" backdrop="static" >
					<Modal.Header closeButton>
						<Modal.Title>Customer Data</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form id="dataform">
							<textarea rows="20" cols="50" onChange={this.handleRawDataChange} value={JSON.stringify(rawCustomerData)} form="dataform">
							</textarea>
						</form>
					</Modal.Body>
					<Modal.Footer>
						{/* <Button onClick={this.submitRawData} className="btn-green">{showLoader(this.state.isLoading) }OK</Button> */}
					</Modal.Footer>
				</Modal>
			</>
		)
	}

	togglePassModal = () => {
		this.setState({ showPassModal: false })
	}

	passCodeFormModal = () => {
		const { modalTitle, modalBody } = this.state;
		return (
			<>
				<Modal
					size="lg" centered
					show={this.state.showPassModal} onHide={this.togglePassModal}
					aria-labelledby="ModalHeader"
					backdrop="static"
				>
					<Modal.Header closeButton>
						<Modal.Title><h2>{modalTitle}</h2></Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h3>
							{modalBody}
						</h3>
					</Modal.Body>
					<Modal.Footer>
						{/* <Button onClick={this.close} className="btn-green">OK</Button> */}
					</Modal.Footer>
				</Modal>
			</>
		)
	}


	changeVisualCustomization = (dataParams, selectedValue, actionType) => {

		if (dataParams && dataParams.custModification && this.props.modify !== "create_enterprise") {
			dataParams.custModification.forEach((element) => {
				if (selectedValue === "smartvision") {
					if (document.getElementById(element.id) && element.visibility === false) {
						document.getElementById(element.id).style.display = 'none';
					}
					if (document.getElementById(element.id) && element.disabled === true) {
						document.getElementById(element.id).disabled = true;
					}
					if (document.getElementById(element.id) && element.value) {
						document.getElementById(element.id).value = element.value;
					}
				} else {
					if (document.getElementById(element.id) && element.visibility === false) {
						document.getElementById(element.id).style.display = 'block';
					}
					if (document.getElementById(element.id) && element.disabled === true) {
						document.getElementById(element.id).disabled = false;
					}
					if (document.getElementById(element.id) && element.reverseValue) {
						document.getElementById(element.id).value = element.reverseValue;
					}
				}
				if (selectedValue === "pndsafety") {   //TP-6260
					if (document.getElementById(element.id) && element.visibility === false) {
						if (element.id === "safetyScanFrequencydiv") {
							document.getElementById(element.id).style.display = 'block';
						}
					}
				}
				if (selectedValue !== "pndsafety") {   //TP-6260
					if (document.getElementById(element.id) && element.visibility === false) {
						if (element.id === "safetyScanFrequencydiv") {
							document.getElementById(element.id).style.display = 'none';
						}
					}
				}
			});
		}
		if (dataParams && dataParams.custFields && actionType === "onChange" && this.props.modify !== "create_enterprise") {
			let copyObj = { ...this.state.custFields };
			let changeKeyObj = { ...dataParams.custFields };
			if (selectedValue === "smartvision") {
				for (var key in changeKeyObj) {
					copyObj[key] = changeKeyObj[key];
				}
			} else {
				copyObj = Object.keys(this.state.dbCustFieldsValue).length > 0 ? { ...this.state.dbCustFieldsValue } : { ...this.state.custFields }
			}
			this.setState({ custFields: copyObj });
			if (selectedValue !== "smartvision" && this.props.modify !== "create_enterprise")
				this.setState({ showAlert: true, modalTitle: "Alert", modalBody: "Please select the relevant Enterprise Settings before clicking on Submit." });

			if (dataParams.dependentObject && this.props.modify !== "create_enterprise") {
				for (const obj of dataParams.dependentObject) {
					if (copyObj && copyObj[obj.dependentId] && document.getElementById(obj.id)) {
						document.getElementById(obj.id).style.display = 'block';
					} else if (document.getElementById(obj.id)) {
						document.getElementById(obj.id).style.display = 'none';
					}

				}

			}

		}


	}

	encodeLicenseKey = (data) => {
		try {
			//console.log(data);
			let lkCode = "";
			lkCode = moment(data.start_date).format("DDMMYY");
			lkCode += moment(data.end_date).format("DDMMYY");
			lkCode += moment(data.install_by_date).format("DDMMYY");
			lkCode += (data.total_licences) ? padLeadingZeros(data.total_licences, 3) : (data.quantity) ? padLeadingZeros(data.quantity, 3) : null;
			lkCode += this.props.viewDetails.account_id;
			// lkCode += (data.total_licences) ? padLeadingZeros(data.price * data.total_licences, 7): (data.total_price) ? padLeadingZeros(data.total_price, 7) : null;
			lkCode += (data.total_licences) ? padLeadingZeros(data.price * data.total_licences, 7) : (data.total_price || data.total_price === 0) ? padLeadingZeros(data.total_price, 7) : null;
			lkCode += data.audio_mode === 'tech2Tech' ? '1' : '0';
			lkCode += padLeadingZeros(data.number_of_experts, 3);
			lkCode += data.is_customizable === true ? '1' : '0';
			lkCode += data.is_gps_enabled === true ? '1' : '0';
			lkCode += data.assign_jobs_to_all_users === true ? '1' : '0';
			lkCode += data.asset_scan === true ? '1' : '0';

			if (data.streaming_medium === 'open_tok') {
				lkCode += '1';
			} else if (data.streaming_medium === 'onprem') {
				lkCode += '2';
			} else if (data.streaming_medium === 'both') {
				lkCode += '3';
			}
			lkCode += data.is_solo_program === true ? '1' : '0';
			lkCode += data.guest_user_enabled === true ? '1' : '0';
			if (data.screen_share_mode === "screen_share_disabled") {
				lkCode += '1';
			} else if (data.screen_share_mode === "tech_screen_share_enabled") {
				lkCode += '2';
			} else if (data.screen_share_mode === "expert_screen_share_enabled") {
				lkCode += '3';
			} else if (data.screen_share_mode === "screen_share_enabled") {
				lkCode += '4';
			}
			lkCode += padLeadingZeros(data.password_expiry_duration, 3);
			lkCode += data.screen_recording_enabled === true ? '1' : '0';
			lkCode += data.zoom_enabled === true ? '1' : '0';
			lkCode += data.torch_enabled === true ? '1' : '0';
			lkCode += data.annotation_color_enabled === true ? '1' : '0';
			lkCode += data.annotation_shape_enabled === true ? '1' : '0';
			lkCode += data.swap_camera_enabled === true ? '1' : '0';
			lkCode += data.is_offline_jobs_enabled === true ? '1' : '0';

			//Newly added key for encodeing licence key 
			/**TZ-972 -- modified fields for Lkey */
			if (data.expert_can_disconnect_user === "disabled") {
				lkCode += '0';
			} else if (data.expert_can_disconnect_user === "enabled_on_demand") {
				lkCode += '1';
			} else if (data.expert_can_disconnect_user === "enabled_for_all") {
				lkCode += '2';
			}
			if (data.can_turn_off_user_video === "disabled") {
				lkCode += '0';
			} else if (data.can_turn_off_user_video === "enabled_on_demand") {
				lkCode += '1';
			} else if (data.can_turn_off_user_video === "enabled_for_all") {
				lkCode += '2';
			}
			lkCode += data.reset_user_password === 'reset_password_without_qr' ? '1' : data.reset_user_password === 'reset_password_with_qr' ? '2' : '0';
			lkCode += data.download_usage === "as_csv" ? '1' : '0';
			lkCode += data.invite_online_user === true ? '1' : '0';
			lkCode += data.allow_custom_logo === true ? '1' : '0';
			lkCode += data.allow_company_name_as_report_author === true ? '1' : '0';
			lkCode += data.wake_up_device_for_calls === true ? '1' : '0';
			lkCode += data.enable_silent_recording === true ? '1' : '0';
			lkCode += data.enable_remote_capture === true ? '1' : '0';

			//TP-4283 -- New settings added to the license key
			if (data.is_ios_login_allowed === "ios_login_disabled") {
				lkCode += '0';
			} else if (data.is_ios_login_allowed === "ios_login_enabled_ondemand") {
				lkCode += '1';
			} else if (data.is_ios_login_allowed === "ios_login_enabled_all") {
				lkCode += '2';
			}
			//lkCode += data.enable_asset_class === true ? '1' : '0';
			/**TZ-1085 -- Changed the data type from Boolean to Enum */
			if (data.enable_asset_class === "disabled") {
				lkCode += '0';
			} else if (data.enable_asset_class === "enabled_on_demand") {
				lkCode += '1';
			} else if (data.enable_asset_class === "enabled_for_all") {
				lkCode += '2';
			}
			lkCode += data.enable_knowledge_capture === true ? '1' : '0';
			lkCode += data.enable_checklist === true ? '1' : '0';
			lkCode += data.mandatory_assignee_jobs === true ? '1' : '0';
			lkCode += data.enable_streaming === true ? '1' : '0';
			lkCode += padLeadingZeros(data.idle_timeout, 3);
			if (data.session_type === "RM") {
				lkCode += '0';
			} else if (data.session_type === "CM") {
				lkCode += '1';
			} else if (data.session_type === "PM") {
				lkCode += '2';
			}
			lkCode += data.enable_skip_task === true ? '1' : '0';
			lkCode += data.enable_next_task === true ? '1' : '0';
			/**TZ-972 */
			if (data.enable_job_template === "disabled") {
				lkCode += '0';
			} else if (data.enable_job_template === "enabled_on_demand") {
				lkCode += '1';
			} else if (data.enable_job_template === "enabled_for_all") {
				lkCode += '2';
			}
			if (data.enable_user_video === "my_camera_disabled") {
				lkCode += '0';
			} else if (data.enable_user_video === "my_camera_enabled") {
				lkCode += '1';
			} else if (data.enable_user_video === "my_camera_enabled_with_prefs") {
				lkCode += '2';
			}
			lkCode += data.enable_auto_connect === true ? '1' : '0';
			/**TZ-972 -- modified lkeys */
			if (data.allow_new_evidence === "disabled") {
				lkCode += '0';
			} else if (data.allow_new_evidence === "enabled_on_demand") {
				lkCode += '1';
			} else if (data.allow_new_evidence === "enabled_for_all") {
				lkCode += '2';
			}
			/**TZ-972 new feature settings*/
			if (data.create_scan_job_by_technician === "disabled") {
				lkCode += '0';
			} else if (data.create_scan_job_by_technician === "enabled_for_some") {
				lkCode += '1';
			} else if (data.create_scan_job_by_technician === "enabled_for_all") {
				lkCode += '2';
			}
			if (data.allow_annotation_pointer === "disabled") {
				lkCode += '0';
			} else if (data.allow_annotation_pointer === "enabled_on_demand") {
				lkCode += '1';
			} else if (data.allow_annotation_pointer === "enabled_for_all") {
				lkCode += '2';
			}
			lkCode += data.enable_job_reviewer === "enabled_for_all" ? '1' : '0';
			if (data.enable_job_scheduler === "disabled") {
				lkCode += '0';
			} else if (data.enable_job_scheduler === "enabled_on_demand") {
				lkCode += '1';
			} else if (data.enable_job_scheduler === "enabled_for_all") {
				lkCode += '2';
			}
			
			lkCode += data.email_notification === true ? '1' : '0';
			lkCode += data.enable_incident === true ? '1' : '0';
			lkCode += data.enable_inspection === true ? '1' : '0';

			//console.log(lkCode, lkCode.length);
			if (lkCode.length === 91)//TZ-972
				return lkCode;
			else return null;
		} catch (error) {
			return null;
		}
	}



	uploadFile(event) {
		if (!event.target.files[0])
			return false;

		this.setState({ uploadedFileInfo: event.target.files[0], imageErrorMSG: null });
		var uplodedImage = document.getElementById('upload-image');
		var fileImage = document.getElementById('upload');

		let fsize = event.target.files[0].size;
		let maxfilesize = 1024 * 1024; // 1 Mb

		if (fsize > maxfilesize) {
			this.setState({ imageErrorMSG: "File size should not exceed 1 MB." });
		} else {

			let img = new Image();
			img.src = URL.createObjectURL(event.target.files[0]);
			img.onload = () => {

				let height = img.height;
				let width = img.width;
				let fileInfo = this.state.uploadedFileInfo;
				let today = Math.floor(Date.now() / 1000);
				let urlPath = URL.createObjectURL(fileInfo);
				let fullFileName = this.props.viewDetails.company_name + '_' + this.props.viewDetails.account_id + '_' + today + '.' + fileInfo.name.split('.')[1];
				let imagePrv = document.getElementById("image-preview");
				fileInfo.URLPath = urlPath;
				fileInfo.S3FileName = fullFileName.replaceAll(' ', '');
				var colorSum = 0;

				let detFields = { ...this.state.detFields, file: fileInfo };

				if (height && width && (height > 644 || width > 1200)) {
					this.setState({ imageErrorMSG: "Maximum dimensions is allowed : 1200 pixels (width) & 644 pixels (height)" });
				} else {
					// create canvas
					var canvas = document.createElement("canvas");
					canvas.width = img.width;
					canvas.height = img.height;

					var ctx = canvas.getContext("2d");
					ctx.drawImage(img, 0, 0);

					var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
					var data = imageData.data;
					var r, g, b, avg;

					for (var x = 0, len = data.length; x < len; x += 4) {
						r = data[x];
						g = data[x + 1];
						b = data[x + 2];

						avg = Math.floor((r + g + b) / 3);
						colorSum += avg;
					}

					var brightness = Math.floor(colorSum / (img.width * img.height));
					if (brightness < 127.5) {
						this.setState({ imageErrorMSG: "Only image files with white background." });
					}

					if (!this.state.imageErrorMSG) {
						if (imagePrv && imagePrv.style.display === 'none')
							imagePrv.style.display = 'inline-block'
						this.setState({ detFields: detFields }, () => this.uploadToS3());
					}

				}

			}
		};
	}


	uploadToS3() {

		if (!this.state.detFields.file)
			return false;

		let file = this.state.detFields.file;
		if (file.S3FileName) {
			const name = file.S3FileName;
			file = new File([file], name, { type: file.type });
		}


		S3FileUpload.uploadFile(file, S3config)
			.then(data => {
				//console.log(data.location)
				if (data.result.status === 204) {
					let jobsURLObj = { "key": data.key, "location": data.location, "name": data.key.split('/')[1] };
					let detFields = { ...this.state.detFields, jobsLogoUrl: jobsURLObj };
					this.setState({ detFields: detFields });
					this.checkValidation();
				}
			}
			).catch(err => {
				console.error(err)
			}
			);
	}

	deleteS3Files = (s3FileObj) => {

		if (!s3FileObj)
			return false;

		let filename = s3FileObj.name;

		S3FileUpload.deleteFile(filename, S3config)
			.then(response => {
				if (response.status === 204) {
					let detFields = { ...this.state.detFields, jobsLogoUrl: null, file: null };
					this.setState({ detFields: detFields });
					this.checkValidation();
				}
			}
			).catch(err => {
				console.error(err)
			})

	}

	onToggleHandler(params) {
		// let filename = this.state.detFields.jobsLogoUrl && this.state.detFields.jobsLogoUrl.name ? this.state.detFields.jobsLogoUrl.name:"";
		// if(filename)
		// 	S3FileUpload.deleteFile(filename,S3config);

		let detFields = { ...this.state.detFields, allowRebranding: params, file: null, jobsLogoUrl: null };
		this.setState({ detFields: detFields, imageErrorMSG: null }, () => this.checkValidation());


	}


	render() {
		custDetailFields[0].required = false;//first
		custDetailFields[1].required = false;//last
		custDetailFields[2].required = false;//email
		custDetailFields[3].required = false;//phone
		custDetailFields[4].required = false;//company name
		custDetailFields[5].required = false;//website

		custDetailFields[2].readonly = true;//email
		custDetailFields[4].readonly = true;//company name

		custDetailFields[2].disabled = false;//email

		licensePricingFields[0].disabled = false;//total price
		licensePricingFields[2].disabled = false;//overage cost
		licensePricingFields[3].disabled = false;//monthly min

		customizeFields[5].disabled = false;//active status
		dateFields[0].disabled = true;//start date
		customizeFields[5].required = true;//streammin mode
		//customizeFields[33].required=true;//idle timeout (in mins)

		if ("enterprise" === this.props.modify) {
			//customizeFields[8].minDate = new Date(currentDate); //to not allow selection of past date
			custDetailFields[4].readonly = false;//company name
			custDetailFields[5].readonly = false;//website
			custDetailFields[6].required = false;//passcode TZ-947

		} else if ("create_enterprise" === this.props.modify) {
			custDetailFields[0].required = true;//first
			custDetailFields[1].required = true;//last
			custDetailFields[2].required = true;//email
			custDetailFields[3].required = true;//phone
			custDetailFields[4].required = true;//company name
			custDetailFields[5].required = true;//website
			custDetailFields[6].required = true;//passcode TZ-972
			custDetailFields[2].readonly = false;//email
			custDetailFields[4].readonly = false;//company name
			customizeFields[8].minDate = new Date(currentDate); //to not allow selection of past date
			dateFields[0].disabled = false;//start date
		} else if ("prepaid" === this.props.modify) {
			custDetailFields[2].disabled = true;//email
			licensePricingFields[0].disabled = true;//total price
			licensePricingFields[2].disabled = true;//overage cost
			licensePricingFields[3].disabled = true;//monthly min
			customizeFields[5].disabled = true;//active status
		}

		let imagePreview, uploadButtonText = 'Upload Image';


		if (this.state.detFields.file && this.state.detFields.file.URLPath) {
			imagePreview = <img id="image-src-preview-path" src={this.state.detFields.file.URLPath} title={this.state.detFields.file.name} alt={this.state.detFields.file.name} />;
			uploadButtonText = 'Change Image';
		} else if (this.state.detFields.allowRebranding && this.state.detFields.jobsLogoUrl) {
			imagePreview = <img id="image-src-preview-path" src={this.state.detFields.jobsLogoUrl.location} title={this.state.detFields.jobsLogoUrl.name} alt={this.state.detFields.jobsLogoUrl.name} />
			uploadButtonText = 'Change Image';
		}
		return (
			<div className="page">
				<form id="enterpriseForm">
					<div className="container-fluid">
						<div className="row">
							<div className="col-sm-12 col-lg">
								<h5 className="text-center">Customer Details</h5>
								<FormFields items={custDetailFields} error={this.error} values={this.state.detFields} onClickField={this.handleChange} type="details" />
								<FormFields items={behaviourKey} error={this.error} values={this.state.behaviourFields} onClickField={this.handleChange} type="behaviourKey" />
								<div>
									<div className={rebranding.item.class} id={rebranding.item.id + 'pDiv'} key={rebranding.item.id} style={{ display: (this.props.modify === 'enterprise') ? 'block' : 'none' }}>
										<label className="col-xs-3" htmlFor={rebranding.item.id + "div"}>{rebranding.item.label}&nbsp;</label>
										<label className="col-xs-4 col-xs-offset-2 switch">
											<input type="checkbox" id={rebranding.item.id} name={rebranding.item.id} disabled={rebranding.item.disabled} onChange={event => this.onToggleHandler(!this.state.detFields.allowRebranding)} checked={this.state.detFields[rebranding.item.id]} />
											<span className="slider round"></span>
										</label>
										<label htmlFor={rebranding.item.id + 'YesNo'}>&nbsp;{(this.state.detFields[rebranding.item.id]) ? 'Yes' : 'No'}</label>
									</div>
									{(!this.state.detFields.file && !this.state.detFields.jobsLogoUrl && this.state.detFields.allowRebranding) &&
										<ValdationBulletPoints />
									}
									{(this.state.detFields.allowRebranding) &&
										<div id="upload-image-div">
											<div id="image-preview" style={{ display: (this.state.detFields.jobsLogoUrl && this.state.detFields.jobsLogoUrl.name) ? 'inline-block' : 'none' }} className="form-group preview">{imagePreview}</div>
											<div className="input-group">
												<input type="file" id="upload" accept=".jpg, .png , .jpeg" style={{ display: 'none' }} onChange={event => this.uploadFile(event)} ></input>
												<label id="upload-image" className="btn-blue-primary" htmlFor="upload">{uploadButtonText}</label>
											</div>

										</div>
									}
									{this.state.imageErrorMSG &&
										<div id="error"> {this.state.imageErrorMSG} </div>
									}
								</div>
								<br />
								{/**TP-2749 */ "enterprise" === this.props.modify ?
									<div className="bevfld">
										{/* TP-2702 */}
										<CustomerModel viewDetails={this.props.viewDetails} />
									</div>
									:
									''
								}
							</div>
							<div className="col-sm-12 col-lg">
								<h5 className="text-center">Pricing Details</h5>
								<FormFields items={licensePricingFields} values={this.state.priceFields} error={this.error} onClickField={this.handleChange} type="pricing" />
								<FormFields items={dateFields} values={this.state.dateFields} onClickField={this.handleChange} type="date" />
								{("create_enterprise" === this.props.modify) &&
									<FormFields items={mandatoryFields} className="two" values={this.state.custFields} onClickField={this.handleChange} error={this.error} type="customize" />
								}
								{("enterprise" === this.props.modify) && "admin" === sessionStorage.userDbRole ?
									<FormFields items={licenseKey} values={this.state.licenseKey} type="licensekey" onClickField={this.copyToClipboard} ></FormFields>
									:
									null
								}
								{"enterprise" === this.props.modify && "admin" === sessionStorage.userDbRole && this.state.licenseKey.lkey === this.state.licenseKey.dbLKey ?
									null !== this.state.licenseKey.dbLKey ?
										<FormFields items={confirmationKey} type="confirmationKey" onClickField={this.handleChange} error={this.error}></FormFields>
										:
										"enterprise" === this.props.modify && this.state.licenseKey.lkey !== this.state.licenseKey.dbLKey && this.state.isValidForm ? pendingApprovalDivMSG : null
									:
									"enterprise" === this.props.modify && this.state.licenseKey.lkey !== this.state.licenseKey.dbLKey && this.state.isValidForm ? pendingApprovalDivMSG : null
								}


							</div>
							{"enterprise" === this.props.modify &&
								<div className="col-sm-12 col-lg">
									<h5 className="text-center">Customizations Offered</h5>
									<FormFields items={customizeFields} values={this.state.custFields} onClickField={this.handleChange} error={this.error} state={this.state} type="customize" />
								</div>
							}
						</div>
						<div className="row">
							<div className="mandatory-note"><i className="text-danger">Note: All * are mandatory fields</i></div>
							<div className="col-sm-12 col-sm-12">
								<Button disabled={!this.state.isValidForm || this.state.isLoading} onClick={this.handleSubmit}
									className="btn-green">
									{showLoader(this.state.isLoading)}Submit</Button>
							</div>
							{"enterprise" === this.props.modify && "admin" === sessionStorage.userDbRole ?
								<div className="col-sm-12 col-sm-12">
									<Button onClick={this.toggleDataModal}
										className="btn-red">
										Show Raw Data
									</Button>
								</div>
								:
								null
							}
						</div>
					</div>
				</form>
				{this.state.showModal && <ModalPopUp modify={this.props.modify} modalTitle={this.state.modalTitle} modalBody={this.state.modalBody} serverCode={this.state.serverErrorCode} />}
				{this.state.showAlert && <AlertPopUp modalTitle={this.state.modalTitle} modalBody={this.state.modalBody} closeAlert={this.closeAlert} />}
				{/*TP-2659*/this.state.showConfirmModal && <ConfirmPopUp updateParent={this.updateShowConfirmModal} showConfirmModal={this.state.showConfirmModal} modalTitle={this.state.modalTitle} modalBody={this.state.modalBody} confirmCode={this.setEnableKC} />}
				{this.rawDataFormModal()}
				{this.passCodeFormModal()}
			</div>)
	}
};


class ModalPopUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false
		}
		this.close = this.close.bind(this);
	}

	close() {
		this.setState({ showModal: false });
		//TZ-980 -- For create enterprise API call response changed the
		// excluded Server side codes
		if ("create_enterprise" === this.props.modify) {
			if (allowedErrorCodeCreateEnterprise.includes(this.props.serverCode)) {
				return
			} else {
				window.location.reload();
			}
		} else {
			if (allowedErrorCode.includes(this.props.serverCode)) {
				return
			} else {
				window.location.reload();
			}
		}

	}

	componentDidMount() {
		this.setState({ showModal: true });
	}

	render() {
		return (
			<Modal show={this.state.showModal} onHide={this.close} backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>{this.props.modalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.props.modalBody}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.close} className="btn-green">OK</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

class AlertPopUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alertPopUp: false
		}
		this.close = this.close.bind(this);
	}

	close() {
		this.setState({ alertPopUp: false });
		this.props.closeAlert();
	}

	componentDidMount() {
		this.setState({ alertPopUp: true });
	}

	render() {
		return (
			<Modal show={this.state.alertPopUp} onHide={this.close} backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>{this.props.modalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.props.modalBody}
				</Modal.Body>
				<Modal.Footer>
					<Button onClick={this.close} className="btn-green">OK</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

/**TP-2659 -- Confirm pop up modal when Allow iOS expert flag 
 * is turned off, so that user is aware that along with that 
 * the Enable Knowledge Capture flag will also be turned off */
class ConfirmPopUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showConfirmModal: props.showConfirmModal ? props.showConfirmModal : false
		}
		this.close = this.close.bind(this);
		this.confirm = this.confirm.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.showConfirmModal !== this.props.showConfirmModal) {
			this.setState({
				showConfirmModal: nextProps.showConfirmModal
			});
		}
	}

	close() {
		this.setState({ showConfirmModal: false });
		this.props.updateParent(false);
		//this.props.closeCode();
		//window.location.reload();
	}

	confirm() {
		//this.setState({showConfirmModal:false});
		this.close();
		this.props.confirmCode();
	}

	componentDidMount() {
		//this.setState({showConfirmModal:true});
	}

	render() {
		return (
			<Modal show={this.state.showConfirmModal} onHide={this.close} backdrop="static" keyboard={false}>
				<Modal.Header>
					<Modal.Title>{this.props.modalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.props.modalBody}
				</Modal.Body>
				<Modal.Footer>
					<div /**TP-2659*/ className="row" style={{ width: "100%" }}>
						<div className="col-sm-6" style={{ alignItems: "center" }}>
							<Button onClick={this.close} className="button-btn button-btn-red" >Cancel</Button>
						</div>
						<div class="col-sm-6" style={{ alignItems: "center" }}>
							<Button onClick={this.confirm} className="button-btn button-btn-green">OK</Button>
						</div>
					</div>

				</Modal.Footer>
			</Modal>
		);
	}
}
