import React from 'react';
import {connect} from 'react-redux';
import {updateGroupConfiguration} from '../redux/Group/GroupActions'

 class StreamDropDown extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            value: props.propParam.is_onprem ? "OWRTC":"OT",
            groupName:props.propParam.group_name,  //TP-749
            groupId:props.propParam.group_id //TP-749
        }
    }

    handleChange = (event) =>{
        this.setState({value:event.target.value});
        this.setGroupConfiguration(event.target.value);
    }

    setGroupConfiguration = async (selectedStreamType) =>{

        let params={
          group_id: this.props.propParam.group_id,
          codec_type: this.props.propParam.codec_type,
          location_hint: this.props.propParam.location_hint,
          is_onprem:(selectedStreamType !== 'OT') ? true:false,
          keyName:"is_onprem",
          groupName:this.props.propParam.group_name,
          groupStreamType:selectedStreamType
        }

        const onSuccessMsg =  this.props.propParam?.isongoingnow ? params.groupName+" is updated to "+params.groupStreamType+". This change will be applied after the current session ends." 
                             :params.groupName+" is updated to "+params.groupStreamType; 
        
        this.props.updateGroupConfiguration(this.props.propParam,params,onSuccessMsg);
     } 

    render(){
        return(
            <div>
              {/*TP-738*/}
                <select id={"group-steam-type-"+this.state.groupId} key={this.state.groupId} className="group-steam-type" value={this.state.value} onChange={this.handleChange}>            
                    <option value="OT">OT</option>
                    <option value="OWRTC">OWRTC</option>
                </select>
            </div>
        )
    }
}
const mapDispatchToProps = dispatch =>({
  updateGroupConfiguration: (groupInfo,groupValue,successMsg) => dispatch(updateGroupConfiguration(groupInfo,groupValue,successMsg)),
})
export default connect(null,mapDispatchToProps)(StreamDropDown)