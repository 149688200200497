import {CustomerActionTypes} from './CustomerTypes';

const INITIAL_STATE = {
    allCustomers: [],
    isFetching: false,
    errorMessage: undefined,
    errorPayLoad:undefined,
    isFirstPageLoad:true,
    isUpdatedSuccessfull:true,
  };

  const customerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case CustomerActionTypes.FETCH_CUSTOMER_START:
        return {
          ...state,
          isFetching: true,
          isFirstPageLoad:false,
        };
      case CustomerActionTypes.UPDATE_PAGE_LOAD_SUCCESS:
        return{
          ...state,
          isFirstPageLoad:!state.isFirstPageLoad
        };
      case CustomerActionTypes.FETCH_CUSTOMER_SUCCESS:
        return {
          ...state,
          allCustomers: action.payload
        };
      case CustomerActionTypes.FETCH_CUSTOMER_FAILURE:
        return {
          ...state,
          errorMessage: action.payload,
          errorPayLoad:action.payloadError
          
        };
      case CustomerActionTypes.FETCH_ENTERPRISE_SUCCESS:
       const newCustomerList =  state.allCustomers.map((obj) => 
          obj.account_id === action.custPayload.account_id ? Object.assign(obj, { ...action.custPayload, ...action.payload }): obj)
          return{
            ...state,
            allCustomers:newCustomerList
          };
      case CustomerActionTypes.FETCH_ENTERPRISE_USER_PROFILE_SUCCESS:
        let finalCustomerList = state.allCustomers;
        const defaultProfile = {
          my_camera_on: false,
          streaming_quality: "medium",
          image_quality: "medium",
          allow_auto_connect: false,
          disable_notifications: false
        }
        finalCustomerList.forEach(cust => {
          cust.users = cust.users.map((obj) => 
          obj.id === action.userPayload.id ? 
            Object.assign(obj, { ...action.custPayload, ...action.userPayload }) 
            : 
            Object.assign(obj, defaultProfile) 
            )
        })
        return {
          ...state,
          allCustomers:finalCustomerList
        }
       case CustomerActionTypes.FETCH_CUSTOMER_END:
        return{
          ...state,
          isFetching:false,
          isFirstPageLoad:true
        };
       case CustomerActionTypes.DELETE_CUSTOMER_SUCCESS:
        const updatedCustomerList = state.allCustomers.filter((cust)=> cust.account_id !== action.payload);
        return {
          ...state,
          allCustomers:updatedCustomerList
        }
        case CustomerActionTypes.DELETE_CUSTOMER_FAILURE:
          return{
            ...state,
            errorMessage: action.payload
          }
         case CustomerActionTypes.UPDATE_USER_PROFILE_ACTION:
           const custIndex =  state.allCustomers.findIndex((cust)=>cust.account_id === action.payloadAccount.account_id);
           const updatedCustCopy = updateUserProfileByKey(state.allCustomers,custIndex,action.payloadAccount,action.payloadValue,action.payloadValue.keyName)
           return{
            ...state,
            allCustomers:updatedCustCopy
          }
          case CustomerActionTypes.UPDATE_PROCESSING_START:
            return {
              ...state,
              isUpdatedSuccessfull:false,
            }
           case CustomerActionTypes.UPDATE_PROCESSING_END:
             return{
               ...state,
               isUpdatedSuccessfull:true,
             }   
      default:
        return state;
    }
  };
  
  export default customerReducer;

 

  const updateUserProfileByKey = (allCustomer,idx,userInfo,paramValue,keyName) =>{
    const customer =  allCustomer[idx];
    if(customer && keyName){
      const users = customer.users;
      const userIdx = users.findIndex(usr=>usr.id === userInfo.id);
      if(userIdx > -1){
        const userObject = users[userIdx];

        if(keyName === "available_monthly_minutes"){
          userObject[keyName] += paramValue[keyName];
          userObject["max_monthly_minutes"] += paramValue[keyName];
        }else if(keyName === "email"){
          userObject[keyName] = paramValue["new_email"];
        }else{
          userObject[keyName] = paramValue[keyName];
        }

        users[userIdx] =  userObject;
        customer.users = users;
      }   
       
      allCustomer[idx] = customer;   
    }
    return allCustomer;
  }