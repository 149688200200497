import React, { Component } from 'react';

import { OTSession, OTStreams, preloadScript } from 'opentok-react';
import Subscriber from './Subscriber';

class TokBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      connected: false,
      subscribed:"Waiting for guest to start screen sharing"
    };

    this.sessionEvents = {
      sessionConnected: () => {
        this.setState({ connected: true });
      },
      sessionDisconnected: () => {
        this.setState({ connected: false });
      }
    };
  }

  onError = (err) => {
    this.setState({ error: `Failed to connect: ${err.message}` });
    this.props.disableSSBtn(false);
  }

  onConnect = () => {
    //Session is connected
    console.log("Session is connected: "+this.state.connected);
  }

  onSubscribe = () => {
    this.setState({subscribed:null});
    this.props.disableSSBtn(true);
  }

  onUnSubscribe = () => {
    this.setState({subscribed:"Guest has disconnected video, you may stop screen sharing"});
    this.props.disableSSBtn(false);
  }

  componentDidMount(){
    this.props.socket.on('respond-to-the-call', this.publisherCallResponse);
  }

  render() {
    return (
      <OTSession
        apiKey={this.props.apiKey}
        sessionId={this.props.sessionId}
        token={this.props.token}
        eventHandlers={this.sessionEvents}
        onConnect={this.onConnect}
        onError={this.onError} >
        {this.state.subscribed}
        <OTStreams>
          <Subscriber
          onSubscribe={this.onSubscribe}
          onUnSubscribe={this.onUnSubscribe}/>
        </OTStreams>
        {this.state.error ? <div>{this.state.error}</div> : null}
      </OTSession>
    );
  }
}

export default preloadScript(TokBox);
