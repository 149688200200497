import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {OutlinedInput, InputLabel, InputAdornment, FormControl, Box, TextField} from '@mui/material';

export default function SearchBox(props) {
    return (
        <Box bgcolor={"white"} sx={{ width: '27ch', height: 45, borderRadius: 2, /* marginLeft: "40%" */ marginRight: "17%" }}>
            <FormControl sx={{ m: 1, width: '25ch' }} >
                {/* <InputLabel htmlFor="input-with-icon-outlined-input">{props.labelText}</InputLabel> */}
                <OutlinedInput
                    id="input-with-icon-outlined-input"
                    // label={props.labelText}     //TP-6387         
                    /* startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    } */
                    placeholder={props.labelText}
                    onChange={(e) => props.keyPressFn(e)}
                    /* variant="filled" */
                    /* sx={{ backgroundColor: "white", width: '25ch' }} */
                />
            </FormControl>
        </Box>
    )
}