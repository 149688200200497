import * as React from 'react';
import Stack from '@mui/material/Stack';
import './DirectionIcon.css';

export default function DirectionIcon(props) {
    const [dir, setDir] = React.useState('');

    const handleSort = (value) => {
        setDir(value)
        props.setSortDir(value);
    }

    return (
        <>
        { dir && dir === "desc" ?
            <span class="fa-stack fa-2x" onClick={() => handleSort("asc")}>            
                <i className="fa fa-sort-desc left-icon fa-stack-1x switch-icons" style={{ color: "#2bf288", cursor: "default" }}></i>
                <i className="fa fa-sort-asc right-icon fa-stack-1x switch-icons" title="Sort in Ascending Order" style={{ cursor: "pointer" }}></i>
            </span>
            :    
            <span class="fa-stack fa-2x" onClick={() => handleSort("desc")}>
                <i className="fa fa-sort-asc left-icon fa-stack-1x switch-icons" style={{ color: "#2bf288", cursor: "default" }}></i>
                <i className="fa fa-sort-desc right-icon fa-stack-1x switch-icons" title="Sort in Descending Order" style={{ cursor: "pointer" }}></i>
            </span>        
        }
        </>
    )
}