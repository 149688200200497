import React from "react";
import {Modal,Button} from 'react-bootstrap';
import { connect } from "react-redux";
import superagent from "superagent";
import {updateUserProfile} from '../redux/Customer/CustomerActions';

function isEditMode(){
  var flag = sessionStorage.getItem("userDbRole");
  if(flag==='write_pro' || flag==='write_ent'
      || flag==='write_all' || flag==='admin' || flag==='write_my_account'){
        return true;
  }else{
    return false;
  }
}

function showLoader(isLoading){
    if (!isLoading) return "";
    else return (<i className="fa fa-spinner fa-spin"></i>)
}

class Edit extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      isEditable:false,
      showModal:false,
      fieldValue:'',
      firstName: props.params.first_name ? props.params.first_name :'', /**TP-4084*/
      lastName: props.params.last_name ? props.params.last_name :'', /**TP-4084*/
      serverResponse:null,
      loading:false,
      success:false,
      errorMsg:null,
      verifyFieldValue:''
    }
  }



  close = () => {
    if(this.props.fieldName === 'EditRow'){
      //TP-4084
      this.setState({showModal: false,fieldValue:'',verifyFieldValue:''})//TP-4359
    }else{
      this.setState({ showModal: false});
    }
  }

  open = () => {
    this.setState({ showModal: true, serverResponse:null,errorMsg:null});
  }

  //TP-4084
  inputChange = (e, key) => {
    e.preventDefault();
      this.setState({
        [key]: e.target.value
      })
  }

  inputEmailChange = (e) =>{
    e.preventDefault();
      this.setState({
        fieldValue: e.target.value
      });    
  }

  inputConfirmEmailChange = (e) =>{
    e.preventDefault();
    this.setState({
      verifyFieldValue: e.target.value
    });    
}

  save = async (e) => {
    e.preventDefault();  

    let apiUrl = null;
    let params = null;
    let dataParams = this.props.params;
    let msg = null;
    if(/*TP-4084*/"EditRow"===this.props.fieldName && this.state.firstName!=='' && this.state.lastName!=='' && this.validateEmail()){ //TP-4359

        params={
          user_id: dataParams.id,
          first_name: this.state.firstName,
          last_name: this.state.lastName,
          email: dataParams.email,
          new_email: this.state.fieldValue,
        }
    } else if ("EditRow"===this.props.fieldName && this.state.firstName!=='' && this.state.lastName!=='') { //TP-4359
      //TP-4084 -- When only Firstname or Lastname of the user is edited
      params={
        user_id: dataParams.id,
        first_name: this.state.firstName,
        last_name: this.state.lastName        
      }
    } else {
      //TP-4084 Handled the use case for when all the fields of the form are empty
      return;
    }

    //return;
      apiUrl = "/api/dash/setUserConfiguration";

      if (null!==sessionStorage.getItem("userToken") && null!==params && null!==apiUrl) {
        
        this.setState({isLoading:true});
        await superagent.post(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .send(params)
        .then(response => response)
        .then(data => {
          if(200===data.status){
            msg = "Data updated successfully";
            this.setState({ serverResponse:msg, isLoading:false,success:true });
            if(this.props.updateUserProfile){
              //TP-4359
              let sendParams = {};
              if (this.state.firstName!=='' && this.state.lastName!=='' && this.validateEmail()) {
                sendParams = {
                  first_name: this.state.firstName,
                  keyName: "first_name"
                }
                this.props.updateUserProfile(this.props.params,sendParams);                
                sendParams = {
                  last_name: this.state.lastName,
                  keyName: "last_name"
                }
                this.props.updateUserProfile(this.props.params,sendParams);
                sendParams = {
                  email: dataParams.email,
                  new_email: this.state.fieldValue,
                  keyName:"email"
                }
                this.props.updateUserProfile(this.props.params,sendParams);
                if(this.props.parentCommunicator)
                  this.props.parentCommunicator(sendParams.keyName);                
              } else if (this.state.firstName!=='' && this.state.lastName!=='') {
                sendParams = {
                  first_name: this.state.firstName,
                  keyName: "first_name"
                }
                this.props.updateUserProfile(this.props.params,sendParams);
                
                sendParams = {
                  last_name: this.state.lastName,
                  keyName: "last_name"
                }
                this.props.updateUserProfile(this.props.params,sendParams);
                if(this.props.parentCommunicator)
                  this.props.parentCommunicator("email"); 
              }
            }
          }
        }).catch(error => {
          if(undefined===error.status){
            msg = "May be internet is not connected, try again sometime";
          }else if(400===error.status){
            msg = "Data not updated";
          }else if(401===error.status){
            msg = "Session token experied, please re-login";
          }else if(403===error.status){
            msg = "You are not authorized";
          }else if(404===error.status){
            msg = "Not able to request server";
          }else if(422===error.status){
            msg = "Invalid input value";
          }else if(500===error.status){
            msg = "Something went wrong, try again sometime";
          }
          this.setState({ serverResponse:msg, isLoading:false, success:false });
        });
      }else{
          this.close();
      }
    
  }

  componentDidMount(){
    this.setState({fieldValue: '',verifyFieldValue:'',isEditable:isEditMode()});
  }


  checkEmailExists = (e) => {
    e.preventDefault();
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if(e.target.value && /*TP-4084*/''!==e.target.value && e.target.value.match(regexEmail)){
      superagent.get(sessionStorage.getItem("url")+`/api/checkEmailExists/${this.state.fieldValue}`)
      .set("Authorization", "Bearer " + sessionStorage.getItem("userToken")) //TP-4416
      .then(response => response)
      .then(data =>{
        if(data.body){
          this.setState({errorMsg:"This Email is already in use"})
        }else{
          this.setState({errorMsg:null})
        }
      })
    }else if(e.target.value){
      this.setState({errorMsg:"This Email is not valid"})
    } 
  }

  validateEmail = () =>{
    let email =  document.getElementById('newEmail').value;
    let conEmail =  document.getElementById('confirmEmail').value;
    if(this.state.errorMsg===null){
      if(email !== conEmail){
        this.setState({errorMsg:"Email do not match"});
        return false;
      }else if (email === '' || conEmail === '') {
        //TP-4084
        //this.setState({errorMsg:"Email/Confirm email field can't be empty"});
        this.setState({errorMsg:null});
        return false;
      } else{
        this.setState({errorMsg:null});
        return true;
      }
    }
   
  }

  serverPostRequest = async(params, apiUrl, msg) =>{
    if (null!==sessionStorage.getItem("userToken")
        && null!==params && null!==apiUrl) {
        this.setState({isLoading:true});
         await superagent.post(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .send(params)
        .then(response => response)
        .then(data => {
          if(200===data.status){
            msg = "Data updated successfully";
            this.setState({ serverResponse:msg, isLoading:false,success:true });
            if(this.props.updateUserProfile){
              this.props.updateUserProfile()
            }
          }
        }).catch(error => {
          if(undefined===error.status){
            msg = "May be internet is not connected, try again sometime";
          }else if(400===error.status){
            msg = "Data not updated";
          }else if(401===error.status){
            msg = "Session token experied, please re-login";
          }else if(403===error.status){
            msg = "You are not authorized";
          }else if(404===error.status){
            msg = "Not able to request server";
          }else if(422===error.status){
            msg = "Invalid input value";
          }else if(500===error.status){
            msg = "Something went wrong, try again sometime";
          }
          this.setState({ serverResponse:msg, isLoading:false, success:false });
        });
    }else{
        this.close();
    }
  }

  render() {
    let firstNameDiv, lastNameDiv, fieldDiv, labelDiv, confirmDiv = null;
    if("EditRow" === this.props.fieldName){
      /**TP-4084*/
      firstNameDiv = <div><b>First Name:&nbsp;</b><input id="firstName" type="text" style={{width:'400px'}} autoComplete="off" onChange={e=>this.inputChange(e,'firstName')}  value={this.state.firstName} /></div>
      lastNameDiv = <div><b>Last Name:&nbsp;</b><input id="lastName" type="text" style={{width:'400px'}} autoComplete="off" onChange={e=>this.inputChange(e,'lastName')}  value={this.state.lastName} /></div>
      labelDiv = <div><b>Current Email:&nbsp;</b><input id="currentEmail" type="email" disabled={true} style={{width:'400px',color:"#060606"}} value={this.props.fieldValue} /></div>
      fieldDiv = <div><b>New Email:&nbsp;</b><input id="newEmail" type="email" style={{width:'400px'}} autoComplete="off" onChange={e=>this.inputEmailChange(e,'email')} onBlur={e=>this.checkEmailExists(e)} value={this.state.fieldValue} /></div>
      confirmDiv = <div><b>Confirm Email:&nbsp;</b><input id="confirmEmail" type="email" style={{width:'400px'}} autoComplete="off" onFocus={e=>this.checkEmailExists(e)} onChange={e=>this.inputConfirmEmailChange(e,'confirmEmail')} onBlur={e=>this.validateEmail(e)} value={this.state.verifyFieldValue} ></input></div>
    }

    return (
      <span>
        {
          this.state.isEditable && this.props.fieldName === "EditRow" /**TP-4084*/ && 
            <i className="fa fa-pencil-square-o new-edit-link" id={"editEmail"+this.props.params.id} onClick={this.open}></i>
        }
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Update Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="modify-invitee-email" className="container-fluid">
              <div className="row">
                  {/**TP-4084 -- New input fields added for firstname & lastname*/}
                  {firstNameDiv}
                  {lastNameDiv}
                  {labelDiv}
                  {fieldDiv}
                  {confirmDiv}
              </div>
              <div className="row-email">
                <small className="text-email-danger col-12">{this.state.errorMsg}</small>
                {/*TP-1342*/}
                {/* <details> 
                  <summary className="small"><i className="fa fa-info"/>&nbsp;Note</summary>
                  <i className="small">After successful data save, click refresh from homepage to see the changes</i>
                </details> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container-fluid">
              <div className="row">
              {
                null!==this.state.serverResponse&&
                <div className="col-sm-12 mb-1">
                  <div
                  className={this.state.success?"alert text-center alert-success":"alert text-center alert-danger"}>
                    {this.state.serverResponse}
                  </div>
                </div>
              }
              <Button onClick={e=>this.save(e)} className={this.state.errorMsg===null ? "d-block m-auto btn-green": "d-block m-auto btn-gray"}
                disabled={null!==this.state.errorMsg}>
                {showLoader(this.state.isLoading)} Save
              </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = dispatch => ({
  updateUserProfile: (accountParam,userParam) => dispatch(updateUserProfile(accountParam,userParam))
})
export default connect(null,mapStateToProps)(Edit)