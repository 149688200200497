//created by Roopesh Singh TP-501
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import ImageNotFound from './no-image-icon.png';
import siteLogo from './GreenLogo.JPG';
import {localizedData} from '../languages/localizedData'; //TP-4211
let fontStyle;
let globalLanguage = "en"; //TP-4211

//TP-4211 -- the languages we support
const includedLanguages = ['en', 'fr', 'de', 'pt', 'es', 'it', 'ja-JP', 'zh-CN', 'zh-cn']

const base64Img = require('base64-img');
let statusObj = {'created': localizedData[globalLanguage]['Created'], 'assigned': localizedData[globalLanguage]['Assigned'],'in_progress': localizedData[globalLanguage]['In Progress'], 'finished': localizedData[globalLanguage]['Finished'], 'skipped': localizedData[globalLanguage]['Skipped']}

const _format = async (data) => {
    try{
        let dataArr = [];
        for(let i=0;i < data.length; i++){
            let task = data[i];
            let task_data,tableBody,parsedTaskData = {};
            let pageBreakObj = (i === data.length - 1) ? '' : {text: '',pageBreak: 'after'}
            let audioLink, audioText;

            switch(task.name){
                case "Pass/Fail/NA":
                case "Yes/No/NA":
                    task_data = task.task_data ? task.task_data : []
                    audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                    tableBody = await imgDataLoop(task_data);
                    if(Array.isArray(task_data) && task_data.length> 0){
                        parsedTaskData = JSON.parse(task_data[0]);
                    }
                    dataArr.push([
                        {
                            text: localizedData[globalLanguage][`Procedure`]+` ${task.sequence + (task.sub_sequence ? task.sub_sequence : '')}`,
                            style: 'subheader',
                        },
                        {
                            style: 'tableExample',
                            color: '#444',
                            table: {
                                widths: [175, '*'],
                                body: [
                                    [{text: localizedData[globalLanguage]['Task Instruction']+':', style: 'dataOddRow'}, {text: `${task.instruction}`,style: 'oddRow'}],
                                    [{text: localizedData[globalLanguage]['Task Status']+':', style: 'dataEvenRow'}, {text: `${statusObj[task.status]}`,style: 'evenRow'}],
                                    [{text: localizedData[globalLanguage][`Executed By`]+`:`, style: 'dataOddRow'}, {text: getExecutedBy({task}), style: 'oddRow'}],
                                    [{text: `\n`+localizedData[globalLanguage][`Execution Time`]+`:`, style: 'dataEvenRow'}, {text: `${getExecutionDuration({task})}\n ${moment(task.execution_start_time).isValid() ? moment(task.execution_start_time).format("DD MMMM YYYY, h:mm:ss a"): ''} - ${moment(task.execution_end_time).isValid() ? moment(task.execution_end_time).format("DD MMMM YYYY, h:mm:ss a"): ''}`, style: 'evenRow'}],
                                    [{text: localizedData[globalLanguage]['Expert Call Detail(s)']+':', style: 'dataOddRow'}, {stack:
                                        [
                                            {
                                                ul: [
                                                        ...getMultipleParsedCallRecords({call_recordings: task.call_recordings})
                                                ]
                                            }
                                        ], style: 'oddRow'}],
                                    [{text: localizedData[globalLanguage][`Device Details`]+`:`, style: 'dataEvenRow'},
                                        {
                                            text: `${parsedTaskData.device_manufacturer || ''} ${parsedTaskData.device_model || ''} ${parsedTaskData.serial_number || ''} ${parsedTaskData.coordinates || ''}`,
                                            style: 'evenRow'
                                        }
                                    ],
                                    [{text: localizedData[globalLanguage]['Observation']+':', style: 'dataOddRow'}, {text: `${parsedTaskData.cl_observation ? localizedData[globalLanguage][parsedTaskData.cl_observation] : ''}`,style: 'oddRow'}],
                                    [{text: localizedData[globalLanguage]['Comment']+':', style: 'dataEvenRow'}, {text: `${getComment(task)}`,style: 'evenRow'}],
                                ]
                            },
                            layout: tableLayout()
                        },
                        { text : task.skipped_comment ? [{text:localizedData[globalLanguage]['Skipped Comment']+': ' , style: 'subheader'}, { text: `${task.skipped_comment}`}] : '', style: 'recordedLink'},
                        { text : audioLink ? [{text:localizedData[globalLanguage]['Recorded Audio']+': ' , style: 'subheader'}, { text: localizedData[globalLanguage][`Click here to listen to the operator’s comments`], link: audioLink }] : '', style: 'recordedLink'},
                        { text : audioText ? [{text:localizedData[globalLanguage]['Operator Comments']+': ' , style: 'subheader'}, { text: audioText }] : ''},
                    pageBreakObj
                    ]);
                    break;
                default:
                dataArr.push('');
                break;
            }
        }
        return dataArr.flat();
    }catch(error){
        console.error(`error in _format method while constructiing pdf data ${error.message}`)
        return [];
    }
}

const getMultipleParsedCallRecords = ({call_recordings}) => {
    let callRecordings = [];
    try{
        if(call_recordings){
            callRecordings = call_recordings.map((call_recording) => {
                const callRecordingData = call_recording ? JSON.parse(call_recording) : null
                return {text: localizedData[globalLanguage][`Call to`]+` ${callRecordingData.expert_first_name} ${callRecordingData.expert_last_name}`+ localizedData[globalLanguage][` at `]+`${moment(callRecordingData.call_start_time).format("DD MMMM YYYY, h:mm:ss a")}`+localizedData[globalLanguage][` for `]+` ${callRecordingData.call_duration} `+localizedData[globalLanguage][`seconds`]}
            });
        }
        return callRecordings;
    }catch(error){
        console.log('getMultipleParsedCallRecords error', error)
    }
}

const audio_link = ({audio_link_data}) => {
    try {        
        if(Array.isArray(audio_link_data) && audio_link_data.length> 0){
            const parsedTaskData = JSON.parse(audio_link_data[0]);
            const audioOURL = parsedTaskData ? parsedTaskData.url:null;
            const audioName = audioOURL.substring(audioOURL.lastIndexOf('/')).substring(1);
            return 'assets/'+encodeURIComponent(audioName)
        }
        return '';
    }catch (error){
        console.error('get multiple Audio Parse Error:'+error);
        return '';
    }
}

//TP-2036
const audio_text = ({audio_link_data}) => {
    try {        
        if(Array.isArray(audio_link_data) && audio_link_data.length> 0){
            const parsedTaskData = JSON.parse(audio_link_data[0]);
            return parsedTaskData.comments;
        }
        return '';
    }catch (error){
        console.error('get multiple Audio text Parse Error:'+error);
        return '';
    }
}

const imgDataLoop = async (task_data) => {
    try{
        const tableBody = [];
        for (let index = 0; index < task_data.length; index++) {
                        
            let parseData = JSON.parse(task_data[index]);
            const url = parseData.url ? parseData.url : ''
            let imgData = await getImageDataUrl(url);
            let imgIndex = url.split('/').length -1;
            let imageLink = url.split('/')[imgIndex]
            if(url){
                tableBody.push({
                    image: imgData,
                    // link: parseData.url,
                    link: 'assets/'+encodeURIComponent(imageLink),
                    fit: [80, 80],
                    margin: 15,
                    alignment: 'left'
                });
            }else{
                tableBody.push('')
            }
        }
        return tableBody;
    }catch(err){
        console.error(`error in imgDataLoop `, err)
    }
};

const getImageDataUrl = (imageUrl) =>{
    try{
        let imgDataUrl;
        return new Promise((resolve, reject) => {
            base64Img.requestBase64(imageUrl, function(err, res, body) {
                if(imageUrl === '' || err || res.statusCode !== 200){
                    imgDataUrl = ImageNotFound;
                }else{
                    imgDataUrl = body;
                }
                resolve(imgDataUrl);
            });
        })
    }catch(err){
        console.error(`error in getImageDataUrl `, err)
    }
};

// Mb2-836
const getExecutionDuration = ({task}) => {
    try{
        if(moment(task.execution_start_time).isValid() && moment(task.execution_end_time).isValid()){
            const a = moment(task.execution_end_time), b = moment(task.execution_start_time);
            let timediff = Math.floor(Math.abs(a.diff(b))/1000) ;
                if (timediff > 0){
                    return (localizedData[globalLanguage]['Duration']+': (' + timediff + ' '+localizedData[globalLanguage]['seconds']+')');
                }else{
                    return '';
                }
        }else{
            return ''
        }
    }catch(err){
        console.error(`error in getExecutionDuration`, err)
        return ''
    }
}

const getExecutedBy = ({task}) => {
    if(task.status === 'finished' || task.status === 'skipped'){
        let name;
        name = task.first_name || localizedData[globalLanguage]['Non'];
        name += ' ';
        name += task.last_name || localizedData[globalLanguage]['-existent'];
        name += task.is_offline_mode? ' ('+localizedData[globalLanguage]['offline']+')' : ' ('+localizedData[globalLanguage]['online']+')'
        return name;
    }else{
        return '';
    }
}

const showOriginalReferenceFileName = ({fileName}) => {
    try{
        let originalFileName = '';
        if (fileName){
            let splitedFileName = fileName.split(/_(.+)/);//TP-1058
            // splitedFileName.shift();originalFileName = splitedFileName.join('-');
            originalFileName = splitedFileName[1];
        }
        if(originalFileName){
            return originalFileName;
        }else{
            return ''
        }
    }catch(error){
        console.log('showOriginalFileName error', error)
        return ''
    }
}

const getAssetReferenceFiles = ({files, type}) => {
    let referenceMaterials = [];
    try{
        referenceMaterials = files.filter((file) => file.type === type).map((file) => {
            return {text: `${showOriginalReferenceFileName({fileName: file.file_name })}`, link: 'assets/'+encodeURIComponent(file.file_name)}//TP-2882
        });
        return referenceMaterials;
    }catch(error){
        console.log('getAssetReferenceFiles error', error)
    }
}

const tableLayout = () => {
    const layout = fontStyle === "NotoSansSC" ? {
        paddingTop: function(i, node) { return 0; },
        paddingBottom: function(i, node) { return 0; },
    } : {}

    return layout
}

// TP-3837
const getComment = (task) => {
    try{
        let comment = "";
        if (task.name === "Pass/Fail/NA" || task.name === "Yes/No/NA" ){
            comment = task.tech_comment? task.tech_comment : localizedData[globalLanguage]['NA']
        }else{
            comment = task.tech_comment? task.tech_comment : ''
        }    
        return comment
    }catch(error){
        console.log('getComment error',error)
        return ""
    }    
}    

//TP-3147
const jobStatusSummary = (job_status, totalExecutionTime) => {
    try{
        const totalExecutionTimeInSecs = Math.floor(totalExecutionTime/1000) ;
        const minutes = Math.floor(totalExecutionTimeInSecs / 60);
        const seconds = totalExecutionTimeInSecs - minutes * 60;

        if(job_status === 'in_progress'){
            return localizedData[globalLanguage]['Job is not yet completed.']
        }else if(job_status === 'finished'){
            let str = localizedData[globalLanguage]['Job was completed in'] + ((minutes > 0 ? ` ${minutes}`+localizedData[globalLanguage][` minutes and`] : '') + ` ${seconds}`+localizedData[globalLanguage][` seconds.`])
            return str;
        }else{
            return localizedData[globalLanguage]["Job has been assigned but has not yet been started to be worked on."]
        }
    }catch(error){
        console.error(`errror in jobStatusSummary ${error.message}`)
        return ''
    }
}

const _summary = async (data) => {
    try{
        let tableHeaderFontSize = fontStyle === "NotoSansSC" ? 15 : 17;
        let tableContentFontSize = fontStyle === "NotoSansSC" ? 13 : 15;
        let statusObj = {'created': localizedData[globalLanguage]['Created'], 'assigned': localizedData[globalLanguage]['Assigned'],'in_progress': localizedData[globalLanguage]['WIP'], 'finished': localizedData[globalLanguage]['Finished'], 'skipped': localizedData[globalLanguage]['Skipped']};       
        let tableContent = [[
            { text: '#', style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize},
            { text: localizedData[globalLanguage]['Instruction'], style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize},
            { text: localizedData[globalLanguage]['Assignee'], style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize},
            { text: localizedData[globalLanguage]['Status'], style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize}
        ]];
        let totalExecutionTime = 0;
        for(let i=0; i < data.length; i++){
            let tInstruction = '';
            if(data[i].instruction && data[i].instruction.length > 50) {
                tInstruction = data[i].instruction.substring(0,50) + "...";
            }else{
                tInstruction = data[i].instruction;
            }
            let assignee = (data[i].first_name || localizedData[globalLanguage]['Non']) + ' ' + (data[i].last_name || localizedData[globalLanguage]['- existent']);
            let status = data[i].status;
            let sequence = data[i].sequence + (data[i].sub_sequence ? data[i].sub_sequence : '');
            let styleClass = (i + 1)%2 === 0 ? 'evenRow' : 'oddRow'
            tableContent.push([
                    {
                        text: `${sequence}`, style: styleClass, fontSize: tableContentFontSize
                    },
                    {
                        text: `${tInstruction}`, style: styleClass, fontSize: tableContentFontSize
                    },
                    {
                        text: `${assignee}`, style: styleClass, fontSize: tableContentFontSize
                    },
                    {
                        text: `${statusObj[status]}`, style: styleClass, fontSize: tableContentFontSize
                    }
                ])
            totalExecutionTime += moment(data[i].execution_start_time).isValid() && moment(data[i].execution_end_time).isValid() ? (moment(data[i].execution_end_time) - moment(data[i].execution_start_time)) : 0;
        }

        let summaryData = [
            {text: localizedData[globalLanguage]['Summary'], style: 'header', fontSize: fontStyle === "NotoSansSC" ? 45 : 50, color: '#2d3858'},
            {text: jobStatusSummary(data[0].job_status, totalExecutionTime), style: 'statusSummary'},//TP-3147
            {
                columns: [
                    {
                        style: 'tableExample',
                        table: {
                            widths: [30, 480, 135, 65],
                            body: tableContent
                        }
                    },
                ]
            
            },
            {text: '',pageBreak: 'after'}
        ];

        return summaryData.flat();
    }catch(error){
        console.error(`errror in _summary ${error.message}`)
        return [];
    }
};

//TP-3993
const setFont = (language) => {
    fontStyle = (language === "zh-cn" || language === "zh-CN" || language === "ja-JP") ? "NotoSansSC" : "Roboto";
}

const _introduction = async ({jobName, company_name, creater_name , job_created_on, last_updated_on, customer}) => {
    try{
        let introData = [], logo_url, headerFontSize = fontStyle === "NotoSansSC" ? 45 : 50,
        logoSize = fontStyle === "NotoSansSC" ? [150, 100] : [200, 150];
        if (customer.allow_custom_logo && customer.jobs_logo_url && customer.jobs_logo_url !== ''){
            logo_url = JSON.parse(customer.jobs_logo_url).location;//T32-10
        }
        let imgData = logo_url && logo_url !== '' ? await getImageDataUrl(logo_url) : await getImageDataUrl(siteLogo);
        introData.push(
            {
                text:[
    
                    {text: localizedData[globalLanguage][`Job Report`]+`\n\n`, style:'header', fontSize: headerFontSize, alignment: 'center'},
                    {text: localizedData[globalLanguage][`Job Name`]+`: ${jobName}\n\n`, style:'header', alignment: 'center'},
                    {text: localizedData[globalLanguage][`Account Name`]+`: ${company_name}\n\n`, style:'header', alignment: 'center'},
                    {text: localizedData[globalLanguage][`Created by`]+`: ${creater_name}\n\n`, style:'header', alignment: 'center'},
                    {text: localizedData[globalLanguage][`Created On`]+`: ${job_created_on}\n\n`, style:'header', alignment: 'center'},
                    {text: localizedData[globalLanguage][`Updated On`]+`: ${last_updated_on}\n\n`, style:'header', alignment: 'center'},
                    
                ],
                margin: [ 0, 10, 0, 0 ]
            },
            {
                image: imgData,
                fit: logoSize,
                alignment: 'center',
                margin: [ 5, 10, 0, 0 ],
                pageBreak: 'after'
            }
        )
        return introData;
    }catch(error){
        console.log(error)
    }
}

// TP-2826
const _assetSummary = async (data) => {
    try{
        const asset = data[0];
        let tableContent = [], description = '', comments = '';

        if(asset.asset_description && asset.asset_description.length > 50) {
            description = asset.asset_description.substring(0,50) + "...";
        }else{
            description = asset.asset_description;
        }
        if(asset.comments && asset.comments.length > 50) {
            comments = asset.comments.substring(0,50) + "...";
        }else{
            comments = asset.comments;
        }
        let asset_class_name = asset.asset_class_name;
        let rowFontSize = fontStyle === "NotoSansSC" ? 15 : 17

        tableContent.push(
            [{text: localizedData[globalLanguage][`Name`]+`:`, style: 'oddRow', fontSize: rowFontSize}, {text: `${asset.asset_name}`, style: 'oddRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Description`]+`:`, style: 'evenRow', fontSize: rowFontSize}, {text: `${description}`, style: 'evenRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Asset Class`]+`:`, style: 'oddRow', fontSize: rowFontSize}, {text: `${asset_class_name}`, style: 'oddRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Geo Location`]+`:`, style: 'evenRow', fontSize: rowFontSize}, {text: `${asset.geo_location || ''}`, style: 'evenRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Installed Location`]+`:`, style: 'oddRow', fontSize: rowFontSize}, {text: `${asset.asset_installed_at || ''}`, style: 'oddRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Installed On`]+`:`, style: 'evenRow', fontSize: rowFontSize}, {text: `${moment(asset.asset_installed_on).format("DD MMMM YYYY")}`, style: 'evenRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Installation End Date`]+`:`, style: 'oddRow', fontSize: rowFontSize}, {text: `${moment(asset.asset_installation_end_date).format("DD MMMM YYYY")}`, style: 'oddRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage][`Comment`]+`:`, style: 'evenRow', fontSize: rowFontSize}, {text: `${comments}`, style: 'evenRow', fontSize: rowFontSize}],
            [{text: localizedData[globalLanguage]['Asset Reference Material']+':', style: 'oddRow', fontSize: rowFontSize}, {stack:
                [
                    {
                        ul: [
                                ...getAssetReferenceFiles({files: data, type: 'asset_reference'})
                        ]
                    }
                ] ,style: 'oddRow'}
            ],
            [{text: localizedData[globalLanguage]['Look Up Image']+':', style: 'evenRow', fontSize: rowFontSize}, {stack:
                [
                    {
                        ul: [
                                ...getAssetReferenceFiles({files: data, type: 'look_up_reference'})
                        ]
                    }
                ] ,style: 'evenRow'}
            ],
        )

        let summaryData = [
            {text: localizedData[globalLanguage]['Asset Details'], style: 'header', fontSize: fontStyle === "NotoSansSC" ? 45 : 50, color: '#2d3858'},
            {
                columns: [
                    {
                        style: 'tableExample',
                        table: {
                            widths: [175, '*'],
                            body: tableContent
                        }
                    },
                ]
            
            },
            {text: '',pageBreak: 'after'}
        ];

        return summaryData.flat();
    }catch(error){
        console.error(`errror in _assetSummary ${error.message}`)
        return [];
    }
};

export default async ({checkListTasks, modelAssets:assets, selectedJob, customer, language}) => {
    try{
		//TP-4211
        globalLanguage = includedLanguages.includes(language) === false ? "en" : (language === "zh-cn" || language === "zh-CN" ) ? "zh-cn" : language;
        statusObj = {'created': localizedData[globalLanguage]['Created'], 'assigned': localizedData[globalLanguage]['Assigned'],'in_progress': localizedData[globalLanguage]['In Progress'], 'finished': localizedData[globalLanguage]['Finished'], 'skipped': localizedData[globalLanguage]['Skipped']}
        const data = checkListTasks;
        const {vfs} = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        pdfMake.fonts = {
            NotoSansSC: {
                normal: 'https://telepresenz-20-p2pfiles.s3.us-east-2.amazonaws.com/NotoSansSC-Regular.otf',
                bold: 'https://telepresenz-20-p2pfiles.s3.us-east-2.amazonaws.com/NotoSansSC-Medium.otf',
                italics: 'https://telepresenz-20-p2pfiles.s3.us-east-2.amazonaws.com/NotoSansSC-Regular.otf',
                bolditalics: 'https://telepresenz-20-p2pfiles.s3.us-east-2.amazonaws.com/NotoSansSC-Medium.otf'
            },
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-Italic.ttf'
            }
        }
        setFont(language);
        //TP-502
        let downloaded_on =   moment(new Date()).format('DD MMMM YYYY HH:mm:ss');
        let generated_by = customer && customer.allow_company_name_as_report_author ? customer.company_name : 'Telepresenz ™';
        generated_by += (customer && customer.allow_company_name_as_report_author && customer.company_url && customer.company_url !== '' ? ` - ${customer.company_url}` : ''); 
        generated_by += " "+localizedData[globalLanguage]["on"]+" "+downloaded_on; 
        const summaryData = data.length> 0 ? await _summary(data) : '';
        const assetSummaryData = assets && assets.length > 0 ? await _assetSummary(assets) : '';
        let company_name = data[0] ? data[0]['company_name'] : '';
        let creater_name = `${data[0] ? data[0].creater_fname : ''}`;
        creater_name += ` ${data[0] ? data[0].creater_lname : ''}`;
        let job_created_on = data[0] ? moment(data[0].job_created_on).format("DD MMMM YYYY") : '';
        let last_updated_on = data[0] ? moment(data[data.length-1].job_updated_on).format("DD MMMM YYYY") : '';
        

        const introData = await _introduction({jobName: selectedJob.name, company_name, creater_name, job_created_on, last_updated_on, customer});
        const formattedData = await _format(data);

        const documentDefinition = {
                pageSize: 'A4',
                pageMargins: [ 40, 60, 40, 60 ],
                pageOrientation: 'landscape',
                header: function(currentPage, pageCount) {
                    if(currentPage !== 1 && currentPage !== 2){
                        return([
                            { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 800, y2: 40, lineWidth: 0.5 }] },
                            {text: '\n'},
                        ])
                    }else{
                        return null;
                    }
                },
                footer: function(currentPage, pageCount) {
                    if(currentPage !== 1 && currentPage !== 2){
                            return ([
                            { canvas: [{ type: 'line', x1: 40, y1: 10, x2: 800, y2: 10, lineWidth: 0.5 }] },
                            {
                                alignment: 'justify',
                                columns: [
                                    { text: localizedData[globalLanguage]['Proprietary & Confidential'], margin: [40, 5, 0, 0],fontSize: 10 },
                                    { text: localizedData[globalLanguage]['Page']+' | '+ currentPage.toString(), alignment: 'center', margin: [0, 5, 0, 0],fontSize: 10 },
                                    { text: `${company_name}`, alignment: 'right' , margin: [0, 5, 40, 0],fontSize: 10 },
                                ],
                            },

                        ])
                    }else if(currentPage === 1){
                        return {text: localizedData[globalLanguage][`Generated By`]+` ${generated_by}`, style:'tableHeader', margin: [0, 30, 0, 0], alignment: 'center', fontSize: 13};
                    }else{
                        return null;
                    }
                },
                // watermark: { text: 'test watermark', angle: 70, color: 'blue', opacity: 0.3, bold: true, italics: false  },
                content: [
                    ...introData,
                    ...summaryData,
                    ...assetSummaryData,
                    ...formattedData
                ],
                defaultStyle: {
                    font: fontStyle
                },
                styles: {
                    header: {
                    fontSize: fontStyle === "NotoSansSC" ? 14 : 16,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                subheader: {
                    fontSize: fontStyle === "NotoSansSC" ? 14 : 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                    color:'#2d3858'
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: fontStyle === "NotoSansSC" ? 11 : 13,
                    color: 'black'
                },
                oddRow: {
                    fillColor: '#384571',
                    color:'#FFFFFF'
                },
                evenRow: {
                    fillColor: '#2D3858',
                    color:'#FFFFFF'
                },
                dataOddRow: {
                    bold: true,
                    fontSize: fontStyle === "NotoSansSC" ? 11 : 13,
                    fillColor: '#384571',
                    color:'#FFFFFF'
                },
                dataEvenRow: {
                    bold: true,
                    fontSize: fontStyle === "NotoSansSC" ? 11 : 13,
                    fillColor: '#2D3858',
                    color:'#FFFFFF'
                },
                blueFont: {
                    color:'#2d3858'
                },
                recordedLink: {
                    margin: [0, 10, 0, 5],
                },
                statusSummary: {
                    bold: true,
                    italics: true,
                    fontSize: fontStyle === "NotoSansSC" ? 18 : 20,
                    color: '#2d3858',
                    margin: [0, 10, 0, 5],
                }
            },            
        };

        return documentDefinition;
    }catch(error){
        console.error(`report not generated ${error.message}`)
        return {}
    }       
}

