 import React from "react";
import {Collapse,NavbarToggler} from 'reactstrap';
import IdleTimer from "react-idle-timer";
import telepresenzBrand from "./care4dImg/white_logo_transparent_branding.png";

const environment = {
  "https://dev.telepresenzdemo.com" : "DEV",
  "https://qa.telepresenzdemo.com" : "QA",
  "https://demo.telepresenz.com" : "DEMO",
  "https://app.telepresenz.com" : "PROD"
}

const behavior = {
  "default":"",
  "smartvision":"SmartVision",
  "vuyu":"VuYu"
}

function showLoader(isLoading){
    if (!isLoading) return "";
    else return (<div className="loader dimScreen"><i className="fa fa-spinner fa-spin" style={{fontSize:"60px",color:"white"}}></i></div>)
}
export default class DashboardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen:false,
      isLoading:false,
      pendingRequestCount:0, // TP-6475
      pendingEnterpriceRequestCount: 0 ,  // TP-6475
      totalPendingRequest : 0, // TP-6475
    }
    this.idleTimer = null;
  }
  componentDidMount() {
    // Activate the event listener
      // this.setupBeforeUnloadListener();
      this.fetchPendingModifyAccount();  // TP-6475
  }
  // Setup the beforeunload event listener
  setupBeforeUnloadListener = () => {
      window.addEventListener("beforeunload", (ev) => {
          ev.preventDefault();
          return this.props.signout();
      });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onIdle = () => {
      this.setState({isLoading:true});
      this.props.signout();
  }

  onActive = () => {
    this.idleTimer.reset();
  }

     // TP-6475
    handleBellIcon = (e) => {
      this.props.breadcrumbTail("pendApprvl",this.props.allCustomers,"Pending Approvals");
    }

     // TP-6475
  fetchPendingModifyAccount = async () => {
    let apiUrl = "/api/dash/getPendingAccountApprovals";
    fetch(sessionStorage.getItem("url") + apiUrl, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + sessionStorage.getItem("userToken")
        },
    })
    .then((response) => response.json())
    .then((data) => {
        this.setState({pendingRequestCount:data.pendingRequest.length });
    })


    let apiUrl2 = "/api/dash/getAllCustomers";
    fetch(sessionStorage.getItem("url") + apiUrl2, {
      method: "GET",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + sessionStorage.getItem("userToken")
      },
  })
  .then((response) => response.json())
  .then((data) => {
    let entaccreq = [] ;
    data.forEach(c => c.subscription_status === "enterprise_requested" && entaccreq.push(c)) ;
      this.setState({pendingEnterpriceRequestCount:entaccreq.length});
      // this.setState({totalPendingRequest:((this.state.pendingRequestCount) + (this.state.pendingEnterpriceRequestCount))});
   })
}

  render() {
    return (
      <div id="header_bar" className="fixed-top">
      { showLoader(this.state.isLoading) }
          <nav className="navbar navbar-dark relative bg-primary navbar-expand-lg py-md-0 navbar-gradient">
            <div className="navbar-brand no-cursor-pointer">
              <img alt="Log into telepresenz!" src={telepresenzBrand} width="142" height="95"/>
            </div>
            <hr className="hr-separator" />
            <NavbarToggler onClick={() => this.toggle()} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <ul className="text-center navbar-nav nav-fill w-100 justify-content-end align-items-lg-center">
                <span className="navbar-text signout-no-decoration">
                  {environment[sessionStorage.getItem("url")]} Dashboard - {sessionStorage.getItem("isSmartVision") !== "false"? behavior[sessionStorage.getItem("userBehaviour")]:''} {this.props.userRole[sessionStorage.getItem("userDbRole")]}
                </span>
                <li /* TP-6475 */ >  
                <button 
                id="notifyIcon"
                style={{display:this.props.enterpriseWriteAccess || this.props.myWriteAccountAccess || this.props.myReadAccountAccess? "flex": "none"}}
                onClick={e=> this.handleBellIcon(e)}>
                <i className="fa fa-bell fa-2x bell-icon"></i>
                <span id="superscriptValue">{(this.state.pendingRequestCount) + (this.state.pendingEnterpriceRequestCount)}</span>
                </button>
                </li>
                <hr className="hr-separator" />
                <li className="navbar-left">
                  <div onClick={this.onIdle}
                    className="small-nav-link"
                    title="Click to log out of the platform">
                    <i className="fa fa-sign-out"></i>
                    <span className="signout-no-decoration">Signout</span>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          debounce={250}
          stopOnIdle={true}
          timeout={1000*60*15} />
      </div>
    );
  }
}
