import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { Grid } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Carousel from 'react-material-ui-carousel'; /**TP-5488*/
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

const breakArr = [
    {
        breakpoint: 1536,
        cols: 5,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 1200,
        cols: 4,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 900,
        cols: 4,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 600,
        cols: 2,
        rows: 1,
        gap: 10
    },
    {
        breakpoint: 0,
        cols: 2,
        rows: 1,
        gap: 10
    }
]

const theme = createTheme({
    spacing: 2,
    palette: {
      background: {
        paper: '#384571', // your color
      },
      divider: {
        primary: '#1c2238',
        secondary: '#384571'
      }
    },
    typography: {
        allVariants: {
            fontFamily: "Titillium Web",
            color: "white",   
            paddingTop: 6,
            paddingBottom: 10,                     
        },
        body2: {
            textAlign: 'left',
            paddingLeft: 10,
            paddingBottom: 2
        }
    },
  });

  theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  const SubCard = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'left',
    background: '#1c2238',
    color: "white",
    height: "fit-content", //170, 
    lineHeight: '60px',
    /* margin: '10px', */
    borderRadius: '10px' /**TP-5488*/
  }));

/* const ActionBox = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    flexDirection: 'row',
    justifyContent: 'flex-end'
})) */

const colorPalettes = [
    {name: 'Most Recent', value: '#6fc566'},
    {name: 'Moderately Recent', value: '#e6e635'},
    {name: 'Very Old', value: '#FF0000'},
    {name: 'NA', value: '#777'}
]

const setColor = (value) => {
    let color = '';
    colorPalettes.forEach(cp => {
        //colorPalettes.name === columns[8].cell(row) ? colorPalettes.value : '#0c7aff'
        if (cp.name === value) 
            color = cp.value;
    })
    return color;
}

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 140, height: 140
      },
      children: `${name.split(' ')[0][0]}`,
    };
  }

const Item = ({index, row, columns}) => {
    //console.log(columns);
    const [actionCol] = columns.filter(col => col.selector === "action");
    const [typeCol] = columns.filter(col => col.selector === "group_type");
    const [codecCol] = columns.filter(col => col.selector === "codec_type");
    const [primaryCol] = columns.filter(col => col.selector === "primary_name");
    const [streamCol] = columns.filter(col => col.selector === "stream_type");
    const [expertsCol] = columns.filter(col => col.selector === "experts");
    const [techCol] = columns.filter(col => col.selector === "techs");
    return <Grid key={index} item xs={6} sm={4.4} md={3} lg={2.4}>
                <Box sx={{ maxWidth: 355, borderRadius: '10px', boxShadow: 3}}>
                    <Card sx={{ maxWidth: 345, borderRadius: '10px' }}>
                        {/* <CardActionArea> */}
                        { columns[9] && columns[9].cell !== undefined && columns[9].cell(row) !== undefined ?
                            <CardContent sx={{ padding: 3, background: setColor(typeCol.cell(row)) }}>                      
                            </CardContent>  
                             :
                            ''
                        }
                        <CardContent>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item xs={8} md={10}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {columns[0].cell(row)}
                                    </Typography>
                                </Grid>
                                {/* <Grid item xs={4} md={2}>
                                    <Typography variant="" sx={{ textAlign:"right" }}>
                                        {columns[10].cell(row)}
                                    </Typography>
                                </Grid> */}
                            </Grid>
                            <Divider ></Divider>
                            <Box pt={6} pb={5}>
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="body1" >
                                        {columns[1].name + ": "}
                                    </Typography>
                                    <Typography variant="body1" >
                                        {columns[1].cell(row)}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    {/*  */}
                                    { primaryCol && primaryCol !== null && row.primary_name !== '' ?
                                        <>
                                            <Typography variant="body1">
                                            {/* &nbsp;&nbsp;&nbsp; */}{primaryCol.name+": "}
                                            </Typography>
                                            <Typography variant="body1">
                                                {primaryCol.cell(row)}
                                            </Typography>                                        
                                        </>
                                        :
                                        <>
                                            <Typography variant="body1" >
                                                {codecCol.name+": "}
                                            </Typography>
                                            <Typography variant="body1" >
                                                {codecCol.cell(row)}
                                            </Typography>                                        
                                        </>
                                    }
                                </Stack>
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="body1" >
                                        {streamCol.name + ": "}
                                    </Typography>
                                    <Typography variant="body1" >
                                        {streamCol.cell(row)}
                                    </Typography>
                                </Stack>                                
                            </Box>
                            <SubCard elevation={1}>
                                <Grid container textAlign="center">
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {expertsCol.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {techCol.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {expertsCol.cell(row)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {techCol.cell(row)}
                                        </Typography>
                                    </Grid>
                                </Grid>   
                                <Divider color='#384571' sx={{ }} style={{ margin: 10 }}></Divider> 
                                <Grid container textAlign="center">
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[2].name + ": " }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            { columns[2].cell(row)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[3].name +": "}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6}>
                                        <Typography variant="body1" >
                                            {columns[3].cell(row)}
                                        </Typography>
                                    </Grid>
                                </Grid> 
                                {/* <Stack direction="row" spacing={2} textAlign="center">
                                    <Typography variant="body2" component="div">
                                        {columns[2].name + ": " }
                                    </Typography>
                                    <Typography variant="body2" component="div">
                                        { columns[2].cell(row)}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={2} textAlign="center">
                                    <Typography variant="body2">
                                        {columns[3].name +": "}
                                    </Typography>
                                    <Typography variant="body2">
                                        {columns[3].cell(row)}
                                    </Typography>
                                </Stack> */}
                            </SubCard>
                        </CardContent>
                        {/* </CardActionArea> */}
                        { actionCol !== undefined && actionCol !== null ?
                            <Box pb={5} pt={5} sx={{textAlign: "right"}}>
                                <Grid pl={4} container>
                                    <Grid item xs={10} sm={10} md={10} lg={10}>

                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={2} >
                                        <Typography variant="" > 
                                            {actionCol.cell(row)}
                                        </Typography>
                                    </Grid>                                            
                                </Grid>
                            </Box>
                            :
                            ''
                        }
                    </Card>
                </Box>
            </Grid>
}

const PageLayout = (props) => {
    return <div>
        <Paper elevation={0} sx={{ background: '#1c2238' }}>
            <ThemeProvider theme={theme}>
                <Grid container spacing={1}>                    
                    { props.data.map((dat,index) => {
                        return <Item index={index} row={dat} columns={props.columns}></Item>
                        })
                    }
                </Grid>  
            </ThemeProvider>          
        </Paper>
    </div>
}

const NoDataComponent = (props) => {
    return <div>
        <Paper elevation={0} sx={{ background: '#384571', height: "80px" }}>
            <ThemeProvider theme={theme}>
                <Box 
                    display="flex" 
                    width={"100%"} height={"100%"} 
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="" sx={{ color:"white" }}>
                        {props.messageText}
                    </Typography>
                </Box>
            </ThemeProvider>
        </Paper>
    </div>
}

let pagination = [];
//let pagination = getPagination(props.data, 1);

const getPagination = (total_data, pageNo) => {
    const maxWidth = window.innerWidth;
    let cols, rows;
    for( let i=0; i<breakArr.length; i++) {
        if(maxWidth >= breakArr[i].breakpoint) {
            cols= breakArr[i].cols;
            rows = breakArr[i].rows;
            break;
        }
    }
    const data_perpage = cols * rows;
    const total_no_of_pages = Math.ceil(total_data.length/data_perpage) 
    let startingPoint = (data_perpage * pageNo) - data_perpage;
    let endPoint = startingPoint + data_perpage;
    let finalData = total_data.slice(startingPoint, endPoint);
    pagination.push({data: finalData, currentPage: pageNo, perPage: data_perpage, totalPages: total_no_of_pages});
    if (total_no_of_pages > 1 && pagination.length < total_no_of_pages) {
        for(let pp=pageNo+1; pp <= total_no_of_pages; pp++) {
            startingPoint = (data_perpage * pp) - data_perpage;
            endPoint = startingPoint + data_perpage;
            finalData = total_data.slice(startingPoint, endPoint);
            pagination.push({data: finalData, currentPage: pp, perPage: data_perpage, totalPages: total_no_of_pages});
        }
    }
    //console.log(pagination);
    return pagination;
}

class LayoutGroups extends React.Component  {  
    constructor(props) {
        super(props);
        pagination = [];
    }

    componentDidMount() {
        pagination = (this.props && this.props.data && this.props.data.length > 0) ? getPagination(this.props.data, 1) : [];        
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.data !== this.props.data && this.props.data !== undefined && this.props.data.length > 0) {
            pagination = [];
            pagination = getPagination(this.props.data, 1);
        }
        if(prevProps.isSorted !== this.props.isSorted && this.props.isSorted === true) {
            pagination = [];
            pagination = getPagination(this.props.data, 1);
        }
        /* if(prevProps.data !== undefined && prevProps.data.length > 0 && prevProps.data[0].account_id !== this.props.data[0].account_id){
            pagination = [];
            pagination = getPagination(this.props.data, 1);
        } */
    }

    render () {
        const {props} = this;
        pagination = [];
        pagination = (props && props.data && props.data.length > 0) ? getPagination(props.data, 1) : [];
        //console.log(pagination, props.columns);
        if (pagination.length > 0) {
            return <div>
                <Carousel autoPlay={false} cycleNavigation={false} animation="slide"
                    //IndicatorIcon={<FiberManualRecordIcon size='small' /* className={classes.indicatorIcon} *//>}
                    swipe={false}
                    NextIcon={<ArrowForwardIosIcon size='small' />}
                    PrevIcon={<ArrowBackIosIcon size='small' />}
                    indicatorIconButtonProps={{
                        style: {
                            padding: '3px',    // 1
                            color: '#384571'       // 3
                        }
                    }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            color: 'grey'
                        }
                    }}
                    indicatorContainerProps={{
                        style: {
                            marginTop: '40px', // 5
                            textAlign: 'center' // 4
                        }
                
                    }}
                >
                    { pagination.length > 0 && pagination[0].totalPages > 0 && pagination[0].totalPages > 1 ?
                        pagination.map((pp, ind, pagination) => {
                            return <PageLayout data={pp.data} currentPage={pp.currentPage} columns={props.columns}></PageLayout>                        
                            
                        })
                        :
                            <PageLayout data={props.data} currentPage={1} columns={props.columns}></PageLayout>
                    }
                </Carousel>
            </div>
        } else {
            return <NoDataComponent messageText={props.nodataMessage} />
        }
    }
}

export default LayoutGroups;