import React from 'react';
import moment from "moment";
import superagent from "superagent";
import DataTable from "react-data-table-component";
import {Button, Modal} from 'react-bootstrap';

const guestUsersColumn = [
    {name:"A/C Id",selector:"account_id", maxWidth:"120px"},
    {name:"Name",selector:"user_name",sortable:true, maxWidth:"70vw"},
    {name:"Email",selector:"user_email", sortable:true, maxWidth:"70vw"},
    /* {name:"Group Name",selector:"group_name", sortable:true, maxWidth:"70vw"}, */
    {name:"Joined On",selector:"joined_on", sortable:true, maxWidth:"70vw"},
    {name:"Expert/Technician",selector:"role", sortable:true, maxWidth:"70vw"},
    {name:"Status",selector:"status", sortable:true, maxWidth:"120px"},
    {name:"Action",selector:"action",maxWidth:"120px"},
  ]

const OrphanedCheckbox = (props) => {
    return( <div id="show-online-container">
      <label className="col-xs-3 non-pointer" htmlFor={props.id}>{props.label}&nbsp;</label>
      <label className="col-xs-4 col-xs-offset-2 switch">
        <input type="checkbox" id={props.id}
        name={props.id}
        onChange={props.toggleGroup}
        checked={props.value}/>
        <span className="slider round"></span>
      </label>
    </div>
    )
}

class AlertPopUp extends React.Component{
	constructor(props){
	  super(props);
	  this.state = {
		alertPopUp: false
	  }
	  this.close = this.close.bind(this);
	}
  
	close() {
	  this.setState({alertPopUp:false});
	  this.props.closeAlert();
	}
  
	componentDidMount(){
	  this.setState({alertPopUp:true});
	}
  
	render() {
	  return (
		  <Modal show={this.state.alertPopUp} onHide={this.close} backdrop="static" keyboard={false}>
			<Modal.Header>
			  <Modal.Title>{this.props.modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  {this.props.modalBody}
			</Modal.Body>
			<Modal.Footer>
			  <Button onClick={this.close} className="btn-green">OK</Button>
			</Modal.Footer>
		  </Modal>
	  );
	}
}

export default class GuestUsers extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            guestUserList: [],
            filteredGuestUserList: [],
            orphanedUserToggle:{
                value: false,
                label:"Include Live Session Guest Users",
                id:"groups-online-groups"
            },
            resetPaginationToggle:false,
            selectedPageNumber:1
            //filterGuestBasedOn: "orphaned"
        };
    }

    componentWillReceiveProps = ({tabIndex, allCustomers})=>{
        if(tabIndex === "4"){
          //this.searchlogs();
        }
    }

    componentDidMount = () => {
        const {allCustomers} = this.props.param;
        //console.log(allCustomers);
        let guestUserList = [];
        for (let i=0; i< allCustomers.length; i++) {
            const users = allCustomers[i].users;
            if (users === undefined || users.length === 0) continue;
            let guestUsers = users.filter(u => u.is_guest === true);
            if (guestUsers.length > 0) {
                guestUsers.forEach(user => {
                    const guestUser = {
                        account_id: allCustomers[i].account_id,
                        company_name: allCustomers[i].company_name,
                        user_id: user.id,
                        user_name: user.first_name+" "+user.last_name,
                        user_email: user.email,
                        joined_on: user.last_accessed_on,
                        role: (user.isexpert === true) ? "Expert" : "Technician",
                        status: user.is_insession ? "In live session" : "Orphaned", //(user.is_loggedin) ?
                        is_insession: user.is_insession,
                        is_loggedin: user.is_loggedin,
                        is_inactive: user.is_inactive,
                        action: user.is_insession ? false : true
                    }
                    guestUserList.push(guestUser);
                });
            }
        }
        this.setState({guestUserList}, () => {
            //console.log(this.state.guestUserList);
            this.getFilteredList();
        });
    }

    getFilteredList = () => {
        const {guestUserList, orphanedUserToggle} = this.state;
        let filteredGuestUserList = [];
        if(orphanedUserToggle.value === false){
            filteredGuestUserList = guestUserList.filter( user => user.status === "Orphaned" );
        } else {
            filteredGuestUserList = guestUserList;
        }
        this.setState({ filteredGuestUserList });
        //return newGuestList;
    }

    toggleGuestType = () => {
        this.setState(({ orphanedUserToggle : {
            ...this.state.orphanedUserToggle,
            value : !this.state.orphanedUserToggle.value
          }
        }),()=>this.getFilteredList());
    }

    handleClear = () => {
        const { resetPaginationToggle} = this.state;
        this.setState({  resetPaginationToggle: !resetPaginationToggle});
    }
      
    updatePageNumber(pageNum){
        this.setState({selectedPageNumber:pageNum})
    }

    deleteUser = async(row) => {
        if (null!==sessionStorage.getItem("userToken")
            && null!==row) {
              //TP-3064 -- Changed the API from `deleteUser/${row.user_id}` to the below
                const apiUrl = `/api/deleteGuestUser/${row.user_email}`;
                let msg = null;
                let headers = new Headers();
                headers.append("Authorization","Bearer " + sessionStorage.getItem("userToken"));
                //console.log(sessionStorage.getItem("url") + apiUrl); return;
                this.setState({isLoading:true});
                fetch(sessionStorage.getItem("url") + apiUrl, { method: "DELETE",headers: headers})
                .then(response => response) 
                .then(data => {
                  if(200===data.status){
                    // Socket signal sent to all users of this account
                    // to delete this user from their user list
                    // and to Log this user out 
                    const json = {
                      userIsDeleted: true,
                      email: row.email,
                      loginTimeStamp: sessionStorage.getItem("lastAccessedOn")
                    }
                    this.props.socket && this.props.socket.emit('log-me-out', json);
                    const users = this.state.filteredGuestUserList.filter(e => {
                        if(row.user_id) {
                            return e.user_id !== row.user_id;
                        } 
                    });
                    msg = "User deleted successfully";
                    this.setState({ filteredGuestUserList: users, serverResponse:msg, isLoading:false,success:true, modalTitle:"Alert" });
                  }
                }).catch(error => {
                  if(undefined===error.status){
                    msg = "May be internet is not connected, try again in sometime";
                  }else if(400===error.status){
                    msg = "Data not updated";
                  }else if(401===error.status){
                    msg = "Session token experied, please re-login";
                  }else if(403===error.status){
                    msg = "You are not authorized";
                  }else if(404===error.status){
                    msg = "Not able to request server";
                  }else if(422===error.status){
                    msg = "Invalid input value";
                  }else if(500===error.status){
                    msg = "Something went wrong, try again sometime";
                  }
                  this.setState({ serverResponse:msg, isLoading:false, success:false });
                });
        }
    }

    closeAlert = () => {
        this.setState({ serverResponse: null })
    }

    render() {
        const {filteredGuestUserList} = this.state;
        //filteredGuestUserList.forEach(row =>)
        if(sessionStorage.getItem("userDbRole") === "admin" ){
            if(guestUsersColumn[6]){
                guestUsersColumn[6].cell=row=>row.action !=null && row.action && <a title={row.isInactive ? "This account is inactive" :""} className={"align-Lock-Icon"} onClick={() => this.deleteUser(row)} >
                {row.action ? <i className="fa fa-trash fa-lg" style={{color: "#cd4d4d" }} />: ''}</a>
            }else{
                guestUsersColumn.push({name:"Action",selector:"action",sortable:true,width:"120px"})
                guestUsersColumn[6].cell=row=>row.action !=null && <a title={row.isInactive ? "This account is inactive" :""} className={row.email === sessionStorage.getItem("userEmail") ? "disabled-lock-icon":"align-Lock-Icon"} onClick={ row.active ? () => this.deleteUser(row): ""} >
              <i className={row.action ? "fa fa-lock fa-lg": "fa fa-unlock fa-lg"} style={{color: row.email === sessionStorage.getItem("userEmail") ? "#4B4B4B" : row.pwd_lock ? "#ff0a09" :"#2ac878"}} /></a>;
            
      
            }
        }
        return (
            <div className="col-sm-8" style={{marginLeft: "20%"}}>
                <OrphanedCheckbox {...this.state.orphanedUserToggle} toggleGroup={this.toggleGuestType}/>
                <DataTable
                pagination={filteredGuestUserList.length>3}
                paginationPerPage={3}
                onChangePage={page=>this.updatePageNumber(page)}
                paginationDefaultPage={this.state.selectedPageNumber}
                columns={guestUsersColumn}
                data={filteredGuestUserList}
                className="group-participants"
                noDataComponent="No Guest Users are there"
                progressPending={this.state.isLoading}
                progressComponent={<div><i className="fa fa-spinner fa-spin" style={{fontSize:"60px",color:"white"}}/></div>}
                persistTableHead
                paginationResetDefaultPage={this.state.resetPaginationToggle}
                onClear={this.handleClear}
                />
                {this.state.serverResponse && this.state.serverResponse!==null && <AlertPopUp modalTitle={this.state.modalTitle} modalBody={this.state.serverResponse} closeAlert={this.closeAlert} />}
            </div>
        )
    }
}