import React,{useEffect,useRef} from 'react';
import drawBarChart from './drawBarGraphChart';

const BarGraphChart = ({data}) =>{
    const ref = useRef(null);
    useEffect(()=>{
        if(ref.current){
            drawBarChart(ref.current,data)
        }
    })
    return(
        <div id='barGraphChart' className='bar-graph-chart'>
            <svg id='barGraphChartBox' className='bar-graph-chart-box' ref={ref} ></svg>
        </div>
    )
}

export default BarGraphChart;