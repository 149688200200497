import React from 'react';

const ADMIN_USER_ROLE_TYPE = {
    /*TP-4573 read_pro : "Limited Pro/Basic Account Access", */
    read_ent : "Limited Enterprise Account Access",
    read_all : "Limited Customers Account Access",
    read_my_account:"My Read Account Access",
    /*TP-4573 write_pro : "Pro/Basic Account Access", */
    write_ent : "Enterprise Account Access",
    write_all : "All Customers Account Access",
    write_my_account:"My Account Admin Access",
    admin : "Admin Access",
    group_admin : "Group Admin",
    none : "No"
};

const USER_ROLE_TYPE = {
    /*TP-4573 read_pro : "Limited Pro/Basic Account Access", */
    read_ent : "Limited Enterprise Account Access",
    read_all : "Limited Customers Account Access",
    /*TP-4573 write_pro : "Pro/Basic Account Access", */
    write_ent : "Enterprise Account Access",
    write_all : "All Customers Account Access",
    admin : "Admin Access",
    group_admin : "Group Admin",
    none : "No"
}; 


const List = (props) =>{
    const {selectedVal,userInfo} = props;
    const userRoleType = userInfo.isadmin ? ADMIN_USER_ROLE_TYPE : USER_ROLE_TYPE;
    const options = Object.keys(userRoleType).map(elem => ( <option value={elem} key={elem} className="user-role" >{userRoleType[elem]}</option>));
    return(
        <select
            id="select-user-role-type" 
            value={selectedVal}
            onChange={()=>props.confirmationPopup()}>
            {options}
        </select>
    ) 
}


export default List;