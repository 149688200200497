import React ,{ useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './lineStyle.css';
import moment from 'moment';

const colors = ["red","blue","green","yellow"];
const LineChartGraph = ({data}) => {

	const d3Chart = useRef();

	useEffect(()=>{

				// // Transform data
			    // const permits = data.filter(event => { return event.diffMins !==null}) 

        
				const d3SvgElement = document.getElementById('d3ChartField').getElementsByTagName("g");
				if(d3SvgElement.length > 1){
					for (let index = d3SvgElement.length - 1; index >= 0; index--) {
						d3SvgElement[index].parentNode.removeChild(d3SvgElement[index]);
					}
					document.querySelectorAll(".tooltip").forEach(el => el.remove());
				}

				const margin = {top: 60, right: 40, bottom: 40, left: 80}//{top: 30, right: 30, bottom: 30, left: 30}
				const width = parseInt(d3.select('#d3ChartField').style('width')) - margin.left - margin.right
				const height = parseInt(d3.select('#d3ChartField').style('height')) - margin.top - margin.bottom 

				// Set up chart
				const svg = d3.select(d3Chart.current)
								.attr('width', width + margin.left + margin.right)
								.attr('height', height + margin.top + margin.bottom)
								.append('g').attr('transform', 'translate('+ margin.left + ',' + margin.top + ')')		

				// x axis scale 
				const getX = d3.scaleTime()
							.domain(d3.extent(data, function(d){return d.date}))
							.range([0,width])

				svg.append('g')
					.attr('transform', 'translate(0,' + height + ')')
					.attr('stroke','black')
					.call(d3.axisBottom(getX))

				// Get the max value of counts
				const yMaxValue = d3.max(data, function(d){return d.count})
				const yMinValue =  d3.min(data,function(d){return d.count} )
				// y axis scale 

				const getY = d3.scaleLinear()
							.domain([0, yMaxValue + 100])
							.range([height, 0]);			

				
				svg.append("g").attr("stroke", "black").call(d3.axisLeft(getY)).append("text")
					.attr("fill", "#000")
					.attr("transform", "rotate(-90)")
				   	.attr("y", 6)
					.attr("dy", "0.71em")
					.attr("text-anchor", "end")


				// Draw line
				svg.append('path')
					.datum(data)
					.attr('fill', 'none')
					 .attr('stroke','#7DCEA0')
					//.attr('stroke', function(d) { return colors[Math.floor(Math.random() * colors.length)]})
					.attr('stroke-width', 3)
					.attr('d', d3.line().x(function(d){return getX(d.date)}).y(function(d){return getY(d.count)}))	

				//Add title for y axis
				svg.append('text')
					.attr('x',(width/2))
					//.attr('y', (margin.top/5 - 10))
					.attr('y',0-margin.top / 2)
					.attr('text-anchor', 'middle')
					.attr('font-size', '20px')
					.attr('fill','black')
					.attr('stroke','black')
					.text('USAGE DETAILS ')

				
					svg.append('text')
					.attr("stroke", "black")
					.attr("fill", "#0000")
					// .attr('class', 'label')
					.attr('x', -(height / 2.5))
					.attr('y', -65)
					.attr('transform', 'rotate(-90)')
					.attr('text-anchor', 'middle')
					.text("Duration (mins)");

				// Define the div for the tooltip
				const div = d3.select("body").append("div")	
				.attr("class", "tooltip")				
				.style("opacity", 0);	
				
				 // Add the Circle
				svg.selectAll("myCircles")
					.data(data)
					.enter()
					.append("circle")
				    // .attr("fill", function(d) { return colors[Math.floor(Math.random() * colors.length)]})
				    // .attr("stroke", function(d) { return colors[Math.floor(Math.random() * colors.length)]})
					.attr("fill", "#D35400")
					.attr("stroke", "none")
				    .attr("cx", function(d) { return getX(d.date)})
				    .attr("cy", function(d) { return getY(d.count)})
				    .attr("r", 5)
					.on("mouseover", function(d,i) {		
						div.transition()		
							.duration(200)		
							.style("opacity", .9);		
						div.html(moment(i.date).format("DD/MM/YYYY") + "<br/>  Min: "  + i.count)		
							.style("left", d.x + "px")		
							.style("top", d.y + "px");	
						})					
					.on("mouseout", function(d) { div.transition().duration(500).style("opacity", 0); });
					

			
	},[data])

	return (
		<div id='d3ChartField'>
			<svg ref={d3Chart}></svg>
		</div>
	)
}

export default LineChartGraph;