import * as d3 from 'd3';
import moment from 'moment';

const parseDate = d3.timeParse('%Y-%m-%d');

export const getLineGraphData = (dataArray) =>{
    const sortedArray = dataArray.sort((a,b)=> new Date(a.start_time) - new Date(b.start_time));
    const datesArray = [... new Set(sortedArray.map(each => each.start_time.slice(0,10)))]
    const finalCountArray = [];
    datesArray.map((time)=>{
        let date = time;
        let diffMinCount = 0;

        dataArray.map(eachData=>{
            let timestamp = eachData.start_time.slice(0,10);
            if(timestamp === date){
                diffMinCount+= eachData.diffMins;
            }})

            let counts = {date:parseDate(date),count:diffMinCount}
            finalCountArray.push(counts)
        })

    return finalCountArray;
}

export const getBarGraphData = (array) =>{
    const dataArray = array.filter(li=> li.first_name && li.last_name);
    const myMapObj = {};
    const returnArray = [];
    for(let dataObj of dataArray){
        let userName = dataObj.first_name//dataObj.first_name+' '+dataObj.last_name;
        if(myMapObj[userName]){
            myMapObj[userName] += dataObj.diffMins;
        }else{
            myMapObj[userName] = dataObj.diffMins;
        }
    }
    Object.entries(myMapObj).forEach((key)=>{
        let entryObj = {name:key[0],value:key[1]}
        returnArray.push(entryObj);
    })

    return returnArray;
    
}


export const getMinMaxDates = (arrayData)=>{
    const sortedArray = arrayData.sort((a,b)=> new Date(a.start_time) - new Date(b.start_time));
    return {
        min:sortedArray[0].start_time,
        max:sortedArray[arrayData.length -1].start_time
    }
}

export const getMinutesDifference = param =>{
    let startTime = new Date(param.start_time);
    let stopTime = new Date(param.stop_time);
    let diffMs = (stopTime - startTime);
    return Math.round((diffMs/1000)/60); 
}

export const getMinutesByMonth = (dataArr) =>{
    const monthMinCountArr = [];
    const minutesByMonth = dataArr.reduce((accumlator,current)=>{
        let month =  moment(current.start_time).format('MM/YYYY')
        if(accumlator[month]){
            accumlator[month] += current.diffMins;
        }else{
            accumlator[month] = current.diffMins;
        }
        return accumlator;
    },{})
   // return minutesByMonth;
   Object.entries(minutesByMonth).forEach((key)=>{
        let entryObj = {name:key[0],value:key[1]}
        monthMinCountArr.push(entryObj);
   });
   monthMinCountArr.sort((a,b)=>{
    return new Date(a.name) -  new Date(b.name)
   })

   return monthMinCountArr;
}


export const getGroupsNameCount = (array)=>{
    const outputArray = [];
    const groupNameCount =  array.reduce((accumlator,current)=>{
        let groupName = current.group_name, diffMinsVal = current.diffMins;
        if(accumlator[groupName]){
            accumlator[groupName] += diffMinsVal; 
        }else{
            accumlator[groupName] = diffMinsVal; 
        }
        return accumlator;
    },{})
    
    const totalGroupCount =  Object.values(groupNameCount).reduce((accum,curr)=>{ return accum = accum+curr},0);

    for(let[key,gainVal] of Object.entries(groupNameCount)){

        let grpObj = {};
         grpObj.label = key;
         grpObj.count = gainVal;
         grpObj.value = (gainVal * 100 / totalGroupCount).toFixed(2);
         outputArray.push(grpObj)


    }

    return outputArray;

}