export const trimText = (value, limit) => {
    try {
        if(value.length > limit) {
            return value.slice(0, limit) + "...";
        } else 
            return value;        
    } catch (error) {
        console.log('trimText error', error)
        return ''
    }
}