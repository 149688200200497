import React,{Fragment,Component}  from 'react';
import { Label } from 'reactstrap';
import Select from 'react-select';
import './editGroup.css';
import { connect } from 'react-redux';
import superagent from "superagent";
import {updateUserProfile} from '../redux/Customer/CustomerActions';
import LoadThreeDots from '../Utils/LoadThreeDots';

class EditGroupForm extends Component{
   constructor(props){
      super(props);
       this.state = {
        groups:[],
        allCustomers:[],
        currentUserGroupInfo:[],
        currentUserInfo:null,
        userGroupIdMapValue:[],
        isLoading:false,
        serverError:null,
        accountGroupsOptions:[],
        selectedCustomer:[],
        selectedPickedOptions:[],
        allGroupsByAccountId:[]
       };
   }


 componentDidMount(){
    this.getUserGroupMapDetails().then(this.updateStateParameter());
}

updateStateParameter(){
   const {currentUserGroupInfo,currentUserInfo,customers,groups} =  this.props;
   //Added filter to filter out only enterprise account 
   let customersAll = customers.allCustomers.filter(cust=> cust.is_paid_offline);
   customersAll = customersAll.map((cust) =>{ return  {value : cust.account_id,label : cust.company_name,...cust}})
   customersAll =  customersAll.sort(this.sortAlphabeticalOrder);

   let allgroups = groups.groups;
   allgroups = allgroups.map((grp)=>{ return {value:grp.group_id,label:grp.group_name,...grp}})

   const groupsByAccountId = allgroups.reduce((prev,accum)=>{
       prev[accum.account_id] = prev[accum.account_id] || [];
       prev[accum.account_id].push(accum)
       return prev;
   },Object.create(null))

   const acountByCurrentUser = customersAll.filter((cust)=> cust.account_id === currentUserInfo.account_id);
//    const groupsByCurrentUser = [];
   const groupsByCurrentUser = groupsByAccountId ? groupsByAccountId[acountByCurrentUser[0].account_id]:''; 

//    acountByCurrentUser.forEach(ele=>{
//         if(groupsByAccountId[ele.account_id])
//         groupsByCurrentUser.push(groupsByAccountId[ele.account_id])
//    })

   this.setState({currentUserGroupInfo,currentUserInfo});
   this.setState({groups:allgroups,allCustomers:customersAll,allGroupsByAccountId:groupsByAccountId,
                   selectedCustomer:acountByCurrentUser,accountGroupsOptions:groupsByCurrentUser});
}

handleToggleOption = (selectedOptions,selectedObject) => {
    this.setState({selectedPickedOptions: selectedOptions});
}


  handleSelect = (selectedOption)=>{
    this.setState({
        selectedCustomer:selectedOption
    },()=> this.updateGroupOptions())
  }
  

  updateGroupOptions = () => {
    const {selectedCustomer,allGroupsByAccountId} = this.state;
    const selectedGroupArray = [] , selectedGroupOption = [];
    selectedCustomer.forEach((cust)=>{
        if(cust.account_id && allGroupsByAccountId[cust.account_id]){
            allGroupsByAccountId[cust.account_id].forEach((elm)=>
                selectedGroupArray.push(elm)
        )}
    })
    this.setState({accountGroupsOptions:selectedGroupArray});
    //this.setState(prev=>({accountGroupsOptions:prev.allGroupsByAccountId[account_id]}))
  }

  updateGroupIdForGroupAdmin = async (e) =>{
    e.preventDefault();
    const {selectedPickedOptions,currentUserInfo,userGroupIdMapValue} = this.state;
        
    if(selectedPickedOptions.length === 0){
        window.flash('Group Admin should have atleast 1 group for saving','error')  
        return;
    }

    let addGroupIds = [] , removedGroupId = [] ,apiUrl =  "/api/dash/setUserConfiguration", params = {}, msg = null; 
    
        addGroupIds =  this.findNewSelectedItem(selectedPickedOptions,userGroupIdMapValue);
        removedGroupId = this.findDeSelectedItem(selectedPickedOptions,userGroupIdMapValue);
        params.user_id = currentUserInfo.id;
        params.action_type = "group_admin";
        params.add_group_ids =  JSON.stringify(addGroupIds);
        params.remove_group_ids = JSON.stringify(removedGroupId);
    
    if (null!==sessionStorage.getItem("userToken")
        && null!==params && null!==apiUrl) {
        this.setState({isLoading:true});
        await superagent.post(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .send(params)
        .then(response => response)
        .then(data => {
        if(200===data.status){
            msg = "Data updated successfully";
            this.setState({isLoading: false});
            window.flash(msg, 'success');
            this.getUserGroupMapDetails();
            this.props.updateUserProfile(currentUserInfo,params)
        }
        }).catch(error => {
        
        if(undefined===error.status){
            msg = "May be internet is not connected, try again sometime";
        }else if(400===error.status){
            msg = "Data not updated";
        }else if(401===error.status){
            msg = "Session token experied, please re-login";
        }else if(403===error.status){
            msg = "You are not authorized";
        }else if(500===error.status){
            msg = "Something went wrong, try again sometime";
        }
            window.flash(msg, 'error');
        });
    }

}

findDeSelectedItem(currentArray, previousArray) {

 let  resultArray = previousArray.filter((o1)=>{
        return !currentArray.some(function(o2){
            return o1 === o2.group_id;   
        });
    })
    
    return resultArray;
 }

 findNewSelectedItem(currentArray, previousArray){
    return currentArray.filter(entry1 => !previousArray.some(entry2 => entry1.group_id === entry2)).map((item) => item.group_id)
 }



getUserGroupMapDetails = async () => {

    const {currentUserInfo} =  this.props;
    let apiUrl =  "/api/dash/getUserDetails", params = {}, msg = null; 
        params = { user_id:currentUserInfo.id, user_detail_type:"group_admin"}

    if(sessionStorage.getItem("userToken") && apiUrl){
        this.setState({isLoading:true});
        await superagent.post(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .send(params)
        .then(response => response)
        .then(data => {
        if(200===data.status && data.body){
            let response = data.body;
            response = response.map(user=>user.group_id)
            this.setState({isLoading: false,userGroupIdMapValue:response},()=>this.setValueFromAllGroups());
        }
        }).catch(error => {
        
            if(undefined===error.status){
                msg = "Internet is not connected, please try again after connecting network";
            }else if(400===error.status){
                msg = "condition not matching so User Deatils not found";
            }else if(401===error.status){
                msg = "Session token experied, Please re-login";
            }else if(403===error.status){
                msg = "You are not authorized";
            }else if(422===error.status){
                msg = "Invalid parameters sent";
            }else if(500===error.status){
                msg = "Something went wrong, try again later";
            }else{
                msg = error.message ? error.message : 'Something went wrong'
            }

            window.flash(msg, 'error');
        });
    }
}

setValueFromAllGroups(){
    const {userGroupIdMapValue,groups,allCustomers,selectedCustomer} = this.state;
    const selectedOptions = [] , selectedCustomerByGroups = [] ;
    userGroupIdMapValue.forEach((element) => {
        for(let indx =0; indx < groups.length;indx++){
            if(groups[indx].group_id === element)
            selectedOptions.push(groups[indx]);  
        }
    }); 
    const setMappedWithGrp = selectedOptions.map(sel=>sel.account_id);
    const selectedMappedCustomer = [...new Set(setMappedWithGrp)]; 
    //selectedPickedOptions:selectedMappedCustomer  
    selectedMappedCustomer.forEach((ele)=>{
        for(let jndx = 0; jndx < allCustomers.length; jndx++){
            if(ele === allCustomers[jndx].account_id){
                selectedCustomerByGroups.push(allCustomers[jndx]);
            }
        }
    })

    if(selectedMappedCustomer.length === 0 && selectedCustomerByGroups.length === 0){
        if(Array.isArray(selectedCustomer))
            selectedCustomerByGroups.push(selectedCustomer.pop())
        else{
            selectedCustomerByGroups.push(selectedCustomer);
        }
       
    } 
    
  this.setState({selectedCustomer:selectedCustomerByGroups,selectedPickedOptions:selectedOptions})

}


sortAlphabeticalOrder(x,y){
    if(x.company_name.toLowerCase() < y.company_name.toLowerCase()) {return -1;}
    if(x.company_name.toLowerCase() > y.company_name.toLowerCase()) {return 1;}
    return 0;
}

render(){
    return(
    <div className='edit-container' id="form-wrapper">
        <div className="formheader">
                <h2>Add Group</h2>								
            </div>
        <form className='form-container' onSubmit={this.handleSubmit} >
           
          <div className="form-group">
          <Label for="accountName">Account:</Label>
              <Select
                    isLoading={this.state.isLoading}
                    id="selectCustomerDropDown"
                    value={this.state.selectedCustomer}
                    className="globalCustomerHuntCls"
                    onChange={this.handleSelect}
                    options={this.state.allCustomers}
                    isMulti="true"
                />
          </div>
          
          <div className="form-group">
          <Label for="groupName">Groups:</Label>
              <div  style={{marginTop: 5, color: "black"}}>
                    <Select
                        isLoading={this.state.isLoading}
                        id="globalGroupsHuntCls"
                        value={this.state.selectedPickedOptions}
                        onCloseResetsInput={this.state.selectedPickedOptions.length > 1 ? true : false}
                        onChange={this.handleToggleOption}
                        options={this.state.accountGroupsOptions}
                        isMulti="true"
                    />
                </div>
          </div>
          <div className="flex-center">
            <button id='btnClose' className='btn-close-red'  onClick={(e) =>this.props.CloseEditGroupFunc(false)}>Close</button>
            <button id='btnSave' className='btn-save-green' onClick={(e) =>this.updateGroupIdForGroupAdmin(e)}>Save</button>
        </div>
        </form>
        {this.state.isLoading && 
            <LoadThreeDots isLoading={this.state.isLoading} ></LoadThreeDots>
        }
    </div>
    )}
}

const mapStateToProps = state => {
    const {customers,groups} = state;
    return {
      customers,
      groups
    }
  };

const mapDispatchToProps = (dispatch) => ({
    updateUserProfile:(accountParam,userParam) => dispatch(updateUserProfile(accountParam,userParam))
  });

export default connect(mapStateToProps,mapDispatchToProps)(EditGroupForm); 