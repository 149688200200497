import React from 'react';
import DataTable from "react-data-table-component";
import moment from "moment";
import "react-dropdown-tree-select/dist/styles.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import './connection.css'
import RegisterDevice from './registeredDevice';
//TP-738
// import GroupsDiagnostic from './groupsDiagnostic';
// import UsersDiagnostic from './usersDiagnostic';
import LogsDiagnostic from '../users_tab/diagnostic';
import GuestUsersList from '../users_tab/guestUsers';

const uluColumns = [
  {name:"User Email",selector:"email",sortable:true,maxWidth:"40vw"},
  {name:"Socket Ids",selector:"socketId",sortable:false,maxWidth:"40vw"},
  {name:"Socket Count",selector:"socketcountId",sortable:true,maxWidth:"40vw"},
  {name:"Socket Created Time",selector:"created_on",sortable:true,maxWidth:"40vw"},
]


export default class SocketDiagnosticDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableList:[],
      tableHeader:"Socket Connection",
      isLoading:false,
      index:0
    }
  }

  componentDidMount() {

    const {socket} = this.props;
   

    this.callSocketEmit(socket);    
    this.listenSocketConnection(socket);

  }

  componentWillUnmount() {
  
 }

  callSocketEmit = (socket) =>{ socket.emit('get-connected-sockets')}

  listenSocketConnection = (socket) =>{ socket.on('get-connected-sockets', this.showCallResponse)} 

  handleSelect(event) {
    event.preventDefault();
    let key = event.target ? event.target.getAttribute("selectedindex") : "";
    if (key){
        this.setState({ tabIndex:key,date: new Date()});
    }
}

  showCallResponse = ({userlist}) => {
  
    if(userlist.length>0){
      var returnUserList = []; 

      userlist.forEach((item, index) => {
        if(item){
          let {created_on,email,socketId} = item;
         
          let userObj = {};
          userObj['divId'] = index +1;
          userObj['created_on'] = created_on;
          userObj['email'] = email;
          userObj['socketId'] = socketId;
          userObj['socketcountId'] = 1;
          userObj['socketColorCode'] = "default";

          let elementPos = returnUserList.map(function(x) {return x.email; }).indexOf(email);
          let foundObj = returnUserList[elementPos] ? returnUserList[elementPos] : undefined;
              
          if(foundObj){
            foundObj['socketcountId'] += 1;
            foundObj['socketId'] = socketId;
            foundObj['socketId'] = foundObj['socketId']+','+userObj['socketId'];
            foundObj['socketColorCode'] = "red";
            returnUserList[elementPos] = foundObj;
          }else{
            returnUserList.push(userObj);
          }
        }  
      
      });

      this.setState({tableList:returnUserList});
      this.setState({isLoading: false});
    }

  }


  refreshScoketData = (socket) => {
    
    this.setState({isLoading: true});
    this.callSocketEmit(socket);
  }

  customSort = (rows, field, direction) =>{
    if(!field){ 
      field="socketcountId"; 
      direction="desc"
    }
    let infoPicked = rows.filter(row=>null!==row[field]);
    let infoNullPicked = rows.filter(row=>null===row[field]);
    let mul=-1;
    if("asc"===direction){
      mul=1;
    }else if("desc"===direction){
      mul=-1;
    }

      if("created_on"===field){
        infoPicked.sort((c1,c2)=>{
          if(moment(c1[field]).isAfter(moment(c2[field]))){
            return -1*mul;
          }else if(moment(c1[field]).isBefore(moment(c2[field]))){
            return 1*mul;
          }else return 0;
        });
      }else if("socketcountId"===field){
        infoPicked.sort((a, b)=>{
        if(a[field] < b[field]) return -1*mul;
        else if(a[field] > b[field]) return 1*mul;
        return 0;
        })
      } else if("email"===field || "socketcountId" === field){
        infoPicked.sort((a, b)=>{
            if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
            else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
            return 0;
           })
        }

    return [...infoPicked, ...infoNullPicked];
  }


  render() {
   
    uluColumns[0].cell=row=>undefined!==row.email ? <div className={row.socketColorCode==="red" ?"text-ellipses red ":"text-ellipses"}> {row.email}</div>:<div className="text-ellipses"></div>
    uluColumns[1].cell=row=>undefined!==row.socketId ? <div className={row.socketColorCode==="red" ?"text-ellipses red ":"text-ellipses"}> {row.socketId}</div>:<div className="text-ellipses"></div>
    uluColumns[2].cell=row=>undefined!==row.socketcountId ? <div className={row.socketColorCode==="red" ?"text-ellipses red ":"text-ellipses"}> {row.socketcountId}</div>:<div className="text-ellipses"></div>
    uluColumns[3].cell=row=>undefined!==row.created_on ? <div className={row.socketColorCode==="red" ?"text-ellipses red ":"text-ellipses"}> {moment(row.created_on).format("DD MMM,YYYY HH:mm")}</div>:<div className="text-ellipses"></div>
   
   
    return (
      <div className="page">
        <div className="media">
          <h3>Please rotate your device to landscape mode</h3>
        </div>
        <div className="">
        <Tabs>
            <TabList id="connection-row" className="connection-tabs-row">
              <Tab selectedindex={1} onClick={(event)=> this.handleSelect(event)}>Socket</Tab>
              <Tab selectedindex={2} onClick={(event)=>this.handleSelect(event)}>iOS Tokens</Tab>
             { /*TP-738
              <Tab selectedindex={3} onClick={(event)=>this.handleSelect(event)}>Groups</Tab> */}
              <Tab selectedindex={3} onClick={(event)=> this.handleSelect(event)}>Logs</Tab> 
              <Tab selectedindex={4} onClick={(event)=> this.handleSelect(event)}>Guest Users</Tab> 
            </TabList>
            <TabPanel> 
          <div className="container-fluid media-show">             
            <div className="row">
              <div className="col-xl-3 align-self-center">
                <i title="Click here to get socket connection" className={this.state.isLoading?"socketIconCls fa fa-refresh fa-spin":"socketIconCls fa fa-refresh"} onClick={(e)=>this.refreshScoketData(this.props.socket)}></i>
              </div>
              <div className="col-12 align-self-start">
                <DataTable
                  pagination={this.state.tableList.length > 10}
                  paginationPerPage={this.props.pagination}
                  columns={uluColumns}
                  keyField={"email"}
                  data={this.state.tableList}
                  className="table-responsive table-container socket-info"
                  subHeader={this.state.tableList.length>0}
                  progressPending={this.state.isLoading}
                  sortFunction={(rows, field, direction)=>this.customSort(rows, field, direction)}
                />
              </div>
            </div>  
          </div> 
        </TabPanel>
          <TabPanel>
            <RegisterDevice />
          </TabPanel>
          {/* TP-738 <TabPanel>
            <GroupsDiagnostic param={this.props} />
          </TabPanel> */}
          {/*TP-809*/}
          <TabPanel>
            <LogsDiagnostic param={this.props} requestType="email" />
          </TabPanel>
          <TabPanel>
            <GuestUsersList param={this.props} />
          </TabPanel>
        </Tabs>
        </div>

      </div>
    );
  }
}

