import React from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import superagent from 'superagent';
import LoadThreeDots from '../Utils/LoadThreeDots';
import DataTable from "react-data-table-component";
import {getMinutesDifference,getLineGraphData,getMinutesByMonth,getGroupsNameCount} from '../D3Chart/visualizations/chartHelper';
import { GrLineChart } from 'react-icons/gr';
import { BsTable } from 'react-icons/bs';
import {FaFileCsv} from 'react-icons/fa';
import LineGraph from '../D3Chart/visualizations/LineGraph';
import BarGraphChart from '../D3Chart/visualizations/BarGraphChart';
import DonutChart from '../D3Chart/visualizations/DonutChart';
import './usageStyle.css';

const usageColumns = [
    {name:"#", selector:"index", maxWidth:"100px"},//TP-2410
    {name:"Group ",selector:"group_name",sortable:true, maxWidth: "700px"},
    {name:"Start Time",selector:"start_time",sortable:true, width: "300px"},
    {name:"End Time",selector:"stop_time",sortable:true, width: "300px"},
    {name:"Duration (mins)",selector:"duration",sortable:true}
  ];

const td = new Date();
const backOneMonthDate = new Date(td.getFullYear(), td.getMonth(), 1);//TP-2410

const typeOfChart = [
    {value:'line-type',text:'Line'},
    {value:'bar-type',text:'Bar'},
    {value:'donut-type',text:'Donut'}
]

class UsageDetails extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            userId:this.props.userData,
            isLoading:false,
            showUsageTab:true,
            startDate:backOneMonthDate,//Tp-2410
            endDate:new Date(),
            selectedChartType:'Line',
            usageRecords:[],
            lineGraphStateData:[],
            barGraphStateData:[],
            donutChartStateData:[],
            isNoDataFound:false,
            pageNumber:1,
        }
    }

    componentDidMount(){
        this.getUserDetails();
    }

    async getUserDetails(){
        const apiUrl = '/api/dash/getUserDetails';
        const {userId,startDate,endDate} = this.state;
        let param = {  
            user_id:userId.id,
            user_detail_type:'usage',
            from_date: moment(startDate).format('YYYY-MM-DD'), //'2022-02-01',
            to_date: moment(endDate).format('YYYY-MM-DD')//'2022-02-21'
        }

        if(sessionStorage.getItem('userToken')){
            this.setState({isLoading:true});
            await superagent.post(sessionStorage.getItem("url") + apiUrl)
            .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
            .accept("application/json")
            .send(param)
            .then(response=> response)
            .then(data=>{
                if(data.body && data.status === 200){
                  let records =  data.body , lineGraphData , barGraphData , donutChartData;
                  const updatedRecords = records.map((obj, i)=>({...obj,diffMins:getMinutesDifference(obj), index: ++i}));//TP-2410
                  lineGraphData = getLineGraphData(records);
                  barGraphData = getMinutesByMonth(records);
                  donutChartData = getGroupsNameCount(records);
                  this.setState({   usageRecords:updatedRecords,
                                    lineGraphStateData:lineGraphData,
                                    barGraphStateData:barGraphData,
                                    donutChartStateData:donutChartData,
                                    isLoading:false,
                                    isNoDataFound:records.length > 0 ? false:true,
                                    pageNumber:1
                                });
                }
            }).catch((error)=>{
                 
              let msg = null;
              this.setState({isLoading:false});

              if(error.status === undefined){
                msg = "May be internet is not connected, try again sometime";
              }else if(error.status === 400){
                  msg= 'Data not updated';
              }else if(error.status === 401){
                  msg = 'Session token expired,Please re-login';
              }else if(error.status === 403){
                  msg = 'You are not authorized';
              }else if (error.status === 422){
                  msg = 'Invalid parameter';
              }else if(error.status === 500){
                  msg = 'Something went wrong, try again later'
              }  
              window.flash(msg,'error');
              
            })
        }
    }

    handleSelectChange = (event) =>{
        this.setState({selectedChartType:event.target.value});
    }

    handleStartDateChange = (date)=>{
        this.setState({startDate:date},()=>this.getUserDetails());
    }

    handleEndDateChange = (date)=>{
        this.setState({endDate:date},()=> this.getUserDetails());
    }

    //TP-2410
    parseMilliseconds = (startTime,endTime,duration) => {

        if (startTime !== '' && endTime !== ''){
            let minutes, seconds;
            if(duration !== ''){
                seconds = Math.floor(duration/1000);
                minutes = Math.floor(seconds/60);
                // hours = Math.floor(minutes/60);
                
                // minutes = minutes-(hours*60);
                // seconds = seconds-(hours*60*60)-(minutes*60);
                // if(seconds > 0){
                //     minutes = minutes + 1;
                // }
            }else{
                let st = moment(startTime);
                let et = moment(endTime);
                st = this.roundOffSessionTime(st,true);
                et = this.roundOffSessionTime(et,false);
                let timeDiff = moment.duration(et.diff(st));
                // hours = parseInt(timeDiff.asHours());
                // minutes = parseInt(timeDiff.asMinutes())%60;
                minutes = parseInt(timeDiff.asMinutes());
            }
            return (minutes);
        }else{
            return '--';
        }

    }
    //TP-2410
    roundOffSessionTime = (d,isStartTime,duration) => {
        if(isStartTime){
                d.set({second:0,millisecond:0});
        }else{
            if(d.seconds() > 0){
                if(duration === ''){
                    d.add('minutes', 1);
                }
                d.set({second:0,millisecond:0});
            }
        }
        return d;
    }

    getDateTimeString = (_date,isStartTime,duration, seperator) => {
        let date = _date ? moment(_date) : '';
        if (date !== ''){
            date = this.roundOffSessionTime(date,isStartTime,duration);
            return date.format("DD MMMM YYYY"+seperator+" HH:mm");
        }else{
            return ''
        }
    }

    roundOffSessionTime = (d,isStartTime,duration) => {
        if(isStartTime){
                d.set({second:0,millisecond:0});
        }else{
            if(d.seconds() > 0){
                if(duration === ''){
                    d.add('minutes', 1);
                }
                d.set({second:0,millisecond:0});
            }
        }
        return d;
    }

    handleToggle = () =>{
        this.setState({showUsageTab:!this.state.showUsageTab})
    }

    downloadUsageInCSV = async () => {
       
        const headers = {
            group_name: 'Group',
            first_name: "User First Name",
            last_name: "User Last Name",
            start_time: "Start Time", // (UTC)
            stop_time: "End Time", // (UTC)
            duration: "Duration (mins)",
            credit_id: "License No."
        };

        let { usageRecords} = this.state;        
        this.setState({ isLoading: true });
        
        if (usageRecords.length === 0) {
            this.setState({ isLoading: false });
            window.flash("No data found",'error')
            return;
        } 
        
        try{
            const itemsFormatted = []; 
            usageRecords.forEach((item) => {
                itemsFormatted.push({
                    group_name: item.group_name === null || item.group_name === ''? "Removed": item.group_name,
                    first_name: item.first_name === null || item.first_name === ''? "Removed": item.first_name,
                    last_name: item.last_name === null || item.last_name === ''? "Removed": item.last_name,
                    start_time: this.getDateTimeString(item.start_time,true,item.duration," "),
                    stop_time: this.getDateTimeString(item.stop_time,false,item.duration," "),
                    duration: this.parseMilliseconds(item.start_time,item.stop_time,item.duration),//TP-2410
                    credit_id: item.credit_id
                });
            });
                const timestamp = new Date().toISOString();
    
                var fileTitle = `Usage Details ${timestamp}`; // or 'my-unique-title'
    
                this.exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON
                this.setState({ isLoading: false });
        }catch(err) {
               this.setState({ isLoading: false });
               
               window.flash(err.message,'error')
           }
        
    }

        // Exporting jSON data to csv format
    exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = this.convertToCSV(jsonObject);

        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
    
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','
    
                line += array[i][index];
            }
    
            str += line + '\r\n';
        }
    
        return str;
    }

    usageCustomSort(rows,field,direction){
        if(rows.length === 0) return; 
        // if(!field)
        //     field="id";
        
        let usagePicked = rows.filter(row=>null!==row[field]);    
        let mul=-1;

        if("asc"===direction){
            mul=1;
        }else if("desc"===direction){
            mul=-1;
        }
        if("start_time"===field || field === 'stop_time'){
            usagePicked.sort((c1,c2)=>{
              if(moment(c1[field]).isAfter(moment(c2[field]))){
                return -1*mul;
              }else if(moment(c1[field]).isBefore(moment(c2[field]))){
                return 1*mul;
              }else return 0;
            });
          }else if("group_name"===field){
            usagePicked.sort((a, b)=>{
              if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
              else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
              return 0;
             })
          }else if('duration' === field){
            let keyName = usagePicked[0].diffMins ? 'diffMins' : field;
            usagePicked.sort((a,b)=>{
                if(a[keyName] < b[keyName]) return -1*mul;
                else if(a[keyName] > b[keyName]) return 1*mul;
                return 0
            })
          }
        //   else if("id"===field){
        //     usagePicked.sort((a, b)=>{
        //       if(a[field] < b[field]) return -1*mul;
        //       else if(a[field] > b[field]) return 1*mul;
        //       return 0;
        //      })
        //   }

        return usagePicked;

    }  

    renderSwitch = (param)=>{

        const{lineGraphStateData,barGraphStateData,donutChartStateData} = this.state;
        
        switch(param){
            case 'Line':
                return  <LineGraph data={lineGraphStateData} ></LineGraph>
            case 'Bar':
                 return  <BarGraphChart data={barGraphStateData}></BarGraphChart>  
            case 'Donut':
                return  <DonutChart data={donutChartStateData}></DonutChart>
                
        }
    }

    setDataTablePage = (page) =>{
        this.setState({pageNumber:page})
    }

    render(){
        const {isLoading,startDate,endDate,showUsageTab,selectedChartType,usageRecords,isNoDataFound} =  this.state;
                

        // usageColumns[0].cell=row=>row.id ? <span className='d-line'>{row.id}</span>:<>{row.id}</>
        usageColumns[1].cell=row=>row.group_name && row.is_deleted === false ? <span className="d-inline align-cn" >{row.group_name}</span>: row.group_name && row.is_deleted ? <span style={{textDecoration:'line-through',color:'red'}}>{row.group_name}</span>:<>{'Removed'}</>
        usageColumns[2].cell=row=>row.start_time ? <span className="d-inline align-cn" >{this.getDateTimeString(row.start_time,true,row.duration," ")}</span>:<>{row.start_time}</>  
        usageColumns[3].cell=row=>row.stop_time ? <span className="text align-cn" >{this.getDateTimeString(row.stop_time,true,row.duration," ")}</span>:<>{row.stop_time}</>;
        usageColumns[4].cell=row=>row.duration ? <span className="text align-cn" >{this.parseMilliseconds(row.start_time,row.stop_time,row.duration)}</span>:<>{row.duration}</>;
       
        return(
            <div>
                <div id='chart-control' className='chart-control-container'>

                <select className='chart-type' style={{display:showUsageTab ? 'none':'block'}} value={selectedChartType} onChange={this.handleSelectChange}>
                        {typeOfChart.map((ch)=> <option id={ch.value} key={ch.value}>{ch.text}</option>)}
                </select>

                    <DatePicker id="chart_start_date"
                        placeholderText="start date"
                        selected={startDate}
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        autoComplete="off"
                        onChange={this.handleStartDateChange}
                        />&nbsp;
                    <DatePicker id="chart_end_date"
                        placeholderText="end date"
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        maxDate={new Date()}
                        autoComplete="off"
                        onChange={this.handleEndDateChange}
                        />&nbsp;
                    {showUsageTab ? 
                        // <div className='svg-container'>
                        //     <GrLineChart title='Chart View' className='user-usage-icon linechart-svg' color='white' onClick={this.handleToggle}/>
                        // </div>
                        ''
                        :
                        <div className='svg-container'>
                            <BsTable title='Table View' className='user-usage-icon' onClick={this.handleToggle}/>
                        </div>
                    }
                    <div className='download-csv-container'>
                        <FaFileCsv title='Download as csv' onClick={this.downloadUsageInCSV} />
                    </div>     

                </div>
                {showUsageTab ?
                    <DataTable
                        pagination={usageRecords.length>10}
                        paginationPerPage={10}
                        paginationTotalRows={usageRecords.length}
                        onChangePage={page => this.setDataTablePage(page)}
                        paginationDefaultPage={this.state.pageNumber}
                        columns={usageColumns}
                        data={usageRecords}
                        className="table-responsive table-container usage-participants"
                        noDataComponent="No data found"
                        keyField={"id"}
                        sortFunction={(rows, field, direction)=>this.usageCustomSort(rows, field, direction)}
                        subHeader={usageRecords.length>1}
                    />
                    :
                    <div>
                        {/* {selectedChartType === 'Line' ? 
                            lineGraphStateData.length > 0 && <LineGraph data={lineGraphStateData} ></LineGraph> :
                            barGraphStateData.length > 0 && <BarGraph data={barGraphStateData}></BarGraph> 

                        } */}
                        {!isNoDataFound && this.renderSwitch(selectedChartType)}
                        
                        {isNoDataFound &&
                            <NoDataComponent msg={'No data found'}/>
                        }

                    </div>
                }
                {isLoading && <LoadThreeDots isLoading={isLoading} ></LoadThreeDots>}
        </div>
        )
    }
}

export default UsageDetails;



export const NoDataComponent = ({msg}) =>{
    return(
        <div className="sc-dxgOiQ kJdJTV table-responsive table-container usage-participants">
        <header className="sc-kAzzGY gXlqCi rdt_TableHeader">
            <div className="sc-chPdSV ksKbuS"></div>
            <div className="sc-kgoBCf hFJClG">
            </div>
            <div className="sc-cSHVUG jJknLL">
                <div className="sc-jzJRlG cChDHo">
                </div>
                <div></div>
            </div>
        </header>
        <div className="sc-jKJlTe jDHQPG">
            <div className="sc-bdVaJa eNvZbY rdt_Table">
                <div className="sc-eNQAEJ ehOquN">{msg}</div>
            </div>
        </div>
    </div>
    )
}