import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import {Button, Modal} from 'react-bootstrap';
import superagent from "superagent";
import { connect } from "react-redux";
import {deleteCustomerSuccess} from '../redux/Customer/CustomerActions';
import "./customers.css";
import Layout from '../layouts/layout';
import SearchAppBar from './topNavBar';
import { trimText } from '../Utils/formatUtils';

let customerColumnsForEnterprise = [
  {name:"Name / AC ID",selector:"company_name"},
  {name:"Total Licenses",selector:"total_licences",sortable:true, maxWidth:"70px"},
  {name:"Cost",selector:"cost",sortable:true, maxWidth:"70px"},
  {name:"Last Used By",selector:"last_used_by",sortable:true},
  {name:"Last Used On",selector:"last_used_on",sortable:true},
  {name:"Usage (used/max)",selector:"total_usage",sortable:true},
  {name:"Start Date",selector:"subscription_start_date",sortable:true},
  {name:"End Date",selector:"subscription_end_date",sortable:true},
  {name:"Status",selector:"status",sortable:false,maxWidth:"150px"},
  {name:"Action",selector:"action",sortable:false,maxWidth:"140px"},
  {name:"Modify",selector:"modify",sortable:false,maxWidth:"140px"}
];

let customerColumnsNonEnterprise = [
  {name:"Name / AC ID",selector:"company_name"},
  {name:"Total Licenses",selector:"total_licences",sortable:true},
  {name:"Cost",selector:"cost",sortable:true},
  {name:"Last Used By",selector:"last_used_by",sortable:true},
  {name:"Last Used On",selector:"last_used_on",sortable:true},
  {name:"Usage (used/max)",selector:"total_usage",sortable:true},
  {name:"Start Date",selector:"subscription_start_date",sortable:true},
  {name:"End Date",selector:"subscription_end_date",sortable:true},
  {name:"Modify",selector:"modify",sortable:false,maxWidth:"140px"}
];

const sortColumns = [
  {name:"Last Used On",value:"last_used_on",isDefault:true},
  /* {name:"Oldest Case",value:"last_used_on desc"}, */
  {name:"Start Date",value:"subscription_start_date"},
  /* {name:"Start Date Oldest",value:"subscription_start_date desc"}, */
  {name:"End Date",value:"subscription_end_date"},
  /* {name:"End Date Oldest",value:"subscription_end_date desc"}, */
  {name:"Company Name",value:"company_name"},
  {name:"Last Used By",value:"last_used_by"},
  {name:"Total Number of Licenses",value:"total_licences"},
  {name:"Cost",value:"cost"},
  /* {name:"Cost (decreasing)",value:"cost desc"}, */
  {name:"Total Usage",value:"total_usage"}  
];

  function calculateCost(row){
    let totalcost = 0;
    row.purchases.forEach((purchase, i) => {
      if(2===purchase.plan_id)
        totalcost=totalcost+240*purchase.total_licences;
      else if(3===purchase.plan_id)
        totalcost=totalcost+600*purchase.total_licences;
      else if(row.total_price)
        totalcost = row.total_price; 
      else if(undefined!==row.price)
        totalcost = purchase.total_licences * row.price;
      else totalcost="";
    });
  return totalcost;
  }

  function fileExists(file_url){
    //console.log(file_url);
    //TP-5488
    let full_url;
    if(window.location.href.indexOf("localhost") > 0)
      full_url = "https://dashboard.telepresenzdemo.com/logo/"+file_url;
    else 
      full_url = window.location.href+"logo/"+file_url;
    var http = new XMLHttpRequest();
    http.onerror = function () {
      if (this.status === undefined || this.status === 0) {
        console.log('error due to CORS issue');
        return false;
      } else {
        console.log("** An error occurred during the transaction", this);
        return false;
      }
    
    };

    http.open('HEAD', full_url, true);
    http.send();
    //console.log(http.status, http.status != 404 && http.status !== 403);

    return http.status != 404 && http.status !== 403;
    //return http.status === 200;
  }

const ExpiryCheckbox = (props) => {
  return <div>
    <label className="col-xs-3 disable-pointer-event" htmlFor={props.id}>{props.label}&nbsp;</label>
    <label className="col-xs-4 col-xs-offset-2 switch">
      <input type="checkbox" id={props.id}
      name={props.id}
      onChange={props.toggleExpiry}
      checked={props.value}/>
      <span className="slider round"></span>
    </label>
  </div>
}

// function capitalizeWords(str){
//   let words =  str.split('_');
//   for(let i =0;i < words.length;i++){
//     words[i] =  words[i].charAt(0).toUpperCase() + words[i].slice(1);
//   }
//   return words.join(" ");
// }
 class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      showExpiry:{
        value: false,
        label:"Show All",
        id:"expired-accounts"
      },
      showPopUp:false,
      modalTitle:null,
      modalBody:null,
      selectedRow:null,
      serverMsg:null,
      isPostPaidAccDel:true,
      adminUserInfo:null,
      purchaseDetails:null,
      inviteList:[],
      isOnload:true,
      pageNumber:1,
      gridViewContainer: false, //TP-6405
      tableViewContainer: true, //TP-6405
      isSorted: false,
      showLegends: false,
      sortColumn: "last_used_on",
      sortDirection: "asc",
      searchValue:"",  //TP-6823
    };
  }

  componentDidMount() {
    this.setCustomerList(true);    
    window.addEventListener("resize", this.reDrawCompany);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reDrawCompany);
  }

  reDrawCompany = () => {
    this.setState({ isSorted: true }, () => {
      this.setState({ isSorted: false });
    })
  }

  setCustomerList = (isFirst = false) => {
    const {state} = this.props.params;
    const {inviteList} = state;
    let {customers} = this.props;
    if(!this.state.showExpiry.value){
      customers = customers.filter(c=>"past_due"===c.subscription_status||null===c.subscription_end_date||moment(c.subscription_end_date).isAfter(moment(new Date())));
    }
    this.setState({customers : customers,inviteList : inviteList}, () => {
      if (isFirst === true) {
        if (this.state.gridViewContainer)
            this.customSortCards("last_used_on", "asc");
        else {
          if (this.state.tableList) //TP-6405
          this.customSort(this.state.tableList,"last_used_on", "asc");
        }
      }
      if(this.state.customers.length > 0)
        this.setState({ showLegends: true })
      else
        this.setState({ showLegends: false });
      this.setState({ isSorted: true }, () => {
        this.setState({ isSorted: false })
      })
    });
     //TP-962-> if the customer list is 0 then show past date list
    if(customers.length===0 && this.state.isOnload){
      this.toggleExpiry();
      this.setState({isOnload:false})
    }
  }

  setSortColumn = (value) => {
    this.customSortCards(value, this.state.sortDirection);
    this.setState({ sortColumn: value });
  }

  setSortDir = (value) => {
    this.customSortCards(this.state.sortColumn, value);
    this.setState({ sortDirection: value });
  }

  customSortCards = (field, direction) => {    
    console.log(field, direction);
    if(null===field) field="last_used_on";
    let custPicked = this.state.customers.filter(row=>null!==row[field]);
    let custNullPicked = this.state.customers.filter(row=>null===row[field]);
    let mul=-1;
    if("asc"===direction){
      mul=1;
    }else if("desc"===direction){
      mul=-1;
    }

    // console.log(rows, field, direction);
    if("last_used_on"===field||"subscription_start_date"===field||"subscription_end_date"===field){
      custPicked.sort((c1,c2)=>{
        if(moment(c1[field]).isAfter(moment(c2[field]))){
          return -1*mul;
        }else if(moment(c1[field]).isBefore(moment(c2[field]))){
          return 1*mul;
        }else return 0;
      });
    }else if("company_name"===field||"last_used_by"===field){
      custPicked.sort((a, b)=>{
        if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
        else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
        return 0;
        })
    }else if("total_licences"===field){
      custPicked.sort((a, b)=>{
        if(a[field] < b[field]) return -1*mul;
        else if(a[field] > b[field]) return 1*mul;
        return 0;
        })
    }else if("cost"===field){
      custPicked = this.state.customers.filter(row=>""!==calculateCost(row));
      custNullPicked = this.state.customers.filter(row=>""===calculateCost(row));
      custPicked.sort((a, b)=>{
        if(calculateCost(a) < calculateCost(b)) return -1*mul;
        else if(calculateCost(a) > calculateCost(b)) return 1*mul;
        return 0;
        })
    }else if("total_usage"===field){
      custPicked.sort((a, b)=>{
        if(a.total_used+a.total_max < b.total_used+b.total_max) return -1*mul;
        else if(a.total_used+a.total_max > b.total_used+b.total_max) return 1*mul;
        return 0;
        })
    }

    //return [...custPicked, ...custNullPicked];
    this.setState({customers: [...custPicked, ...custNullPicked]}, () => {
      this.setState({ isSorted: true }, () => {
        this.setState({ isSorted: false });
      });
    });
  }

  customSort = (rows, field, direction) => {
    if(null===field) field="last_used_on";
    let custPicked = rows.filter(row=>null!==row[field]);
    let custNullPicked = rows.filter(row=>null===row[field]);
    let mul=-1;
    if("asc"===direction){
      mul=1;
    }else if("desc"===direction){
      mul=-1;
    }

    // console.log(rows, field, direction);
    if("last_used_on"===field||"subscription_start_date"===field||"subscription_end_date"===field){
      custPicked.sort((c1,c2)=>{
        if(moment(c1[field]).isAfter(moment(c2[field]))){
          return -1*mul;
        }else if(moment(c1[field]).isBefore(moment(c2[field]))){
          return 1*mul;
        }else return 0;
      });
    }else if("company_name"===field||"last_used_by"===field){
      custPicked.sort((a, b)=>{
        if(a[field].toLowerCase() < b[field].toLowerCase()) return -1*mul;
        else if(a[field].toLowerCase() > b[field].toLowerCase()) return 1*mul;
        return 0;
        })
    }else if("total_licences"===field){
      custPicked.sort((a, b)=>{
        if(a[field] < b[field]) return -1*mul;
        else if(a[field] > b[field]) return 1*mul;
        return 0;
        })
    }else if("cost"===field){
      custPicked = rows.filter(row=>""!==calculateCost(row));
      custNullPicked = rows.filter(row=>""===calculateCost(row));
      custPicked.sort((a, b)=>{
        if(calculateCost(a) < calculateCost(b)) return -1*mul;
        else if(calculateCost(a) > calculateCost(b)) return 1*mul;
        return 0;
        })
    }else if("total_usage"===field){
      custPicked.sort((a, b)=>{
        if(a.total_used+a.total_max < b.total_used+b.total_max) return -1*mul;
        else if(a.total_used+a.total_max > b.total_used+b.total_max) return 1*mul;
        return 0;
        })
    }

    return [...custPicked, ...custNullPicked];
  }

  keyPress = (e) =>{
    this.setState({searchValue: e.target.value});  //TP-6823
    if(!e) e = window.event;
    //Customer History
    // TP-6823
    if(/* e.keyCode === 13 && */ e.target.value.length - 1 >0){
      // Declare variables
      var filter = e.target.value.toLowerCase();
      var item = this.state.customers.filter(function(value){
        value.company_name=null==value.company_name?value.account_id:value.company_name;

        return value.company_name.toLowerCase().includes(filter)
        || value.account_id.toLowerCase().includes(filter);
      });
      
      this.setState({'customers':item}, () => {
        if(this.state.customers.length > 0)
          this.setState({ showLegends: true })
        else
          this.setState({ showLegends: false });
        this.setState({ isSorted: true }, () => {
          this.setState({ isSorted: false });
        })
      });
    }else{
      this.setCustomerList();
    }
  }
//TP-6823
  toggleExpiry = () => {
    if (this.state.searchValue.length < 1) {   
    this.setState(({
      pageNumber:1,
      showExpiry : {...this.state.showExpiry,value : !this.state.showExpiry.value}
      }),()=>this.setCustomerList())
    } 
    else if (this.state.searchValue.length > 0) {
      var filter2 = this.state.searchValue.toLowerCase();
      var item2 = this.state.customers.filter(function(value){
        value.company_name=null==value.company_name?value.account_id:value.company_name;
        return value.company_name.toLowerCase().includes(filter2)
        || value.account_id.toLowerCase().includes(filter2);
      });
      
      this.setState({'customers':item2}, () => {
        if(this.state.customers.length > 0)
          this.setState({ showLegends: true })
        else
          this.setState({ showLegends: false });
        this.setState({ isSorted: true }, () => {
          this.setState({ isSorted: false });
        })
      });
      this.setState(({
        pageNumber:1,
        showExpiry : {...this.state.showExpiry,value : !this.state.showExpiry.value}
        }))
    } 
  }

  
deleteCustomerAccountId = async () => {
  let dataParam =  this.state.selectedRow;
  if(dataParam && !dataParam.account_id)
  return false;

  let apiUrl ="/api/dash/deleteCustomerByAccountId/" + dataParam.account_id;
  let headers = new Headers();
      headers.append("Authorization", "Bearer " + sessionStorage.getItem("userToken"));
  let msg;

  fetch(sessionStorage.getItem("url") + apiUrl, {
    method: "DELETE",
    headers: headers,
  })
    .then((response) => {
      if (200 === response.status) {
         msg = "Account deleted successfully ";
         this.setState({serverMsg:msg,showPopUp:false,selectedRow:null,modalTitle:"Alert"}); 
         this.setCustomerListAfterRemoved(dataParam);
         if(this.props.deleteCustomerSuccess)
           this.props.deleteCustomerSuccess(dataParam.account_id); 
      } 
    })
    .catch((error) => {
      if (undefined === error.status) {
        msg = "May be internet is not connected, try again sometime";
      } else if (400 === error.status) {
        msg = "Data not Deleted";
      } else if (403 === error.status) {
        msg = "You are not authorized to delete the customer";
      } else if (404 === error.status) {
        msg = "Customer cannot be found using this account id";
      } else if (422 === error.status) {
        msg = "Invalid request data";
      } else if (500 === error.status) {
        msg = "Something went wrong, try again sometime";
      }
      this.setState({serverMsg:msg,showPopUp:false,selectedRow:null,modalTitle:"Alert"});
    });
};

setCustomerListAfterRemoved = (removedParam) =>{
  let {customers} = this.state;
      customers = customers.filter((cust) => cust.account_id !== removedParam.account_id);
      this.setState({customers:customers});
}

isPaidOffline = (element) =>{
  return element.is_paid_offline && element.is_paid_offline === true;
}
//{/*TP-809 */}   
showConfirmationBox = (e,rowData) =>{
  let accountPaidType, accountType, adminUserData, purchaseDtls=[],credits=[]; 
    accountPaidType = rowData.is_paid_offline;
    accountType = accountPaidType ? "ENTERPRISE":"PREPAID";
    adminUserData = rowData.users && rowData.users.length > 0 ? rowData.users.filter(usr=>usr.isadmin).pop():
                    this.state.inviteList.filter((invitee)=> invitee.account_id === rowData.account_id).pop();

    rowData.purchases.forEach((purchase)=>{
      credits = rowData.credits ? rowData.credits.filter(credit=>credit.purchase_id === purchase.id):[];
      purchase.purchase_id = credits.length > 0 &&  credits[0].purchase_id ? credits[0].purchase_id :"";
      purchaseDtls.push(purchase) 
    });

  this.setState({showPopUp:true,selectedRow:rowData,isPostPaidAccDel:accountPaidType,adminUserInfo:adminUserData,purchaseDetails:purchaseDtls,
    modalTitle:"Confirm Delete",modalBody:`Are you sure you want to permanently delete this ${accountType} account?`});
}
closeConfirm = ()=>{
  this.setState({showPopUp:false,selectedRow:null,modalTitle:null,modalBody:null});
}


closeAlertPopUp = () => {
  this.setState({serverMsg:null,selectedRow:null,modalTitle:null,modalBody:null});
}

//{/*TP-809 */}   
deletePrepaidAccount = async () =>{
    
  const {purchaseDetails,adminUserInfo} = this.state;

    if(!adminUserInfo){
      this.closeConfirm();
      this.setState({serverMsg:"Unable to delete, no valid user found!",showPopUp:false,modalTitle:"Alert"});
      return false;
    } 

  let result = purchaseDetails.reduce((accumlatorPromise,nextObj)=>{
    return accumlatorPromise.then(()=>{
        this.setState({isLoading:true});
       return this.cancelSubscriptionPromise(nextObj,adminUserInfo)
      });
  },Promise.resolve())
   
  result.then(() => {
    this.deletePrepaidAccountById();
    this.setState({isLoading:false});
  })
}
//TP-729
cancelSubscriptionPromise(purchaseObj,adminObj) {

return new Promise((resolve,reject)=>{
  // if purchaseObj will not able to find purchase obj or admin obj then it it will skip and run next purchase entry
   // or else if will resolve and move for next iterration
  if(purchaseObj && purchaseObj.purchase_id && adminObj && adminObj.id){
    let url = '/api/cancelSubscriptionByPurchaseId';
    let params = {
      purchaseId:purchaseObj.purchase_id,
      adminUserId:adminObj.id,
      accountId:adminObj.account_id
    }
    
    if(sessionStorage.userToken) {
      superagent.post(sessionStorage.url+ url)
      .set("Authorization", "Bearer " + sessionStorage.userToken)
      .send(params)
      .accept("application/json")
      .then(response => response)
      .then(data => {
        if (null !== data.body) {
          console.log('Cancelled Subscription successfully');
          resolve(data.body.id);
        }
      }).catch(error => {
          console.error(JSON.parse(error.message));
          this.closeConfirm();
          this.setState({serverMsg:"Deletion unsuccessful, unable to delete this account!",showPopUp:false,modalTitle:"Alert"});
          return error;
      })
      
    }
  }else{
    resolve();
  }
})
}


deletePrepaidAccountById = async () => {

if(!this.state.adminUserInfo.account_id)
  return false;


let apiUrl = "/api/deleteAccount/" + this.state.adminUserInfo.account_id;
let headers = new Headers();
headers.append("Authorization","Bearer " + sessionStorage.getItem("userToken"));
let msg = null;

fetch(sessionStorage.getItem("url") + apiUrl, { method: "DELETE",headers: headers})
  .then((response) => {
    if (200 === response.status) {
      msg = "Account deleted successfully ";
      this.setState({serverMsg:msg,showPopUp:false,modalTitle:"Alert"});
      this.setCustomerListAfterRemoved(this.state.adminUserInfo);
      if(this.props.deleteCustomerSuccess)
           this.props.deleteCustomerSuccess(this.state.adminUserInfo.account_id);
       
    } else {
      console.log('Error accour while deleting account!');
      this.closeAlert();
    }
  })
  .catch((error) => {
    if (undefined === error.status) {
      msg = "May be internet is not connected, try again sometime";
    } else if (400 === error.status) {
      msg = "Data not Deleted";
    } else if (403 === error.status) {
      msg = "You are not authorized to delete the customer";
    } else if (404 === error.status) {
      msg = "Customer cannot be found using this account id";
    } else if (422 === error.status) {
      msg = "Invalid request data";
    } else if (500 === error.status) {
      msg = "Something went wrong, try again sometime";
    }
    this.setState({serverMsg:msg,showPopUp:false,modalTitle:"Alert"});
  });
};

toggleContainer = () => {
  
    this.setState({
      tableViewContainer:!this.state.tableViewContainer,
      gridViewContainer:!this.state.gridViewContainer,
    }); //TP-1347  
}

getFilterData(columns){
  columns.filter( col => col.selector !== "status");
  if (this.state.tableViewContainer === true) columns.filter( col => col.selector !== "modify");
  columns = this.getVendorFilter(columns);
  return columns
}
 //TP-1169 -> below condition added to check if details are related to vendor trial then remove action column
getVendorFilter(columns){
  let isVendorTrialAccount = false;
  isVendorTrialAccount = this.state.customers.some(cust=> (cust.subscription_status === "trial_pending_approval" || cust.subscription_status === "trial_approved"))
  columns =  isVendorTrialAccount ? columns.filter( col => col.selector !== "action"): columns;
  columns = isVendorTrialAccount ? columns.filter( col => col.selector !== "modify"): columns;
  return columns;
}

setDataTablePage(pageNum){
  this.setState({pageNumber:pageNum})
}
  render() {
    customerColumnsForEnterprise[0].cell=row =><div onClick={() => this.props.params.breadcrumbTail("customer",row,null!==row.company_name?row.company_name:row.account_id)}
                                  className="hyperlink text-ellipses" data-toggle="tooltip"
                                  title="View Details">
                                  {null!==row.company_name?row.company_name:row.account_id}
                                  </div>;
    customerColumnsForEnterprise[1].cell=row => row.total_licences ? <div className="hyperlink" onClick={()=>this.props.params.breadcrumbTail("licenses",row, "Licenses")} title="Licenses">{row.total_licences}</div>: '';
    customerColumnsForEnterprise[2].cell=row => calculateCost(row);
    customerColumnsForEnterprise[3].cell=row => row.last_used_by ? <div className="hyperlink text-ellipses" onClick={()=>this.props.params.breadcrumbTail("user",row.last_used_by_Obj,row.last_used_by)} title={row.last_used_by.length > 15?row.last_used_by:""} data-toggle="tooltip" >{null===row.last_used_by?"NA": row.last_used_by.length > 25?trimText(row.last_used_by, 25):row.last_used_by}</div>:<div>NA</div>;
    if (this.state.gridViewContainer)
      customerColumnsForEnterprise[4].cell=row => row.last_used_on !== null ? moment(row.last_used_on).format("D.MM.YYYY HH:mm"):"NA";
    else
      customerColumnsForEnterprise[4].cell=row => row.last_used_on !== null ? moment(row.last_used_on).format("DD MMM,YYYY HH:mm"):"NA";

    customerColumnsForEnterprise[5].cell=row => row.total_used + "/" + row.total_max;
    if (this.state.gridViewContainer) {
      customerColumnsForEnterprise[6].cell=row => row.subscription_start_date !== null ? moment(row.subscription_start_date).format("D.MM.YYYY"):"NA";
      customerColumnsForEnterprise[7].cell=row => row.subscription_end_date !== null ? moment(row.subscription_end_date).format("D.MM.YYYY"):"NA";
      customerColumnsForEnterprise[8].cell=row => row.isInactive === undefined || row.isInactive === true ? 'Inactive':'Active';
      if (sessionStorage.url.includes("staging") === false) {
        customerColumnsForEnterprise[9].cell=row => row.id && sessionStorage.userDbRole === "admin" && row.subscription_status !== "trial_approved" && row.subscription_status !== "trial_pending_approval" ? <span ><i style={{fontSize: "25px"}} className="fa fa-trash-o fa-1x account-delete-bin" title="Delete" onClick={(e)=>this.showConfirmationBox(e,row)}></i></span>:<div className="text-ellipses"></div>
        customerColumnsForEnterprise.push({name:"Modify",selector:"modify",sortable:false,maxWidth:"140px"})
        customerColumnsForEnterprise[10].cell=row => row.id && (sessionStorage.userDbRole === "admin" || this.props.myWriteAccountAccess) && row.subscription_status !== "trial_approved" && row.subscription_status !== "trial_pending_approval" ? <span ><i style={{fontSize: "25px"}} className="fa fa-edit account-enterprise-icon" title="Modify" onClick={()=>this.props.params.breadcrumbTail("modEnt",row,null!==row.company_name?row.company_name:row.account_id)}></i></span>:<div className="text-ellipses"></div>
      } else {
        customerColumnsForEnterprise.push({name:"Modify",selector:"modify",sortable:false,maxWidth:"140px"})
        customerColumnsForEnterprise[9].cell=row => row.id && (sessionStorage.userDbRole === "admin" || this.props.myWriteAccountAccess) && row.subscription_status !== "trial_approved" && row.subscription_status !== "trial_pending_approval" ? <span ><i style={{fontSize: "25px"}} className="fa fa-edit account-enterprise-icon" title="Modify" onClick={()=>this.props.params.breadcrumbTail("modEnt",row,null!==row.company_name?row.company_name:row.account_id)}></i></span>:<div className="text-ellipses"></div> 
      }
    } else {
      customerColumnsForEnterprise[6].cell=row => row.subscription_start_date !== null ? moment(row.subscription_start_date).format("DD MMM,YYYY HH:mm"):"NA";
      customerColumnsForEnterprise[7].cell=row => row.subscription_end_date !== null ? moment(row.subscription_end_date).format("DD MMM,YYYY HH:mm"):"NA";
      customerColumnsForEnterprise[8].cell=row => row.isInactive ? 'Inactive':'Active';
      if (sessionStorage.url.includes("staging") === false)
        customerColumnsForEnterprise[9].cell=row => row.id && sessionStorage.userDbRole === "admin" && row.subscription_status !== "trial_approved" && row.subscription_status !== "trial_pending_approval" ? <span ><i className="fa fa-trash-o fa-1x account-delete-bin-table" title="Delete" onClick={(e)=>this.showConfirmationBox(e,row)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-edit account-enterprise-icon-table" title="Modify" onClick={()=>this.props.params.breadcrumbTail("modEnt",row,null!==row.company_name?row.company_name:row.account_id)}></i></span>:<div className="text-ellipses"></div>
      else 
      customerColumnsForEnterprise[9].cell=row => row.id && sessionStorage.userDbRole === "admin" && row.subscription_status !== "trial_approved" && row.subscription_status !== "trial_pending_approval" ? <span ><i className="fa fa-edit account-enterprise-icon-table" title="Modify" onClick={()=>this.props.params.breadcrumbTail("modEnt",row,null!==row.company_name?row.company_name:row.account_id)}></i></span>:<div className="text-ellipses"></div>
    }
    
    //customerColumnsForEnterprise[9].cell=row => sessionStorage.url.includes("staging") === false && row.id && sessionStorage.userDbRole === "admin" && row.subscription_status !== "trial_approved" && row.subscription_status !== "trial_pending_approval" ? <span ><i className="fa fa-trash-o fa-1x account-delete-bin" title="Delete" onClick={(e)=>this.showConfirmationBox(e,row)}></i></span>:<div className="text-ellipses"></div>
    //customerColumnsForEnterprise[10].cell=row => row.id && (sessionStorage.userDbRole === "admin" || this.props.myWriteAccountAccess) && row.subscription_status !== "trial_approved" && row.subscription_status !== "trial_pending_approval" ? <span ><i className="fa fa-edit account-enterprise-icon" title="Modify" onClick={()=>this.props.params.breadcrumbTail("modEnt",row,null!==row.company_name?row.company_name:row.account_id)}></i></span>:<div className="text-ellipses"></div>
    if (this.state.gridViewContainer) {
      customerColumnsForEnterprise.push({name:"Image",selector:"jobs_logo_url",sortable:false})
      customerColumnsForEnterprise[11].cell=row=> row.allow_custom_logo === true && row.jobs_logo_url !== ''  ? "https://dashboard.telepresenzdemo.com/logo/"+JSON.parse(row.jobs_logo_url).key : null!==row.company_name ? row.company_name: "NA"; /**TP-5488*/
      customerColumnsForEnterprise[11].fileExists=row => fileExists(JSON.parse(row.jobs_logo_url).key)
    } else {
      if(customerColumnsForEnterprise[customerColumnsForEnterprise.length-1].selector==="jobs_logo_url") {
        customerColumnsForEnterprise = customerColumnsForEnterprise.filter(gr => gr.selector !== "jobs_logo_url")
      }
      if(customerColumnsForEnterprise[customerColumnsForEnterprise.length-1].selector==="modify") {
        customerColumnsForEnterprise = customerColumnsForEnterprise.filter(gr => gr.selector !== "modify")
      }
    }
    
    customerColumnsNonEnterprise[0].cell=row =><div onClick={() => this.props.params.breadcrumbTail("customer",row,null!==row.company_name?row.company_name:row.account_id)}
                                className="hyperlink text-ellipses" data-toggle="tooltip" title="View Details"> {null!==row.company_name?row.company_name:row.account_id}</div>;
    customerColumnsNonEnterprise[1].cell=row => row.total_licences ? <div className="hyperlink" onClick={()=>this.props.params.breadcrumbTail("licenses",row, "Licenses")} title="Licenses">{row.total_licences}</div>: '';
    customerColumnsNonEnterprise[2].cell=row => calculateCost(row);
    customerColumnsNonEnterprise[3].cell=row => row.last_used_by ? <div className="hyperlink text-ellipses" onClick={()=>this.props.params.breadcrumbTail("user",row.last_used_by_Obj,row.last_used_by)} title={row.last_used_by.length > 15?row.last_used_by:""} data-toggle="tooltip">{null===row.last_used_by?"NA": row.last_used_by.length > 25?trimText(row.last_used_by, 25): row.last_used_by}</div>:<div>NA</div>;
    customerColumnsNonEnterprise[4].cell=row => row.last_used_on !== null ? moment(row.last_used_on).format("DD MMM,YYYY HH:mm"):"NA";
    customerColumnsNonEnterprise[5].cell=row => row.total_used + "/" + row.total_max;
    customerColumnsNonEnterprise[6].cell=row => row.subscription_start_date !== null ? moment(row.subscription_start_date).format("DD MMM,YYYY HH:mm"):"NA";
    customerColumnsNonEnterprise[7].cell=row => row.subscription_end_date !== null ? moment(row.subscription_end_date).format("DD MMM,YYYY HH:mm"):"NA";
    customerColumnsNonEnterprise[8].cell=row => row.id && (sessionStorage.userDbRole === "admin" || this.props.myWriteAccountAccess) && row.subscription_status !== "trial_approved" && row.subscription_status !== "trial_pending_approval" ? <span ><i style={{fontSize: "25px"}} className="fa fa-edit edit-enterprise-icon" title="Modify" onClick={()=>this.props.params.breadcrumbTail("modEnt",row,"Modify Enterprise")}></i></span>:<div className="text-ellipses"></div>
    if (this.state.gridViewContainer) {
      customerColumnsNonEnterprise.push({name:"Buffer 1",selector:"buffer_1",sortable:false})
      customerColumnsNonEnterprise.push({name:"Buffer 2",selector:"buffer_2",sortable:false})
      customerColumnsNonEnterprise.push({name:"Image",selector:"jobs_logo_url",sortable:false})
      customerColumnsNonEnterprise[11].cell=row=> row.allow_custom_logo === true && row.jobs_logo_url !== '' ? "https://dashboard.telepresenzdemo.com/logo/"+JSON.parse(row.jobs_logo_url).location : null!==row.company_name ? row.company_name: "NA"; /**TP-5488*/
      customerColumnsNonEnterprise[11].fileExists=row => fileExists(JSON.parse(row.jobs_logo_url).key)
    } else {
      customerColumnsNonEnterprise = customerColumnsNonEnterprise.filter(cc => cc.name !== "Buffer 1");
      customerColumnsNonEnterprise = customerColumnsNonEnterprise.filter(cc => cc.name !== "Buffer 2");
      customerColumnsNonEnterprise = customerColumnsNonEnterprise.filter(cc => cc.name !== "Image");
    }

    return (<div className="page">
        <div className="media">
          <h3>Please rotate your device to landscape mode</h3>
        </div>
        <div className="container-fluid media-show">
          <div className="row">
            { this.state.tableViewContainer ?
              <div className="col-sm-12 col-lg-1">
                <div title="Switch to Card View" onClick={()=>this.toggleContainer()}>
                  <i className="fa fa-id-card switch-icons" style={{color: 'white'}}></i>
                </div>
              </div>
              :
              <>
                <SearchAppBar
                  toggleContainer={this.toggleContainer} 
                  {...this.state.showExpiry} toggleExpiry={this.toggleExpiry}
                  keyPress={this.keyPress} 
                  setSortColumn={(field)=>this.setSortColumn(field)} 
                  setSortDir={(direction)=>this.setSortDir(direction)}
                  //sortFunction={(field, direction)=>this.customSortCards(field, direction)} 
                  sortcolumns={sortColumns}
                />                            
              </>
            }
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              { this.state.tableViewContainer ?
                <DataTable
                  pagination={this.state.customers.length>this.props.pagination}
                  paginationPerPage={this.props.pagination}
                  paginationTotalRows={this.state.customers.length}
                  onChangePage={page => this.setDataTablePage(page)}
                  paginationDefaultPage={this.state.pageNumber}
                  columns={this.state.customers.length>0 && sessionStorage.userDbRole === "admin"  ? this.state.showExpiry.value ? this.getVendorFilter(customerColumnsForEnterprise):this.getFilterData(customerColumnsForEnterprise):customerColumnsNonEnterprise}
                  data={this.state.customers}
                  style={{overflow:'hidden'}}
                  keyField={"account_id"}
                  className="customers-table"
                  sortFunction={(rows, field, direction)=>this.customSort(rows, field, direction)}
                  subHeader={true}
                  subHeaderComponent={(<>
                    <ExpiryCheckbox {...this.state.showExpiry} toggleExpiry={this.toggleExpiry}/>
                    <div className="col-sm-4 col-lg-2">
                      <input type="text" placeholder="Search Customers" id="filterCust" onChange={(e)=>{this.keyPress(e)}}/>
                    </div>
                    </>
                  )}
                  subHeaderAlign="right"
                />
                :
                <Layout data={this.state.customers} 
                  columns={this.state.customers.length>0 && sessionStorage.userDbRole === "admin"  ? this.state.showExpiry.value ? this.getVendorFilter(customerColumnsForEnterprise):this.getFilterData(customerColumnsForEnterprise):customerColumnsNonEnterprise}
                  nodataMessage="There is no records to display"
                  ></Layout>
              }

              { this.state.gridViewContainer && this.state.showLegends === true ?
                <div className='legend-cls'><span className="user-type-flag text-shadox" style={{backgroundColor:"#6fc566",margin:'5px', fontSize: '16px', padding:'6px'}}>Active</span>
                  <span className="user-type-flag text-shadox" style={{backgroundColor:"#FF0000",margin:'5px', fontSize: '16px', padding:'6px'}}>Inactive</span>
                </div>
                :
                ''
              }
            </div>
          </div>
        </div>
        {this.state.showPopUp && this.state.isPostPaidAccDel &&
        <ConfirmationPopUp modalTitle={this.state.modalTitle} 
        modalBody={this.state.modalBody} 
        closeConfirm={this.closeConfirm} 
        onConfirm={this.deleteCustomerAccountId}/>}

        {this.state.showPopUp && !this.state.isPostPaidAccDel &&
        <ConfirmationPopUp modalTitle={this.state.modalTitle} 
        modalBody={this.state.modalBody} 
        closeConfirm={this.closeConfirm} 
        onConfirm={this.deletePrepaidAccount}/>}

        {this.state.serverMsg&&
        <AlertPopUp modalTitle={this.state.modalTitle} 
        modalBody={this.state.serverMsg} 
        closeAlert={this.closeAlertPopUp}/>}

      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteCustomerSuccess: (accountId) => dispatch(deleteCustomerSuccess(accountId)),
});


export default connect(null,mapDispatchToProps)(Customers);


class ConfirmationPopUp extends React.Component{
	constructor(props){
    super();
      this.state = {
        alertPopUp: false
      }
	    this.close = this.close.bind(this);
	}
  
	close() {
	  this.setState({alertPopUp:false});
	  this.props.closeConfirm();
	}
  
	componentDidMount(){
	  this.setState({alertPopUp:true});
	}
  
	render() {
	  return (
		  <Modal show={this.state.alertPopUp} onHide={this.close} backdrop="static" keyboard={false}>
			<Modal.Header>
			  <Modal.Title>{this.props.modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  {this.props.modalBody}
			</Modal.Body>
			<Modal.Footer>
			  <Button onClick={this.props.onConfirm}>OK</Button>
        <Button onClick={this.close}>Cancel</Button>
			</Modal.Footer>
		  </Modal>
	  );
	}
}

class AlertPopUp extends React.Component{
	constructor(props){
	  super(props);
	  this.state = {
		alertPopUp: false
	  }
	  this.close = this.close.bind(this);
	}
  
	close() {
	  this.setState({alertPopUp:false});
	  this.props.closeAlert();
	}
  
	componentDidMount(){
	  this.setState({alertPopUp:true});
	}
  
	render() {
	  return (
		  <Modal show={this.state.alertPopUp} onHide={this.close} backdrop="static" keyboard={false}>
			<Modal.Header>
			  <Modal.Title>{this.props.modalTitle}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			  {this.props.modalBody}
			</Modal.Body>
			<Modal.Footer>
			  <Button onClick={this.close} className="btn-green">OK</Button>
			</Modal.Footer>
		  </Modal>
	  );
	}
  }