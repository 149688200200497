import * as d3 from 'd3';
import './barStyle.css';

const hoverColor = "#eec42d";
const staticColor = '#71EEB8';
const drawChart = (element,paramData) =>{
     const data = paramData;

     d3.select(element).select('svg').remove();

    // Set the dimensions of our chart to be displayed 
    const barsWidth = 500,
    barsHeight = 400,
    axisMargin = 100;

    const chartHeight = barsHeight+axisMargin,
    chartWidth = barsWidth+axisMargin;

    const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'd3-tooltip-class')
    .style('position', 'absolute')
    .style('z-index', '10')
    .style('visibility', 'hidden')
    .style('padding', '10px')
    .style('background', 'rgba(0,0,0,0.6)')
    .style('border-radius', '4px')
    .style('color', '#fff')
    .text('a simple tooltip');

// Select the chart element on the page so we can reference it in code
// Also set the width and height attributes of the chart SVG 

        const d3SvgElement = document.getElementById(element.id).getElementsByTagName("g");
        if(d3SvgElement.length > 1){
            for (let index = d3SvgElement.length - 1; index >= 0; index--) {
                d3SvgElement[index].parentNode.removeChild(d3SvgElement[index]);
            }
        }

    const chart = d3.select(element)
    .attr('width', chartWidth+100)
    .attr('height', chartHeight);

// Create a linear scale for our y-axis to map datapoint values to pixel heights of bars
    const yScale = d3.scaleLinear().domain([0,d3.max(data, function(d){ return d.value;})])
                 .rangeRound([barsHeight, 0]);

// Create a scale that returns the bands each bar should be in along the x-axis
    let xScale = d3.scaleBand().domain(data.map(function(d){return d.name}))
    .rangeRound([0,barsWidth])
    .padding(0.1);

    // Create an SVG group that we will add the individual bar elements of our chart to
    const bars = chart.append('g')
    .attr('id', "bars-container");

    // Bind the data to our .bars svg elements
    // Create a rectangle for each data point and set position and dimensions using scales
    bars.selectAll('.bar')
    .data(data)
    .enter().append("rect")
        .attr('class', "bar")
        .attr('x', function(d){
            return xScale(d.name);
        })
        .attr('y', function(d){
            return yScale(d.value); 
        })
        .attr('width', xScale.bandwidth()-30)
        .attr('height', function(d){return barsHeight-yScale(d.value)})
        .on("mouseover", function(d,i) {
            tooltip.html(`<div>Month: ${i.name}</div><div>Minutes: ${i.value}</div>`).style('visibility', 'visible').style("left", d.x + "px")     
            .style("top", d.y + "px");
            d3.select(this).style("fill", hoverColor);
          })
        .on("mouseout", function(d, i) {
            tooltip.html(``).style('visibility', 'hidden'); 
            d3.select(this).style("fill", staticColor);
            document.querySelectorAll(".tooltip").forEach(el => el.remove());
        });

    // Move the bars so that there is space on the left for the y-axis
    bars.attr('transform', 'translate('+axisMargin+',0)');

    // Create a new SVG group for the y-axis elements
    // Generate the y-axis with 10 ticks and move into position
        const yAxis = chart.append('g')
            .attr('id','y-axis')
            .call(d3.axisLeft(yScale).ticks(10))
            .attr('transform', 'translate('+axisMargin+',0)');

        console.log(yAxis);    

    // Create another group for the x-axis elements
    // Generate the x-axis using the our x scale and move into positon
    // Select the text elements and rotate by 45 degrees
        const xAxis = chart.append('g')
            .attr('id', 'x-axis')
            .call(d3.axisBottom(xScale))
            .attr('transform', 'translate('+(axisMargin-10)+','+barsHeight+')')
            .selectAll("text").style("text-anchor",'middle').attr('transform', 'rotate(0)');
      console.log(xAxis);
        
            bars
            .append('text')
            .attr("stroke", "white")
            .attr("fill", "#ffff")
            .attr('x', -(chartHeight / 2.5))
            .attr('y', -75)
            .attr('transform', 'rotate(-90)')
            .attr('text-anchor', 'middle')
            .text("Duration (mins)");
            

}

export default drawChart;