// redux/product/product.actions.js
import { GroupActionTypes } from "./GroupTypes";
import moment from "moment";
import superagent from "superagent";

export const fetchGroupsStart = () => ({
  type: GroupActionTypes.FETCH_GROUPS_START
});

export const fetchGroupsSuccess = Groups => ({
  type: GroupActionTypes.FETCH_GROUPS_SUCCESS,
  payload: Groups
});

export const fetchGroupsFailure = error => ({
  type: GroupActionTypes.FETCH_GROUPS_FAILURE,
  payload: error
});

export const updateGroupStart = () =>({
  type: GroupActionTypes.UPDATE_STREAM_TYPE
})

export const updateGroupsAction = (accountGroupParam,groupValue) =>({
  type:GroupActionTypes.UPDATE_GROUPS_ACTION,
  payloadGroup:accountGroupParam,
  payloadValue:groupValue
})

export const fetchAllGroupsList = () => {
    return dispatch =>{
      dispatch(fetchGroupsStart());
      if(sessionStorage.getItem("userToken")){
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', "Bearer " + sessionStorage.getItem("userToken"));
        fetch(sessionStorage.getItem("url") + "/api/dash/getAllGroups",{ method: 'GET', headers: myHeaders})
        .then((response) => response.json())
        .then((data) => {
          const groupList = data.filter(group => group.group_id && !group.is_deleted);
          const groups = [];
          groupList.forEach((group) => {
            let experts = [] , techs = [] , expertNames = "" , techNames = "";

            group.users.forEach((user) => {
              user.company_name = group.company_name;
              user.account_id = group.account_id;
              if (user.isexpert) {
                  expertNames = expertNames + " " + user.first_name + " " + user.last_name +",";
                  experts.push(user);
              } else if (!user.isexpert && !user.isadmin) {
                  techNames = techNames + " " + user.first_name + " " + user.last_name + ",";
                  techs.push(user);
              }
            });
            group.experts = experts;
            group.techs = techs;
            group.expertNames = expertNames.slice(0, -1);
            group.techNames = techNames.slice(0, -1);
            groups.push(group);

           
          });
            groups.sort((d1, d2) => {
              if (
                moment(d1.last_active_on).isAfter(moment(d2.last_active_on))
              ) {
                return 1;
              } else if (
                moment(d1.last_active_on).isBefore(moment(d2.last_active_on))
              ) {
                return -1;
              } else return 0;
            });
              
          dispatch(fetchGroupsSuccess(groups));
        })
        .catch((error) => {
          dispatch(fetchGroupsFailure(error))
        });
      }
    }
}


export function updateGroupConfiguration (groupViewInfo,groupValue,successMsg) {
  return function (dispatch){
    dispatch(updateGroupStart());

    if (sessionStorage.getItem("userToken") && groupValue) {
      
      superagent.post(sessionStorage.getItem("url") + "/api/dash/setGroupConfiguration")
     .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
     .accept("application/json")
     .send(groupValue)
     .then(response => response)
     .then(data => {
       if(200===data.status){
        successMsg = successMsg ? successMsg: groupValue.groupName+" is updated to "+groupValue.groupStreamType;
         window.flash(successMsg, 'success');
         dispatch(updateGroupsAction(groupViewInfo,groupValue))
       }
     }).catch(error => {
       let msg = null;
       if(undefined===error.status){
         msg = "May be internet is not connected, try again sometime";
       }else if(400===error.status){
         msg = "Data not updated";
       }else if(401===error.status){
         msg = "Session token experied, please re-login";
       }else if(403===error.status){
         msg = "You are not authorized";
       }else if(404===error.status){
         msg = "Not able to request server";
       }else if(422===error.status){
         msg = "Invalid input value";
       }else if(500===error.status){
         msg = "Something went wrong, try again sometime";
       }
         window.flash(msg, 'error');
     });
   }
  }
}
  


