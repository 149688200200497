import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreVert';
import SelectAutoWidth from '../Utils/DropdownStandard';
import Autocomplete from '@mui/material/Autocomplete';//TP-749
import TextField from '@mui/material/TextField';//TP-749
import DirectionIcon from '../Utils/DirectionIcon';
import {DownloadGroupReport} from './downloadGroupReport';
import { Grid } from '@mui/material';
import { styled, alpha, createTheme, ThemeProvider } from "@mui/material/styles"; //TP-5488

const darkTheme = createTheme({
    palette: {
      primary: {
        main: '#384571',
      },
    },
    typography: {
        fontFamily: "Titillium Web",
    },
    overrides: {
      MuiList: {
        root:{
        width: 'fit-content'
        }
      }
    }
  });
const ShowOnlineCheckbox = (props) => {
    return( <div id="show-online-container">
      <label className="col-xs-3 non-pointer" htmlFor={props.id}>{props.label}&nbsp;</label>
      <label className="col-xs-4 col-xs-offset-2 switch">
        <input type="checkbox" id={props.id}
        name={props.id}
        onChange={props.toggleGroup}
        checked={props.value}/>
        <span className="slider round"></span>
      </label>
    </div>
    )
}
export default function SearchGroupAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      PaperProps={{  
          style: {  
            width: 400,  
          },  
      }}
    >
      { props.gridViewContainer ?
        <MenuItem sx={{ display: {xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
                    <div title="Switch to Tabular View" onClick={()=>props.toggleContainer()}>
                        <i className="fa fa-table switch-icons" style={{color: 'black'}}></i>
                    </div>
        </MenuItem>
                    :
        <MenuItem sx={{ display: {xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
                    <div title="Switch to Card View" onClick={()=>props.toggleContainer()}> 
                        <i className="fa fa-id-card switch-icons" style={{color: 'black'}}></i>
                    </div>
        </MenuItem>
                }
      {props.isAdmin &&
        <MenuItem sx={{ display: {xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
          <DownloadGroupReport propData={props.groups} iconSize={25} 
            viewType={"cards"} location="top-bar" color={"black"}/>               
        </MenuItem>
      }
      <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
        <ShowOnlineCheckbox label="Show Online Groups" {...props.showOnlineGroup} toggleGroup={props.toggleGroup}/>
      </MenuItem>
      { props.gridViewContainer && 
        <>
          <MenuItem sx={{ display: {xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'none'}, '&:hover': {backgroundColor: 'transparent', cursor: 'default'}}}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={10} md={10}>            
                <SelectAutoWidth  
                    //sortFunction={(field, direction)=>props.customSortCards(field, direction)} 
                    setSortColumn={props.setSortColumn} 
                    columns={props.sortcolumns} labelText="Sort By"
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <DirectionIcon  
                    setSortDir={props.setSortDir}
                />
              </Grid>
            </Grid>
          </MenuItem>
        </>

      }
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 1, /* ml: 1, mr: 1 */}}>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static" color="primary" sx={{borderRadius: "8px" }}>
            <Toolbar>        
            <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ flexGrow: 0.1, display: { xs: 'block', sm: 'block' },  paddingTop: "6px" }}
            >
                {/* { props.gridViewContainer ?
                    <div title="Switch to Tabular View" onClick={()=>props.toggleContainer()}>
                        <i className="fa fa-table switch-icons" style={{color: 'white'}}></i>
                    </div>
                    :
                    <div title="Switch to Card View" onClick={()=>props.toggleContainer()}> 
                        <i className="fa fa-id-card switch-icons" style={{color: 'white'}}></i>
                    </div>
                } */}
            </Typography> 
            {/* <Typography 
                  sx={{ flexGrow: 0.25, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }, height: "20px" }}
                  component="div"
              ></Typography>  */}  
            {/* <Typography 
                  sx={{ flexGrow: 0.25, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' }, height: "20px" }}
                  component="div"
              ></Typography> */}        
            <Typography 
                    sx={{ flexGrow: {xs: 0.5, sm: 5, md: 0.1, lg: 0.25, xl: 0.25}, display: { xs: 'block', sm: 'block' }, marginLeft:{ xs: "-630px", sm: "-611px", md: "-200px", xl: "20px"},  align:"center" }}
                    component="div"
            >
                <Autocomplete
                    id="globalGroupHuntId"
                    options={props.globalUniqueData}
                    onChange={(e, data) => props.searchGroup(e, data)}
                    getOptionLabel={props.getOptionLabel}
                    className="globalGroupHuntCls"
                    renderInput={params => (
                        <TextField {...params} placeholder="Search Groups"/>
                    )} />                    
            </Typography>
            <Typography sx={{ flexGrow: 0.1, display: { xs: 'none', sm: 'block' }, marginLeft: "5px" }}
                    component="div">
                {props.isAdmin &&
                    <DownloadGroupReport propData={props.groups} iconSize={25} 
                        viewType={"cards"} location="top-bar" /> 
                }
            </Typography>
            <Typography 
                sx={{ flexGrow: 0.25, display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' }, paddingTop: "9px"}}
                component="div"
                >
                <ShowOnlineCheckbox label="Show Online Groups" {...props.showOnlineGroup} toggleGroup={props.toggleGroup}/>
            </Typography>
            { props.gridViewContainer ?
                <>
                    <Typography 
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' }, marginLeft: "5px", marginBottom: "12px" }}
                        component="div"
                    >
                        <SelectAutoWidth  
                            //sortFunction={(field, direction)=>props.customSortCards(field, direction)} 
                            setSortColumn={props.setSortColumn} 
                            columns={props.sortcolumns} labelText="Sort By"
                        />
                    </Typography>
                    <Typography 
                        sx={{ flexGrow: 0.1, display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' }/* , paddingTop: "3px" */}}
                        component="div"
                    >
                        <DirectionIcon  
                            setSortDir={props.setSortDir}
                        />
                    </Typography>                
                </>
                :
                ''
            }
            <Box sx={{ display: props.gridViewContainer ? { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex', xl: 'none' } : { xs: 'flex', md: 'flex', lg: 'none', xl: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
            </Box>
            </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </ThemeProvider>
    </Box>
  );
}