import { InviteActionTypes } from "./InviteType";

const INITIAL_STATE = {
  invites: [],
  isFetching: false,
  errorMessage: undefined,
};

const InviteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InviteActionTypes.FETCH_INVITES_START:
      return {
        ...state,
        isFetching: true
      };
    case InviteActionTypes.FETCH_INVITES_SUCCESS:
      return {
        ...state,
        invites: action.payload,
        isFetching: false
      };
    case InviteActionTypes.FETCH_INVITES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export default InviteReducer;