import React, { Component } from 'react';

import { OTSubscriber } from 'opentok-react';
import "./tokbox.css";

export default class Subscriber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      audio: true,
      video: true
    };
    this.subscriberEventHandlers = {
      videoDisabled: event => {
        console.log('Subscriber video disabled!');
      },
      videoEnabled: event => {
        console.log('Subscriber video enabled!');
      },
      disconnected: function() {
        console.log('Subscriber disconnected!');
      },
      connected: ()=>{
        //subscriber is connected
        this.props.onSubscribe();
      },
      destroyed: e => {
        //subscriber is destroyed
        this.props.onUnSubscribe();
      },
      audioBlocked: function(event) {
       console.log("Subscriber audio is blocked.")
      },
      audioUnblocked: function(event) {
       console.log("Subscriber audio is unblocked.")
     },
     videoDimensionsChanged: event => {
       console.log('Subscriber video dimensions changed!');
     },
     videoElementCreated: () =>{
       console.log('Subscriber video Element Created!');
     },
     videoDisableWarning: () =>{
       console.log('Subscriber video Disable Warning!');
     },
     videoDisableWarningLifted: () =>{
       console.log('Subscriber video Disable Warning Lifted!');
     }
    };
  }

  setAudio = (audio) => {
    this.setState({ audio });
  }

  setVideo = (video) => {
    this.setState({ video });
  }

  onError = (err) => {
    this.setState({ error: `Failed to subscribe: ${err.message}` });
  }

  render() {
    return (
      <div>
        <OTSubscriber
          properties={{
            subscribeToAudio: this.state.audio,
            subscribeToVideo: this.state.video,
            width:'60vw',
            height:'70vh'
          }}
          eventHandlers={this.subscriberEventHandlers}
          onError={this.onError}
          retry={true}
          maxRetryAttempts={3}
          retryAttemptTimeout={2000}
        />
        {this.state.error}
      </div>
    );
  }
}
