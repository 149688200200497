import * as React from 'react';
export const Dropdown = ({ value, options, onChange }) => {
    return (
        <select value={value} onChange={onChange}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))}
        </select>
    );
  };
  