import React from 'react'
//TP-749
// TP-6811
export default function GroupOnlineIndicator({keyIndex, isCardView}) {
    return (
        <div className="online-ring-container d-inline" key={keyIndex}>
            <div className="ringring" style={isCardView ? {top: "13px", left:"5px"} : {top: "6px", left:"5px"}}></div>
            <div className="Online-circle" id={"indicator"+keyIndex} style={isCardView ? {top: "17px"} : {top: "10px"}}></div>
        </div>    
    )
}
