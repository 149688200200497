import React from "react";
import {Modal,Button} from 'react-bootstrap';
import superagent from "superagent";
import {connect} from 'react-redux';
import {updateUserProfile} from './redux/Customer/CustomerActions';
import {updateGroupsAction} from './redux/Group/GroupActions';

function isEditMode(){
  var flag = sessionStorage.getItem("userDbRole");
  if(flag==='write_pro' || flag==='write_ent'
      || flag==='write_all' || flag==='admin' || flag==='write_my_account'){
        return true;
  }else{
    return false;
  }
}
/*function isEditProMode(){
  var flag = sessionStorage.getItem("userDbRole");
  if(flag==='write_pro' || flag==='write_all' || flag==='admin'){
        return true;
  }else{
    return false;
  }
}*/
function isEditEntMode(){
  var flag = sessionStorage.getItem("userDbRole");
  if(flag==='write_ent' || flag==='write_all' || flag==='admin' || flag==='write_my_account'){
        return true;
  }else{
    return false;
  }
}
function showLoader(isLoading){
    if (!isLoading) return "";
    else return (<i className="fa fa-spinner fa-spin"></i>)
}

 class Edit extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      isEditable:false,
      showModal:false,
      fieldValue:'',
      serverResponse:null,
      loading:false,
      success:false,
      errorMsg:null,
      isValueModified:true,
    }
  }

  inputChange = (e,id) => {
    let errorMsg = null;
    if("number"===e.target.type){
      if(id !== 'available-minutes' && !e.target.value.match(new RegExp("^([0-9]|[1-9][0-9]|[1-9][0-9][0-9])$"))){
        return;
      }
    }else
    if("text"===e.target.type){
      if("Location Hint"===this.props.fieldName){
        //check for IP address
        if(!e.target.value.match(new RegExp("^([0-9. ]+)$")))
          return;
        if(!e.target.value.match(new RegExp("^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"))){
          errorMsg="Invalid IP Address";
        }
      }
    }
    
    
    if(e.target.value){
      this.setState({
        fieldValue: e.target.value,
        serverResponse:null,
        errorMsg:errorMsg
      });
    }
    
  }

  checkedChange = (e) => {
    this.setState({
      fieldValue: e.target.checked?"Yes":"No",
      serverResponse:null
    });
  }

  statusChange = (e) => {
    this.setState({
      fieldValue: e.target.checked?"Online":"Offline",
      serverResponse:null
    });
  }

  close = () => {
    if(this.props.fieldName === 'Email'){
      this.setState({showModal: false,fieldValue:this.props.fieldValue})
    }else{
      this.setState({ showModal: false});
    }
  }

  open = () => {
    this.setState({ showModal: true, serverResponse:null,errorMsg:null});
  }

  inputEmailChange = (e) =>{
      this.setState({fieldValue: e.target.value},() => this.checkEmailExists(e));    
  }

  save = (e) => {
  //TO CALL SAVE API CALL
  // Temporary Code start here
  //  if((("true" === sessionStorage.isSmartVision || sessionStorage.url.indexOf('smartvision') >-1) && ("Log Level"===this.props.fieldName || "Display Speed"===this.props.fieldName || "Status"===this.props.fieldName))){
  //   this.setState({serverResponse:"You cannot perform this action",success:true});
  //   return;
  //  }
  // Temporary code end here

    let apiUrl = null;
    let params = null;
    let dataParams = this.props.params;
    if("Codec Type"===this.props.fieldName){
      params={
        group_id: dataParams.props.viewDetails.group_id,
        codec_type: this.state.fieldValue,// VP8/H.264/mixed
        location_hint: dataParams.props.viewDetails.location_hint,
        keyName:"codec_type"
      }
      apiUrl = "/api/dash/setGroupConfiguration";
    }else if("Log Level"===this.props.fieldName){
      //console.log(sessionStorage.getItem("userBehaviour"));
        params={
          user_id: dataParams.props.viewDetails.id,
          log_level: +this.state.fieldValue,// 0/1/2
          keyName :"log_level"
        }
      apiUrl = "/api/dash/setUserConfiguration";
    }else if("Display Speed"===this.props.fieldName){
        params={
          user_id: dataParams.props.viewDetails.id,
          showuploadspeed: "Yes"===this.state.fieldValue,//true/false
          keyName :"showuploadspeed"
        }
      apiUrl = "/api/dash/setUserConfiguration";
    }else if("Location Hint"===this.props.fieldName){
      params={
        group_id: dataParams.props.viewDetails.group_id,
        codec_type: dataParams.props.viewDetails.codec_type,// VP8/H.264/mixed
        location_hint: this.state.fieldValue.trim(),// 122.171.80.213
        keyName :"location_hint" 
      }
      apiUrl = "/api/dash/setGroupConfiguration";
    }else if("Status"===this.props.fieldName){
       params={
          user_id: dataParams.props.viewDetails.id,
          is_loggedin: "Online"===this.state.fieldValue,
          keyName:"is_loggedin"
      }
      apiUrl = "/api/dash/setUserConfiguration";
    }else if("Additional minutes"===this.props.fieldName){
      
        params={
          user_id: dataParams.props.viewDetails.id,
          available_monthly_minutes: +this.state.fieldValue, // 0/1/2
          credit_id:dataParams.props.viewDetails.credit_id,
          keyName:"available_monthly_minutes"
        }
      apiUrl = "/api/dash/updateMonthlyMinutesOfUser";
    }else if("Email"===this.props.fieldName){
      //console.log(sessionStorage.getItem("userBehaviour"));
        params={
          email: dataParams.props.viewDetails.email,
          new_email: this.state.fieldValue,
          keyName:"email"
        }
      apiUrl = "/api/dash/setUserConfiguration";
    }

    // condiation added to route the api call based on field name
    if("Additional minutes"===this.props.fieldName){
      this.serverPutRequest(params,apiUrl);
    }else if("Email"===this.props.fieldName){
      if(!this.state.errorMsg && params.new_email !== params.email)
        this.serverPostRequest(params,apiUrl)
    }else{
      this.serverPostRequest(params,apiUrl);
    }

    
  }

  serverPostRequest = async(params, apiUrl, msg) =>{
    if (null!==sessionStorage.getItem("userToken")
        && null!==params && null!==apiUrl) {
        this.setState({isLoading:true});
         await superagent.post(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .send(params)
        .then(response => response) 
        .then(data => {
          if(200===data.status){
            msg = "Data updated successfully";
            this.setState({ serverResponse:msg, isLoading:false,success:true });
            const  isGroupActionCall = apiUrl.substring(apiUrl.lastIndexOf("/")).includes("Group");
            if(isGroupActionCall){
              if(this.props.updateGroupsAction)
                this.props.updateGroupsAction(this.props.params.props.viewDetails,params)
              if(this.props.parentCommunicator)
                this.props.parentCommunicator(params)
            }else{
              if(this.props.updateUserProfile)
                this.props.updateUserProfile(this.props.params.props.viewDetails,params)
              if(this.props.parentCommunicator)
                this.props.parentCommunicator(params)
            }
                        
          }
        }).catch(error => {
          if(undefined===error.status){
            msg = "May be internet is not connected, try again sometime";
          }else if(400===error.status){
            msg = "Data not updated";
          }else if(401===error.status){
            msg = "Session token experied, please re-login";
          }else if(403===error.status){
            msg = "You are not authorized";
          }else if(404===error.status){
            msg = "Not able to request server";
          }else if(422===error.status){
            msg = "Invalid input value";
          }else if(500===error.status){
            msg = "Something went wrong, try again sometime";
          }
          this.setState({ serverResponse:msg, isLoading:false, success:false });
        });
    }else{
        this.close();
    }
  }

  componentDidMount(){
    this.setState({fieldValue: this.props.fieldValue});
    if("Codec Type"===this.props.fieldName){
      this.setState({isEditable:isEditEntMode()});
    }else if("Additional minutes"===this.props.fieldName){
      this.setState({fieldValue:1});
      this.setState({isEditable:isEditMode()});
    }else if("Email"===this.props.fieldName){
      this.setState({isValueModified:false,isEditable:isEditMode()});
    }else{
      this.setState({isEditable:isEditMode()});
    }
  }


  serverPutRequest = async(params, apiUrl, msg) =>{
    if (null!==sessionStorage.getItem("userToken")
        && null!==params && null!==apiUrl) {
        this.setState({isLoading:true});
         await superagent.put(sessionStorage.getItem("url") + apiUrl)
        .set("Authorization", "Bearer " + sessionStorage.getItem("userToken"))
        .accept("application/json")
        .send(params)
        .then(response => response)
        .then(data => {
          if(200===data.status){
            msg = "Data updated successfully";
            this.setState({ serverResponse:msg, isLoading:false,success:true });
            const  isGroupActionCall = apiUrl.substring(apiUrl.lastIndexOf("/")).includes("Group");
            if(isGroupActionCall){
              if(this.props.updateGroupsAction)
                this.props.updateGroupsAction(this.props.params.props.viewDetails,params)
              if(this.props.parentCommunicator)
                this.props.parentCommunicator(params)
            }else{
              if(this.props.updateUserProfile)
                this.props.updateUserProfile(this.props.params.props.viewDetails,params)
              if(this.props.parentCommunicator)
                this.props.parentCommunicator(params)
              
            }
             
          }
        }).catch(error => {
          if(undefined===error.status){
            msg = "May be internet is not connected, try again sometime";
          }else if(400===error.status){
            msg = "Data not updated";
          }else if(401===error.status){
            msg = "Session token experied, please re-login";
          }else if(403===error.status){
            msg = "You are not authorized";
          }else if(404===error.status){
            msg = "Not able to request server";
          }else if(422===error.status){
            msg = "Invalid input value";
          }else if(500===error.status){
            msg = "Something went wrong, try again sometime";
          }
          this.setState({ serverResponse:msg, isLoading:false, success:false });
        });
    }else{
        this.close();
    }
  }

  checkEmailExists = () => {

    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if(this.state.fieldValue && this.state.fieldValue.match(regexEmail)){
      superagent.get(sessionStorage.getItem("url")+`/api/checkEmailExists/${this.state.fieldValue}?type=standard`)
      .set("Authorization", "Bearer " + sessionStorage.getItem("userToken")) //TP-4416
      .then(response => response)
      .then(data =>{
        if(data.body){
          this.setState({errorMsg:"This Email is already in use"})
        }else{
          this.setState({errorMsg:null,isValueModified:true})
        }
      })
    }else{
      this.setState({errorMsg:"This Email is not valid"})
    } 
  }

  render() {
    let fieldDiv = <input type="text" value={this.state.fieldValue} onChange={e=>this.inputChange(e)} id={this.state.fieldName}/>;
    if("Address"===this.props.fieldName){
      fieldDiv = <textarea value={this.state.fieldValue}
                          onChange={e=>this.inputChange(e)}
                          id={this.state.fieldName}/>;
    }else if("Codec Type"===this.props.fieldName){
      fieldDiv = <select value={this.state.fieldValue} onChange={e=>this.inputChange(e)}>
        <option value="VP8">VP8</option>
        <option value="H.264">H.264</option>
      </select>;
    }else if("Log Level"===this.props.fieldName){
      fieldDiv = <input type="number" min="0" max="999"
                        value={this.state.fieldValue}
                        onChange={e=>this.inputChange(e)}
                        id={this.state.fieldName}/>;
    }else if("Display Speed"===this.props.fieldName){
      fieldDiv = <>
          <label className="col-xs-4 col-xs-offset-2 switch">
            <input type="checkbox" onChange={e=>this.checkedChange(e)}
            checked={"Yes"===this.state.fieldValue}/>
            <span className="slider round"></span>
          </label>&nbsp;{this.state.fieldValue}
        </>;
    }else if("Status"===this.props.fieldName){
      fieldDiv = <>
          <label className="col-xs-4 col-xs-offset-2 switch">
            <input type="checkbox" onChange={e=>this.statusChange(e)}
            checked={"Online"===this.state.fieldValue}/>
            <span className="slider round"></span>
          </label>&nbsp;{this.state.fieldValue}
        </>;
    }else if("Additional minutes"===this.props.fieldName){
      fieldDiv = <input type="number" min="1" max="999999999"
                        value={this.state.fieldValue}
                        onChange={e=>this.inputChange(e,'available-minutes')}
                        id={this.state.fieldName}/>;
    }else if("Email"=== this.props.fieldName){
      fieldDiv = <input type="email" style={{width:'400px'}} onChange={e=>this.inputEmailChange(e,'email')} value={this.state.fieldValue} id={this.state.fieldName}/>;

    }
    return (
      <span>
        {
          this.state.isEditable
          ?<span onClick={this.open} className="editLink" id="editField">
            { null !== this.props.fieldValue  ? this.props.fieldValue : this.state.fieldValue}
          </span>
          :this.props.fieldValue
        }
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Update Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <b>{this.props.fieldName}:&nbsp;</b>
                <span>{fieldDiv}</span>
              </div>
              <div className="row">
                <small className="text-danger col-12">{this.state.errorMsg}</small>
                {/*TP-1342*/}
                {/* <details>  
                  <summary className="small"><i className="fa fa-info"/>&nbsp;Note</summary>
                  <i className="small">After successful data save, click refresh from homepage to see the changes</i>
                </details> */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container-fluid">
              <div className="row">
              {
                null!==this.state.serverResponse&&
                <div className="col-sm-12 mb-1">
                  <div
                  className={this.state.success?"alert text-center alert-success":"alert text-center alert-danger"}>
                    {this.state.serverResponse}
                  </div>
                </div>
              }
              <Button onClick={e=>this.save(e)} className={!this.state.isValueModified ? "d-block m-auto btn-gray":this.state.errorMsg===null ? "d-block m-auto btn-green":"d-block m-auto btn-gray"}
                disabled={null!==this.state.errorMsg || !this.state.isValueModified}>
                {showLoader(this.state.isLoading) }Save
              </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </span>
    );
  }
}

const mapStateToProps = dispatch =>({
  updateUserProfile: (accountParam,userParam) => dispatch(updateUserProfile(accountParam,userParam)),
  updateGroupsAction:(groupParam,groupValue)=> dispatch(updateGroupsAction(groupParam,groupValue))
});

export default connect(null,mapStateToProps)(Edit)