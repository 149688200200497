import React from 'react';
import moment from "moment";
import superagent from "superagent";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import MultiSelect from "../MultiSelect/multiSelect";
import Select from 'react-select';
import {isSameDateAs} from '../Utils/dateUtils'

const userLogsColumn = [
  {name:"Id",selector:"id",sortable:true, maxWidth:"60px"},
  {name:"Created On",selector:"created_on",sortable:true, maxWidth:"120px"},
  {name:"Details",selector:"track", maxWidth:"70vw"}
]
//{/*TP-809*/}
const SearchInputDiv = (props) => {
  return(
    <div id="input-group-logs" className="input-group">
    <input type="text" className="form-control" id={props.id ? props.id: "search-input" } onKeyPress={(e)=>props.handleKeyDown(e)} onBlur={props.handleOnChage} placeholder={props.placeHolder} style={{borderColor: !props.validValue ? '#F43C33':''}} />
    <div className="input-group-append">
      <button className="btn btn-secondary" type="button" onClick={props.onClickFunc}>
        <i className="fa fa-search"></i>
      </button>
    </div>
  </div>
  )
}



export default class Diagnostic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userLogsList:[],
      start_date: new Date().setDate(new Date().getDate()-1),
      end_date: new Date(),
      globalHunt:[],
      searchLabel:[],
      selectedOptions:[],
      requestType:this.props.requestType ? this.props.requestType: "userId",
      isValidEmail:true,
      emailValue:null,
      noDataMsg:"Search diagnostic logs by email within selected dates",
      selectOptions:[],
      resetPaginationToggle:false,
      selectedPageNumber:1
    };
  }

  startDateChange = date => {
      this.handleClear();
      this.setState({start_date: date},()=> this.searchlogs());
      this.handleClear();
  };

  endDateChange = date => {
    this.handleClear();
    this.setState({end_date: date},() =>  this.searchlogs());
    this.handleClear();
  };

  componentDidMount(){
    if(this.state.requestType === "userId"){
      this.searchlogs();
    }

  }
  componentWillReceiveProps = ({tabIndex})=>{
    if(tabIndex === "3" && this.state.requestType === "userId"){
      this.searchlogs();
    }
  }

  copyToClipboard = (str) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  searchlogs = () =>{
    let params = {
      user_id: this.props.viewDetails.id,
      start_date: moment(this.state.start_date).toISOString(true),
      end_date: moment(this.state.end_date).toISOString(true),
    }
    this.getDiagonosticDetails(params);
  }

  getDiagonosticDetails = async (params, url) =>{
    url = "/api/dash/getUserLogData";
    
    if((params.user_id || params.email) && null != sessionStorage.userToken) {
      this.setState({isLoading: true});
      await superagent.post(sessionStorage.url+ url)
        .set("Authorization", "Bearer " + sessionStorage.userToken)
        .type("application/json")
        .send(params)
        .accept("application/json")
        .then(response => response)
        .then(data => {
          if (null !== data.body) {
            if(this.state.requestType !=="userId" && data.body.length === 0 && params.daysDiffer && params.daysDiffer !== 30){
              let daysDifferValue = params.daysDiffer === 1 ? 7 : 30; 
              let newStartDate = this.reduceDate(new Date(),daysDifferValue);
               this.searchLogsByEmail(newStartDate,this.state.end_date,daysDifferValue);
            }else if(this.state.requestType ==="userId"){
              this.setState({userLogsList:this.showRawJson(data.body)}, ()=> this.feedGlobalHunt());
              this.setState({isLoading: false,noDataMsg:"No diagnostic logs found within selected dates"});
            }else{
              this.setState({userLogsList:this.showRawJson(data.body),emailValue:params.email ,start_date:new Date(params.start_date)}, ()=> this.feedGlobalHunt());
              this.setState({isLoading: false,start_date:new Date(params.start_date),noDataMsg:"No usage log found for this email in last one month"});
            }
            
          }
          
        }
      ).catch(error => {
        let msg=null;
        if(undefined===error.status){
          msg = "Internet is not connected, please try again after connecting network";
        }else if(400===error.status){
          msg = "Not updated";
        }else if(401===error.status){
          msg = "Session token experied, Please re-login";
        }else if(403===error.status){
          msg = "You are not authorized";
        }else if(422===error.status){
          msg = "Invalid parameters sent";
        }else if(500===error.status){
          msg = "Something went wrong, try again later";
        }else{
          console.error(error);
        }
        this.setState({isLoading: false,serverError:msg});
      });
    }
  }

  showRawJson = (logs) => {
    let logList = [];
    if(logs.length>0){
      logs.forEach(log=>{
        let track = log.track_data;
        if(track.data)
        track.data = this.returnStringfy(track.data);
        log.track = JSON.stringify(track, undefined, 4);
        logList.push(log);
        
      });
  
      logList.sort( (log1, log2) => {
        if(moment(log2.created_on).isAfter(moment(log1.created_on))){
          return 1;
        }else if(moment(log1.created_on).isAfter(moment(log2.created_on))){
          return -1;
        }else return 0;
      });
  
      if(this.props.viewDetails && logList[0].track_data.action==="login" && moment(this.props.viewDetails.last_accessed_on).diff(moment(logList[0].created_on))){
        console.log("User last login time : "+moment(this.props.viewDetails.last_accessed_on).format("DD MMM,YYYY HH:mm:ss.s"));
        console.log("User latest logs login time : "+moment(logList[0].created_on).format("DD MMM,YYYY HH:mm:ss.s"));
        var day = moment(this.props.viewDetails.last_accessed_on).diff(moment(logList[0].created_on), 'minutes');
        console.log(day+" Minutes of difference");
      }
    }
    return logList;
  }

  setUserLogData = (data) => {
    let logData = [];
    data.forEach(param=>{
      let trackData = param.track_data;
      let userInfo = trackData.data;
      if(typeof userInfo ==="string"){
        userInfo = JSON.parse(trackData.data)
      }
      param.ip = userInfo.ip;
      param.os = undefined===userInfo.os_name?"NA":(userInfo.os_name + " "+(undefined===userInfo.os_version?"":userInfo.os_version));
      param.browser = undefined===userInfo.browser_name||"NA"===userInfo.browser_name?"NA":(userInfo.browser_name+" "+userInfo.browser_version.substring(0,5));
      param.device = undefined===userInfo.device_manufacturer?"NA":(userInfo.device_manufacturer + " " + userInfo.device_model);
      param.coordinates = userInfo.coordinates;
      param.apk = undefined===userInfo.apk_version?"NA":userInfo.apk_version;
      param.kernel = undefined===userInfo.kernel_version?"NA":userInfo.kernel_version;
      param.serialno = undefined===userInfo.serial_number?"NA":userInfo.serial_number;
      param.track_action = trackData.action;
      logData.push(param);
    })
    logData.sort( (log1, log2) => {
      if(moment(log2.created_on).isAfter(moment(log1.created_on))){
        return 1;
      }else if(moment(log1.created_on).isAfter(moment(log2.created_on))){
        return -1;
      }else return 0;
    });
    if(logData[0].track_action==="login" && moment(this.props.viewDetails.last_accessed_on).diff(moment(logData[0].created_on))){
      console.log("User last login time : "+moment(this.props.viewDetails.last_accessed_on).format("DD MMM,YYYY HH:mm:ss.s"));
      console.log("User latest logs login time : "+moment(logData[0].created_on).format("DD MMM,YYYY HH:mm:ss.s"));
      var day = moment(this.props.viewDetails.last_accessed_on).diff(moment(logData[0].created_on), 'minutes');
      console.log(day+" Minutes of difference");
    }
    return logData;
  }

  feedGlobalHunt = () => {
    this.setState(({globalHunt:[],selectOptions:[]}),
    ()=>{
    let searchParams = this.state.globalHunt;
    let arrayUniqueByKey = this.state.globalHunt;
    let selectOptions =  this.state.selectOptions;
    this.state.userLogsList.forEach((log, i) => {
      if(log.track_data){
        searchParams.push({...log, huntLabel : log.track_data.action,huntBy:'action'});
      }
      // if(log.email){ //TP-853
      //   searchParams.push({...log, huntLabel : log.email.split('@')[0],huntBy:'email'});
      // }
    }); 

    if(searchParams){
      arrayUniqueByKey = [...new Map(searchParams.map(item =>[item['huntLabel'], item])).values()];
      //searchParams = [...new Map(searchParams.map(item =>[item['id'],item])).values()];
    }
    //TP-880
    // let defaultObj =  {value:0,label:'--- Select ---'};
    // selectOptions =  arrayUniqueByKey.map((data)=>({"value":data.id,"label":data.huntLabel}));
    // selectOptions.unshift(defaultObj);

    this.setState({globalHunt: searchParams,searchLabel:arrayUniqueByKey,selectOptions:selectOptions});
  });
    
  }

  search = (paramArray) => {
    
    const {globalHunt} = this.state;
    let finalResult = [];

      if(Array.isArray(paramArray) && paramArray.length){
      paramArray.forEach(function (paramData){
        let huntlabel =  paramData.huntLabel  ? paramData.huntLabel : undefined;
        if(huntlabel && globalHunt && finalResult.length === 0){
          finalResult = globalHunt.filter(log=>huntlabel === log.huntLabel && log.id);
        }else if(huntlabel && globalHunt && finalResult.length){
          let tempResult = []; 
          tempResult = globalHunt.filter(log=>huntlabel === log.huntLabel && log.id);
          finalResult.push(...tempResult);
        }else{
          finalResult = this.state.globalHunt;
        }
      });
    }
    finalResult = [...new Map(finalResult.map(item =>[item['id'],item])).values()];  // to get unique value from final result
    let infoPicked = finalResult.length > 0 ? finalResult: [...new Map(globalHunt.map(item =>[item['id'],item])).values()];
    this.setState({userLogsList: infoPicked});
   
  }

  returnStringfy(text) {
    if (typeof text === "string") {
        return text;
    }
    try {
       let value =  JSON.stringify(text);
        return value;
    } catch (error) {
        return text;
    }
}

setSelectedOptions = option => {

  if(this.state.selectedOptions && this.state.selectedOptions.length === 0){
    this.setState({selectedOptions:[...this.state.selectedOptions,...option]},()=> this.search(option)); 
  }else{
    this.setState({selectedOptions: option},()=> this.search(option));
    //this.setState({selectedOptions:[this.state.selectedOptions,option]},()=> this.search(option));
  }
  //added handleClear twice to handle to Reset Pagination to 1
  if(option && option.length === 0){
    this.handleClear();
    this.handleClear();
  }
}

getOptionLabel =  option =>  option.huntLabel;  

handleToggleOption = (selectedOptions,reason) => {
  
  if(selectedOptions  && (reason === "select-option" || reason === "remove-option"))
      this.setSelectedOptions(selectedOptions);
  else if(selectedOptions && reason === "clear")
      this.setSelectedOptions(selectedOptions);
  
}

handleClearOptions = () => this.setState({selectedOptions:[]},()=> this.search());

handleColor = (time) => {return time.getHours() > 11.30 ? "text-success-time" : "text-error-time"};

//{/*TP-809*/}
searchLogsByEmail = (start_date,end_date,dayDiff) =>{
  let startDate =  moment(start_date).toISOString(true);
  let endDate = moment(end_date).toISOString(true);
  let params = {
    email: document.getElementById("search-input").value.trim(),
    start_date:startDate,
    end_date: endDate,
    daysDiffer:dayDiff
  }
    if((new Date(startDate).isSameDateAs(new Date(endDate)))){  
      // if start date and end date is same then set the hour to 0 that will make it 12 am on same date 
      let newStartDate = new Date(startDate).setHours(0,0,0,0)
      params.start_date = moment(newStartDate).toISOString(true);
      this.getDiagonosticDetails(params);
      this.setState({start_date:newStartDate});
    }else if(this.state.emailValue !== params.email){
      // if the previous email value is not equal to currenct searched email then reset as first search
      // reset the start and end date
      this.setState({start_date: new Date().setDate(new Date().getDate()-1),end_date: new Date()},
                    () => this.getDiagonosticDetails(params))}
    else{
      this.getDiagonosticDetails(params);
    } 
  
}

validateEmail = (event) =>{
  let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let emailAdress = event.target.value.trim();
  if(emailAdress){
      if (emailAdress.match(regexEmail)) {
        if(this.state.emailValue && emailAdress){
          this.handleClear();
          this.setState({isValidEmail:true},()=>this.searchLogsByEmail(new Date().setDate(new Date().getDate()-1),new Date(),1))
        }else{
          this.setState({isValidEmail:true},()=>this.searchLogsByEmail(this.state.start_date,this.state.end_date,1));
        } 
        return true;
      }else{
        this.setState({isValidEmail:false});
        window.flash('Please enter valid email address.','error');
        return false;
      }
  }else{
    this.setState({isValidEmail:true});
  }
}

startDateModify = date => {
  // Twice added handleClear to handle to Reset Pagination to 1
  this.handleClear();
  this.setState({start_date: date},() => this.searchLogsByEmail(this.state.start_date,this.state.end_date));
  this.handleClear();
};

endDateModify = date => {
   // Twice added handleClear to handle to Reset Pagination to 1
  this.handleClear();
  this.setState({end_date: date},() => this.searchLogsByEmail(this.state.start_date,this.state.end_date));
  this.handleClear();
};

reduceDate = (dt,numberOfDays) => {
      return dt.setDate(dt.getDate() - numberOfDays) && dt;
}
// //TP-880
// handleSelectChange = (paramValue) =>{
//   this.setState({selectedDropDownValue:paramValue.value})

//   const {globalHunt} = this.state;
//   let finalResult = [];
   
//   if(paramValue && paramValue.value && globalHunt.length){
//     let tempResult = []; 
//     tempResult = globalHunt.filter(log=>paramValue.label === log.huntLabel && log.id);
//     finalResult.push(...tempResult);
//   }else{
//     finalResult = this.state.globalHunt;
//   }

//   finalResult = [...new Map(finalResult.map(item =>[item['id'],item])).values()];  // to get unique value from final result
//   let infoPicked = finalResult.length > 0 ? finalResult: [...new Map(globalHunt.map(item =>[item['id'],item])).values()];
//   this.setState({userLogsList: infoPicked});
 
// }

getLogsByEmail = e => {
  if (e.target.value && e.key === 'Enter') {
      this.validateEmail(e);    
  }
}

handleClear = () => {
  const { resetPaginationToggle} = this.state;
  this.setState({  resetPaginationToggle: !resetPaginationToggle});
}

updatePageNumber(pageNum){
  this.setState({selectedPageNumber:pageNum})
}

  render() {
    userLogsColumn[1].cell=row=>null!==row.created_on?moment(row.created_on).format("DD MMM,YYYY HH:mm"):"NA";
    userLogsColumn[2].cell=row=><div className="hoverClipCopy-logs"><textarea disabled={true} className="cell-text-area" value={row.track}/><div className="tooltiptext small"><i className="fa fa-copy copySelection" id={"sessionid-"+row.id} onClick={(e) => this.copyToClipboard(row.track)}> Copy</i></div></div>;

      return (<>
      { this.state.requestType === "userId" ?
      <div>
        <div className="logs-row">
          <DatePicker id="start_date"
            placeholderText="start date"
            selected={this.state.start_date}
            dateFormat="dd/MM/yyyy hh:mm aa"
            showTimeSelect
            onChange={this.startDateChange}
            timeClassName={this.handleColor}
            />&nbsp;
          <DatePicker id="end_date"
            placeholderText="end date"
            dateFormat="dd/MM/yyyy hh:mm aa"
            showTimeSelect
            selected={this.state.end_date}
            onChange={this.endDateChange}
            timeClassName={this.handleColor}
            />&nbsp;
            
            <MultiSelect
              items={this.state.searchLabel}
              id="globalLogsHuntCls"
              className="globalLogsHuntCls"
              getOptionLabel={this.getOptionLabel}
              selectedValues={this.state.selectedOptions}
              placeholder="Search"
              onToggleOption={this.handleToggleOption}
              onClearOptions={this.handleClearOptions}
              muiInputClass="mui-logs-InputClass"
              />
            &nbsp;
         
        </div>
        <DataTable
          pagination={this.state.userLogsList.length>3}
          paginationPerPage={3}
          onChangePage={page=>this.updatePageNumber(page)}
          paginationDefaultPage={this.state.selectedPageNumber}
          columns={userLogsColumn}
          data={this.state.userLogsList}
          className="group-participants"
          noDataComponent="No diagnostic logs found within selected dates"
          progressPending={this.state.isLoading}
          progressComponent={<div><i className="fa fa-spinner fa-spin" style={{fontSize:"60px",color:"white"}}/></div>}
          persistTableHead
          paginationResetDefaultPage={this.state.resetPaginationToggle}
          onClear={this.handleClear}
          />
      </div> :
          <div>
          <div className="global-logs-row">
            <DatePicker id="global-start_date"
              placeholderText="start date"
              selected={this.state.start_date}
              dateFormat="dd/MM/yyyy hh:mm aa"
              showTimeSelect
              onChange={this.startDateModify}
              timeClassName={this.handleColor}
              />&nbsp;
            <DatePicker id="global-end_date"
              placeholderText="end date"
              dateFormat="dd/MM/yyyy hh:mm aa"
              showTimeSelect
              selected={this.state.end_date}
              onChange={this.endDateModify}
              timeClassName={this.handleColor}
              />&nbsp;
              
              <SearchInputDiv placeHolder={"Search By email..."}  onClickFunc={()=>this.searchLogsByEmail(this.state.start_date,this.state.end_date,1)}
              validValue = {this.state.isValidEmail} handleKeyDown={this.getLogsByEmail}  />
              &nbsp;
              
              {/* <Select value={this.state.selectOptions.length ? this.state.selectOptions.filter((op)=> op.value === this.state.selectedDropDownValue).pop():0} options={this.state.selectOptions} className="logs-select-cl" styles={selectStyles} 
              onChange={ event => this.handleSelectChange(event) } /> */}
              
               <MultiSelect
                items={this.state.searchLabel}
                id="logs-select-id"
                className="logs-select-cl"
                getOptionLabel={this.getOptionLabel}
                selectedValues={this.state.selectedOptions}
                placeholder="Search"
                onToggleOption={this.handleToggleOption}
                onClearOptions={this.handleClearOptions}
                muiInputClass="mui-logs-InputClass"
              /> 
            &nbsp;
          </div>
          <DataTable
            pagination={this.state.userLogsList.length>3}
            paginationPerPage={3}
            pageCount={3}
            columns={userLogsColumn}
            data={this.state.userLogsList}
            className="table-responsive table-container global-logs-info"
            noDataComponent={this.state.noDataMsg}
            progressPending={this.state.isLoading}
            progressComponent={<div><i className="fa fa-spinner fa-spin" style={{fontSize:"60px",color:"white"}}/></div>}
            persistTableHead
            paginationResetDefaultPage={this.state.resetPaginationToggle}
            onClear={this.handleClear}
            />
        </div>
      }
      </>
    );
  }
}


// const selectStyles = {
//   option: (provided, state) => ({
//     ...provided,
//     color: state.isSelected ? "blue" : "black",
//     fontSize: 16,
//     backgroundColor: state.isSelected ? "#eee" : "",
//     textAlign: "left",
//     cursor: "pointer"
//   }),
//   container: base => ({
//     ...base,
//   }),
//   control: base => ({
//     ...base,
//     height: 32,
//     minHeight: 32,
//     fontSize: 16,
//     borderRadius: 0,
//     width: "100%",
//     textAlign: "left",
//     cursor: "pointer"
//   }),
//   dropdownIndicator: base => ({
//     ...base,
//     display: "none"
//   }),
//   indicatorSeparator: base => ({
//     ...base,
//     display: "none"
//   }),
//   valueContainer: base => ({
//     ...base,
//     padding: 0,
//     paddingLeft: 2
//   })
// };